import { useInfiniteQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { LoadingIcon } from "components/Icons/Icons";
import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import { UserAvatarLink } from "components/UserAvatarLink/UserAvatarLink";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { useProjectId } from "hooks/Network/useProjectId";
import { useOnIntersection } from "hooks/useOnIntersection";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

const LIKES_LENGTH = 10;

type CommentLikesModal = ModalBaseProps & {
  messageId: string;
  commentId?: string;
  /** Likes gotten from already available data, so we don't show 0 likes during loading */
  initialLikes?: number;
};

export function CommentLikesModal({
  isOpened,
  onOpenChange,
  messageId,
  commentId,
  initialLikes,
}: CommentLikesModal): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();

  const {
    data: commentLikesData,
    isFetching: isLoadingCommentLikes,
    hasNextPage: hasMoreCommentLikes,
    isFetchingNextPage: isLoadingMoreCommentLikes,
    fetchNextPage: fetchMoreCommentLikes,
    error: commentLikesError,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.MESSAGES_COMMENT_LIKES(projectId, messageId, commentId!),
    queryFn: async ({ pageParam = 0 }) => {
      const { data } = await api.getMessagesCommentsLikesV1(messageId, commentId!, {
        Offset: pageParam * LIKES_LENGTH,
        Limit: LIKES_LENGTH,
      });

      return data;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
    enabled: !!commentId,
  });

  const commentLikes = useMemo(() => commentLikesData?.pages.flatMap((x) => x.items ?? []) ?? [], [commentLikesData]);
  const totalCommentLikes = commentLikesData?.pages[0].total ?? initialLikes ?? 0;

  const loaderRef = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingMoreCommentLikes && hasMoreCommentLikes) {
        void fetchMoreCommentLikes();
      }
    }, [fetchMoreCommentLikes, hasMoreCommentLikes, isLoadingMoreCommentLikes]),
  });

  if (!commentId) {
    return null;
  }

  return (
    <Modal.Root
      size="sm"
      title={t("component.community-post.comment.likes.modal.title")}
      description={t("component.community-post.comment.likes.modal.description-total", {
        count: totalCommentLikes,
      })}
      {...{ isOpened, onOpenChange }}
    >
      <div className="flex flex-col gap-4" data-testid="comment-likes-modal-content">
        {isLoadingCommentLikes ? (
          <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
        ) : (
          <>
            {(commentLikesError || !commentLikes.length) && (
              <span className="text-caption">{t("component.community-post.comment.likes.modal.no-data")}</span>
            )}
            {commentLikes.length > 0 && (
              <div className="max-h-64 overflow-y-auto">
                {commentLikes.map((commentLike) => (
                  <li
                    className="flex items-center gap-2 py-2"
                    key={`comment_${commentId}_like_${commentLike.author.id}`}
                  >
                    <div className="mr-2 size-8">
                      <UserAvatarLink user={commentLike.author} />
                    </div>
                    <UserNameLink user={commentLike.author}>
                      <span className="text-caption-bold text-black">{commentLike.author.fullName}</span>
                    </UserNameLink>
                  </li>
                ))}
                {hasMoreCommentLikes && (
                  <div className="p-4" ref={loaderRef}>
                    <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </Modal.Root>
  );
}
