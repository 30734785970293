import iconSearchMd from "assets/icons/search-md.svg";
import { Icon } from "components/Icon/Icon";
import type { InputProps } from "components/Input/Input";
import { Input } from "components/Input/Input";
import { forwardRef } from "react";

export interface SearchInputProps extends Omit<InputProps, "prefix" | "inputMode" | "type"> {}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  function SearchInput(props, ref): React.ReactNode {
    return (
      <Input ref={ref} prefix={<Icon name={iconSearchMd} size={16} />} inputMode="search" type="search" {...props} />
    );
  },
);
