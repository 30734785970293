import type { ConstraintListItemDto, ImageDto } from "api/types";
import iconCheck from "assets/icons/check.svg";
import iconUsersCheck from "assets/icons/users-check.svg";
import award from "assets/images/award.svg";
import { StaticButton } from "components/Button/StaticButton";
import { ToggleButton } from "components/Button/ToggleButton";
import { Card, CardActions, CardContent, CardDescription, CardHeader, CardImage } from "components/Card/Card";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useTranslation } from "react-i18next";

interface GroupCardProps {
  id: string;
  actions: ContextMenuAction[];
  image?: ImageDto;
  name: string;
  description: string;
  type: "regular" | "realEstate" | "projectConnection" | "system";
  detailLink?: string;
  isMember: boolean;
  isOwner: boolean;
  followButtonLabel: string;
  unfollowButtonLabel: string;
  onFollow: (id: string) => void;
  onUnfollow: (id: string) => void;
  groupAudience?: ConstraintListItemDto[];
  isGroupDisabled: boolean;
  isResidentGroup: boolean;
  hasAudience: boolean;
}

export function GroupCard({
  id,
  actions,
  image,
  name,
  description,
  type,
  detailLink,
  isMember,
  isOwner,
  followButtonLabel,
  unfollowButtonLabel,
  onFollow,
  onUnfollow,
  isGroupDisabled,
  isResidentGroup,
  hasAudience,
}: GroupCardProps): React.ReactNode {
  const { t } = useTranslation();
  const user = useSessionUser();

  const onClickToggleFollow = () => {
    if (isMember) onUnfollow(id);
    else onFollow(id);
  };

  return (
    <Card data-testid="group-list-item" link={detailLink} isFaded={isGroupDisabled}>
      <CardImage
        image={
          // eslint-disable-next-line no-nested-ternary
          image && !isResidentGroup ? image : user.project.backgroundImage ? user.project.backgroundImage : null
        }
        labels={[
          user.isAdmin && type !== "regular" && (
            <Label theme="gray">
              {type === "realEstate" && t("page.discovery.group-type.real-estate")}
              {type === "projectConnection" && t("page.discovery.group-type.project-connection")}
              {type === "system" && t("page.discovery.group-type.platform")}
            </Label>
          ),
          user.isAdmin && hasAudience && (
            <Label theme="blue">
              <Icon name={iconUsersCheck} />
            </Label>
          ),
        ].filter(Boolean)}
      />
      <CardContent>
        <CardHeader title={name} titleLink={detailLink} menuActions={actions} />
        <CardDescription description={description} />

        <CardActions>
          {isOwner && isResidentGroup ? (
            <StaticButton>
              <div className="flex items-center gap-1">
                <img className="size-4" src={award} alt="" />
                <span>{t("model.interest-group.owner")}</span>
              </div>
            </StaticButton>
          ) : (
            <ToggleButton
              className="w-full sm:w-auto sm:max-w-full"
              data-testid="group-action-follow-unfollow"
              config={{
                checked: {
                  label: unfollowButtonLabel,
                  icon: <Icon name={iconCheck} />,
                },
                unchecked: {
                  label: followButtonLabel,
                },
              }}
              isChecked={isMember}
              onClick={onClickToggleFollow}
            />
          )}
        </CardActions>
      </CardContent>
    </Card>
  );
}
