import type { EventCategoryDto } from "api/types";
import type { TFunction } from "i18next";

export function getEventCategoryName(t: TFunction, key: EventCategoryDto["id"] | "all"): string {
  switch (key) {
    case "all":
      return t("model.event.category.all");
    case "maintenance":
      return t("model.event.category.maintenance");
    case "social":
      return t("model.event.category.social");
    case "sport":
      return t("model.event.category.sport");
    case "unknown":
    default:
      return t("model.event.category.other");
  }
}
