import { useQueryClient } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import { signOut } from "supertokens-web-js/recipe/session";

export function useLogout(): (opts?: { keepUrl?: boolean }) => Promise<void> {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const postHog = usePostHog();

  return useCallback(
    async ({ keepUrl } = {}) => {
      await signOut();
      queryClient.clear();
      postHog?.reset(true);

      if (location.pathname !== routes.authentication.login()) {
        const currentUrl = location.pathname + location.search + location.hash;
        if (!keepUrl || !currentUrl || currentUrl === "/") {
          navigate(routes.authentication.login());
        } else {
          navigate(routes.authentication.login() + "?redirect=" + encodeURIComponent(currentUrl));
        }
      }
    },
    [navigate, postHog, queryClient],
  );
}
