import announcementIcon from "assets/icons/announcement-03.svg";
import bicycleIcon from "assets/icons/aop/bicycle.svg";
import carrotIcon from "assets/icons/aop/carrot.svg";
import coffeeIcon from "assets/icons/aop/coffee.svg";
import dogIcon from "assets/icons/aop/dog.svg";
import factoryIcon from "assets/icons/aop/factory.svg";
import forkAndKnifeIcon from "assets/icons/aop/fork-and-knife.svg";
import handshakeIcon from "assets/icons/aop/handshake.svg";
import mailboxIcon from "assets/icons/aop/mailbox.svg";
import networkIcon from "assets/icons/aop/network.svg";
import plantIcon from "assets/icons/aop/plant.svg";
import sportIcon from "assets/icons/aop/sport.svg";
import wineGlassIcon from "assets/icons/aop/wine-glass.svg";
import asteriskIcon from "assets/icons/asterisk-01.svg";
import batteryMidIcon from "assets/icons/battery-mid.svg";
import bellRingingIcon from "assets/icons/bell-ringing-04.svg";
import bookOpenIcon from "assets/icons/book-open-01.svg";
import building01Icon from "assets/icons/building-01.svg";
import building02Icon from "assets/icons/building-02.svg";
import carIcon from "assets/icons/car-01.svg";
import compassIcon from "assets/icons/compass-01.svg";
import cursorClickIcon from "assets/icons/cursor-click-02.svg";
import diceIcon from "assets/icons/dice-6.svg";
import faceHappyIcon from "assets/icons/face-happy.svg";
import gamingPadIcon from "assets/icons/gaming-pad-01.svg";
import giftIcon from "assets/icons/gift-02.svg";
import imageUserCheckIcon from "assets/icons/image-user-check.svg";
import lightBulbIcon from "assets/icons/lightbulb-04.svg";
import mapIcon from "assets/icons/map-01.svg";
import markerPinIcon from "assets/icons/marker-pin-06.svg";
import medicalSquareIcon from "assets/icons/medical-square.svg";
import messageChatSquareIcon from "assets/icons/message-chat-square.svg";
import messageDotsSquareIcon from "assets/icons/message-dots-square.svg";
import musicNoteIcon from "assets/icons/music-note-01.svg";
import packageIcon from "assets/icons/package.svg";
import searchIcon from "assets/icons/search-sm.svg";
import shoppingCartIcon from "assets/icons/shopping-cart-01.svg";
import targetIcon from "assets/icons/target-01.svg";
import toolIcon from "assets/icons/tool-02.svg";
import usersIcon from "assets/icons/users-03.svg";
import wifiIcon from "assets/icons/wifi.svg";
import { Icon } from "components/Icon/Icon";

export type GroupIconType =
  | "announcement"
  | "asterisk"
  | "batteryMid"
  | "bellRinging"
  | "bicycle"
  | "bookOpen"
  | "building01"
  | "building02"
  | "car"
  | "carrot"
  | "coffee"
  | "compass"
  | "cursorClick"
  | "dice"
  | "dog"
  | "faceHappy"
  | "factory"
  | "forkAndKnife"
  | "gamingPad"
  | "gift"
  | "handshake"
  | "imageUserCheck"
  | "lightBulb"
  | "mailbox"
  | "map"
  | "markerPin"
  | "medicalSquare"
  | "messageChatSquare"
  | "messageDotsSquare"
  | "musicNote"
  | "network"
  | "package"
  | "plant"
  | "search"
  | "shoppingCart"
  | "sport"
  | "target"
  | "tool"
  | "users"
  | "wifi"
  | "wineGlass";

interface GroupIconProps {
  icon: GroupIconType;
  size?: number;
  className?: string;
}

function getIcon(icon: GroupIconType, size?: number): React.ReactNode {
  switch (icon) {
    case "announcement":
      return <Icon name={announcementIcon} size={size} />;
    case "asterisk":
      return <Icon name={asteriskIcon} size={size} />;
    case "batteryMid":
      return <Icon name={batteryMidIcon} size={size} />;
    case "bellRinging":
      return <Icon name={bellRingingIcon} size={size} />;
    case "bicycle":
      return <Icon name={bicycleIcon} size={size} />;
    case "bookOpen":
      return <Icon name={bookOpenIcon} size={size} />;
    case "building01":
      return <Icon name={building01Icon} size={size} />;
    case "building02":
      return <Icon name={building02Icon} size={size} />;
    case "car":
      return <Icon name={carIcon} size={size} />;
    case "carrot":
      return <Icon name={carrotIcon} size={size} />;
    case "coffee":
      return <Icon name={coffeeIcon} size={size} />;
    case "compass":
      return <Icon name={compassIcon} size={size} />;
    case "cursorClick":
      return <Icon name={cursorClickIcon} size={size} />;
    case "dice":
      return <Icon name={diceIcon} size={size} />;
    case "dog":
      return <Icon name={dogIcon} size={size} />;
    case "faceHappy":
      return <Icon name={faceHappyIcon} size={size} />;
    case "factory":
      return <Icon name={factoryIcon} size={size} />;
    case "forkAndKnife":
      return <Icon name={forkAndKnifeIcon} size={size} />;
    case "gamingPad":
      return <Icon name={gamingPadIcon} size={size} />;
    case "gift":
      return <Icon name={giftIcon} size={size} />;
    case "handshake":
      return <Icon name={handshakeIcon} size={size} />;
    case "imageUserCheck":
      return <Icon name={imageUserCheckIcon} size={size} />;
    case "lightBulb":
      return <Icon name={lightBulbIcon} size={size} />;
    case "mailbox":
      return <Icon name={mailboxIcon} size={size} />;
    case "map":
      return <Icon name={mapIcon} size={size} />;
    case "markerPin":
      return <Icon name={markerPinIcon} size={size} />;
    case "medicalSquare":
      return <Icon name={medicalSquareIcon} size={size} />;
    case "messageChatSquare":
      return <Icon name={messageChatSquareIcon} size={size} />;
    case "messageDotsSquare":
      return <Icon name={messageDotsSquareIcon} size={size} />;
    case "musicNote":
      return <Icon name={musicNoteIcon} size={size} />;
    case "network":
      return <Icon name={networkIcon} size={size} />;
    case "package":
      return <Icon name={packageIcon} size={size} />;
    case "plant":
      return <Icon name={plantIcon} size={size} />;
    case "search":
      return <Icon name={searchIcon} size={size} />;
    case "shoppingCart":
      return <Icon name={shoppingCartIcon} size={size} />;
    case "sport":
      return <Icon name={sportIcon} size={size} />;
    case "target":
      return <Icon name={targetIcon} size={size} />;
    case "tool":
      return <Icon name={toolIcon} size={size} />;
    case "users":
      return <Icon name={usersIcon} size={size} />;
    case "wifi":
      return <Icon name={wifiIcon} size={size} />;
    case "wineGlass":
      return <Icon name={wineGlassIcon} size={size} />;
  }
}

export function GroupIcon({ icon, size, className }: GroupIconProps): React.ReactNode {
  return <div className={className}>{getIcon(icon, size)}</div>;
}

export const GROUP_ICONS: GroupIconType[] = [
  "announcement",
  "asterisk",
  "batteryMid",
  "bellRinging",
  "bicycle",
  "bookOpen",
  "building01",
  "building02",
  "car",
  "carrot",
  "coffee",
  "compass",
  "cursorClick",
  "dice",
  "dog",
  "faceHappy",
  "factory",
  "forkAndKnife",
  "gamingPad",
  "gift",
  "handshake",
  "imageUserCheck",
  "lightBulb",
  "mailbox",
  "map",
  "markerPin",
  "medicalSquare",
  "messageChatSquare",
  "messageDotsSquare",
  "musicNote",
  "network",
  "package",
  "plant",
  "search",
  "shoppingCart",
  "sport",
  "target",
  "tool",
  "users",
  "wifi",
  "wineGlass",
];
