import type { DragEndEvent, DragStartEvent } from "@dnd-kit/core";
import type {
  CreatedEntityDto,
  CreateSurveyRequestDto,
  QuestionDto,
  SurveyDetailsDto,
  UpdateSurveyRequestDto,
} from "api/types";
import { Button } from "components/Button/Button";
import type { DatePickerValue } from "components/DateAndTimePicker/DateAndTimePicker";
import { DynamicRepeater } from "components/DynamicRepeater/DynamicRepeater";
import { DynamicRepeaterItem } from "components/DynamicRepeater/DynamicRepeaterItem";
import { DynamicRepeaterItemOverlay } from "components/DynamicRepeater/DynamicRepeaterItemOverlay";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormDateAndTimePicker } from "components/Form/FormDateAndTimePicker";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { FormSelect } from "components/Form/FormSelect";
import { FormTextArea } from "components/Form/FormTextArea";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Wizard, WizardStep } from "components/Wizard/Wizard";
import { addMinutes } from "date-fns";
import { createRequiredStringRule } from "helpers/rules";
import { useProject } from "hooks/Network/useProject";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useSlug } from "hooks/useSlug";
import { groupBy } from "lodash-es";
import { feelingAtHomeTemplate, questionTypes, surveyCategories } from "modules/surveys/constants";
import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "routes";

import { FormQuestion } from "./components/FormQuestion";

export interface LayoutProps {
  defaultFormValues: FormValues;
  groups: { id: string | undefined; name: string }[];
  createSurvey: (payload: CreateSurveyRequestDto) => Promise<CreatedEntityDto>;
  editSurvey: (payload: UpdateSurveyRequestDto) => Promise<SurveyDetailsDto>;
  isSubmitting: boolean;
  isPublished: boolean;
}

export interface Question {
  qId?: string;
  type: QuestionDto["type"];
  question: string;
  options?: { oId?: string; option: string }[];
  useForAnalysis?: boolean;
}

export interface FormValues {
  type: SurveyDetailsDto["type"];
  category: SurveyDetailsDto["category"];
  startsAt: DatePickerValue;
  endsAt: DatePickerValue;
  group?: { id: string | undefined; name: string };
  title: string;
  description: string;
  thankYouMessage: string;
  questions: Question[];
  internal: boolean;
}

const TITLE_MAX_LENGTH = 255;
const DESCRIPTION_MAX_LENGTH = 500;

export function Layout({
  defaultFormValues,
  groups,
  createSurvey,
  editSurvey,
  isSubmitting,
  isPublished,
}: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();
  const sessionUser = useSessionUser();
  const { id: surveyId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [dragItemId, setDragItemId] = useState<number | null>(null);
  const form = useForm<FormValues>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  });
  const { fields, update, append, remove, move } = useFieldArray<FormValues, "questions">({
    control: form.control,
    name: "questions",
  });

  const start = useWatch({ control: form.control, name: "startsAt" });
  const category = useWatch({ control: form.control, name: "category" });
  const canLeaveFirstStep = async () =>
    await form.trigger(["type", "startsAt", "endsAt", "group", "title", "description", "thankYouMessage"]);
  const canLeaveFinal = async () => await form.trigger();

  const minStart = useMemo(() => addMinutes(new Date(), 15), []);

  const project = useProject();

  async function handleSave() {
    const formValues = form.getValues();

    if (!formValues.startsAt) {
      throw new Error("Missing start date");
    }

    if (!formValues.endsAt) {
      throw new Error("Missing end date");
    }

    if (!formValues.questions.length) {
      showFlashToast({
        type: "error",
        title: t("page.surveys-create-or-edit.error.questions.min"),
      });

      return;
    }

    let doesnNotHaveMultipleOptions = false;
    let hasRepeatedOptions = false;
    formValues.questions.forEach((question) => {
      if (question.type !== "multipleChoice") {
        return;
      }

      if (!question.options?.length || question.options.length < 2) {
        doesnNotHaveMultipleOptions = true;
      }

      const groupedOptions = groupBy(question.options, (val) => val.option);
      for (const group in groupedOptions) {
        if (groupedOptions[group].length > 1) {
          hasRepeatedOptions = true;
        }
      }
    });

    if (doesnNotHaveMultipleOptions) {
      showFlashToast({
        type: "error",
        title: t("page.surveys-create-or-edit.error.multiple-choice.less-than-required"),
      });

      return;
    }

    if (hasRepeatedOptions) {
      showFlashToast({
        type: "error",
        title: t("page.surveys-create-or-edit.error.multiple-choice.unique"),
      });

      return;
    }

    const payload = {
      title: formValues.title,
      description: formValues.description,
      startDate: formValues.startsAt.toISOString(),
      endDate: formValues.endsAt.toISOString(),
      surveyCompleted: formValues.thankYouMessage,
      questions: formValues.questions.map((question, idx) => ({
        id: question.qId,
        sorting: idx,
        type: question.type,
        question: question.question,
        offeredAnswers: question.options?.length
          ? question.options.map((option) => ({ id: option.oId, answer: option.option }))
          : undefined,
        useForAnalysis: !!question.useForAnalysis,
      })),
      groupId: formValues.group?.id,
      internal: formValues.internal,
    };

    if (surveyId) {
      await editSurvey({ ...payload });
      form.reset();
      navigate(routes.surveys.details({ slug, id: surveyId }));
    } else {
      const survey = await createSurvey({ ...payload, type: "survey", category: formValues.category });
      form.reset();
      navigate(routes.surveys.details({ slug, id: survey.id }));
    }
  }

  function handleDragStart(event: DragStartEvent) {
    setDragItemId(event.active.data.current?.sortable.index);
  }

  function handleDragEnd(event: DragEndEvent) {
    setDragItemId(null);
    const { active, over } = event;

    if (over && active.id !== over.id) {
      move(active.data.current?.sortable.index, over?.data.current?.sortable.index);
    }
  }

  useEffect(() => {
    if (surveyId) {
      return;
    }

    update(0, { type: "open", question: "" });
  }, [surveyId, update]);

  function handleCategoryChange(category: SurveyDetailsDto["category"]) {
    if (category === "other") {
      form.reset();

      return;
    }

    remove(0);

    form.setValue("title", t(feelingAtHomeTemplate.title as any, { projectName: project.name }));
    form.setValue("description", t(feelingAtHomeTemplate.description as any, { projectName: project.name }));
    form.setValue("thankYouMessage", t(feelingAtHomeTemplate.thankYouMessage as any, { projectName: project.name }));

    for (let i = 0; i < feelingAtHomeTemplate.questions.length; i++) {
      append({
        type: feelingAtHomeTemplate.questions[i].type as QuestionDto["type"],
        question: t(feelingAtHomeTemplate.questions[i].title as any, { projectName: project.name }),
        options: feelingAtHomeTemplate.questions[i].options?.map((option) => ({
          option: t(option.option as any, { projectName: project.name }),
        })),
        useForAnalysis: feelingAtHomeTemplate.questions[i].useForAnalysis,
      });
    }
  }

  const isUseForAnalysisSelected = useMemo(() => {
    return fields.reduce((counter, field) => (field.useForAnalysis ? (counter += 1) : counter), 0) === 1;
  }, [fields]);

  return (
    <DocumentPaper
      theme="wide"
      title={surveyId ? t("page.surveys.create-or-edit.title.edit") : t("page.surveys.create-or-edit.title.new")}
    >
      <Form formMethods={form} onSubmit={handleSave}>
        <Wizard
          id="survey-create-or-edit-wizard"
          actionsText={{
            finish:
              surveyId != null ? t("component.wizard.action.edit.finish") : t("component.wizard.action.create.finish"),
          }}
          onFinish={form.handleSubmit(handleSave)}
          strictOrder={surveyId === null}
          isSubmitting={isSubmitting}
        >
          <WizardStep
            id={1}
            canLeave={canLeaveFirstStep}
            hasFinish={surveyId != null}
            title={
              <>
                <span>{t("component.wizard.step", { step: 1 })}</span>
                <span>{t("component.wizard.step-general")}</span>
              </>
            }
          >
            <div className="flex w-full flex-col justify-between gap-4 @lg:flex-row">
              <FormContent className="h-fit w-full" maxWidth="xl">
                <FormField label={t("page.surveys.create-or-edit.form.category.label")} required>
                  <FormSelect<FormValues, SurveyDetailsDto["category"]>
                    name="category"
                    placeholder={t("page.surveys.create-or-edit.form.category.placeholder")}
                    items={
                      sessionUser.isSuperAdmin ? surveyCategories : surveyCategories.filter((x) => x !== "feelAtHome")
                    }
                    onChange={(x) => handleCategoryChange(x!)}
                    keySelector={(x) => x}
                    renderOption={(x) => {
                      switch (x) {
                        case "other":
                          return t("page.surveys.categories.other");
                        case "feelAtHome":
                          return t("page.surveys.categories.feel-at-home");
                      }
                    }}
                    rules={{
                      required: t("components.form.error.required", {
                        inputName: t("page.surveys.create-or-edit.form.type.label"),
                      }),
                    }}
                    disabled={!!surveyId}
                  />
                </FormField>
                <FormField label={t("page.surveys.create-or-edit.form.starts-at.label")} required>
                  <FormDateAndTimePicker<FormValues>
                    name="startsAt"
                    id="startsAt"
                    type="datetime"
                    min={minStart}
                    rules={{
                      validate: {
                        laterThanMin: (startDate) => {
                          if (
                            startDate == null ||
                            (!!surveyId && !!defaultFormValues.startsAt && defaultFormValues.startsAt < new Date())
                          ) {
                            return undefined;
                          }

                          return startDate < minStart
                            ? t("page.surveys.create-or-edit.form.starts-at.error.must-be-in-future")
                            : undefined;
                        },
                      },
                      required: {
                        message: t("components.form.error.required", {
                          inputName: t("page.surveys.create-or-edit.form.starts-at.label"),
                        }),
                        value: true,
                      },
                    }}
                    disabled={!!defaultFormValues.startsAt && defaultFormValues.startsAt < new Date()}
                  />
                </FormField>
                <FormField label={t("page.surveys.create-or-edit.form.ends-at.label")} required>
                  <FormDateAndTimePicker<FormValues>
                    name="endsAt"
                    id="endsAt"
                    type="datetime"
                    min={start || minStart}
                    rules={{
                      validate: {
                        laterThanStart: (endDate) => {
                          if (endDate == null) {
                            return undefined;
                          }

                          return endDate <= start
                            ? t("page.surveys.create-or-edit.form.ends-at.error.later-than-start-date")
                            : undefined;
                        },
                      },
                      required: {
                        message: t("components.form.error.required", {
                          inputName: t("page.surveys.create-or-edit.form.ends-at.label"),
                        }),
                        value: true,
                      },
                    }}
                  />
                </FormField>
                <FormField label={t("page.surveys.create-or-edit.form.group.label")}>
                  <FormSelect<FormValues, { id: string | undefined; name: string }>
                    name="group"
                    placeholder={t("page.surveys.create-or-edit.form.group.placeholder")}
                    items={groups}
                    keySelector={(x) => x.id || "undefined"}
                    renderOption={(x) => x.name}
                    disabled={isPublished}
                  />
                </FormField>
                {sessionUser.isSuperAdmin && (
                  <FormField label="">
                    <FormCheckbox<FormValues> name="internal" label={t("page.surveys.create-or-edit.internal.label")} />
                  </FormField>
                )}
              </FormContent>
              <FormContent className="w-full" maxWidth="xl">
                <FormField label={t("page.surveys.create-or-edit.form.title.label")} required>
                  <FormInput<FormValues>
                    name="title"
                    placeholder={t("page.surveys.create-or-edit.form.title.placeholder")}
                    rules={{
                      validate: {
                        required: createRequiredStringRule(t, "page.surveys.create-or-edit.form.title.label"),
                      },
                      maxLength: {
                        message: t("components.form.error.max-length", {
                          length: TITLE_MAX_LENGTH,
                        }),
                        value: TITLE_MAX_LENGTH,
                      },
                    }}
                  />
                </FormField>
                <FormField label={t("page.surveys.create-or-edit.form.description.label")} required>
                  <FormTextArea<FormValues>
                    name="description"
                    placeholder={t("page.surveys.create-or-edit.form.title.placeholder")}
                    rules={{
                      validate: {
                        required: createRequiredStringRule(t, "page.surveys.create-or-edit.form.title.label"),
                      },
                      maxLength: {
                        message: t("components.form.error.max-length", {
                          length: DESCRIPTION_MAX_LENGTH,
                        }),
                        value: DESCRIPTION_MAX_LENGTH,
                      },
                    }}
                  />
                </FormField>
                <FormField label={t("page.surveys.create-or-edit.form.thanks-message.label")} required>
                  <FormTextArea<FormValues>
                    name="thankYouMessage"
                    placeholder={t("page.surveys.create-or-edit.form.thanks-message.placeholder")}
                    rules={{
                      validate: {
                        required: createRequiredStringRule(t, "page.surveys.create-or-edit.form.thanks-message.label"),
                      },
                      maxLength: {
                        message: t("components.form.error.max-length", {
                          length: DESCRIPTION_MAX_LENGTH,
                        }),
                        value: DESCRIPTION_MAX_LENGTH,
                      },
                    }}
                  />
                </FormField>
              </FormContent>
            </div>
          </WizardStep>
          <WizardStep
            id={2}
            canLeave={canLeaveFinal}
            hasFinish={surveyId != null}
            title={
              <>
                <span>{t("component.wizard.step", { step: 2 })}</span>
                <span>{t("page.surveys.create-or-edit.wizard.step-questions")}</span>
              </>
            }
          >
            <FormContent maxWidth="2xl">
              <DynamicRepeater
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                itemIds={fields.map((field) => field.id)}
              >
                {fields.map((question, idx) => (
                  <DynamicRepeaterItem
                    key={question.id}
                    id={question.id}
                    title={t("page.surveys.create-or-edit.question.label", { count: idx + 1 })}
                    disabled={false}
                  >
                    <FormQuestion
                      idx={idx}
                      control={form.control}
                      updateField={update}
                      removeField={remove}
                      types={questionTypes}
                      question={question}
                      showOptions={question.type === "multipleChoice"}
                      showDelete={
                        fields.length > 1 &&
                        (defaultFormValues.startsAt ? defaultFormValues.startsAt > new Date() : true)
                      }
                      showUseForAnalysisOption={category === "feelAtHome"}
                      isUseForAnalysisDisabled={isUseForAnalysisSelected && !question.useForAnalysis}
                    />
                  </DynamicRepeaterItem>
                ))}
                <DynamicRepeaterItemOverlay
                  show={dragItemId !== null}
                  title={t("page.surveys.create-or-edit.question.label", { count: dragItemId! + 1 })}
                >
                  <FormQuestion
                    idx={dragItemId!}
                    control={form.control}
                    updateField={() => null}
                    removeField={() => null}
                    types={questionTypes}
                    question={fields[dragItemId!]}
                    showOptions={fields[dragItemId!]?.type === "multipleChoice"}
                    showDelete={defaultFormValues.startsAt ? defaultFormValues.startsAt > new Date() : true}
                    showUseForAnalysisOption={category === "feelAtHome"}
                    isUseForAnalysisDisabled={isUseForAnalysisSelected && !fields[dragItemId!]?.useForAnalysis}
                  />
                </DynamicRepeaterItemOverlay>
              </DynamicRepeater>
              <Button
                data-testid="add-question-btn"
                styling="secondary"
                onClick={() => append({ type: "open", question: "" }, { shouldFocus: false })}
              >
                {t("page.surveys.create-or-edit.question.add")}
              </Button>
            </FormContent>
          </WizardStep>
        </Wizard>
      </Form>
    </DocumentPaper>
  );
}
