import type { AdminTicketDetailsDto, EditTicketCommentRequest, SelfDto, TicketCommentRequest } from "api/types";
import type { CommentPayload } from "components/Ticket/TicketCommentField/TicketCommentFieldPure";
import { useUploadDocument } from "hooks/Network/useUploadDocument";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { ticketMutations } from "queries/tickets";

export function changingAssigneeWillResultIn403(
  ticket: AdminTicketDetailsDto,
  assignee: { id: string },
  sessionUser: SelfDto,
): boolean {
  if (!ticket) {
    return false;
  }

  if (sessionUser.isSuperAdmin) {
    return false;
  }

  if (ticket.assignee?.id === assignee.id) {
    return false;
  }

  if (ticket.assignee?.id !== sessionUser.id) {
    return false;
  }

  if (ticket.user.id === sessionUser.id) {
    return false;
  }

  if (sessionUser.isOnlyAllowedToViewAssignedOrOwnTickets) {
    return true;
  } else {
    const categoryPermissions = sessionUser.role.permissions.repairCategories.find(
      (x) => x.categoryId === ticket.category.id,
    );

    if (!categoryPermissions) {
      return false;
    }

    if (categoryPermissions.canListAllRequests) {
      return false;
    }

    if (ticket.visibility !== "private" && categoryPermissions.canListCollectiveRequests) {
      return false;
    }

    return true;
  }
}

export function canSeeServiceHistory(ticket: AdminTicketDetailsDto, sessionUser: SelfDto): boolean {
  return (
    sessionUser.project.type === "addressBased" &&
    (sessionUser.isSuperAdmin ||
      (!sessionUser.isOnlyAllowedToViewAssignedOrOwnTickets &&
        sessionUser.role.permissions.repairCategories.some(
          (x) => x.categoryId == ticket?.category.id && x.canListAllRequests,
        )))
  );
}

export function useTicketCommentHandler(ticketId: string): {
  createComment: (payload: CommentPayload & { fetchAllActivities?: boolean }) => Promise<unknown>;
  editComment: (payload: CommentPayload & { commentId: string }) => Promise<void>;
} {
  const postCommentMutation = ticketMutations.useAddComment(ticketId);
  const editCommentMutation = ticketMutations.useUpdateComment(ticketId);

  const { uploadFormImage } = useUploadImage();
  const { uploadFormDocument } = useUploadDocument();

  async function createComment({
    content,
    images,
    documents,
    type,
    fetchAllActivities,
  }: CommentPayload & {
    fetchAllActivities?: boolean;
  }) {
    const data: TicketCommentRequest = {
      content,
      imageId: undefined,
      documentIds: [],
      accessType: type,
    };

    if (images.length > 0) {
      const uploadedImage = await uploadFormImage(images[0]);
      if (uploadedImage) {
        data.imageId = uploadedImage.id;
      }
    }
    if (documents.length > 0) {
      const uploadedDocument = await uploadFormDocument(documents[0]);

      if (uploadedDocument) {
        data.documentIds = [uploadedDocument.id];
      }
    }

    await postCommentMutation.mutateAsync({
      data,
      fetchAllActivities,
    });
  }

  async function editComment({ commentId, content, images, documents }: CommentPayload & { commentId: string }) {
    const data: EditTicketCommentRequest = {
      content,
      imageId: undefined,
      documentIds: [],
    };

    if (images.length > 0) {
      const uploadedImage = await uploadFormImage(images[0]);
      if (uploadedImage) {
        data.imageId = uploadedImage.id;
      }
    }
    if (documents.length > 0) {
      const uploadedDocument = await uploadFormDocument(documents[0]);

      if (uploadedDocument) {
        data.documentIds = [uploadedDocument.id];
      }
    }

    await editCommentMutation.mutateAsync({
      commentId,
      data,
    });
  }

  return { createComment, editComment };
}
