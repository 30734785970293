import type { InfiniteData } from "@tanstack/react-query";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type {
  CommentCreateRequest,
  CommentDto,
  CommentDtoPaginationResultDto,
  CommentUpdateRequest,
  MessageStatusChangeRequest,
  MessageUpdateRequest,
  MessageV2Dto,
} from "api/types";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { useFlashToast } from "components/FlashToast/FlashToast";
import type { FormImage } from "components/ImageInput/useImageInput";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadDocument } from "hooks/Network/useUploadDocument";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useBool } from "hooks/useBool";
import { useSignalRHub, useSignalRSubscription } from "hooks/useSignalR";
import { useConfig } from "providers/ConfigProvider";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import type { ApiResponseType } from "types/api-types";

const LIKES_AND_COMMENTS_LENGTH = 5;

export enum Tabs {
  LIKES,
  COMMENTS,
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useData = (message: MessageV2Dto, isExpanded: boolean) => {
  const projectId = useProjectId();
  const sessionUser = useSessionUser();
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const { uploadFormImage, isUploadingImage } = useUploadImage();
  const { uploadFormDocument, isUploadingDocument } = useUploadDocument();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<Tabs | undefined>(isExpanded ? Tabs.COMMENTS : undefined);
  const [analyze, analyzeHandler] = useBool();
  const [showNewCommentPill, newCommentPillHandler] = useBool(false);
  const dashboardUrl = useConfig("newDashboardRootUrl");

  const { signalRConnection } = useSignalRHub("community-feed-detail-hub", {
    query: `userId=${sessionUser.id}&entityId=${message.id}&entityType=Message`,
    disabled: activeTab !== Tabs.COMMENTS,
  });

  useEffect(() => {
    queryClient.setQueryData(QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id), message);
  }, [queryClient, message, projectId]);

  const { data: sentiment } = useQuery({
    queryKey: QUERY_KEYS.MESSAGES_AI_SENTIMENT(projectId, message.id),
    queryFn: () => api.postMessagesAiSentimentV1(message.id),
    enabled: analyze,
    select: commonAPIDataSelector,
  });
  const { data: topics } = useQuery({
    queryKey: QUERY_KEYS.MESSAGES_AI_TOPICS(projectId, message.id),
    queryFn: () => api.postMessagesAiTopicsV1(message.id),
    enabled: analyze,
    select: commonAPIDataSelector,
  });

  const { data: messageDetails, error: messageError } = useQuery({
    queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id),
    queryFn: () => api.getMessagesDetailsV2(message.id).then((x) => x.data),
    initialData: message,
    staleTime: Infinity,
  });

  const likes = useInfiniteQuery({
    queryKey: QUERY_KEYS.MESSAGES_LIKES(projectId, message.id),
    queryFn: async ({ pageParam = 0 }) => {
      const { data } = await api.getMessagesLikesV1(message.id, {
        Offset: pageParam * LIKES_AND_COMMENTS_LENGTH,
        Limit: LIKES_AND_COMMENTS_LENGTH,
      });

      return data;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
    enabled: activeTab === Tabs.LIKES,
    refetchOnWindowFocus: true,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const comments = useInfiniteQuery({
    queryKey: QUERY_KEYS.MESSAGES_COMMENTS(projectId, message.id),
    queryFn: async ({ pageParam = 0 }) => {
      const { data } = await api.getMessagesCommentsV1(message.id, {
        Offset: pageParam * LIKES_AND_COMMENTS_LENGTH,
        Limit: LIKES_AND_COMMENTS_LENGTH,
      });

      return data;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
    enabled: activeTab === Tabs.COMMENTS,
    staleTime: 15 * 60 * 1000, // 15 minutes
    refetchOnWindowFocus: true,
  });

  const createQuickReplyLink = useMutation({
    mutationFn: () =>
      api
        .postQuickReplyMessageCreateTokenV1({ messageId: message.id as any, userId: sessionUser.id as any })
        .then((x) => x.data),
    onSuccess: async (token) => {
      const quickReplyMessagePath = routes.quickReplyMessage({ token });
      await navigator.clipboard.writeText(`${dashboardUrl}${quickReplyMessagePath}`);
      showFlashToast({ type: "success", title: t("component.community-post.quick-reply-link.success") });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.quick-reply-link.error") });
    },
  });

  const deletePost = useMutation({
    mutationFn: ({ reason, details }: Omit<MessageStatusChangeRequest, "newStatus">) =>
      api.postMessagesChangeStatusV1(message.id, { newStatus: "deleted", reason: reason, details: details }),
    onSuccess: async () => {
      showFlashToast({ type: "success", title: t("component.community-post.delete.success") });

      if (sessionUser.isSuperAdmin) {
        await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id) });
      } else {
        await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES(projectId) });
      }
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.delete.error") });
    },
  });

  const archivePost = useMutation({
    mutationFn: ({ reason, details }: Omit<MessageStatusChangeRequest, "newStatus">) =>
      api.postMessagesChangeStatusV1(message.id, { newStatus: "archived", reason: reason, details: details }),
    onSuccess: async () => {
      showFlashToast({ type: "success", title: t("component.community-post.archive.success") });

      if (sessionUser.isSuperAdmin) {
        await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id) });
      } else {
        await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES(projectId) });
      }
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.archive.error") });
    },
  });

  const editPost = async ({
    payload,
    images,
    documents,
    successMessage,
    failureMessage,
  }: {
    payload: MessageUpdateRequest;
    images: FormImage[];
    documents: FormDocument[];
    successMessage: string;
    failureMessage: string;
  }) => {
    const imageUploadPromises = images.map((image) => uploadFormImage(image));
    // Curently only 1 document is allowed
    const documentUploadPromises = documents.map((document) => uploadFormDocument(document));

    const imageIds = (await Promise.allSettled(imageUploadPromises))
      .map((image) => (image.status === "fulfilled" ? image.value?.id : ""))
      .filter(Boolean) as string[];
    const documentIds = (await Promise.allSettled(documentUploadPromises))
      .map((document) => (document.status === "fulfilled" ? document.value?.id : ""))
      .filter(Boolean) as string[];

    return await updateMessage.mutateAsync({
      payload: { ...payload, imageIds, documentIds },
      successMessage: successMessage,
      failureMessage: failureMessage,
    });
  };

  const updateMessage = useMutation({
    mutationFn: ({ payload }: { payload: MessageUpdateRequest; successMessage: string; failureMessage: string }) =>
      api.putMessagesV1(message.id, payload),
    onSuccess: async (_, { successMessage }) => {
      showFlashToast({ type: "success", title: successMessage });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id) });
    },
    onError(_, { failureMessage }) {
      showFlashToast({ type: "error", title: failureMessage });
    },
  });

  const likePost = useMutation({
    mutationFn: () => api.postMessagesLikeV1(message.id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id) });
      await likes.refetch();
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.like.error") });
    },
  });

  const unlikePost = useMutation({
    mutationFn: () => api.deleteMessagesLikeV1(message.id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id) });
      await likes.refetch();
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.unlike.error") });
    },
  });

  const likeComment = useMutation({
    mutationFn: (commentId: string) => api.postMessagesCommentsLikesV1(message.id, commentId),
    onSuccess: async (_, commentId: string) => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_COMMENTS(projectId, message.id) });
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.MESSAGE_COMMENT_DETAILS(projectId, message.id, commentId),
      });
      await comments.refetch();
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.comment.like.error") });
    },
  });

  const unlikeComment = useMutation({
    mutationFn: (commentId: string) => api.deleteMessagesCommentsLikesV1(message.id, commentId),
    onSuccess: async (_, commentId: string) => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_COMMENTS(projectId, message.id) });
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.MESSAGE_COMMENT_DETAILS(projectId, message.id, commentId),
      });
      await comments.refetch();
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.comment.unlike.error") });
    },
  });

  const createComment = async ({
    comment,
    files,
    failureMessage,
    parentId,
  }: {
    comment: string;
    files: FormImage[];
    failureMessage: string;
    parentId?: string;
  }) => {
    const uploadedImage = await uploadFormImage(files[0]);

    return await commentPost.mutateAsync({
      payload: { content: comment, imageId: uploadedImage?.id, parentId },
      failureMessage: failureMessage,
    });
  };

  const commentPost = useMutation({
    mutationFn: ({ payload }: { payload: CommentCreateRequest; failureMessage: string }) =>
      api.postMessagesCommentsV1(message.id, payload).then((x) => x.data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id) });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGE_COMMENTS_DETAILS(projectId, message.id) });
      await onFetchNewComments();
    },
    onError(_, { failureMessage }) {
      showFlashToast({ type: "error", title: failureMessage });
    },
  });

  const editComment = async ({
    comment,
    commentId,
    files,
    failureMessage,
  }: {
    comment: string;
    commentId: string;
    files: FormImage[];
    failureMessage: string;
  }) => {
    const uploadedImage = await uploadFormImage(files[0]);

    return await updateComment.mutateAsync({
      payload: { content: comment, imageId: uploadedImage?.id },
      commentId: commentId,
      failureMessage: failureMessage,
    });
  };

  const updateComment = useMutation({
    mutationFn: ({
      payload,
      commentId,
    }: {
      payload: CommentUpdateRequest;
      commentId: string;
      failureMessage: string;
    }) => api.putMessagesCommentsV1(message.id, commentId, payload).then((x) => x.data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id) });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGE_COMMENTS_DETAILS(projectId, message.id) });

      await onFetchNewComments();
    },
    onError(_, { failureMessage }) {
      showFlashToast({ type: "error", title: failureMessage });
    },
  });

  const deleteComment = useMutation({
    mutationFn: (commentId: string) => api.deleteMessagesCommentsByIdV1(message.id, commentId).then((x) => x.data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id) });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGE_COMMENTS_DETAILS(projectId, message.id) });
      await onFetchNewComments();
    },
    onError() {
      showFlashToast({ type: "error", title: t("component.community-post.comments.delete.error") });
    },
  });

  const [showTranslation, showTranslationHandler] = useBool();
  const translation = useQuery({
    queryKey: QUERY_KEYS.MESSAGE_TRANSLATION(projectId, message.id, sessionUser.language.id),
    queryFn: () => api.getMessagesTranslationsDetailsV2(message.id, sessionUser.language.id),
    retry: false,
    enabled: showTranslation,
  });

  const markAsRead = useMutation({
    mutationFn: () => api.postMessagesReadV1(message.id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id) });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_FEED_STATS(projectId) });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.mark-as-read.error") });
    },
  });

  const onGroupFollowChange = async () => {
    await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id) });
  };

  const onNewLikesOrCommentsOnPost = useCallback(
    (
      ...args: [
        {
          entityId: string;
          entityType: "message" | "comment" | "poll" | "survey";
          likeTotal: number;
          commentTotal: number;
          authorId: string;
        },
      ]
    ) => {
      if (!isExpanded || args[0].authorId === sessionUser.id) {
        return;
      }

      void queryClient.setQueryData<ApiResponseType<"getMessagesDetailsV2"> | undefined>(
        QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id),
        (oldData) => {
          if (!oldData) {
            return;
          }

          return {
            ...oldData,
            totalLikeCount: args[0].likeTotal,
            totalCommentCount: args[0].commentTotal,
          };
        },
      );
    },
    [isExpanded, sessionUser, queryClient, message.id, projectId],
  );
  useSignalRSubscription(signalRConnection, "UpdateMessageLikeAndCommentsCount", onNewLikesOrCommentsOnPost);

  const onNewCommentLike = useCallback(
    (...args: [{ entityId: string; likeTotal: number; authorId: string }]) => {
      if (!isExpanded || args[0].authorId === sessionUser.id) {
        return;
      }

      void queryClient.setQueryData<InfiniteData<CommentDtoPaginationResultDto> | undefined>(
        QUERY_KEYS.MESSAGES_COMMENTS(projectId, message.id),
        (oldData) => {
          if (!oldData) {
            return;
          }

          return {
            ...oldData,
            pages: [
              ...oldData.pages.map((page) => ({
                ...page,
                items: page.items.map((item: CommentDto) =>
                  item.id === args[0].entityId ? { ...item, totalLikesCount: args[0].likeTotal } : item,
                ),
              })),
            ],
          };
        },
      );
    },
    [isExpanded, sessionUser, queryClient, message.id, projectId],
  );
  useSignalRSubscription(signalRConnection, "UpdateCommentLikeCount", onNewCommentLike);

  const onNewComment = useCallback(
    (...args: [{ entityId: string; entityType: "message" | "comment" | "poll" | "survey"; authorId: string }]) => {
      if (!isExpanded || args[0].authorId === sessionUser.id) {
        return;
      }

      newCommentPillHandler.setTrue();
    },
    [isExpanded, sessionUser, newCommentPillHandler],
  );
  useSignalRSubscription(signalRConnection, "NewCommentOnMessage", onNewComment);

  async function onFetchNewComments() {
    newCommentPillHandler.setFalse();
    await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_COMMENTS(projectId, message.id) });
    await comments.refetch();
  }

  if (isExpanded && !comments.isFetched) {
    void comments.refetch();
  }

  const allLikes = useMemo(() => {
    return likes.data?.pages.flatMap((x) => x.items ?? []) ?? [];
  }, [likes]);

  const allComments = useMemo(() => {
    return comments.data?.pages.flatMap((x) => x.items ?? []) ?? [];
  }, [comments]);

  return {
    post: messageError ? null : messageDetails,
    likes: allLikes,
    fetchLikes: likes,
    onLike: likePost.mutateAsync,
    onUnlike: unlikePost.mutateAsync,
    onLikeComment: likeComment.mutateAsync,
    onUnlikeComment: unlikeComment.mutateAsync,
    comments: allComments,
    fetchComments: comments,
    isMarkingAsRead: markAsRead.isPending,
    commentPost: createComment,
    markAsRead: markAsRead.mutateAsync,
    deletePost: deletePost.mutateAsync,
    archivePost: archivePost.mutateAsync,
    copyQuickReply: createQuickReplyLink.mutateAsync,
    editPost: editPost,
    editComment: editComment,
    deleteComment: deleteComment.mutateAsync,
    activeTab,
    setActiveTab,
    isSubmitting: updateMessage.isPending || isUploadingImage || isUploadingDocument,
    analyze,
    onAnalyze: analyzeHandler.setTrue,
    onCloseAnalyze: analyzeHandler.setFalse,
    analysis: {
      topics,
      sentiment,
    },
    translation: showTranslation ? translation.data?.data : undefined,
    translationIsLoading: translation.isLoading,
    onTranslate: showTranslationHandler.setTrue,
    onHideTranslation: showTranslationHandler.setFalse,
    onGroupFollowChange,
    showNewCommentPill,
    onFetchNewComments,
  };
};
