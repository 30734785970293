import type { LanguageDto, ProjectConnectionGroupDetailDto, UserRoleDto, UserRoleTypePermissionsDto } from "api/types";
import { createFormTranslations } from "helpers/languages";
import { createFormPermissions } from "helpers/system-groups/permissions";

import type { FormValues } from "./Layout";

export function getDefaultFormValues(
  roles: UserRoleDto[],
  languages: LanguageDto[],
  defaultsGroupsPermissions: UserRoleTypePermissionsDto[],
  group?: ProjectConnectionGroupDetailDto,
): FormValues {
  return {
    type: group?.type || "interest",
    nameTranslations: createFormTranslations({
      languages,
      translations: group?.translations,
      translationKey: "name",
    }),
    descriptionTranslations: createFormTranslations({
      languages,
      translations: group?.translations,
      translationKey: "description",
    }),
    icon: group?.icon,
    image: group?.image ? [group.image] : [],
    postingLevel: group?.postingLevel || "projectAndProjectConnection",
    audience: group?.defaultPostingAudience || "projectConnection",
    permissions: createFormPermissions(roles, defaultsGroupsPermissions, group?.permissions),
  };
}
