import { twResolve, twVariants } from "helpers/tw-resolve";
import type React from "react";
import type { VariantProps } from "tailwind-variants";

import type { ButtonBaseProps } from "./Button";
import { ButtonLoader } from "./ButtonLoader";

type ToggleButtonStateConfig = {
  label: string;
  icon?: React.ReactNode;
};

export type ToggleButtonProps = Omit<ButtonBaseProps, "children" | "title"> & {
  size?: ToggleButtonVariants["size"];
  config: {
    checked: ToggleButtonStateConfig;
    unchecked: ToggleButtonStateConfig;
  };
  isChecked: boolean | undefined;
};

export function ToggleButton({
  config,
  "data-testid": dataTestId,
  isChecked,
  isLoading,
  disabled,
  className,
  size = "md",
  onClick,
  ...otherProps
}: ToggleButtonProps): React.ReactNode {
  // The longer label is used as the placeholder to maintain button width
  const placeholderState = config.checked.label.length > config.unchecked.label.length ? "checked" : "unchecked";
  const overlayState = placeholderState === "checked" ? "unchecked" : "checked";

  return (
    <button
      {...{ onClick, ...otherProps }}
      type="button"
      disabled={disabled || isLoading}
      className={twResolve(
        toggleButtonVariants({
          state: isChecked ? "checked" : "unchecked",
          size,
        }),
        className,
      )}
      data-testid={dataTestId}
    >
      <ButtonLoader isVisible={isLoading} />
      {/* Placeholder label */}
      <div
        className={twResolve(
          "invisible flex h-full items-center gap-1 opacity-0",
          ((placeholderState === "checked" && isChecked) || (placeholderState === "unchecked" && !isChecked)) &&
            "visible opacity-100",
        )}
      >
        {config[placeholderState].icon}
        <span className="whitespace-nowrap">{config[placeholderState].label}</span>
      </div>

      {/* Overlay label */}
      <div
        className={twResolve(
          "invisible absolute left-1/2 top-1/2 flex h-full -translate-x-1/2 -translate-y-1/2 items-center gap-2 opacity-0",
          ((overlayState === "checked" && isChecked) || (overlayState === "unchecked" && !isChecked)) &&
            "visible opacity-100",
        )}
      >
        {config[overlayState].icon}
        <span className="whitespace-nowrap">{config[overlayState].label}</span>
      </div>
    </button>
  );
}

export const toggleButtonVariants = twVariants({
  base: [
    "relative flex cursor-pointer items-center justify-center gap-2 rounded-lg focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-1",
    "font-old-semibold transition-colors focus-visible:ring-blue-600 disabled:cursor-not-allowed disabled:opacity-60 [&>svg]:size-4",
  ],
  variants: {
    state: {
      checked: "bg-aop-dark-blue-500 text-white",
      unchecked: "bg-grey-100 text-aop-dark-blue-500 hover:bg-grey-300/60 disabled:bg-grey-100",
    },
    size: {
      sm: "h-8 px-2 text-caption",
      md: "h-10 px-4 text-body",
    },
  },
  defaultVariants: {
    state: "unchecked",
    size: "md",
  },
});

export type ToggleButtonVariants = VariantProps<typeof toggleButtonVariants>;
