declare global {
  interface Window {
    dataLayer: any[];
  }
}

const gtag: (...args: [string, ...any[]]) => void = function () {
  // Can't use arrow func + destructuring as Google expects
  // arguments objects in dataLayer (not an array of arguments).
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
};

export function initializeGoogleAnalytics(): void {
  const tagId = window.G_ANALYTICS_TAG;

  if (!tagId) {
    console.error("No Google Analytics Id key set, Google Analytics will be disabled");

    return;
  }

  install(tagId);
}

function install(tagID: string) {
  const scriptId = "ga-gtag";

  if (document.getElementById(scriptId)) {
    return;
  }

  const script = document.createElement("script");
  script.id = scriptId;
  script.type = "text/javascript";
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${tagID}`;
  document.head.insertBefore(script, document.head.firstChild);

  window.dataLayer = window.dataLayer || [];

  gtag("js", new Date());
  gtag("config", tagID);
}
