import iconX from "assets/icons/x.svg";
import { Icon } from "components/Icon/Icon";

export function MediaXButton(props: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  "data-testid"?: string;
}): React.ReactNode {
  return (
    <button
      className="flex size-3.5 items-center justify-center rounded-full bg-grey-900"
      data-testid={props["data-testid"]}
      onClick={props.onClick}
      type="button"
    >
      <Icon name={iconX} size={12} className="text-white" />
    </button>
  );
}
