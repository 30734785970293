import type { Row, TableState } from "@tanstack/react-table";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type {
  AdminTicketDetailedListItemDto,
  AdminTicketFiltersDto,
  AdminTicketTabsStatsDto,
  SelfDto,
} from "api/types";
import iconClock from "assets/icons/clock.svg";
import iconCopy01 from "assets/icons/copy-01.svg";
import iconDownload01 from "assets/icons/download-01.svg";
import iconFilterFunnel01 from "assets/icons/filter-funnel-01.svg";
import iconMessageCircle01 from "assets/icons/message-circle-01.svg";
import iconStar01 from "assets/icons/star-01.svg";
import iconTrash02 from "assets/icons/trash-02.svg";
import iconUsers01 from "assets/icons/users-01.svg";
import iconX from "assets/icons/x.svg";
import { Anchor } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import { Checkbox } from "components/Checkbox/Checkbox";
import { CheckboxMultiSelect } from "components/CheckboxMultiSelect/CheckboxMultiSelect";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import type { Tab } from "components/ContentTabs/ContentTabs";
import { ContentTabs } from "components/ContentTabs/ContentTabs";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { SearchInput } from "components/SearchInput/SearchInput";
import { Select } from "components/Select/Select";
import { Table } from "components/Table/Table";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { parseISO } from "date-fns";
import { formatAddress } from "helpers/address";
import { daysBetween } from "helpers/date";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useOnIntersection } from "hooks/useOnIntersection";
import { usePermission } from "hooks/usePermission";
import { useScreenIsBiggerThan } from "hooks/useScreenIsBiggerThan";
import { useSlug } from "hooks/useSlug";
import type { TFunction } from "i18next";
import { debounce } from "lodash-es";
import { canListCategories } from "modules/ticket-categories/permissions";
import { RemsStatus } from "modules/tickets/components/RemsStatus";
import { TicketTableActions } from "modules/tickets/components/TicketTableActions";
import type { TicketFilterParams, TicketFilterTypes, TicketSorting, TicketTabs } from "modules/tickets/constants";
import {
  CLOSED_TICKET_TAB,
  IN_PROGRESS_TICKET_TAB,
  NEW_TICKET_TAB,
  REMINDER_TICKET_TAB,
  ticketRatingValues,
  ticketVisibilityValues,
} from "modules/tickets/constants";
import { canListStatuses } from "modules/tickets/permissions";
import { usePostHog } from "posthog-js/react";
import type { ChangeEvent, Ref } from "react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { routes } from "routes";

import type { OnUpdateParams, TicketsFilters } from "./Loader";

export interface LayoutProps {
  tab: TicketTabs;
  residentFocusMode: boolean;
  sorting: TicketSorting;
  onUpdateSorting: (val: TicketSorting) => void;
  queryParams: TicketsFilters;
  onUpdateParams: OnUpdateParams;
  totalTickets?: number;
  totalTicketsInTab?: number;
  tabsStats?: AdminTicketTabsStatsDto;
  tickets: AdminTicketDetailedListItemDto[];
  isLoadingTickets: boolean;
  hasMoreTickets: boolean | undefined;
  loadMoreTickets: () => void;
  isLoadingMoreTickets: boolean;
  ticketFilterValues: AdminTicketFiltersDto | undefined;
  clearFilters: () => void;
  filterCount: number;
  exportTickets: () => void;
  isDownloading: boolean;
  deleteTicket: (ticketId: string) => void;
  markAsRead: () => void;
  copyQuickReplyLink: (ticketId: string) => void;
  searchInputRef: Ref<HTMLInputElement> | undefined;
}

export function Layout({
  queryParams,
  onUpdateParams,
  totalTickets,
  totalTicketsInTab,
  tabsStats,
  tickets,
  isLoadingTickets,
  hasMoreTickets,
  loadMoreTickets,
  isLoadingMoreTickets,
  ticketFilterValues,
  clearFilters,
  filterCount,
  exportTickets,
  isDownloading,
  deleteTicket,
  markAsRead,
  copyQuickReplyLink,
  tab,
  residentFocusMode,
  sorting,
  onUpdateSorting,
  searchInputRef,
}: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const hasPermission = usePermission();
  const [isFilterMenuOpen, filterMenuHandler] = useBool(false);
  const [isMarkAsReadModalOpen, markAsReadModalHandler] = useBool(false);
  const [ticketIdToDelete, setTicketIdToDelete] = useState<string | undefined>(undefined);

  const sessionUser = useSessionUser();
  const posthog = usePostHog();

  const onUpdateFilters = useCallback(
    (filter: TicketFilterParams, value: TicketFilterTypes) => {
      onUpdateParams(filter, value);
    },
    [onUpdateParams],
  );

  const onTabSelection = useCallback(
    (val: TicketTabs) => {
      onUpdateParams("Tab", val);
    },
    [onUpdateParams],
  );

  const onSearch = useMemo(
    () => debounce((e: ChangeEvent<HTMLInputElement>) => onUpdateFilters("Search", e.target.value), 500),
    [onUpdateFilters],
  );

  const tabs: Tab<TicketTabs>[] = [
    {
      id: NEW_TICKET_TAB,
      name: tabsStats == null ? t("page.tickets.table.tabs.new.loading") : t("page.tickets.table.tabs-label.new"),
      count: tabsStats?.newTickets,
    },
    {
      id: IN_PROGRESS_TICKET_TAB,
      name:
        tabsStats == null ? t("page.tickets.table.tabs.progress.loading") : t("page.tickets.table.tabs-label.progress"),
      count: tabsStats?.inProgressTickets,
    },
    {
      id: CLOSED_TICKET_TAB,
      name: tabsStats == null ? t("page.tickets.table.tabs.closed.loading") : t("page.tickets.table.tabs-label.closed"),
      count: tabsStats?.closedTickets,
    },
    {
      id: REMINDER_TICKET_TAB,
      name: tabsStats == null ? t("page.tickets.table.tabs.later.loading") : t("page.tickets.table.tabs-label.later"),
      icon: <Icon name={iconClock} className="text-blue-500" />,
      isSecondary: true,
      isHidden: !tabsStats || tabsStats.reminderTickets === 0,
      count: tabsStats?.reminderTickets,
    },
  ];

  const isMd = useScreenIsBiggerThan("md");

  const hasFilters = filterCount > 0 || !!queryParams.Search || residentFocusMode;

  return (
    <DocumentPaper
      title={t("page.tickets.title")}
      subTitle={t("page.tickets.subtitle")}
      theme="minimal"
      actions={
        <>
          {hasPermission(canListCategories) && (
            <Button type="link" href={routes.ticketCategories.list({ slug })} styling="secondary">
              {t("navigation.tickets.categories")}
            </Button>
          )}
          {hasPermission(canListStatuses) && (
            <Button type="link" href={routes.ticketStatuses.list({ slug })} styling="secondary">
              {t("navigation.tickets.statuses")}
            </Button>
          )}
          {hasPermission((x) => x.canCreateTicket && x.userManagement.canListUsers) && (
            <Button
              type="link"
              data-testid="create-ticket-button"
              href={routes.tickets.create({ slug })}
              onClick={() => posthog?.capture("clicked_create_ticket")}
            >
              {t("page.tickets.create-btn")}
            </Button>
          )}
        </>
      }
      header={
        <div className="flex flex-col gap-2">
          <div className="flex w-full grow flex-wrap items-center gap-4 lg:flex-nowrap">
            <div className="w-full max-w-[396px]">
              <SearchInput
                data-testid="search-input"
                ref={searchInputRef}
                placeholder={t("page.tickets.search.placeholder")}
                defaultValue={queryParams.Search}
                onChange={onSearch}
              />
            </div>
            <div className="flex w-full grow flex-wrap items-center gap-4 lg:flex-nowrap">
              <Button styling="secondary" onClick={filterMenuHandler.toggle} icon={<Icon name={iconFilterFunnel01} />}>
                <span className="flex items-center gap-2">
                  {t("page.tickets.header.button.filter")}
                  {filterCount > 0 && (
                    <span className="size-5 rounded-full bg-aop-basic-blue-500 text-caption text-white">
                      {filterCount}
                    </span>
                  )}
                </span>
              </Button>
              {filterCount > 0 && (
                <Button styling="secondary" onClick={clearFilters} icon={<Icon name={iconX} />}>
                  {t("page.tickets.header.button.filter-clear")}
                </Button>
              )}
              <div className="md:ml-auto">
                <Button
                  styling="secondary"
                  onClick={exportTickets}
                  isLoading={isDownloading}
                  icon={<Icon name={iconDownload01} />}
                >
                  {t("page.tickets.header.button.export")}
                </Button>
              </div>
            </div>
          </div>
          {isFilterMenuOpen && (
            <TicketFilters
              activeFilters={queryParams}
              onUpdateFilters={onUpdateFilters}
              values={ticketFilterValues || { assignees: [], statuses: [], categories: [], addressTypes: [] }}
              onClose={filterMenuHandler.setFalse}
            />
          )}
        </div>
      }
    >
      <ContentTabs<TicketTabs> onTabChange={onTabSelection} tabs={tabs} activeTabId={tab}>
        <TicketTableActions
          results={totalTickets}
          total={totalTicketsInTab}
          onUpdateFocus={(val) => onUpdateFilters("ResidentFocusMode", val)}
          sorting={sorting}
          onSort={onUpdateSorting}
          onMarkAsRead={markAsReadModalHandler.setTrue}
          tab={tab}
          isFocusModeOn={residentFocusMode}
          disableSorting={residentFocusMode && tab === IN_PROGRESS_TICKET_TAB}
        />
        {isMd ? (
          <TicketsTable
            hasFilters={hasFilters}
            tickets={tickets}
            hasMoreTickets={hasMoreTickets}
            loadMoreTickets={loadMoreTickets}
            isLoadingTickets={isLoadingTickets}
            isLoadingMoreTickets={isLoadingMoreTickets}
            tab={tab}
            sessionUser={sessionUser}
            onDelete={setTicketIdToDelete}
            onCopyQuickReplyLink={copyQuickReplyLink}
          />
        ) : (
          <TicketsMobileView
            hasFilters={hasFilters}
            tickets={tickets}
            hasMoreTickets={hasMoreTickets}
            loadMoreTickets={loadMoreTickets}
            isLoadingTickets={isLoadingTickets}
            isLoadingMoreTickets={isLoadingMoreTickets}
            tab={tab}
            sessionUser={sessionUser}
            onDelete={setTicketIdToDelete}
            onCopyQuickReplyLink={copyQuickReplyLink}
          />
        )}
      </ContentTabs>
      <ConfirmModal
        id="delete-ticket-modal"
        title={t("page.tickets.delete.modal.title")}
        description={t("page.tickets.delete.modal.text")}
        isLoading={false}
        theme="danger"
        onReject={() => setTicketIdToDelete(undefined)}
        rejectBtnProps={{
          "data-testid": "delete-ticket-modal-cancel",
        }}
        onResolve={() => {
          deleteTicket(ticketIdToDelete!);
          setTicketIdToDelete(undefined);
        }}
        resolveBtnProps={{
          "data-testid": "delete-ticket-modal-confirm",
          text: t("common.action.delete"),
        }}
        isOpen={ticketIdToDelete !== undefined}
        shouldCloseOnEsc
        data-testid="delete-ticket-modal"
        onOpenChange={(state) => {
          if (!state) {
            setTicketIdToDelete(undefined);
          }
        }}
      />
      <ConfirmModal
        id="mark-all-as-read-ticket-modal"
        title={t("page.tickets.mark-all-as-read.modal.title")}
        description={t("page.tickets.mark-all-as-read.modal.text")}
        isLoading={false}
        theme="info"
        onReject={markAsReadModalHandler.setFalse}
        rejectBtnProps={{
          "data-testid": "mark-all-as-read-ticket-modal-cancel",
        }}
        onResolve={() => {
          markAsRead();
          markAsReadModalHandler.setFalse();
          posthog?.capture("clicked_maar_project_tickets");
        }}
        resolveBtnProps={{
          "data-testid": "mark-all-as-read-ticket-modal-confirm",
          text: t("common.action.confirm"),
        }}
        isOpen={isMarkAsReadModalOpen}
        onOpenChange={markAsReadModalHandler.set}
        shouldCloseOnEsc
        data-testid="mark-all-as-read-ticket-modal"
      />
    </DocumentPaper>
  );
}

interface TicketsTableProps {
  sessionUser: SelfDto;
  tickets: AdminTicketDetailedListItemDto[];
  hasMoreTickets: boolean | undefined;
  loadMoreTickets: () => void;
  isLoadingTickets: boolean;
  isLoadingMoreTickets: boolean;
  tab: TicketTabs;
  hasFilters: boolean;
  onDelete: (ticketId: string) => void;
  onCopyQuickReplyLink: (ticketId: string) => void;
}

function TicketsTable({
  tickets,
  hasMoreTickets,
  loadMoreTickets,
  isLoadingTickets,
  isLoadingMoreTickets,
  hasFilters,
  tab,
  sessionUser,
  onDelete,
  onCopyQuickReplyLink,
}: TicketsTableProps): React.ReactNode {
  const slug = useSlug();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const posthog = usePostHog();

  const handleRowClick = useCallback(
    (row: Row<AdminTicketDetailedListItemDto>) => {
      posthog?.capture("clicked_ticket");
      navigate(routes.tickets.details({ slug, id: row.original.id }));
    },
    [posthog, navigate, slug],
  );

  const ref = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingMoreTickets && hasMoreTickets) {
        loadMoreTickets();
      }
    }, [isLoadingMoreTickets, hasMoreTickets, loadMoreTickets]),
  });

  const columns = useMemo(() => {
    const helper = createColumnHelper<AdminTicketDetailedListItemDto>();

    return [
      helper.accessor("visibility", {
        header: "",
        cell: (cell) =>
          cell.getValue() !== "private" ? (
            <div className="flex size-full items-center justify-center bg-aop-basic-blue-500 px-2 text-white">
              <Icon name={iconUsers01} />
            </div>
          ) : null,
        size: 32,
      }),
      helper.accessor("status", {
        header: t("page.tickets.table.header.status"),
        cell: (cell) => (
          <div
            className="flex h-8 max-w-[200px] items-center justify-center truncate rounded px-2 text-caption-bold text-white"
            style={{
              textTransform: "uppercase",
              color: cell.getValue().color,
              backgroundColor: `${cell.getValue().color}1A`,
            }}
          >
            {cell.getValue().name}
          </div>
        ),
      }),
      helper.accessor("createdAt", {
        header: t("page.tickets.table.header.created-at"),
        cell: (cell) => (
          <span className="flex whitespace-nowrap text-body-bold">
            {t("page.tickets.table.content.created-at", {
              count: daysBetween(parseISO(cell.getValue()), new Date()),
            })}
          </span>
        ),
      }),
      helper.accessor("closedAt", {
        header: t("page.tickets.table.header.closed-at"),
        cell: (cell) => (
          <span className="text-body">
            {t("page.tickets.table.content.closed-at", {
              when: formatDate(i18n, "dateMonthYearShort", cell.getValue()!),
              who: cell.row.original.closedBy?.fullName || cell.row.original.user.fullName,
            })}
          </span>
        ),
        minSize: 256,
      }),
      helper.accessor("title", {
        header: t("page.tickets.table.header.title"),
        cell: (cell) => (
          <div data-testid="ticket-title-cell" className="flex flex-col gap-0.5">
            <Anchor style="inherit" to={routes.tickets.details({ slug, id: cell.row.original.id })} noPropagation>
              <span onClick={() => posthog?.capture("clicked_ticket")} className="line-clamp-2 min-w-80 text-body-bold">
                {cell.getValue()}
              </span>
            </Anchor>
            <span className="text-overline text-grey-400">
              <span className="flex items-center gap-1">
                {cell.row.original.remindAt && <Icon name={iconClock} size={12} />}
                {tab === REMINDER_TICKET_TAB
                  ? formatDate(i18n, "datetimeShort", cell.row.original.remindAt!)
                  : t("page.tickets.table.content.title", {
                      when: formatDate(i18n, "datetimeShort", cell.row.original.lastActivityAt),
                      who: cell.row.original.lastActivityBy?.fullName || cell.row.original.user.fullName,
                    })}
              </span>
            </span>
          </div>
        ),
        minSize: 256,
      }),
      helper.accessor("user", {
        header: t("page.tickets.table.header.reporter"),
        cell: (cell) => (
          <div className="flex items-center gap-2">
            <div className="size-8">
              <UserAvatar img={cell.getValue().avatar} isUserDeleted={!!cell.getValue().deletedAt} />
            </div>
            <div className="flex flex-col gap-0.5">
              <span className="text-body-bold">{cell.getValue().fullName}</span>
              <span className="text-overline text-grey-400">
                {cell.row.original.company?.name ||
                  (cell.row.original.address ? formatAddress(cell.row.original.address) : undefined)}
              </span>
            </div>
          </div>
        ),
        minSize: 256,
      }),
      helper.accessor("rating", {
        header: t("page.tickets.table.header.rating"),
        cell: (cell) =>
          cell.getValue() ? (
            <div className="flex w-fit items-center gap-1 rounded-md bg-yellow-100 px-2 py-1 text-center">
              <Icon name={iconStar01} className="fill-yellow-400 text-yellow-500" />
              <span className="text-body-bold">{cell.getValue()}</span>
            </div>
          ) : null,
      }),
      helper.accessor("activityCount", {
        header: t("page.tickets.table.header.activity"),
        cell: (cell) => (
          <div className="flex items-center gap-2">
            <Icon
              name={iconMessageCircle01}
              className={
                cell.row.original.hasUnreadActivity ? "fill-aop-basic-blue-500/80 text-aop-basic-blue-500" : undefined
              }
            />
            <span className="text-body-bold">{cell.getValue()}</span>
          </div>
        ),
      }),
      helper.accessor("assignee", {
        header: t("page.tickets.table.header.assignee"),
        cell: (cell) =>
          cell.getValue() ? (
            <div className="flex items-center gap-2">
              <div className="size-8">
                <UserAvatar img={cell.getValue()?.avatar} isUserDeleted={!!cell.getValue()?.deletedAt} />
              </div>
              <div>
                <span className="text-body">{cell.getValue()!.fullName}</span>
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <div className="size-8">
                <UserAvatar img={undefined} />
              </div>
              <div>
                <span className="text-body">{t("page.tickets.table.content.unassigned")}</span>
              </div>
            </div>
          ),
        minSize: 256,
      }),
      helper.accessor("rems", {
        header: "",
        cell: ({ cell }) => <RemsStatus rems={cell.getValue()} />,
      }),
      helper.accessor("id", {
        header: "",
        cell: ({ cell }) => {
          const actions = getTicketActions(
            t,
            sessionUser,
            () => onDelete(cell.getValue()),
            () => onCopyQuickReplyLink(cell.getValue()),
          );

          return (
            <div className="flex justify-end px-2">
              <ContextMenu actions={actions} />
            </div>
          );
        },
      }),
    ];
  }, [i18n, onDelete, onCopyQuickReplyLink, sessionUser, t, tab, slug, posthog]);

  const tableState = useMemo(
    () =>
      ({
        columnVisibility: {
          status: tab !== NEW_TICKET_TAB,
          closedAt: tab === CLOSED_TICKET_TAB,
          createdAt: tab !== CLOSED_TICKET_TAB,
          rating: tab === CLOSED_TICKET_TAB,
          assignee: tab !== CLOSED_TICKET_TAB,
        },
      }) satisfies Partial<TableState>,
    [tab],
  );

  const table = useReactTable({
    columns: columns,
    data: tickets,
    getCoreRowModel: getCoreRowModel(),
    state: tableState,
  });

  return (
    <div className="overflow-auto">
      {isLoadingTickets ? (
        <div className="p-4">
          <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
        </div>
      ) : (
        <>
          {tickets.length > 0 ? (
            <Table table={table} onRowClick={handleRowClick} firstCellIsIndicator hideBorder />
          ) : (
            <span className="block p-6 pb-12 text-caption-bold">
              {hasFilters ? t("page.tickets.table.no-results") : t("page.tickets.table.empty")}
            </span>
          )}
          {hasMoreTickets && (
            <div className="h-16 p-4" ref={ref}>
              <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
            </div>
          )}
        </>
      )}
    </div>
  );
}

function TicketsMobileView({
  sessionUser,
  hasFilters,
  hasMoreTickets,
  isLoadingMoreTickets,
  isLoadingTickets,
  loadMoreTickets,
  tab,
  tickets,
  onDelete,
  onCopyQuickReplyLink,
}: TicketsTableProps) {
  const slug = useSlug();
  const { t, i18n } = useTranslation();
  const posthog = usePostHog();

  const ref = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingMoreTickets && hasMoreTickets) {
        loadMoreTickets();
      }
    }, [isLoadingMoreTickets, hasMoreTickets, loadMoreTickets]),
  });

  return (
    <div className="bg-aop-off-white pb-2">
      {isLoadingTickets ? (
        <div className="p-4">
          <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
        </div>
      ) : (
        <>
          {tickets.length > 0 ? (
            <div className="flex flex-col gap-5 py-5">
              {tickets.map((ticket) => {
                const actions = getTicketActions(
                  t,
                  sessionUser,
                  () => onDelete(ticket.id),
                  () => onCopyQuickReplyLink(ticket.id),
                );

                return (
                  <Link
                    className="flex flex-col gap-4 rounded-lg bg-white p-4 shadow-md hover:shadow-lg"
                    key={ticket.id}
                    to={routes.tickets.details({ slug, id: ticket.id })}
                    onClick={() => posthog?.capture("clicked_ticket")}
                  >
                    <div className="flex flex-col gap-0.5">
                      <span className="flex items-start justify-between gap-2">
                        <span className="flex items-center gap-2">
                          {ticket.visibility !== "private" ? (
                            <span className="inline-flex items-center justify-center rounded bg-aop-basic-blue-500 p-1.5 text-white">
                              <Icon name={iconUsers01} />
                            </span>
                          ) : null}
                          <span className="line-clamp-2 text-body-bold">{ticket.title}</span>
                        </span>
                        <ContextMenu actions={actions} />
                      </span>
                      <span className="text-overline text-grey-400">
                        {t("page.tickets.table.content.title", {
                          when: formatDate(i18n, "datetimeShort", ticket.lastActivityAt),
                          who: ticket.lastActivityBy?.fullName || ticket.user.fullName,
                        })}
                      </span>
                    </div>
                    <div className="flex items-center justify-between gap-2">
                      <div className="flex items-center gap-2">
                        <div className="size-8">
                          <UserAvatar img={ticket.user.avatar} isUserDeleted={!!ticket.user.deletedAt} />
                        </div>
                        <div className="flex flex-col gap-0.5">
                          <span className="text-caption-bold">{ticket.user.fullName}</span>
                          <span className="text-overline text-grey-400">
                            {ticket.company?.name || (ticket.address ? formatAddress(ticket.address) : "-")}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-wrap gap-2">
                        {tab === CLOSED_TICKET_TAB && ticket.rating != null ? (
                          <div className="flex w-fit items-center gap-1 rounded-md bg-yellow-100 px-2 py-1 text-center">
                            <Icon name={iconStar01} className="fill-yellow-400 text-yellow-500" />
                            <span className="text-body-bold">{ticket.rating}</span>
                          </div>
                        ) : null}
                        <div className="flex items-center gap-2">
                          <Icon name={iconMessageCircle01} />
                          <span className="text-body-bold">{ticket.activityCount}</span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap items-center gap-x-2 gap-y-1.5">
                      <div
                        className="inline-block rounded-md px-2 py-1 text-center"
                        style={{
                          textTransform: "uppercase",
                          color: ticket.status.color,
                          backgroundColor: `${ticket.status.color}1A`,
                        }}
                      >
                        <span className="text-caption-bold">{ticket.status.name}</span>
                      </div>

                      <div className="rounded bg-blue-100 px-2 py-1 text-caption">
                        <span className="text-body-bold">
                          {ticket.assignee?.fullName || t("page.tickets.table.content.unassigned")}
                        </span>
                      </div>

                      {tab === NEW_TICKET_TAB && (
                        <span className="flex text-body-bold">
                          {t("page.tickets.table.content.created-at.mobile-prefix")}{" "}
                          {t("page.tickets.table.content.created-at", {
                            count: daysBetween(parseISO(ticket.createdAt), new Date()),
                          })}
                        </span>
                      )}

                      <RemsStatus rems={ticket.rems} />
                    </div>
                  </Link>
                );
              })}
            </div>
          ) : (
            <span className="block p-6 pb-12 text-caption-bold">
              {hasFilters ? t("page.tickets.table.no-results") : t("page.tickets.table.empty")}
            </span>
          )}
          {hasMoreTickets && (
            <div className="h-16 p-4" ref={ref}>
              {(isLoadingTickets || isLoadingMoreTickets) && <LoadingIcon className="inset-0 mx-auto my-4 w-6" />}
            </div>
          )}
        </>
      )}
    </div>
  );
}

interface TickerFiltersProps {
  activeFilters: TicketsFilters;
  onUpdateFilters: (filter: TicketFilterParams, val: TicketFilterTypes) => void;
  values: AdminTicketFiltersDto;
  onClose: () => void;
}

function TicketFilters({ activeFilters, onUpdateFilters, values, onClose }: TickerFiltersProps): React.ReactNode {
  const { t } = useTranslation();
  const sessionUser = useSessionUser();

  // We're creating this object because the Select component checks the existence of items in the selected list by reference
  const unassignedUser = { id: "", fullName: t("page.tickets.header.filter.assignee.unassigned") };

  return (
    <div className="flex flex-col gap-4 border-t border-grey-100 py-2">
      <span className="text-caption-bold">{t("page.tickets.header.filter.label")}</span>
      <div className="flex flex-col gap-2">
        <div className="flex h-full flex-col flex-wrap items-center gap-4 md:flex-row">
          <div className="min-w-52">
            <CheckboxMultiSelect
              items={[unassignedUser, ...values.assignees]}
              selected={[unassignedUser, ...values.assignees].filter((x) => activeFilters.AssigneeIds?.includes(x.id))}
              onChange={(x) =>
                onUpdateFilters(
                  "AssigneeIds",
                  x.map((x) => x.id),
                )
              }
              renderValue={(value) => (
                <span className="block max-w-36 truncate pr-5">{value.map((x) => x.fullName).join(", ")}</span>
              )}
              placeholder={t("page.tickets.header.filter.assignee")}
              renderOption={(x) => x.fullName}
              keySelector={(x) => x.id}
            />
          </div>
          <div className="min-w-52">
            <CheckboxMultiSelect
              items={ticketRatingValues}
              selected={ticketRatingValues.filter((x) => activeFilters.Ratings?.includes(x))}
              onChange={(x) => onUpdateFilters("Ratings", x)}
              placeholder={t("page.tickets.header.filter.rating")}
              renderOption={(x) => {
                switch (x) {
                  case -1:
                    return t("page.tickets.header.filter.rating.not-rated");
                  default:
                    return (
                      <div className="flex w-fit items-center gap-1 rounded-md bg-yellow-100 px-2 text-center">
                        <Icon name={iconStar01} className="fill-yellow-400 text-yellow-500" />
                        <span className="text-caption-bold">{x}</span>
                      </div>
                    );
                }
              }}
              keySelector={(x) => x}
            />
          </div>
          <div className="min-w-52">
            <CheckboxMultiSelect
              className="max-h-min"
              items={values.statuses}
              selected={values.statuses.filter((x) => activeFilters.StatusIds?.includes(x.id))}
              onChange={(x) =>
                onUpdateFilters(
                  "StatusIds",
                  x.map((x) => x.id),
                )
              }
              placeholder={t("page.tickets.header.filter.status")}
              renderOption={(x) => (
                <div
                  className="w-full rounded-md px-2 text-center"
                  style={{ textTransform: "uppercase", color: x.color, backgroundColor: `${x.color}1A` }}
                >
                  <span className="text-caption-bold">{x.name || "-"}</span>
                </div>
              )}
              keySelector={(x) => x.id}
            />
          </div>
          <div className="min-w-52">
            <CheckboxMultiSelect
              items={values.categories}
              selected={values.categories.filter((x) => activeFilters.CategoryIds?.includes(x.id))}
              onChange={(x) =>
                onUpdateFilters(
                  "CategoryIds",
                  x.map((x) => x.id),
                )
              }
              renderValue={(value) => (
                <span className="block max-w-36 truncate pr-5">{value.map((x) => x.name).join(", ")}</span>
              )}
              placeholder={t("page.tickets.header.filter.category")}
              renderOption={(x) => x.name}
              keySelector={(x) => x.id}
            />
          </div>
          <div className="min-w-52">
            <Select
              items={ticketVisibilityValues}
              selected={activeFilters.Filter}
              emptyItem={t("page.tickets.header.filter.private-collective")}
              onChange={(x) => onUpdateFilters("Filter", x)}
              renderOption={(x) => {
                switch (x) {
                  case "private":
                    return t("page.tickets.header.filter.filter.private");
                  case "collective":
                    return t("page.tickets.header.filter.filter.collective");
                }
              }}
              keySelector={(x) => x}
            />
          </div>
          {sessionUser.project.type === "addressBased" && (
            <div className="min-w-52">
              <CheckboxMultiSelect
                items={values.addressTypes}
                selected={values.addressTypes.filter((x) => activeFilters.AddressTypes?.includes(x))}
                renderValue={(value) => <span className="block max-w-36 truncate pr-5">{value.join(", ")}</span>}
                onChange={(x) => onUpdateFilters("AddressTypes", x)}
                placeholder={t("page.tickets.header.filter.address-type")}
                renderOption={(x) => x}
                keySelector={(x) => x}
              />
            </div>
          )}
          <label className="flex items-center gap-2" htmlFor="tickets_filter_unread">
            <Checkbox
              name="tickets_filter_unread"
              checked={activeFilters.UnreadOnly}
              onChange={(e) => onUpdateFilters("UnreadOnly", e.target.checked)}
            />
            <span>{t("page.tickets.header.filter.unread")}</span>
          </label>
        </div>
        <div className="self-center justify-self-end">
          <Button styling="tertiary" onClick={onClose} icon={<Icon name={iconX} />}>
            {t("page.tickets.header.button.filter-close")}
          </Button>
        </div>
      </div>
    </div>
  );
}

function getTicketActions(
  t: TFunction,
  sessionUser: SelfDto,
  onDelete: () => void,
  copyQuickReplyLink: () => void,
): ContextMenuAction[] {
  const actions: ContextMenuAction[] = [];

  if (sessionUser.isSuperAdmin) {
    actions.push({
      text: t("page.tickets.table.context-menu.create-quick-reply-link"),
      icon: <Icon name={iconCopy01} />,
      callback: copyQuickReplyLink,
    });
    actions.push({
      text: t("page.tickets.table.context-menu.delete"),
      icon: <Icon name={iconTrash02} />,
      callback: onDelete,
    });
  }

  return actions;
}
