import type { i18n, TFuncKey, TFunction } from "i18next";
import type { Validate } from "react-hook-form";

import { isValidString } from "./validation";

export function createRequiredStringRule<T extends string>(
  t: i18n["t"] | TFunction,
  fieldName: TFuncKey,
  interpolation?: object,
): Validate<any, {}> {
  return (value: T | undefined) => {
    if (value == null || !isValidString(value)) {
      return t("components.form.error.required", {
        inputName: interpolation ? t(fieldName, interpolation as any) : t(fieldName),
      } as any);
    }
  };
}
