import { Icon } from "components/Icon/Icon";
import { twResolve } from "helpers/tw-resolve";
import type React from "react";
import { useTranslation } from "react-i18next";

export type NoticeProps = {
  icon?: string;
  "data-testid"?: string;
  type?: "info" | "error" | "warning" | "positive";
  onExpand?: () => void;
} & (
  | {
      message: string;
      customBody?: never;
    }
  | {
      message?: never;
      customBody: React.ReactNode;
    }
);

export function Notice({
  icon,
  message,
  customBody,
  type = "info",
  onExpand,
  "data-testid": dataTestId,
}: NoticeProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div
      className={twResolve(
        "flex w-full items-center gap-4 rounded-lg border p-4",
        type === "info" && "border-blue-600 bg-blue-100 [&>*]:text-blue-600",
        type === "error" && "border-red-600 bg-red-100 [&>*]:text-red-600",
        type === "positive" && "border-green-600 bg-green-100 [&>*]:text-green-600",
        type === "warning" && "border-yellow-600 bg-yellow-100 [&>*]:text-yellow-600",
      )}
      data-testid={dataTestId}
    >
      {icon && <Icon name={icon} size={24} />}
      {message && <p className="text-caption-bold">{message}</p>}
      {customBody}
      {onExpand && (
        <button type="button" onClick={onExpand} className="ml-auto min-h-6 shrink-0 text-caption-bold underline">
          {t("common.action.see-more")}
        </button>
      )}
    </div>
  );
}
