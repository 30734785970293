import { useMutation } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { ImageDto } from "api/types";
import { useFlashToast } from "components/FlashToast/FlashToast";
import type { FormImage } from "components/ImageInput/useImageInput";
import { isImageUploaded } from "components/ImageInput/useImageInput";
import { parseErrorMessage } from "helpers/Network/errors";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export function useUploadImage(): {
  uploadImage: UploadImage;
  uploadFormImage: UploadFormImage;
  isUploadingImage: boolean;
} {
  const { t } = useTranslation();
  const api = useApi();
  const showFlashToast = useFlashToast();

  const { mutateAsync: uploadImage, isPending: isUploadingImage } = useMutation({
    mutationFn: ({ file, description }: ImageRequest) => {
      const payload: { File: File; Description?: string } = { File: file };
      if (description) {
        payload.Description = description;
      }

      return api.postFilesImagesV1(payload).then(({ data }) => data);
    },
    onError: (error) => {
      showFlashToast({
        type: "error",
        title: t("model.image.action.upload.error"),
        description: parseErrorMessage(error),
      });
    },
  });

  const uploadFormImage = useCallback(
    async (formImage: FormImage | undefined): Promise<ImageDto | undefined> => {
      if (!formImage) {
        return undefined;
      }

      if (isImageUploaded(formImage)) {
        return formImage;
      }

      return await uploadImage(formImage);
    },
    [uploadImage],
  );

  return { uploadImage, uploadFormImage, isUploadingImage };
}

interface ImageRequest {
  file: File;
  description?: string | undefined;
}

export type UploadImage = (image: ImageRequest) => Promise<ImageDto>;
export type UploadFormImage = (image: FormImage | undefined) => Promise<ImageDto | undefined>;
