import { ErrorPage } from "components/Error/ErrorPage";
import { Redirect } from "components/Routing/Redirect";
import { RequireAuth } from "components/Routing/RequireAuth";
import { EventCreateOrEditPage } from "modules/events/pages/CreateOrEdit";
import { EventDetailsPage } from "modules/events/pages/Details";
import type { RouteObject } from "react-router-dom";
import { routes } from "routes";

import { canListEvents, canManageEvents } from "./permissions";
import { eventsRoutes } from "./routes";

export const eventsRoute = [
  {
    path: "",
    element: <Redirect to={routes.calendar.list} />,
  },
  {
    path: eventsRoutes.create,
    element: (
      <RequireAuth permission={canManageEvents}>
        <EventCreateOrEditPage />
      </RequireAuth>
    ),
  },
  {
    path: eventsRoutes.edit,
    element: (
      <RequireAuth permission={canManageEvents}>
        <EventCreateOrEditPage />
      </RequireAuth>
    ),
  },
  {
    path: eventsRoutes.details,
    element: (
      <RequireAuth permission={canListEvents}>
        <EventDetailsPage />
      </RequireAuth>
    ),
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
