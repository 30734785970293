import { twResolve } from "helpers/tw-resolve";

export interface Icon54Props {
  className?: string;
  charCode?: number;
  char?: string | null;
  "data-testid"?: string;
  disableUserSelect?: boolean;
}

export function Icon54({
  className,
  charCode,
  char: charProp,
  "data-testid": dataTestid,
  disableUserSelect,
}: Icon54Props): React.ReactNode {
  if (charCode != null && (charCode < ICON_54.min || charCode > ICON_54.max)) {
    console.error(`CharCode should be in range [${ICON_54.min} - ${ICON_54.max}]`);
  }

  const char = charCode != null ? getCharFromCharCode(charCode) : charProp;

  return (
    <span
      data-icon={char}
      className={twResolve(
        "relative inline-flex w-[3.5rem] font-icon text-[3.5rem] !leading-none before:m-auto before:content-[attr(data-icon)]",
        className,
      )}
      data-testid={dataTestid}
    >
      {disableUserSelect ? null : <span className="absolute inset-0 text-transparent">{char}</span>}
    </span>
  );
}

export const ICON_54 = {
  min: 59648,
  max: 61765,
};

export function getCharFromCharCode(charCode: number): string {
  return String.fromCharCode(charCode);
}

export const CHAR_CODES = [
  60700, 60845, 61286, 61718, 61107, 61563, 60894, 59655, 60337, 60066, 60832, 60986, 59906, 60114, 60431, 59656, 60328,
  59811, 61024, 60083, 60648, 60476, 59705, 61466, 60859, 59658, 61436, 61434, 60686, 61512, 60795, 60550, 59659, 60296,
  60003, 59977, 60698, 60874, 60173, 61460, 60573, 60218, 61338, 60688, 60663, 60052, 60055, 61108, 61394, 60401, 61415,
  60969, 60851, 60596, 60605, 59648, 60115, 61320, 59949, 60206, 59738, 60039, 60006, 59751, 60222, 61688, 59682, 60051,
  59873, 59714, 59664, 60645, 61363, 60756, 59662, 59716, 60944, 61534, 60600, 59843, 60665, 60849, 59671, 60176, 59791,
  61437, 61375, 59969, 61125, 60674, 59752, 59922, 60002, 61043, 60132, 60574, 59841, 59672, 59684, 60492, 59727, 61748,
  60870, 61690, 59689, 60860, 61525, 60754, 61358, 61540, 59744, 60483, 61739, 60568, 60988, 61499, 60652, 61000, 61445,
  59959, 60452, 59685, 60125, 60989, 61367, 59956, 59991, 60081, 60056, 61115, 60722, 60770, 61342, 59740, 60646, 61106,
  60065, 60501, 60395, 60740, 60045, 60032, 61468, 59770, 59803, 60667, 59837, 60604, 61228, 60089, 61526, 60047, 60418,
  60841, 60008, 59885, 60846, 60518, 60810, 60356, 60514, 60567, 60875, 61130, 61725, 60209,
];
// export const CHAR_CODES = Array.from(Array(ICON_54.max - ICON_54.min + 1), (_, i) => ICON_54.min + i);
