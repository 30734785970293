import { AIButton } from "components/Button/AIButton";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormField } from "components/Form/FormField";
import { FormTextArea } from "components/Form/FormTextArea";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { ticketMutations } from "queries/tickets";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type TicketCopilotProps = ModalBaseProps & {
  onGenerated: (message: string) => void;
  ticketId: string;
  defaultInput?: FormValues;
};

interface FormValues {
  input: string;
}

export function TicketAICopilotModal({
  isOpened,
  onOpenChange,
  onGenerated,
  ticketId,
  defaultInput,
}: TicketCopilotProps): React.ReactNode {
  const { t } = useTranslation();
  const form = useForm<FormValues>({ defaultValues: defaultInput });

  useEffect(() => {
    if (defaultInput) {
      form.reset(defaultInput);
    }
  }, [defaultInput, form]);

  const ticketSuggestion = ticketMutations.useGetTicketCopilotSuggestion(ticketId);

  async function handleSubmit() {
    const input = form.getValues("input");

    let suggestion = "";
    suggestion = await ticketSuggestion
      .mutateAsync(input ? { additionalInfo: input } : undefined)
      .then((res) => res.response);

    onGenerated(suggestion);

    form.reset();
    onOpenChange(false);
  }

  return (
    <Modal.Root
      title={t("page.tickets.details.copilot.modal.title")}
      description={t("page.tickets.details.copilot.modal.description")}
      isOpened={isOpened}
      onOpenChange={onOpenChange}
      shouldCloseOnClickOutside={false}
      shouldCloseOnEsc={false}
      isNested
    >
      <Form formMethods={form} onSubmit={handleSubmit}>
        <FormField label={t("page.tickets.details.copilot.modal.text-area.label")} htmlFor="input">
          <FormTextArea
            id="input"
            name="input"
            data-testid="ai-copilot-input"
            placeholder={t("page.tickets.details.copilot.modal.text-area.placeholder")}
          />
        </FormField>
        <Modal.Actions>
          <Button
            type="button"
            styling="secondary"
            disabled={ticketSuggestion.isPending}
            onClick={() => onOpenChange(false)}
          >
            {t("common.action.cancel")}
          </Button>
          <AIButton data-testid="submit-ai-response" type="submit" isLoading={ticketSuggestion.isPending}>
            {t("page.tickets.details.copilot.modal.button")}
          </AIButton>
        </Modal.Actions>
      </Form>
    </Modal.Root>
  );
}
