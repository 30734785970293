import type { QuestionDto, SurveyDetailsDto } from "api/types";

export const surveyCategories: SurveyDetailsDto["category"][] = ["other", "feelAtHome"];

export const questionTypes: QuestionDto["type"][] = ["multipleChoice", "yesNo", "open", "rating"];

export const feelingAtHomeTemplate = {
  title: "template.survey.feel-at-home.title",
  description: "template.survey.feel-at-home.description",
  thankYouMessage: "template.survey.feel-at-home.thank-you-message",
  questions: [
    {
      title: "template.survey.feel-at-home.questions.1.text",
      type: "rating",
      useForAnalysis: false,
    },
    {
      title: "template.survey.feel-at-home.questions.2.text",
      type: "rating",
      useForAnalysis: false,
    },
    {
      title: "template.survey.feel-at-home.questions.3.text",
      type: "multipleChoice",
      options: [
        { option: "template.survey.feel-at-home.questions.3.options.1.text" },
        { option: "template.survey.feel-at-home.questions.3.options.2.text" },
        { option: "template.survey.feel-at-home.questions.3.options.3.text" },
        { option: "template.survey.feel-at-home.questions.3.options.4.text" },
        { option: "template.survey.feel-at-home.questions.3.options.5.text" },
      ],
    },
    {
      title: "template.survey.feel-at-home.questions.4.text",
      type: "open",
    },
    {
      title: "template.survey.feel-at-home.questions.5.text",
      type: "open",
    },
    {
      title: "template.survey.feel-at-home.questions.6.text",
      type: "open",
    },
  ],
};
