import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { t } from "i18next";
import { useForm } from "react-hook-form";

export function ZiezodanForm({
  defaultValues,
  isSubmitting,
  onSubmit,
}: {
  defaultValues: Partial<ZiezodanFormValues>;
  isSubmitting: boolean;
  onSubmit: (data: ZiezodanFormValues) => void;
}): React.ReactNode {
  const formMethods = useForm<ZiezodanFormValues>({ defaultValues });

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <FormContent maxWidth="3xl">
        <FormField label={""} htmlFor="enabled">
          <FormCheckbox<ZiezodanFormValues, "enabled">
            id="enabled"
            name="enabled"
            label={t("page.integration.ziezodan.form.enabled")}
          />
        </FormField>

        <Button type="submit" isLoading={isSubmitting}>
          {t("page.integration.ziezodan.submit")}
        </Button>
      </FormContent>
    </Form>
  );
}

interface ZiezodanFormValues {
  enabled: boolean;
}
