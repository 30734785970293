import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { ConnectedProjectDto } from "api/types";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { QUERY_KEYS } from "query-keys";

export function useConnectedProjects(): UseQueryResult<ConnectedProjectDto[]> {
  const api = useApi();

  return useQuery({
    queryKey: QUERY_KEYS.CONNECTED_PROJECTS,
    queryFn: () => api.getSelfProjectsV1(),
    select: (data) => commonAPIDataSelector(data).projects,
    staleTime: 1000 * 60 * 5,
    gcTime: Infinity,
  });
}
