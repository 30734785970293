import type { ChatDto } from "api/types";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { LoadingIcon } from "components/Icons/Icons";
import { useOnIntersection } from "hooks/useOnIntersection";
import { ChatListItem } from "modules/chats/components/ChatListItem";
import type React from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

interface ChatListViewProps {
  chats: ChatDto[];
  fetchMoreChats: () => void;
  isFetchingInitialChats: boolean;
  isFetchingMoreChats: boolean;
  isMoreChatAvailable: boolean;
}

export function ChatListView({
  chats,
  isFetchingInitialChats,
  isFetchingMoreChats,
  isMoreChatAvailable,
  fetchMoreChats,
}: ChatListViewProps): React.ReactNode {
  const { t } = useTranslation();
  const refLoader = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isFetchingMoreChats && isMoreChatAvailable) {
        fetchMoreChats();
      }
    }, [fetchMoreChats, isMoreChatAvailable, isFetchingMoreChats]),
  });

  if (isFetchingInitialChats) {
    return <FullSizeLoader withPadding />;
  }

  if (chats.length === 0) {
    return (
      <div className="rounded-md bg-white p-4">
        <span className="text-caption text-grey-600">{t("page.chats.no-chats")}</span>
      </div>
    );
  }

  return (
    <div className="overflow-y-auto rounded-md bg-white">
      <ul>
        {chats.map((chat, idx) => (
          <ChatListItem key={chat.id} chatDetails={chat} index={idx} isLast={idx === chats.length - 1} />
        ))}
      </ul>
      {isMoreChatAvailable && (
        <div className="p-4" ref={refLoader}>
          <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
        </div>
      )}
    </div>
  );
}
