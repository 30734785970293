import { ErrorPage } from "components/Error/ErrorPage";
import { canAccessProjectBuildings } from "components/ProjectSidebar/permissions";
import { RequireAuth } from "components/Routing/RequireAuth";
import type { RouteObject } from "react-router-dom";

import { CreateOrEditBuildingPage } from "./pages/CreateOrEdit";
import { BuildingsListPage } from "./pages/List";
import { canManageBuildings } from "./permissions";
import { buildingRoutes } from "./routes";

export const buildingsRoute = [
  {
    path: buildingRoutes.list,
    element: (
      <RequireAuth permission={canAccessProjectBuildings}>
        <BuildingsListPage />
      </RequireAuth>
    ),
  },
  {
    path: buildingRoutes.new,
    element: (
      <RequireAuth permission={canManageBuildings}>
        <CreateOrEditBuildingPage />
      </RequireAuth>
    ),
  },
  {
    path: buildingRoutes.edit,
    element: (
      <RequireAuth permission={canManageBuildings}>
        <CreateOrEditBuildingPage />
      </RequireAuth>
    ),
  },
  {
    path: "*",
    element: <ErrorPage status={404} />,
  },
] satisfies RouteObject[];
