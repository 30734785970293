import Session from "supertokens-web-js/recipe/session";

export async function getAccessTokenPayload(): Promise<
  | {
      emails: string[];
      exp: number;
      iat: number;
      iss: string;
      rsub: string;
      sessionHandle: string;
      "st-ev"?: { v: boolean; t: number };
      sub: string;
      tId: string;
      // Perhaps there are more fields
    }
  | undefined
> {
  const token = await Session.getAccessToken();
  if (!token) {
    return;
  }

  const base64Payload = token.split(".")[1];
  const payload = base64UrlDecode(base64Payload);

  return JSON.parse(payload.toString());
}

function base64UrlDecode(str: string) {
  let output = str.replace(/-/g, "+").replace(/_/g, "/");
  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += "==";
      break;
    case 3:
      output += "=";
      break;
    default:
      throw new Error("base64 string is not of the correct length");
  }

  try {
    return b64DecodeUnicode(output);
  } catch (err) {
    return atob(output);
  }
}

function b64DecodeUnicode(str: string) {
  return decodeURIComponent(
    atob(str).replace(/(.)/g, (_m, p) => {
      let code = (p as string).charCodeAt(0).toString(16).toUpperCase();
      if (code.length < 2) {
        code = "0" + code;
      }

      return "%" + code;
    }),
  );
}
