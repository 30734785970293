import type { BadgeProps } from "components/Badge/Badge";
import { Badge } from "components/Badge/Badge";
import { addOrRemoveFromArray } from "helpers/util";
import type { ReactNode } from "react";
import type { FieldPath, FieldPathValue, FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";

import { FormErrorWrapper } from "./FormErrorWrapper";

export interface BadgeMultiSelectProps<T> {
  id?: string;
  selected: T[] | undefined;
  items: readonly T[];
  renderOption: (item: T, selected: boolean) => ReactNode;
  keySelector: (item: T) => string | number;
  "data-testid"?: string;
  "aria-invalid"?: boolean;
  isGrid?: boolean;
  isWideBadge?: boolean;
}
export type FormBadgeMultiSelectProps<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> = Omit<
  BadgeMultiSelectProps<FieldPathValue<TFormValues, TName>[number]> &
    Pick<BadgeProps<TFormValues>, "isBig" | "centerText">,
  "selected" | "onChange"
> &
  UseControllerProps<TFormValues>;

export function FormBadgeMultiSelect<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({ name, control, rules, defaultValue, ...props }: FormBadgeMultiSelectProps<TFormValues, TName>): React.ReactNode {
  const {
    field: { ref, ...field },
  } = useController<TFormValues>({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <FormErrorWrapper name={name}>
      <div className={props.isGrid ? "grid grid-cols-3 gap-2" : "flex flex-wrap gap-2"}>
        {props.items.map((item) => {
          const id = props.keySelector(item);

          return (
            <Badge
              data-testid={id}
              key={id}
              selected={field.value?.includes(item) ?? false}
              onClick={() => field.onChange(addOrRemoveFromArray(field.value, item))}
              onBlur={field.onBlur}
              tabIndex={0}
              as="button"
              type="button"
              isBig={props.isBig}
              centerText={props.centerText}
            >
              {props.renderOption(item, id === field.value)}
            </Badge>
          );
        })}
      </div>
    </FormErrorWrapper>
  );
}
