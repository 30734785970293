import { infiniteQueryOptions, queryOptions } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";

export const useAutomatedSurveyQueueQueries = () => {
  const api = useApi();
  const projectId = useProjectId();

  const questionAnswerLimit = 50;

  return {
    getQuestionSummary: (queueId: string, questionGroupId: string) =>
      queryOptions({
        queryKey: QUERY_KEYS.AUTOMATED_SURVEY_QUESTION_RESPONSE_SUMMARY(projectId, queueId, questionGroupId),
        queryFn: () => api.getAutomatedSurveysQueuesQuestionsDetailsSummaryV1(queueId, questionGroupId),
        select: commonAPIDataSelector,
        staleTime: 1000 * 60 * 5,
      }),
    getQuestionDetailsInfinite: (queueId: string, questionGroupId: string) =>
      infiniteQueryOptions({
        queryKey: QUERY_KEYS.AUTOMATED_SURVEY_QUESTION_DETAILS(projectId, queueId, questionGroupId),
        queryFn: async ({ pageParam = 0 }) => {
          const { data } = await api.getAutomatedSurveysQueuesQuestionsDetailsV1(queueId, questionGroupId, {
            Offset: pageParam * questionAnswerLimit,
            Limit: questionAnswerLimit,
          });

          return data;
        },
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages) => {
          if (!lastPage.questionGroupAnswers.hasMore) {
            return undefined;
          }

          return pages.length;
        },
      }),
  };
};
