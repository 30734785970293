import { useLogout } from "authentication/useLogout";
import { Anchor } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { getDeepLinkUrl } from "helpers/deep-link";
import { sleep } from "helpers/sleep";
import { useBool } from "hooks/useBool";
import { useInterval } from "hooks/useInterval";
import useIsWindowFocused from "hooks/useIsWindowFocused";
import { getLocalStorageValue, updateLocalStorage } from "hooks/useLocalStorage";
import { useQueryParam } from "hooks/useQueryParam";
import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "routes";
import { isEmailVerified, sendVerificationEmail, verifyEmail } from "supertokens-web-js/recipe/emailverification";
import { doesSessionExist } from "supertokens-web-js/recipe/session";
import Session from "supertokens-web-js/recipe/session";

import inboxPng from "../assets/inbox.png";
import { AuthenticationPage } from "../components/AuthenticationPage";
import { getAccessTokenPayload } from "../helpers/getAccessTokenPayload";

enum PageState {
  Initial,
  Expired,
  Sent,
  VerifiedDifferentDevice,
}

export function VerifyEmailPage(): React.ReactNode {
  const logout = useLogout();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const showFlashToast = useFlashToast();

  const initialState = location.state?.noLoader ? PageState.Sent : PageState.Initial;
  const [isSubmitting, submittingHandlers] = useBool();
  const [state, setState] = useState(initialState);

  const fromSignUp = useQueryParam("from")[0] === "signup";
  const signUpOrigin = useQueryParam("sign-up-origin")[0];
  const [queryParamEmail] = useQueryParam("email");
  const [token] = useQueryParam("token");
  const [brand] = useQueryParam("brand");

  const navigateToRegistration = useCallback(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    const type = queryParams.get("type");

    const newQueryParams = new URLSearchParams();
    if (code) {
      newQueryParams.set("code", code);
    }

    if (type) {
      newQueryParams.set("type", type);
    }

    navigate(`${routes.registration.main()}?${newQueryParams.toString()}`);
  }, [navigate]);

  const sendVerifyEmail = useCallback(
    async (resend?: boolean) => {
      if (resend || !location.state?.noLoader) {
        submittingHandlers.setTrue();
      }

      // Wait for 1.5 seconds to make sure the user actually sees the spinner
      const sleepPromise = resend ? sleep(1500) : undefined;

      try {
        const response = await sendVerificationEmail({
          options: {
            preAPIHook: (x) => {
              const url = new URL(x.url);

              const ogSearchParams = new URLSearchParams(window.location.search);
              for (const [key, value] of ogSearchParams.entries()) {
                if (!url.searchParams.has(key)) {
                  url.searchParams.set(key, value);
                }
              }

              url.searchParams.set("languageId", i18n.language);
              url.searchParams.set("origin", "web");

              return Promise.resolve({
                ...x,
                url: url.toString(),
              });
            },
          },
        });

        const userId = await Session.getUserId();

        updateLocalStorage(sentVerificationEmailStorageKey(userId), { date: Date.now() });

        if (response.status === "EMAIL_ALREADY_VERIFIED_ERROR") {
          // This can happen if the info about email verification in the session was outdated.
          navigateToRegistration();
        } else {
          // Make sure there's enough feedback for the user
          await sleepPromise;
          setState(PageState.Sent);

          if (resend) {
            showFlashToast({ type: "success", title: t("page.verify-email.sent.resend-toast") });
          }
        }
      } catch (err: any) {
        showFlashToast({ type: "error", title: t("common.generic-error") });
      } finally {
        submittingHandlers.setFalse();
      }
    },
    [i18n.language, location.state?.noLoader, navigateToRegistration, submittingHandlers, t, showFlashToast],
  );

  useEffect(() => {
    let cancelled = false;

    void (async () => {
      try {
        if (cancelled) {
          return;
        }

        // Sleep needed to make sure supertokens is initialized and can read the token...
        await sleep(100);
        if (cancelled) {
          return;
        }

        const status = await getEmailVerifiedStatus({ token, queryParamEmail });
        if (cancelled) {
          return;
        }

        switch (status) {
          case VerifyEmailStatus.NoSession:
            // Redirect to login page
            navigate(routes.authentication.login());
            break;
          case VerifyEmailStatus.Verified:
            navigateToRegistration();
            break;
          case VerifyEmailStatus.VerifiedDifferentDevice:
            setState(PageState.VerifiedDifferentDevice);
            break;
          case VerifyEmailStatus.Expired:
            setState(PageState.Expired);
            break;
          case VerifyEmailStatus.NotYetVerified: {
            // Instantly send email (on page load)
            const userId = await Session.getUserId();
            if (cancelled) {
              return;
            }

            const sentVerificationEmailState = getLocalStorageValue<{ date: number } | undefined>(
              sentVerificationEmailStorageKey(userId),
              undefined,
            );
            // If the email was sent less than 15 minutes ago, don't send another one
            const seconds = 15 * 60 * 1000;
            if (sentVerificationEmailState?.date && Date.now() - sentVerificationEmailState.date < seconds) {
              setState(PageState.Sent);

              return;
            }

            await sendVerifyEmail(false);
            break;
          }
          default:
            break;
        }
      } catch (err: any) {
        if (!cancelled) {
          showFlashToast({ type: "error", title: t("common.generic-error") });
        }
      }
    })();

    return () => {
      cancelled = true;
    };
  }, [navigate, navigateToRegistration, sendVerifyEmail, queryParamEmail, token, location.search, showFlashToast, t]);

  const isWindowFocused = useIsWindowFocused();
  useInterval(async () => {
    if (isWindowFocused && state === PageState.Sent && (await isEmailVerified().then((x) => x.isVerified))) {
      navigateToRegistration();
    }
  }, 5000);

  useEffect(() => {
    let cancelled = false;

    async function checkVerified() {
      if (state === PageState.Sent && (await isEmailVerified().then((x) => x.isVerified))) {
        if (!cancelled) {
          navigateToRegistration();
        }
      }
    }

    window.addEventListener("focus", checkVerified);

    return () => {
      cancelled = true;
      window.removeEventListener("focus", checkVerified);
    };
  }, [navigateToRegistration, state]);

  const showSteps = fromSignUp;

  return (
    <AuthenticationPage
      header={
        // eslint-disable-next-line no-nested-ternary
        state === PageState.Expired
          ? t("page.verify-email.expired.title")
          : state === PageState.VerifiedDifferentDevice
            ? t("page.verify-email.outside-session.title")
            : t("page.verify-email.title")
      }
      body={
        // eslint-disable-next-line no-nested-ternary
        state === PageState.Expired
          ? t("page.verify-email.expired.description")
          : state === PageState.VerifiedDifferentDevice
            ? t("page.verify-email.outside-session.description")
            : undefined
      }
      step={showSteps ? 2 : undefined}
      totalSteps={showSteps ? 2 : undefined}
    >
      {state === PageState.Initial ? <FullSizeLoader /> : null}
      {state === PageState.Expired && (
        <div className="flex flex-col gap-8">
          <Button onClick={() => sendVerifyEmail(true)} className="w-full" isLoading={isSubmitting}>
            {t("page.verify-email.resend")}
          </Button>
          <p className="text-caption">
            <Trans
              i18nKey="page.verify-email.expired.still-having-issues"
              components={{
                email: <Anchor to="mailto:support@areaofpeople.com" />,
              }}
            />
          </p>
        </div>
      )}
      {state === PageState.VerifiedDifferentDevice &&
        (signUpOrigin === "web" ? (
          <Button styling="primary" type="link" href="/" className="w-full">
            {t("page.verify-email.outside-session.continue-in-page")}
          </Button>
        ) : (
          <Button
            styling="primary"
            type="link"
            href={getDeepLinkUrl(brand || "areaOfPeople", "/sign-in")}
            isExternal
            className="w-full"
          >
            {t("page.verify-email.outside-session.continue-in-app")}
          </Button>
        ))}
      {state === PageState.Sent && (
        <div className="flex flex-col gap-4">
          <img src={inboxPng} alt="Inbox" className="mx-auto w-20" />
          <p className="mt-4 flex flex-col">
            {t("page.verify-email.sent.description", { email: queryParamEmail })}
            <strong className="mt-4">{t("page.verify-email.sent.no-link.title")}</strong>
            {t("page.verify-email.sent.no-link.description")}
          </p>
          <div className="flex flex-col items-center gap-6">
            <Button
              data-testid="send-verification-email-btn"
              styling="ghostPrimary"
              onClick={() => sendVerifyEmail(true)}
              isLoading={isSubmitting}
            >
              {t("page.verify-email.resend")}
            </Button>
            <Button styling="ghostSecondary" onClick={() => logout()}>
              {t("page.verify-email.go-back")}
            </Button>
          </div>
        </div>
      )}
    </AuthenticationPage>
  );
}

function sentVerificationEmailStorageKey(userId: string) {
  return "sent-verification-email-" + userId;
}

enum VerifyEmailStatus {
  NoSession,
  Verified,
  VerifiedDifferentDevice,
  Expired,
  NotYetVerified,
}

// I'm sorry for this.
async function getEmailVerifiedStatus({
  token,
  queryParamEmail,
}: {
  token: string | null;
  queryParamEmail: string | null;
}) {
  const sessionExists = await doesSessionExist();

  // Step 1. Check if the user is already verified and we don't need to validate the token
  if (token) {
    if (sessionExists) {
      if (queryParamEmail && (await isLoggedInWith(queryParamEmail))) {
        if (await isEmailVerified().then((x) => x.isVerified)) {
          return VerifyEmailStatus.Verified;
        }
      }
    }
  } else {
    if (!sessionExists) {
      return VerifyEmailStatus.NoSession;
    }

    if (await isEmailVerified().then((x) => x.isVerified)) {
      return VerifyEmailStatus.Verified;
    }
  }

  // Step 2. Check if the verify email token is correct
  const emailVerifyResponse = await verifyEmail();

  if (emailVerifyResponse.status === "OK") {
    if (!sessionExists) {
      return VerifyEmailStatus.VerifiedDifferentDevice;
    }

    if (queryParamEmail && (await isLoggedInWith(queryParamEmail))) {
      return VerifyEmailStatus.Verified;
    } else {
      return VerifyEmailStatus.VerifiedDifferentDevice;
    }
  }

  if (emailVerifyResponse.status === "EMAIL_VERIFICATION_INVALID_TOKEN_ERROR") {
    // This can happen if the verification code is expired or invalid.
    // We should ask the user to retry
    if (token) {
      if (sessionExists) {
        const accessTokenPayload = await getAccessTokenPayload();

        // If logged in with same user
        // We don't want to show an error if they're already verified
        if (queryParamEmail && accessTokenPayload?.emails[0] === queryParamEmail) {
          if (accessTokenPayload["st-ev"]?.v || (await isEmailVerified().then((x) => x.isVerified))) {
            return VerifyEmailStatus.Verified;
          }
        }

        return VerifyEmailStatus.Expired;
      }
    } else {
      if (!sessionExists) {
        return VerifyEmailStatus.NoSession;
      } else {
        return VerifyEmailStatus.NotYetVerified;
      }
    }
  }
}

async function isLoggedInWith(email: string) {
  const accessTokenPayload = await getAccessTokenPayload();

  return accessTokenPayload && email && accessTokenPayload.emails[0] === email;
}
