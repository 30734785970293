import { useLogout } from "authentication/useLogout";
import { useEffect, useRef } from "react";

export function LogoutPage(): null {
  const logout = useLogout();
  const alreadyLoggingOut = useRef(false);

  useEffect(() => {
    if (alreadyLoggingOut.current) {
      return;
    }

    alreadyLoggingOut.current = true;

    void logout();
  }, [logout]);

  return null;
}
