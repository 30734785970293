import { useResizeObserver } from "hooks/useResizeObserver";
import type { RefObject } from "react";
import { useRef, useState } from "react";

interface Scale<T> {
  ref: RefObject<T>;
  scale: number;
}

export function useElementScale<T extends HTMLElement>(originalWidth: number, smallMultiplier = 1): Scale<T> {
  const ref = useRef<T>(null);
  const [scale, setScale] = useState(1);
  useResizeObserver(ref, () => {
    if (ref.current) {
      setScale(
        (ref.current.offsetWidth / originalWidth) * (ref.current.offsetWidth < originalWidth ? smallMultiplier : 1),
      );
    }
  });

  return { ref, scale };
}
