import type React from "react";

export function AppHeader({
  children,
  bgImage,
  profileImage,
}: React.PropsWithChildren<{ bgImage?: string; profileImage?: React.ReactNode }>): React.ReactNode {
  return (
    <div className="relative flex items-center justify-center bg-grey-300">
      <div className="w-full">
        <div
          className="bg-cover bg-center pb-[54%]"
          style={{
            backgroundImage: bgImage ? `url(${bgImage})` : "none",
          }}
        />
      </div>
      <div className="absolute -bottom-8 right-5 z-10 flex size-16 items-center justify-center overflow-hidden rounded-full border-2 border-white bg-white bg-cover bg-center">
        {profileImage}
      </div>
      {children}
    </div>
  );
}

export function AppUserItem({ fullName, subTitle }: { fullName: string; subTitle: string }): React.ReactNode {
  return (
    <div className="-mb-px flex items-center bg-white first-of-type:border-t-0">
      <div className="mx-2.5 my-3 flex size-8 items-center justify-center rounded-full border border-grey-300 bg-grey-100 p-2">
        <svg className="w-full" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 24">
          <path
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.458"
            d="M20.954 23.176v-2.43a4.858 4.858 0 00-4.858-4.858H6.379a4.858 4.858 0 00-4.858 4.859v2.429"
          />
          <path
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.458"
            d="M11.237 11.03a4.858 4.858 0 100-9.717 4.858 4.858 0 000 9.717z"
          />
        </svg>
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-[12px] font-old-semibold text-black">{fullName}</p>
        <p className="text-[10px] text-black">{subTitle}</p>
      </div>
    </div>
  );
}
