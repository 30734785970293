import { LoadingIcon } from "components/Icons/Icons";
import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import { ProjectLoader } from "providers/ProjectLoader";
import { SessionUserLoader } from "providers/SessionUserLoader";
import type React from "react";
import { useTranslation } from "react-i18next";

import { PortfolioTicketDetails } from "../pages/PortfolioTicketDetails/PortfolioTicketDetails";

type PortfolioTicketDetailsModalProps = ModalBaseProps & {
  data?: {
    ticketId: string;
    projectId: string;
  };
  leftInSession: number | undefined;
  showNextTicketBtn: boolean;
  onNavigateNextTicket: () => void;
};

export function PortfolioTicketDetailsModal({
  isOpened,
  onOpenChange,
  data,
  leftInSession,
  showNextTicketBtn,
  onNavigateNextTicket,
}: PortfolioTicketDetailsModalProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <Modal.Root
      title={
        showNextTicketBtn && leftInSession != undefined
          ? t("page.portfolio-tickets.details.title-session", { count: leftInSession })
          : t("page.portfolio-tickets.details.title")
      }
      size="fullscreen"
      isScrollable
      {...{ isOpened, onOpenChange }}
    >
      {data ? (
        <div className="flex [&>div]:p-0">
          <ProjectLoader projectId={data.projectId}>
            <SessionUserLoader>
              <PortfolioTicketDetails
                ticketId={data.ticketId}
                showNextTicketBtn={showNextTicketBtn}
                onGoToNextTicket={onNavigateNextTicket}
              />
            </SessionUserLoader>
          </ProjectLoader>
        </div>
      ) : (
        <div className="flex size-full items-center justify-center">
          <LoadingIcon className="size-12" />
        </div>
      )}
    </Modal.Root>
  );
}
