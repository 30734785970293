import { useMutation, useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { MessageCreateRequest } from "api/types";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import type { FormImage } from "components/ImageInput/useImageInput";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useUploadDocument } from "hooks/Network/useUploadDocument";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { QUERY_KEYS } from "query-keys";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export type OptimizeType = "refresh" | "makeShorter" | "makeMorePositive" | "makeUnderstandable";
const postAudienceQueryParams: { IncludeAnnouncements: boolean } = { IncludeAnnouncements: true };

export function Loader({ children }: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();

  const { state: locationState } = useLocation();

  const {
    data: postAudience,
    isFetching: isLoadingAudience,
    error: errorLoadingAudience,
  } = useQuery({
    queryKey: QUERY_KEYS.MESSAGES_AUDIENCE(projectId, postAudienceQueryParams),
    queryFn: async () => api.getCommunityFeedAudienceV1(postAudienceQueryParams),
    select: commonAPIDataSelector,
  });

  const optimizePost = useMutation({
    mutationFn: ({ input, option }: { input: string; option?: Exclude<OptimizeType, "refresh"> }) =>
      api.postCopilotV1({ userInput: input, copilotOptions: option ? [option] : [] }).then((x) => x.data),
    onError() {
      showFlashToast({ type: "error", title: t("page.message-feed.optimize.copilot-error") });
    },
  });

  const { isUploadingImage, uploadFormImage } = useUploadImage();
  const { isUploadingDocument, uploadFormDocument } = useUploadDocument();
  const onSubmit = async ({
    payload,
    images,
    documents,
  }: {
    payload: MessageCreateRequest;
    images: FormImage[];
    documents: FormDocument[];
  }) => {
    const imageUploadPromises = images.map((image) => uploadFormImage(image));
    // Curently only 1 document is allowed
    const documentUploadPromises = documents.map((document) => uploadFormDocument(document));

    const imageIds = (await Promise.allSettled(imageUploadPromises))
      .map((image) => (image.status === "fulfilled" ? image.value?.id : ""))
      .filter(Boolean) as string[];
    const documentIds = (await Promise.allSettled(documentUploadPromises))
      .map((document) => (document.status === "fulfilled" ? document.value?.id : ""))
      .filter(Boolean) as string[];

    return await createMessage.mutateAsync({
      payload: { ...payload, imageIds, documentIds },
    });
  };

  const createMessage = useMutation({
    mutationFn: ({ payload }: { payload: MessageCreateRequest }) => api.postMessagesV1(payload).then((x) => x.data),
    onError() {
      showFlashToast({ type: "error", title: t("page.message-feed.create.notifications.error") });
    },
  });

  const defaultGroupFromUrl = useMemo(() => {
    if (!locationState || !("groupId" in locationState)) {
      return undefined;
    }

    if (!postAudience) {
      return undefined;
    }

    const interestGroup = postAudience.interestGroups.find((group) => group.id === locationState.groupId);
    if (interestGroup) {
      return interestGroup;
    }

    const helpCategory = postAudience.helpCategories.find((group) => group.id === locationState.groupId);
    if (helpCategory) {
      return helpCategory;
    }

    const realEstateGroup = postAudience.realEstateGroups.find((group) => group.id === locationState.groupId);
    if (realEstateGroup) {
      return realEstateGroup;
    }

    return undefined;
  }, [postAudience, locationState]);

  const error = errorLoadingAudience;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const loading = isLoadingAudience;
  if (loading) {
    return <FullSizeLoader withPadding />;
  }

  return children({
    postAudience: postAudience!,
    optimizePost: optimizePost.mutateAsync,
    isOptimizingPost: optimizePost.isPending,
    optimizingPostSetting: optimizePost.variables?.option,
    onSubmit,
    isSubmitting: createMessage.isPending || isUploadingImage || isUploadingDocument,
    defaultFormValues: {
      type: "undefined",
      title: "",
      body: "",
      scheduled: "",
      images: [],
      documents: [],
      group: defaultGroupFromUrl,
      groupAudience: defaultGroupFromUrl
        ? defaultGroupFromUrl.selectableAudience.find((x) => x.type === defaultGroupFromUrl.defaultPostingLevel) ||
          defaultGroupFromUrl.selectableAudience[0]
        : undefined,
    },
  });
}
