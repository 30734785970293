import { useEffect } from "react";

export function useOnDropFiles(listener: (data: DataTransferItemList | undefined) => void, when = true): void {
  useEffect(() => {
    function drop(event: DragEvent) {
      if (!when) {
        return;
      }

      event.preventDefault();

      listener(event.dataTransfer?.items);
    }

    function dragOver(event: Event) {
      event.preventDefault();
    }

    if (when) {
      window.addEventListener("drop", drop);
      document.addEventListener("dragover", dragOver);

      return () => {
        window.removeEventListener("drop", drop);
        document.removeEventListener("dragover", dragOver);
      };
    }
  }, [listener, when]);
}
