import type { QuestionDto, ResponseDto } from "api/types";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { UserAvatarLink } from "components/UserAvatarLink/UserAvatarLink";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import type React from "react";

type SurveyOpenQuestionDetailsModalProps = ModalBaseProps & {
  question: QuestionDto;
  responses: ResponseDto[];
};

export function SurveyOpenQuestionDetailsModal({
  question,
  responses,
  isOpened,
  onOpenChange,
}: SurveyOpenQuestionDetailsModalProps): React.ReactNode {
  return (
    <Modal.Root
      title={question.question}
      isScrollable
      size="lg"
      description={question.description && <LinkFormatter>{question.description}</LinkFormatter>}
      {...{ isOpened, onOpenChange }}
    >
      <article className="grid grid-cols-1 gap-y-2">
        {question.description && <div className="max-w-sm justify-self-center" />}
        <div className="overflow-y-auto py-2 pr-2">
          {responses.map((response) => (
            <Answer key={response.id} response={response} />
          ))}
        </div>
      </article>
    </Modal.Root>
  );
}

function Answer({ response }: { response: ResponseDto }) {
  return (
    <div className="flex items-center gap-2 py-2">
      <div className="mr-2 size-8">
        <UserAvatarLink user={response.respondent} />
      </div>
      <div className="flex flex-col">
        <span className="text-caption-bold text-black">
          <UserNameLink user={response.respondent}>{response.respondent.fullName}</UserNameLink>
        </span>
        <p className="text-grey-900">{response.text ?? "-"}</p>
      </div>
    </div>
  );
}
