import iconBell01 from "assets/icons/bell-01.svg";
import iconMail01 from "assets/icons/mail-01.svg";
import iconMessageSquare02 from "assets/icons/message-square-02.svg";
import iconTool02 from "assets/icons/tool-02.svg";
import { Icon } from "components/Icon/Icon";
import type { NotificationPayload } from "components/Notification/Notification";

export function NotificationIcon({ type }: { type: NotificationPayload["type"] }): React.ReactNode {
  switch (type) {
    case "message":
    case "groupMessage":
      return <Icon name={iconMail01} size={20} />;
    case "messageComment":
      return <Icon name={iconMessageSquare02} size={20} />;
    case "ticket":
    case "ticketAssignee":
    case "ticketComment":
      return <Icon name={iconTool02} size={20} />;
    default:
      return <Icon name={iconBell01} size={20} />;
  }
}

export function NotificationBell(): React.ReactNode {
  return (
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.42 0l-.01 1.48a8.97 8.97 0 00-3.74 1.86c-.9.8-1.43 1.56-1.86 2.61-.3.77-.3.8-.37 5.2l-.06 4.42-.31.63a4.5 4.5 0 01-3.41 2.47l-.66.08v1.36h9.96l-.07.62c-.28 2.23 2.9 3.17 3.98 1.18.2-.34.25-1.15.12-1.55l-.08-.25H24v-.7c0-.68 0-.68-.35-.68a4.6 4.6 0 01-3.76-2.5l-.39-.77-.07-4.43c-.07-4.78-.09-4.84-.77-6.03a7.2 7.2 0 00-4.18-3.22l-1.04-.32V0h-3.02z"
        fill="currentColor"
      />
    </svg>
  );
}
