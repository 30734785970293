import iconUndo from "assets/icons/reverse-left.svg";
import iconRedo from "assets/icons/reverse-right.svg";
import { AIButton } from "components/Button/AIButton";
import { IconButton } from "components/Button/IconButton";
import { Icon } from "components/Icon/Icon";
import { useBool } from "hooks/useBool";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { TicketAICopilotModal } from "./TicketAICopilotModal";
import { useTicketCommentFieldContentManager } from "./TicketCommentFieldContentManager";
import { TicketQuickReplyAICopilotModal } from "./TicketQuickReplyAICopilotModal";

interface TicketCommentFieldAIActionsProps {
  ticketId: string;
  usage: "quickReply" | "ticketDetails";
}

export function TicketCommentFieldAIActions({ ticketId, usage }: TicketCommentFieldAIActionsProps): React.ReactNode {
  const { t } = useTranslation();
  const [isCopilotModalOpened, copilotModalOpenHandlers] = useBool(false);
  const { token } = useParams<{ token?: string }>();

  const { currValue, isUndoable, isRedoable, onReplaceContent, onUndo, onRedo } = useTicketCommentFieldContentManager();

  return (
    <>
      <div className="flex shrink-0 flex-row gap-1 self-start @4xl:gap-2">
        <AIButton data-testid="ticket-response-ai-button" onClick={copilotModalOpenHandlers.setTrue}>
          {isUndoable
            ? t("page.tickets.details.copilot.button.regenerate")
            : t("page.tickets.details.copilot.button.generate")}
        </AIButton>
        {(isUndoable || isRedoable) && (
          <>
            <IconButton styling="secondary" disabled={!isUndoable} title={t("common.action.undo")} onClick={onUndo}>
              <Icon name={iconUndo} size={16} />
            </IconButton>
            <IconButton styling="secondary" disabled={!isRedoable} title={t("common.action.redo")} onClick={onRedo}>
              <Icon name={iconRedo} size={16} />
            </IconButton>
          </>
        )}
      </div>
      {usage === "ticketDetails" && (
        <TicketAICopilotModal
          isOpened={isCopilotModalOpened}
          onOpenChange={copilotModalOpenHandlers.set}
          onGenerated={onReplaceContent}
          ticketId={ticketId}
          defaultInput={{ input: currValue }}
        />
      )}
      {usage === "quickReply" && token && (
        <TicketQuickReplyAICopilotModal
          isOpened={isCopilotModalOpened}
          onOpenChange={copilotModalOpenHandlers.set}
          onGenerated={onReplaceContent}
          defaultInput={{ input: currValue }}
          {...{ token }}
        />
      )}
    </>
  );
}
