import { ErrorPage } from "components/Error/ErrorPage";
import {
  canAccessProjectAddressUpload,
  canAccessProjectCompanyUpload,
  canAccessProjectUserUpload,
} from "components/ProjectSidebar/permissions";
import { RequireAuth } from "components/Routing/RequireAuth";
import type { RouteObject } from "react-router-dom";

import { AddressBulkUpload } from "./pages/AddressBulkUpload";
import { CompanyBulkUpload } from "./pages/CompanyBulkUpload";
import { SurveyResults } from "./pages/SurveyResults";
import { UserBulkUpload } from "./pages/UsersBulkUpload";
import { adminRoutes } from "./routes";

export const adminRoute = [
  {
    path: adminRoutes.surveyExport,
    element: (
      <RequireAuth permission={(x) => x.isSuperAdmin}>
        <SurveyResults />
      </RequireAuth>
    ),
  },
  {
    path: adminRoutes.userBulkUpload,
    element: (
      <RequireAuth permission={canAccessProjectUserUpload}>
        <UserBulkUpload />
      </RequireAuth>
    ),
  },
  {
    path: adminRoutes.addressBulkUpload,
    element: (
      <RequireAuth permission={canAccessProjectAddressUpload}>
        <AddressBulkUpload />
      </RequireAuth>
    ),
  },
  {
    path: adminRoutes.companyBulkUpload,
    element: (
      <RequireAuth permission={canAccessProjectCompanyUpload}>
        <CompanyBulkUpload />
      </RequireAuth>
    ),
  },
  {
    path: "*",
    element: <ErrorPage status={404} />,
  },
] satisfies RouteObject[];
