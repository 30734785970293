import type { HttpResponse, LanguageDto } from "api/types";

export async function getPreprIntegrationList(
  url: string,
  language: LanguageDto["id"],
): Promise<{
  data: {
    Integrations: {
      items: [
        {
          _id: string;
          _slug: string | null;
          name: string | null;
          content: string | null;
          preview_content: string | null;
          created: string | null;
          profile_image: {
            height: number;
            url: string;
            width: number;
          } | null;
          header_image: {
            height: number;
            url: string;
            width: number;
          } | null;
        },
      ];
    };
  };
}> {
  const locale = convertToLocale(language);

  const query = `
    {
      Integrations(locales: ["${locale}"] limit: 100) {
        items {
          _id
          _slug
          name
          content
          preview_content
          created
          header_image {
            url
            width
            height
          }
          profile_image {
            url
            width
            height
          }
        }
      }
    }`;

  const data = await loadGraphQl(url, query);

  return data as any;
}

export async function getPreprIntegrationDetails(
  url: string,
  id: string,
  language: LanguageDto["id"],
): Promise<{
  data: {
    Integration: {
      _id: string;
      name: string | null;
      content: string | null;
      created: string | null;
      external_link: string | null;
      header_image: {
        width: number;
        height: number;
        url: string;
      } | null;
      profile_image: {
        url: string;
        width: number;
        height: number;
      } | null;
      tags: [
        {
          _id: string;
          body: string;
          slug: string;
        },
      ];
    };
  };
}> {
  const locale = convertToLocale(language);

  const query = `
    {
      Integration(slug: "${id}" locales: ["${locale}"]) {
        _id
        name
        content
        created
        external_link
        header_image {
          width
          height
          url
        }
        profile_image {
          url
          width
          height
        }
        tags {
          _id
          body
          slug
        }
      }
    }`;

  const data = await loadGraphQl(url, query);

  return data as any;
}

function convertToLocale(language: LanguageDto["id"]) {
  switch (language) {
    case "nl":
    case "dutch":
      return "nl-NL";
    default:
      return "en-GB";
  }
}

async function loadGraphQl(url: string, query: string): Promise<{ data: unknown }> {
  if (!url) {
    throw new Error("Prepr dashboard URL not found");
  }

  const options = {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ query }),
  };

  const response = await fetch(url, options);

  const result = response as HttpResponse<unknown, unknown>;
  try {
    const json = await response.json();

    if (response.ok) {
      if ("errors" in json) {
        result.error = json.errors?.[0]?.message || json.errors;
        throw result;
      }

      return json as { data: unknown };
    } else {
      result.error = json;
      throw result;
    }
  } catch (error) {
    result.error = error;
    throw result;
  }
}
