import { Button } from "components/Button/Button";
import { Modal } from "components/Modal/Modal";
import { useBool } from "hooks/useBool";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

interface Context {
  isOpen: boolean;
  open: () => void;
}

const ForbiddenPopupContext = React.createContext<Context>(null!);

export function useForbiddenPopupContext(): Context {
  return useContext(ForbiddenPopupContext);
}

export function ForbiddenPopupProvider({ children }: React.PropsWithChildren<{}>): React.ReactNode {
  const { t } = useTranslation();
  const [isModalOpened, modalHandler] = useBool(false);

  return (
    <ForbiddenPopupContext.Provider
      value={{
        isOpen: isModalOpened,
        open: modalHandler.setTrue,
      }}
    >
      <Modal.Root
        isOpened={isModalOpened}
        title={t("component.forbidden-user-details-modal.title")}
        description={t("component.forbidden-user-details-modal.description")}
        size="sm"
        onOpenChange={modalHandler.set}
      >
        <Modal.Actions>
          <Modal.Close>
            <Button className="w-full sm:w-auto" styling="primary">
              {t("common.action.close")}
            </Button>
          </Modal.Close>
        </Modal.Actions>
      </Modal.Root>

      {children}
    </ForbiddenPopupContext.Provider>
  );
}
