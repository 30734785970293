import type { ServicePartnerPageDetailsDto } from "api/types";

import { IqbiDetailsConsumptionCard } from "./IqbiDetailsConsumptionCard";
import { IqbiDetailsInfoCard } from "./IqbiDetailsInfoCard";

interface IqbiDetailsViewProps {
  serviceDetails: ServicePartnerPageDetailsDto;
}

export function IqbiDetailsView({ serviceDetails }: IqbiDetailsViewProps): React.ReactNode {
  return (
    <>
      <IqbiDetailsInfoCard {...{ serviceDetails }} />
      <IqbiDetailsConsumptionCard {...{ serviceDetails }} />
    </>
  );
}
