import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { LanguageDto } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { useLanguages } from "helpers/languages";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { routes } from "routes";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const [isUserLanguageDetermined, userLanguageDeterminedHandlers] = useBool(false);

  const projectId = useProjectId();
  const slug = useSlug();
  const api = useApi();
  const sessionUser = useSessionUser();
  const supportedLanguages = useLanguages();
  const queryClient = useQueryClient();

  const {
    data: onboardingScreens,
    isPending: isPendingOnboardingScreens,
    error: onboardingScreensError,
  } = useQuery({
    queryKey: QUERY_KEYS.ONBOARDING_SCREENS_LIST(projectId),
    queryFn: () => api.getOnboardingScreensV1(),
    select: commonAPIDataSelector,
    enabled: isUserLanguageDetermined,
  });
  const { mutateAsync: changeLanguage } = useMutation({
    mutationFn: (languageId: LanguageDto["id"]) => api.putSelfLanguageV1({ languageId }).then(({ data }) => data),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SELF(projectId) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ONBOARDING_SCREENS_LIST(projectId) });
    },
    // Prevent infinite loading screen in case mutation fails
    onSettled: () => {
      userLanguageDeterminedHandlers.setTrue();
    },
  });

  useEffect(() => {
    if (!supportedLanguages.data) return;

    const systemLanguage = Intl.DateTimeFormat().resolvedOptions().locale.split("-")[0];
    const currUserLanguage = sessionUser.language.id;
    const isSystemLanguageSupported = supportedLanguages.data.some((language) => language.id === systemLanguage);

    if (systemLanguage !== currUserLanguage) {
      // Fallback to English if the system language is not one of the supported languages
      void changeLanguage(isSystemLanguageSupported ? (systemLanguage as LanguageDto["id"]) : "en");
    } else {
      userLanguageDeterminedHandlers.setTrue();
    }
  }, [changeLanguage, sessionUser.language.id, supportedLanguages.data, userLanguageDeterminedHandlers]);

  if (!onboardingScreens) {
    return null;
  }

  if (onboardingScreens && onboardingScreens.items.length == 0) {
    return <Navigate to={routes.onboardingFlow.userProfile({ slug })} replace />;
  }

  if (sessionUser.onboardedAt) {
    return <Navigate to="/" />;
  }

  if (onboardingScreensError) {
    return <ErrorPage error={onboardingScreens} />;
  }

  if (isPendingOnboardingScreens || !isUserLanguageDetermined) {
    return <FullSizeLoader withPadding />;
  }

  return props.children({
    slides: onboardingScreens.items,
  });
}
