import type { AdminTicketCommentDto, UserDto } from "api/types";
import iconLock01 from "assets/icons/lock-01.svg";
import { type BaseCommentFieldProps, CommentFieldWithAvatar } from "components/CommentField/CommentField";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { useDocumentInput } from "components/DocumentInput/useDocumentFile";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { useImageInput } from "components/ImageInput/useImageInput";
import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import { useTicketCommentFieldContentManager } from "./TicketCommentFieldContentManager";

export type CommentType = "internal" | "public";
export type CommentPayload = {
  content: string;
  type: CommentType;
  images: FormImage[];
  documents: FormDocument[];
};

export type TicketCommentFieldPureProps = {
  user: UserDto;
  allowedCommentTypes: CommentType[];
  allowedAttachmentTypes?: BaseCommentFieldProps["allowedAttachments"];
  commentInEditing?: AdminTicketCommentDto;
  actions?: Partial<Record<CommentType, React.ReactNode>>;
  onSend: ({ content, type, images, documents }: CommentPayload) => Promise<void>;
  onCancelEdit?: () => void;
};

export function TicketCommentFieldPure({
  user,
  allowedCommentTypes,
  allowedAttachmentTypes,
  commentInEditing,
  actions,
  onSend,
  onCancelEdit,
}: TicketCommentFieldPureProps): React.ReactNode {
  const [selectedCommentType, setSelectedCommentType] = useState<CommentType>(allowedCommentTypes[0]);
  // Form
  const [images, setImages] = useState<FormImage[]>([]);
  const [documents, setDocuments] = useState<FormDocument[]>([]);
  const { currValue, setCurrValue } = useTicketCommentFieldContentManager();

  const { addImages, removeImage, removeImages } = useImageInput({ onChange: setImages });
  const { addDocuments, removeDocument, removeDocuments } = useDocumentInput({
    maximumFiles: 1,
    onChange: setDocuments,
  });
  const { ref } = useTicketCommentFieldContentManager();
  const { t } = useTranslation();

  useEffect(() => {
    if (commentInEditing) {
      setSelectedCommentType(commentInEditing.internal ? "internal" : "public");
      setCurrValue(commentInEditing.content ?? "");
      setImages(commentInEditing.images ?? []);
      setDocuments(commentInEditing.documents ?? []);
    }
  }, [commentInEditing, setCurrValue]);

  function resetInput() {
    removeImages();
    removeDocuments();
    setCurrValue("");
    onCancelEdit?.();
  }

  const onSubmit = async () => {
    await onSend({
      content: currValue,
      type: selectedCommentType,
      images,
      documents,
    });

    resetInput();
  };

  return (
    <div className="flex flex-col">
      <TicketCommentFieldTypeSelector
        selectedType={selectedCommentType}
        allowedCommentTypes={allowedCommentTypes}
        onSelectType={setSelectedCommentType}
        isDisabled={!!commentInEditing}
      />
      <div
        className={twJoin(
          "rounded-lg rounded-tl-none border p-4",
          selectedCommentType === "public" ? "border-aop-basic-blue-500" : "border-yellow-600",
        )}
      >
        <CommentFieldWithAvatar
          ref={ref}
          allowedAttachments={allowedAttachmentTypes}
          tooltips={{
            send:
              selectedCommentType === "internal"
                ? t("page.tickets.details.comment-field.send-note-tooltip")
                : t("page.tickets.details.comment-field.send-message-tooltip"),
          }}
          onAddImages={addImages}
          onRemoveImage={removeImage}
          onAddDocument={addDocuments}
          onRemoveDocument={removeDocument}
          placeholder={
            selectedCommentType === "internal"
              ? t("page.tickets.details.comment-field.placeholder-note")
              : t("page.tickets.details.comment-field.placeholder-message")
          }
          isNote={selectedCommentType === "internal"}
          onChange={(value) => {
            setCurrValue(value);
          }}
          isEdit={!!commentInEditing}
          onCancel={resetInput}
          autoFocus
          actionComponent={actions?.[selectedCommentType]}
          value={currValue}
          {...{ user, images, documents, onSubmit }}
        />
      </div>
    </div>
  );
}

interface TicketCommentFieldTypeSelectorProps {
  selectedType: CommentType;
  allowedCommentTypes: CommentType[];
  isDisabled?: boolean;
  onSelectType: (type: CommentType) => void;
}

function TicketCommentFieldTypeSelector({
  selectedType,
  allowedCommentTypes,
  isDisabled,
  onSelectType,
}: TicketCommentFieldTypeSelectorProps): React.ReactNode {
  const { t } = useTranslation();

  if (allowedCommentTypes.length === 0) {
    return null;
  }

  return (
    <div className="flex">
      {allowedCommentTypes.includes("public") && (
        <button
          data-testid="public-comment-btn"
          className={twJoin(
            "rounded-tl-lg px-4 py-1.5 only:rounded-t-lg",
            selectedType === "public"
              ? "bg-aop-basic-blue-500 text-white"
              : "bg-blue-100 text-grey-600 hocus:bg-blue-200",
          )}
          type="button"
          onClick={() => onSelectType("public")}
          disabled={isDisabled}
        >
          <span className="text-caption">{t("page.tickets.details.comment-field.comment")}</span>
        </button>
      )}
      {allowedCommentTypes.includes("internal") && (
        <button
          data-testid="internal-note-btn"
          className={twJoin(
            "flex items-center gap-1 rounded-tr-lg px-4 py-1.5 only:rounded-t-lg",
            selectedType === "internal"
              ? "bg-yellow-600 text-yellow-200"
              : "bg-yellow-100 text-yellow-800 hocus:bg-yellow-200",
          )}
          type="button"
          onClick={() => onSelectType("internal")}
          disabled={isDisabled}
        >
          <Icon name={iconLock01} />
          <span className="text-caption">{t("page.tickets.details.comment-field.note")}</span>
        </button>
      )}
    </div>
  );
}
