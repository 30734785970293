import type { AssetBookingDto } from "api/types";
import { Button } from "components/Button/Button";
import { DateRange } from "components/DateRange/DateRange";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { User } from "components/User/User";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { parse } from "date-fns";
import { useTranslation } from "react-i18next";

type BookingDetailModalProps = ModalBaseProps & {
  booking: AssetBookingDto;
  onDeleteBooking: (bookingId: string) => void;
};

export function BookingDetailModal({
  booking,
  onDeleteBooking,
  isOpened,
  onOpenChange,
}: BookingDetailModalProps): React.ReactNode {
  const { t } = useTranslation();

  if (!booking) {
    return null;
  }

  return (
    <Modal.Root
      title={t("page.bookings.asset-detail.booking-details.title")}
      description={
        <div className="flex flex-wrap gap-0.5">
          {t("page.bookings.asset-detail.booking-details.date")}{" "}
          <DateRange
            start={parse(booking.startTime, "HH:mm:ss", parse(booking.date, "yyyy-MM-dd", new Date()))}
            end={parse(booking.endTime, "HH:mm:ss", parse(booking.date, "yyyy-MM-dd", new Date()))}
          />
        </div>
      }
      size="sm"
      {...{ isOpened, onOpenChange }}
    >
      {booking.author && (
        <div className="flex flex-col gap-0.5">
          <span className="text-body-bold">{t("page.bookings.asset-detail.booking-details.author")}</span>
          {booking.author.canViewProfile ? (
            <UserNameLink user={booking.author}>
              <User title={booking.author.fullName} avatar={booking.author.avatar} />
            </UserNameLink>
          ) : (
            <User title={booking.author.fullName} avatar={booking.author.avatar} />
          )}
        </div>
      )}
      {booking.bookingReason && (
        <div className="flex flex-col gap-0.5">
          <span className="text-body-bold">{t("page.bookings.asset-detail.booking-details.reason")}</span>
          <p className="max-w-prose">{booking.bookingReason}</p>
        </div>
      )}
      {booking.canDelete && (
        <Modal.Actions>
          {/* Can only decline future or ongoing reservations */}
          <Button onClick={() => onDeleteBooking(booking.id)} styling="danger">
            {t("page.bookings.asset-detail.booking-details.cancel")}
          </Button>
        </Modal.Actions>
      )}
    </Modal.Root>
  );
}
