import type { ConnectedProjectDto } from "api/types";
import iconBriefcase02 from "assets/icons/briefcase-02.svg";
import { Icon } from "components/Icon/Icon";
import { SearchInput } from "components/SearchInput/SearchInput";
import { isTouchDevice } from "helpers/device";
import { sortBy } from "lodash-es";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

import { ProjectItem } from "./ProjectItem";

export interface ProjectListProps {
  projects: ConnectedProjectDto[];
  recentProjects?: ConnectedProjectDto[];
  currentProjectId: string;
  showCurrentProject?: boolean;
  showRecentProjects?: boolean;
  showPortfolio?: boolean;
  isLoading?: boolean;
  onSelectProject: (projectId: string) => void;
  onClose?: () => void;
  noBottomPadding?: boolean;
  maxProjects?: number;
}

export function ProjectList({
  projects,
  recentProjects = [],
  currentProjectId,
  showCurrentProject,
  showRecentProjects,
  showPortfolio,
  isLoading,
  onSelectProject,
  onClose,
  noBottomPadding,
  maxProjects,
}: ProjectListProps): React.ReactNode {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");

  recentProjects = showRecentProjects ? recentProjects : [];

  const filteredList = useMemo(() => {
    const searchTerm = searchQuery?.trim().toLowerCase();
    const recentProjectIds = new Set(recentProjects.map((x) => x.id));
    const tempList = projects.filter(
      (p) =>
        (searchTerm || !recentProjectIds.has(p.id)) &&
        (showCurrentProject || p.id !== currentProjectId) &&
        (!searchTerm || p.name.toLowerCase().includes(searchTerm) || p.city?.toLowerCase().includes(searchTerm)),
    );

    const sortedList = sortBy(tempList, (x) => x.name.toUpperCase());

    return sortedList;
  }, [currentProjectId, projects, recentProjects, searchQuery, showCurrentProject]);

  const showRecent = !searchQuery && !!recentProjects.length;
  const showOther = !!filteredList.length;
  const showNoResultsFound = !!searchQuery && !filteredList.length;

  return (
    <div className="flex flex-col gap-2">
      {projects.length > 3 && (
        <SearchInput
          className="w-full"
          value={searchQuery}
          placeholder={t("component.project-switcher.search.placeholder")}
          onChange={(e) => setSearchQuery(e.target.value || "")}
          onKeyDown={(e) => {
            if (e.key === "Enter" && filteredList.length === 1) {
              onSelectProject(filteredList[0].id);
            }
          }}
          autoFocus={!isTouchDevice()}
        />
      )}
      {isLoading ? (
        <div>{t("component.project-switcher.loading")}</div>
      ) : (
        <div
          className={twJoin("overflow-auto", noBottomPadding ? "" : "pb-7")}
          style={{
            maxHeight: maxProjects ? `${maxProjects * 72}px` : "auto",
          }}
        >
          {showPortfolio && !searchQuery && (
            <>
              <Link
                className="flex w-full items-center gap-4 rounded-md p-2 hover:bg-green-100 focus:outline-none focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-grey-900"
                to={routes.portfolio.overview()}
                onClick={onClose}
              >
                <div className="flex size-14 items-center justify-center">
                  <Icon name={iconBriefcase02} size={24} className="text-grey-700" />
                </div>
                <div className="flex flex-1 flex-col items-start">
                  <span className="line-clamp-1 break-all text-left text-body-bold">
                    {t("component.project-switcher.portfolio")}
                  </span>
                  <span className="text-overline text-grey-700">
                    {t("component.project-switcher.portfolio.subtitle")}
                  </span>
                </div>
              </Link>
              {showRecent || showOther ? <hr className="my-4 h-px border-0 bg-grey-300" /> : null}
            </>
          )}
          {showRecent && (
            <ul className="flex flex-col">
              {recentProjects.map((p) => (
                <li key={p.id}>
                  <ProjectItem project={p} onClick={() => onSelectProject(p.id)} />
                </li>
              ))}
            </ul>
          )}
          {showRecent && showOther && <hr className="my-4 h-px border-0 bg-grey-300" />}
          {showOther && (
            <ul className="flex flex-col">
              {filteredList.map((p) => (
                <li key={p.id}>
                  <ProjectItem project={p} onClick={() => onSelectProject(p.id)} />
                </li>
              ))}
            </ul>
          )}
          {showNoResultsFound && (
            <p className="mt-4 text-caption text-grey-600">{t("component.project-switcher.search.no-results")}</p>
          )}
        </div>
      )}
    </div>
  );
}
