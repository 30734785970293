import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { createRequiredStringRule } from "helpers/rules";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface IqbiFormValues {
  enabled: boolean;
  credentials: string;
  locationUuid: string;
}

export function IqbiForm({
  defaultValues,
  isSubmitting,
  onSubmit,
}: {
  defaultValues: Partial<IqbiFormValues>;
  isSubmitting: boolean;
  onSubmit: (data: IqbiFormValues) => void;
}): React.ReactNode {
  const { t } = useTranslation();
  const formMethods = useForm<IqbiFormValues>({ defaultValues });
  const enabled = useWatch({ control: formMethods.control, name: "enabled" });

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <FormContent maxWidth="3xl">
        <FormField label={""} htmlFor="enabled">
          <FormCheckbox<IqbiFormValues, "enabled">
            id="enabled"
            name="enabled"
            label={t("page.integration.iqbi.form.enabled")}
            onChange={(e) => {
              if (enabled && !e.target.checked) {
                void formMethods.trigger();
              }

              formMethods.setValue("enabled", e.target.checked);
            }}
          />
        </FormField>

        <FormField label={t("page.integration.iqbi.form.credentials")} required htmlFor="credentials">
          <FormInput<IqbiFormValues, "credentials">
            id="credentials"
            name="credentials"
            rules={{
              validate: {
                required: enabled
                  ? createRequiredStringRule(t, "page.integration.iqbi.form.credentials")
                  : () => undefined,
              },
            }}
            type="password"
            placeholder="username:password"
            className="max-w-[32ch]"
            disabled={!enabled}
          />
        </FormField>

        <FormField label={t("page.integration.iqbi.form.location-uuid")} required htmlFor="locationUuid">
          <FormInput<IqbiFormValues, "locationUuid">
            id="locationUuid"
            name="locationUuid"
            rules={{
              validate: {
                required: enabled
                  ? createRequiredStringRule(t, "page.integration.iqbi.form.location-uuid")
                  : () => undefined,
              },
            }}
            placeholder="00000000-0000-0000-0000-000000000000"
            className="max-w-[38ch]"
            disabled={!enabled}
          />
        </FormField>

        <Button type="submit" isLoading={isSubmitting}>
          {t("page.integration.iqbi.submit")}
        </Button>
      </FormContent>
    </Form>
  );
}
