import type { QuickReplyRepairRequestDto } from "api/types";
import iconMail01 from "assets/icons/mail-01.svg";
import iconPhone from "assets/icons/phone.svg";
import { Icon } from "components/Icon/Icon";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

export function TicketResidentInfo({
  ticket,
  isGray,
}: {
  ticket: QuickReplyRepairRequestDto;
  isGray?: boolean;
}): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className={twJoin("flex flex-col gap-4 rounded-lg p-4", isGray ? "bg-grey-100" : "bg-white")}>
      <span className="text-left text-body-bold">{t("page.tickets.details.user.reporter")}</span>
      <div className="flex flex-row items-center gap-2">
        <div className="size-24 self-center">
          <UserAvatar img={ticket.author.avatar} isUserDeleted={!!ticket.author.deletedAt} />
        </div>
        <div className="flex flex-col">
          <span className="line-clamp-3 text-body-bold">{ticket.author.fullName}</span>
          <div className="text-grey-700">
            {ticket.company ? <p>{ticket.company}</p> : null}
            {ticket.address ? <p>{ticket.address}</p> : null}
          </div>
        </div>
      </div>
      <div>
        <span className="text-caption-bold">{t("page.tickets.details.user.contact-details")}</span>
        <hr className="my-2 text-grey-300" />
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <span className="shrink-0">
              <Icon name={iconMail01} className="text-grey-600" />
            </span>
            <span className="text-caption">{ticket.author.email || "-"}</span>
          </div>
          <div className="flex items-center gap-2">
            <span className="shrink-0">
              <Icon name={iconPhone} className="text-grey-600" />
            </span>
            <span className="text-caption">{ticket.author.phoneNumber || "-"}</span>
          </div>
        </div>
      </div>
      {ticket.addressType ? (
        <div>
          <span className="text-caption-bold">{t("page.tickets.details.user.address-type")}</span>
          <hr className="my-2 text-grey-300" />
          <span className="text-caption">{ticket.addressType}</span>
        </div>
      ) : null}
    </div>
  );
}
