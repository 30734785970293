import type { AutomatedSurveyQueueDetailsDto } from "api/types";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { AutomatedSurveyQuestionGroupAnswerCard } from "components/SurveyQuestionGroupAnswerCard/AutomatedSurveyQuestionGroupAnswerCard";
import { parseISO } from "date-fns";
import { useBool } from "hooks/useBool";
import { type QuestionData, QueueDetailsModal } from "modules/automated-surveys/components/QueueDetailsModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

export interface LayoutProps {
  queueDetails: AutomatedSurveyQueueDetailsDto | undefined;
}

export function Layout({ queueDetails }: LayoutProps): React.ReactNode {
  const { t } = useTranslation();
  const [isQuestionModalOpened, questionModalHandler] = useBool(false);
  const [selectedQuestionData, setSelectedQuestionData] = useState<QuestionData | null>(null);
  const [selectedQuestionId, setSelectedQuestionId] = useState<string>();

  function handleViewQuestionDetails(idx: number) {
    if (!queueDetails) return;

    setSelectedQuestionId(queueDetails.questionDetails[idx].questionGroupId);
    setSelectedQuestionData({
      questionNum: idx + 1,
      ratingQuestion: queueDetails.questionDetails[idx].ratingQuestionText!,
      openQuestion: queueDetails.questionDetails[idx].openQuestionText!,
    });
    questionModalHandler.setTrue();
  }

  const isEnded = queueDetails ? parseISO(queueDetails.endDate) < new Date() : false;

  return (
    <DocumentPaper
      theme="minimal"
      title={queueDetails?.title}
      header={
        <div className="flex w-full flex-col items-center justify-around gap-2 md:flex-row">
          <div className="flex flex-col items-center justify-between">
            <span className="text-headline2">{queueDetails?.numberResponses}</span>
            <span className="text-body">{t("page.automated-surveys-queue-details.header.responses")}</span>
          </div>
          <div className="flex flex-col items-center justify-between">
            <span className="text-headline2">{queueDetails?.numberLeftInQueue}</span>
            <span className="text-body">{t("page.automated-surveys-queue-details.header.n-left-in-queue")}</span>
          </div>
          <div className="flex flex-col items-center justify-between">
            <span className="text-headline2">{queueDetails?.questionDetails.length}</span>
            <span className="text-body">{t("page.automated-surveys-queue-details.header.n-of-questions")}</span>
          </div>
          <div className="flex flex-col items-center gap-2">
            <div className="flex items-center gap-2 rounded-full px-2 ring-1 ring-grey-600">
              <span
                className={twJoin(
                  "block size-2.5 shrink-0 rounded-full",
                  // eslint-disable-next-line no-nested-ternary
                  isEnded ? "bg-aop-basic-blue-500" : queueDetails?.isActive ? "bg-green-600" : "bg-yellow-500",
                )}
              />
              <span className="whitespace-nowrap">
                {
                  // eslint-disable-next-line no-nested-ternary
                  isEnded
                    ? t("page.automated-surveys-queue-details.header.status.ended")
                    : queueDetails?.isActive
                      ? t("page.automated-surveys-queue-details.header.status.active")
                      : t("page.automated-surveys-queue-details.header.status.inactive")
                }
              </span>
            </div>
            <span className="text-body">{t("page.automated-surveys-queue-details.header.status")}</span>
          </div>
        </div>
      }
    >
      <div className="grid grid-cols-1 gap-6 3xl:w-1/2 3xl:grid-cols-1">
        {queueDetails?.questionDetails.map((questionDetails, idx) => (
          <AutomatedSurveyQuestionGroupAnswerCard
            key={idx}
            order={idx + 1}
            question={questionDetails}
            onClickViewDetails={() => handleViewQuestionDetails(idx)}
          />
        ))}
      </div>
      <QueueDetailsModal
        isOpened={isQuestionModalOpened}
        onOpenChange={questionModalHandler.set}
        questionData={selectedQuestionData}
        questionId={selectedQuestionId}
      />
    </DocumentPaper>
  );
}
