export interface ListProps<TItem> {
  list: TItem[];
  children?: (item: TItem) => React.ReactNode;
  getKey: (item: TItem) => string;
}

export function List<TItem>(props: ListProps<TItem>): React.ReactNode {
  return (
    <ul className="divide-y divide-grey-100">
      {props.list.map((item) => (
        <li className="flex py-4" key={props.getKey?.(item)}>
          {props.children?.(item) ?? String(item)}
        </li>
      ))}
    </ul>
  );
}
