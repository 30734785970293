import { ErrorPage } from "components/Error/ErrorPage";
import { canAccessManageNeighbours } from "components/ProjectSidebar/permissions";
import { RequireAuth } from "components/Routing/RequireAuth";
import type { RouteObject } from "react-router-dom";

import { CreateOrEditUserPage } from "./pages/CreateOrEdit";
import { UserDetailPage } from "./pages/UserDetail";
import { UserOverviewPage } from "./pages/UserOverview";
import { canInviteUser } from "./permissions";
import { usersRoutes } from "./routes";

export const usersRoute = [
  {
    path: usersRoutes.list,
    element: (
      <RequireAuth permission={canAccessManageNeighbours}>
        <UserOverviewPage />
      </RequireAuth>
    ),
  },
  {
    path: usersRoutes.details,
    element: <UserDetailPage />,
  },
  {
    path: usersRoutes.new,
    element: (
      <RequireAuth permission={canInviteUser}>
        <CreateOrEditUserPage />
      </RequireAuth>
    ),
  },
  {
    path: usersRoutes.edit,
    element: (
      <RequireAuth permission={(x) => x.isAdmin}>
        <CreateOrEditUserPage />
      </RequireAuth>
    ),
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
