import type { ImageDto, UserDto } from "api/types";
import iconCheck from "assets/icons/check.svg";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Gallery } from "components/Gallery/Gallery";
import { Icon } from "components/Icon/Icon";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { UserAvatarLink } from "components/UserAvatarLink/UserAvatarLink";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface ChatMessageProps {
  isOwnMessage: boolean;
  hasNextMessage: boolean;
  isRead: boolean;
  user: UserDto;
  canSeeUserDetails?: boolean;
  content?: string;
  image?: ImageDto;
  sentAt: string;
}

export function ChatMessage({
  isOwnMessage,
  hasNextMessage,
  isRead,
  user,
  canSeeUserDetails,
  content,
  image,
  sentAt,
}: ChatMessageProps): React.ReactNode {
  const { i18n } = useTranslation();

  return (
    <div
      className={twJoin("flex gap-2", isOwnMessage ? "self-end" : "self-start", !hasNextMessage && "pb-6")}
      data-testid="chat-message"
    >
      <div className={twJoin("size-9 self-end", isOwnMessage && "order-last", hasNextMessage && "invisible")}>
        {canSeeUserDetails && !isOwnMessage ? <UserAvatarLink user={user} /> : <UserAvatar img={user.avatar} />}
      </div>
      <div
        className={twJoin(
          "sentry-block relative rounded-lg p-3",
          isOwnMessage ? "bg-aop-chat-green-100" : "bg-grey-100",
        )}
      >
        <div className="flex max-w-[200px] flex-col gap-2 lg:max-w-md">
          {content && (
            <span className="max-w-full self-start text-caption">
              <LinkFormatter>{content}</LinkFormatter>
            </span>
          )}
          {image && (
            <div className="max-w-full self-start">
              <Gallery images={[image]} />
            </div>
          )}
          <div className="flex items-center gap-2 self-end">
            <span className="self-end text-overline text-grey-600">{formatDate(i18n, "time", sentAt)}</span>
            {isOwnMessage && (
              <div className={twJoin("flex", isRead ? "text-aop-basic-blue-500" : "text-grey-500")}>
                <Icon name={iconCheck} size={16} className="-mr-2.5" />
                <Icon name={iconCheck} size={16} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
