import { useDocumentTitle } from "hooks/useDocumentTitle";
import { StopGlobalLoadingSpinner } from "providers/GlobalLoadingSpinner";

interface ErrorPageInnerProps {
  labels: {
    error: string;
    solutions?: string;
  };
  content: {
    title: string;
    status: string;
    solutions: React.ReactNode[];
    image: string;
  };
}

export function ErrorPageInner({ labels, content }: ErrorPageInnerProps): React.ReactNode {
  useDocumentTitle(content.title);

  return (
    <div
      className="flex h-full flex-col flex-wrap items-center justify-center p-8 sm:flex-row"
      data-testid="error-page"
    >
      <StopGlobalLoadingSpinner />
      <div className="mt-8 flex flex-col justify-center text-left sm:mt-0">
        <span className="whitespace-pre-wrap text-headline2 leading-old-10">{content.title}</span>
        <p
          className="mt-1 max-w-sm text-grey-700"
          data-testid="error-page-status"
        >{`${labels.error} ${content.status}`}</p>
        {labels.solutions && <span className="mb-1 mt-12 text-body-bold">{labels.solutions}</span>}
        <ul className="list-disc pl-8">
          {content.solutions?.map((solution, idx) => (
            <li className="whitespace-pre py-2 text-grey-700" key={`error_solution_${idx}`}>
              {solution}
            </li>
          ))}
        </ul>
      </div>
      <img className="w-2/3 pt-8 sm:ml-28 sm:w-1/3" src={content.image} alt="" />
    </div>
  );
}
