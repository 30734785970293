import * as V from "victory";

import { chartTheme, dataColors } from "../theme";

interface Props {
  size: number;
  primaryPercentage: number;
  benchmarkPercentage?: number;
}

export function DonutChart({
  primaryPercentage,
  benchmarkPercentage,
  size,
  children,
}: React.PropsWithChildren<Props>): React.ReactNode {
  return (
    <div
      className="relative"
      style={{
        width: size + "px",
      }}
    >
      <V.VictoryPie
        theme={chartTheme}
        width={size}
        height={size}
        style={{ data: { fill: ({ datum }: any) => datum.color } }}
        radius={size / 2 - 8}
        innerRadius={size / 2.8}
        data={getSlices(primaryPercentage, benchmarkPercentage)}
        labels={[]}
      />
      <div className="absolute left-1/2 top-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 flex-col items-center px-4 pb-2">
        {children}
      </div>
    </div>
  );
}

function getSlices(primary: Props["primaryPercentage"], benchmark: Props["benchmarkPercentage"]) {
  const primarySlice = {
    y: primary,
    color: dataColors.primary,
  };

  if (benchmark && benchmark > primary) {
    return [
      primarySlice,
      {
        y: benchmark - primary,
        color: dataColors.benchmark,
      },
      {
        y: 100 - benchmark,
        color: dataColors.chartBackground,
      },
    ];
  } else {
    return [
      primarySlice,
      {
        y: 100 - primary,
        color: dataColors.chartBackground,
      },
    ];
  }
}
