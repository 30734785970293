interface CardGridProps {
  children: React.ReactNode;
}

export function CardGrid({ children }: CardGridProps): React.ReactNode {
  return (
    <div className="grid auto-rows-min grid-cols-1 gap-4 @2xl:grid-cols-2 @5xl:grid-cols-3 @7xl:grid-cols-4">
      {children}
    </div>
  );
}
