import { ErrorPage } from "components/Error/ErrorPage";
import { RequireAuth } from "components/Routing/RequireAuth";
import type { RouteObject } from "react-router-dom";

import { CreateOrEditPlatformGroupsPage } from "./pages/CreateOrEdit";
import { ListPlatformGroupsPage } from "./pages/List";
import { canListPlatformGroups, canManagePlatformGroups } from "./permissions";
import { platformGroupsRoutes } from "./routes";

export const platformGroupsRoute = [
  {
    path: platformGroupsRoutes.list,
    element: (
      <RequireAuth permission={canListPlatformGroups}>
        <ListPlatformGroupsPage />
      </RequireAuth>
    ),
  },
  {
    path: platformGroupsRoutes.create,
    element: (
      <RequireAuth permission={canManagePlatformGroups}>
        <CreateOrEditPlatformGroupsPage />
      </RequireAuth>
    ),
  },
  {
    path: platformGroupsRoutes.edit,
    element: (
      <RequireAuth permission={canManagePlatformGroups}>
        <CreateOrEditPlatformGroupsPage />
      </RequireAuth>
    ),
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
