import { Button } from "components/Button/Button";
import { Select } from "components/Select/Select";
import { useBool } from "hooks/useBool";
import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

export interface WizardStepProps {
  id: number;
  title: React.ReactNode;
  children: React.ReactNode;
  canLeave: () => boolean | Promise<boolean>;
  hasFinish: boolean;
  isHidden?: boolean;
}

export interface WizardProps {
  id: string;
  children: React.ReactElement<WizardStepProps>[];
  strictOrder: boolean;
  isSubmitting: boolean;
  onFinish: () => void;
  actionsText: {
    prevStep?: string;
    nextStep?: string;
    finish?: string;
  };
  initialStepId?: WizardStepProps["id"];
}

export function WizardStep(props: WizardStepProps): React.ReactNode {
  return props.children;
}

export function Wizard({
  children,
  id,
  actionsText,
  strictOrder,
  isSubmitting,
  initialStepId,
  onFinish,
}: WizardProps): React.ReactNode {
  const { t } = useTranslation();

  const tabs = children
    .filter((x) => !x.props.isHidden)
    .map((step) => ({
      id: step.props.id,
      title: step.props.title,
      content: step.props.children,
      canLeave: step.props.canLeave,
      hasFinish: step.props.hasFinish,
    }));

  const [currentStepId, setCurrentStepId] = useState(() => initialStepId ?? tabs[0].id);
  const [maxVisitedStepId, setMaxVisitedStepId] = useState(currentStepId);
  const [isCheckingCanLeave, isCheckingCanLeaveHandlers] = useBool();

  const currentTabIndex = tabs.findIndex((tab) => tab.id === currentStepId);
  const previousTab = tabs[currentTabIndex - 1];
  const currentTab = tabs[currentTabIndex];
  const nextTab = tabs[currentTabIndex + 1];

  function isTabDisabled(stepId: number) {
    if (stepId === currentStepId) {
      return false;
    }

    if (!strictOrder) {
      return false;
    }

    if (maxVisitedStepId > stepId) {
      return false;
    }

    return currentStepId < stepId;
  }

  async function switchTab(tab: (typeof tabs)[number]) {
    if (!tab) {
      return;
    }

    // If we are going back, we don't need to check if we can leave
    if (tab.id < currentTab.id) {
      setCurrentStepId(tab.id);
      setMaxVisitedStepId(Math.max(maxVisitedStepId, tab.id));
    } else {
      // If we are going forward, we need to check if we can leave the current tab, not the destination one
      const currentTab = tabs.find(({ id }) => id === currentStepId);
      const canLeave = await currentTab!.canLeave?.();
      if (canLeave) {
        setCurrentStepId(tab.id);
        setMaxVisitedStepId(Math.max(maxVisitedStepId, tab.id));
      }
    }
  }

  const lastStepId = tabs[tabs.length - 1].id;
  const isFinishButtonShown = currentTab.hasFinish || currentStepId === lastStepId;

  return (
    <div data-testid="wizard">
      <div>
        {tabs.length > 1 && (
          <>
            <div className="relative z-20 sm:hidden">
              <label className="sr-only" htmlFor={id}>
                Select a tab
              </label>
              <Select
                placeholder="Steps"
                items={tabs}
                selected={currentTab}
                onChange={switchTab}
                keySelector={(tab) => tab.id}
                renderOption={(tab) => tab.title}
              />
            </div>
            <div className="hidden sm:block">
              <div className="-mb-px flex" role="tablist">
                {tabs.map((tab) => (
                  <button
                    className={twJoin(
                      "relative flex flex-auto justify-center rounded-3px border-2 border-transparent pb-3 pt-2 text-center before:absolute before:-inset-x-0.5 before:-bottom-0.5 before:h-0.5 before:content-[''] hover:bg-grey-100 focus-visible:rounded-b-none focus-visible:border-grey-700 focus-visible:outline-none focus-visible:before:opacity-0",
                      // eslint-disable-next-line no-nested-ternary
                      tab.id === currentStepId
                        ? "text-aop-basic-blue-500 before:bg-aop-basic-blue-500"
                        : isTabDisabled(tab.id)
                          ? "pointer-events-none text-grey-300 before:bg-grey-400"
                          : "text-grey-700 before:bg-grey-400",
                    )}
                    key={tab.id}
                    type="button"
                    data-testid="tabs-tab"
                    disabled={isTabDisabled(tab.id)}
                    onClick={() => switchTab(tab)}
                    role="tab"
                    id={`tab-${tab.id}`}
                    aria-selected={tab.id === currentStepId ? "true" : "false"}
                    aria-disabled={isTabDisabled(tab.id) ? "true" : "false"}
                    aria-controls={`${id}-${tab.id}-panel`}
                  >
                    <span className="flex flex-col items-center text-body-bold">{tab.title}</span>
                  </button>
                ))}
              </div>
            </div>
          </>
        )}
        <div className="mt-6">
          {tabs.map((tab) => (
            <div
              className={tab.id === currentStepId ? undefined : "hidden"}
              key={tab.id}
              aria-labelledby={`tab-${tab.id}`}
              role="tabpanel"
              id={`${id}-${tab.id}-panel`}
            >
              {tab.content}
            </div>
          ))}
        </div>
      </div>
      <div className="my-6 flex flex-wrap gap-4">
        {previousTab && (
          <Button
            data-testid="prev-step"
            disabled={isCheckingCanLeave || isSubmitting}
            styling="secondary"
            onClick={() => switchTab(previousTab)}
          >
            {actionsText.prevStep || t("component.wizard.action.prev-step")}
          </Button>
        )}

        {nextTab && (
          <Button
            data-testid="next-step"
            disabled={isCheckingCanLeave || isSubmitting}
            styling="secondary"
            onClick={() => switchTab(nextTab)}
          >
            {actionsText.nextStep || t("component.wizard.action.next-step")}
          </Button>
        )}

        {isFinishButtonShown && (
          <Button
            data-testid="finish-step"
            isLoading={isCheckingCanLeave || isSubmitting}
            onClick={async () => {
              try {
                isCheckingCanLeaveHandlers.setTrue();

                const canLeave = await currentTab.canLeave();
                if (canLeave) {
                  onFinish();
                }
              } finally {
                isCheckingCanLeaveHandlers.setFalse();
              }
            }}
          >
            {actionsText.finish || t("component.wizard.action.finish")}
          </Button>
        )}
      </div>
    </div>
  );
}
