import type { QuestionDto, ResponseDto, SurveyDetailsDto, SurveyDto } from "api/types";
import iconStar01 from "assets/icons/star-01.svg";
import { AIButton } from "components/Button/AIButton";
import { Button } from "components/Button/Button";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { UserAvatarLink } from "components/UserAvatarLink/UserAvatarLink";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { differenceInDays, isBefore, parseISO } from "date-fns";
import { avgBy } from "helpers/math";
import { useAIToolingManager } from "hooks/useAIToolingManager";
import { useBool } from "hooks/useBool";
import { useSlug } from "hooks/useSlug";
import { round } from "lodash-es";
import { BarChart } from "modules/analytics/components/BarChart";
import { PieChart } from "modules/analytics/components/PieChart";
import { SurveyExportModal } from "modules/surveys/components/SurveyExportModal";
import { SurveyOpenQuestionDetailsModal } from "modules/surveys/components/SurveyOpenQuestionDetailsModal";
import { SurveyOpenQuestionResponseSentimentModal } from "modules/surveys/components/SurveyOpenQuestionResponseSentimentModal";
import { usePostHog } from "posthog-js/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { routes } from "routes";

export interface LayoutProps {
  survey: SurveyDetailsDto;
  onDelete: (surveyId: SurveyDto) => Promise<unknown>;
}

// TODO(dev): Remove logic for survey of type "poll" once is considered irrelevant.
export function Layout({ survey, onDelete }: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal("survey-delete-modal");
  const [isSurveyExportModalOpened, surveyExportModalOpenHandlers] = useBool();

  const { startDate, endDate, isRunning, isFinished, isUpcoming, responses, days } = useMemo(() => {
    const startDate = survey.startsAt ? parseISO(survey.startsAt) : undefined;
    const endDate = survey.endsAt ? parseISO(survey.endsAt) : undefined;

    const isRunning = startDate && endDate && isBefore(startDate, new Date()) && isBefore(new Date(), endDate);
    const isUpcoming = startDate && isBefore(new Date(), startDate);
    const isFinished = endDate && isBefore(endDate, new Date());
    const days = endDate && startDate ? differenceInDays(endDate, startDate) : undefined;

    const userIds = new Set(survey.questions.flatMap((x) => x.responses?.map((x) => x.respondent.id) ?? []));

    return { startDate, endDate, isRunning, isUpcoming, isFinished, days, responses: userIds.size };
  }, [survey]);

  return (
    <DocumentPaper
      theme="minimal"
      title={survey.type === "poll" ? t("page.survey-details.poll-title") : t("page.survey-details.survey-title")}
      subTitle={
        survey.type === "poll" ? t("page.survey-details.poll-subtitle") : t("page.survey-details.survey-subtitle")
      }
      actions={
        <>
          {survey.canDelete ? (
            <Button styling="danger" onClick={openDeleteModal}>
              {t("page.survey-details.actions.delete")}
            </Button>
          ) : null}
          {survey.answerCount && survey.answerCount > 0 ? (
            <Button onClick={surveyExportModalOpenHandlers.setTrue} styling="secondary">
              {t("page.survey-details.actions.export")}
            </Button>
          ) : null}
          {survey.canEdit ? (
            <Button type="link" href={routes.surveys.edit({ slug, id: survey.id })}>
              {t("page.survey-details.actions.edit")}
            </Button>
          ) : null}
        </>
      }
    >
      <div className="max-w-screen-2xl">
        <div className="flex flex-col items-start gap-6 md:flex-row">
          <div className="flex w-full flex-col gap-2 rounded-lg bg-white p-6 md:order-last md:w-auto md:max-w-md">
            <div className="flex flex-col gap-2">
              {survey.internal && <Label theme="aopDarkBlue">{t("page.survey-details.info.internal")}</Label>}
              <div>
                <h2 className="text-headline3 leading-old-headline4">{survey.title}</h2>
                <p className="italic">
                  {t("page.survey-details.info.group.in", {
                    groupName: survey.group ? survey.group.name : t("page.survey-details.info.group.general"),
                  })}
                </p>
              </div>
            </div>
            <p className="mt-2 whitespace-pre-wrap">{survey.description}</p>

            <hr className="my-2 text-grey-300" />

            <div className="flex flex-col gap-2">
              <p className="flex gap-1">
                {
                  // eslint-disable-next-line no-nested-ternary
                  isFinished ? (
                    <span className="flex items-center">
                      <span className="mr-2 block size-2.5 shrink-0 rounded-full bg-aop-basic-blue-500" />
                      <span className="whitespace-nowrap">{t("page.survey-details.info.finished")}</span>
                    </span>
                  ) : // eslint-disable-next-line no-nested-ternary
                  isRunning ? (
                    <span className="flex items-center">
                      <span className="mr-2 block size-2.5 shrink-0 rounded-full bg-green-600" />
                      <span className="whitespace-nowrap">{t("page.survey-details.info.running")}</span>
                    </span>
                  ) : isUpcoming ? (
                    <span className="flex items-center">
                      <span className="mr-2 block size-2.5 shrink-0 rounded-full bg-yellow-500" />
                      <span className="whitespace-nowrap">{t("page.survey-details.info.upcoming")}</span>
                    </span>
                  ) : null
                }
                {(isFinished || isRunning) && responses ? (
                  <strong>({t("page.survey-details.info.responses", { count: responses })})</strong>
                ) : null}
              </p>
              <hr className="my-2 text-grey-300" />
              <table>
                <tbody>
                  {startDate ? (
                    <tr>
                      <td className="whitespace-nowrap pr-1">
                        {isUpcoming
                          ? t("page.survey-details.info.starts-at")
                          : t("page.survey-details.info.started-at")}
                        :{" "}
                      </td>
                      <td className="w-full font-old-semibold">
                        <FormattedDate date={startDate} format="datetime" />
                      </td>
                    </tr>
                  ) : null}
                  {endDate ? (
                    <tr>
                      <td className="whitespace-nowrap pr-1">
                        {isUpcoming || isRunning
                          ? t("page.survey-details.info.ends-at")
                          : t("page.survey-details.info.ended-at")}
                        :{" "}
                      </td>
                      <td className="w-full font-old-semibold">
                        <FormattedDate date={endDate} format="datetime" />
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
              {days ? <p>{t("page.survey-details.info.open-for", { count: days })}</p> : null}
            </div>

            <hr className="my-2 text-grey-300" />

            {survey.createdBy ? (
              <div className="flex items-center gap-1">
                <div className="mr-2 size-8">
                  <UserAvatarLink user={survey.createdBy} />
                </div>
                <UserNameLink user={survey.createdBy}>
                  <span className="font-old-semibold text-black">{survey.createdBy.fullName}</span>
                </UserNameLink>
              </div>
            ) : null}
          </div>

          <div className="flex w-full flex-1 flex-col gap-4 md:w-auto">
            {survey.questions.map((question) => {
              return (
                <div className="flex flex-col gap-2 rounded-lg bg-white p-6 shadow-md" key={question.id}>
                  <span className="text-body-bold">{question.question}</span>
                  {survey.canViewResults &&
                    question.responses &&
                    (question.responses.length > 0 ? (
                      <>
                        {question.type === "open" && <OpenAnswerWidget question={question} />}
                        {question.type === "yesNo" && <YesNoWidget question={question} />}
                        {question.type === "multipleChoice" && <MultipleChoiceWidget question={question} />}
                        {question.type === "rating" && <RatingWidget question={question} />}
                      </>
                    ) : (
                      <p className="my-2 text-grey-700">
                        {isFinished
                          ? t("page.survey-details.no-responses.finished")
                          : t("page.survey-details.no-responses.running")}
                      </p>
                    ))}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <DeleteModal
        title={t("page.surveys.actions.delete.title")}
        description={t("page.surveys.actions.delete.description")}
        onDelete={() => onDelete(survey)}
        deleteBtnProps={{
          "data-testid": "survey-action-delete",
        }}
        {...deleteModalProps}
      />
      <SurveyExportModal
        isOpened={isSurveyExportModalOpened}
        onOpenChange={surveyExportModalOpenHandlers.set}
        surveyId={survey.id}
        surveyName={survey.title}
      />
    </DocumentPaper>
  );
}

function YesNoWidget({ question }: { question: QuestionDto }) {
  const { t } = useTranslation();
  const yes = question.responses?.filter((r) => r.pollAnswer === true).length ?? 0;
  const no = question.responses?.filter((r) => r.pollAnswer === false).length ?? 0;
  const total = yes + no;

  if (total === 0) {
    return null;
  }

  return (
    <div>
      <PieChart
        size={300}
        slices={[
          { label: t("page.survey-details.poll.answer.yes"), amount: yes },
          { label: t("page.survey-details.poll.answer.no"), amount: no },
        ]}
        hoverLabel={(x) => (
          <div className="flex flex-col">
            <strong>{x.label}</strong>
            <span>{t("page.survey-details.pie-chart.label.responses", { count: x.amount })}</span>
            <span>
              {t("page.survey-details.pie-chart.label.percentage", { percentage: round((x.amount / total) * 100) })}
            </span>
          </div>
        )}
      />
      <span className="align-bottom text-grey-600">{t("page.survey-details.poll.responses", { count: total })}</span>
    </div>
  );
}

const MAX_OPEN_ANSWERS = 5;

function OpenAnswerWidget({ question }: { question: QuestionDto }) {
  const [isSentimentModalOpen, sentimentModalOpenHandlers] = useBool(false);
  const [isDetailsModalOpened, detailsModalOpenHandlers] = useBool();
  const { t } = useTranslation();
  const postHog = usePostHog();
  const { id: surveyId } = useParams<{ id: string }>();

  const responses = question.responses?.filter((response) => response.text);
  const { isAvailable: isAIToolingAvailable } = useAIToolingManager();

  if (!responses) {
    return null;
  }

  const onClickSentimentAnalysis = () => {
    postHog?.capture("clicked_survey_sentiment_analysis", {
      surveyId: surveyId,
    });

    sentimentModalOpenHandlers.setTrue();
  };

  const isAnyResponsesOmitted = responses.length > MAX_OPEN_ANSWERS;

  return (
    <>
      <div className="flex w-full flex-col gap-8">
        <div className="flex w-full flex-col gap-2">
          {responses.slice(0, MAX_OPEN_ANSWERS).map((response) => (
            <Answer key={response.id} response={response} />
          ))}
        </div>
        <div className=" flex w-full flex-col justify-center gap-2 md:flex-row md:items-center md:justify-end">
          {isAIToolingAvailable && (
            <AIButton onClick={onClickSentimentAnalysis}>{t("page.automated-surveys.sentiment.btn")}</AIButton>
          )}
          {isAnyResponsesOmitted && (
            <>
              <Button styling="primary" onClick={detailsModalOpenHandlers.setTrue}>
                {t("page.survey-details.open-answers.show-more")}
              </Button>
              <SurveyOpenQuestionDetailsModal
                question={question}
                responses={responses}
                isOpened={isDetailsModalOpened}
                onOpenChange={detailsModalOpenHandlers.set}
              />
            </>
          )}
        </div>
      </div>
      {isAIToolingAvailable && (
        <SurveyOpenQuestionResponseSentimentModal
          questionId={question.id}
          isOpen={isSentimentModalOpen}
          onOpenChange={sentimentModalOpenHandlers.set}
        />
      )}
    </>
  );
}

function Answer({ response }: { response: ResponseDto }) {
  return (
    <div className="flex items-center gap-2 py-2">
      <div className="mr-2 size-8">
        <UserAvatarLink user={response.respondent} />
      </div>
      <div className="flex flex-col">
        <span className="text-caption-bold text-black">
          <UserNameLink user={response.respondent}>{response.respondent.fullName}</UserNameLink>
        </span>
        <p className="text-grey-900">{response.text ?? "-"}</p>
      </div>
    </div>
  );
}

function RatingWidget({ question }: { question: QuestionDto }) {
  const { t } = useTranslation();

  if (!question.responses) {
    return null;
  }

  const validResponses = question.responses.filter((r) => r.rating != null);
  const averageRating = avgBy(
    validResponses,
    (r) => r.rating,
    () => 1,
  );

  return (
    <div className="mt-2 flex flex-col gap-2">
      <div className="flex items-center gap-3">
        <Icon name={iconStar01} size={32} className="text-yellow-500" />
        <div className="text-headline1">
          <span>{averageRating ? round(averageRating, 1) : "-"}</span>
          <span className="text-headline3 font-old-normal">/10</span>
        </div>
      </div>
      <span className="align-bottom text-grey-600">
        {t("page.survey-details.rating.responses", { count: validResponses.length })}
      </span>
    </div>
  );
}

function MultipleChoiceWidget({ question }: { question: QuestionDto }) {
  const { t } = useTranslation();

  if (!question.answers) {
    return null;
  }

  const total = question.responses?.filter((x) => x.offeredAnswerId).length ?? 0;

  const slices = question.answers.map((a) => ({
    x: a.text,
    y: question.responses?.filter((r) => r.offeredAnswerId === a.id)?.length ?? 0,
  }));

  if (question.answers?.length === 2) {
    return (
      <PieChart
        size={300}
        slices={slices.map((x) => ({ amount: x.y, label: x.x }))}
        hoverLabel={(x) => (
          <div className="flex max-w-[200px] flex-col">
            <strong>{x.label}</strong>
            <span>{t("page.survey-details.pie-chart.label.responses", { count: x.amount })}</span>
            <span>
              {t("page.survey-details.pie-chart.label.percentage", { percentage: round((x.amount / total) * 100) })}
            </span>
          </div>
        )}
      />
    );
  }

  return (
    <div className="max-w-md">
      <BarChart
        primaryData={slices}
        formatYTick={(x) => (x % 1 === 0 ? x : "")}
        type="text"
        labelOrientation="vertical"
        formatXTick={(x) => (x.length > 10 ? x.slice(0, 10) + "..." : x)}
        renderLabel={(x) => (
          <div className="flex max-w-[200px] flex-col">
            {x.primary ? <span className="font-old-semibold">{x.primary?.x}</span> : null}
            <span>{t("page.survey-details.bar-chart.label.responses", { count: x.primary?.y })} </span>
            <span>
              {t("page.survey-details.bar-chart.label.percentage", {
                percentage: x.primary?.y && total ? round((x.primary?.y / total) * 100) : 0,
              })}
            </span>
          </div>
        )}
      />
    </div>
  );
}
