import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import type { DateRangeOption } from "modules/analytics/util";
import { QUERY_KEYS } from "query-keys";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { NonEmptyArray } from "types/utility-types";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();

  const dateRangeOptions = useMemo<NonEmptyArray<DateRangeOption>>(
    () => [
      {
        name: t("common.date-range.last-months", { count: 6 }),
        value: "last6Months",
      },
      {
        name: t("common.date-range.last-year"),
        value: "lastYear",
      },
    ],
    [t],
  );

  const [dateRange, setDateRange] = useState<DateRangeOption>(dateRangeOptions[0]);

  const {
    data: contributionData,
    isLoading: isLoadingContributionData,
    isRefetching: isRefetchingContributionData,
    error: contributionError,
  } = useQuery({
    queryKey: QUERY_KEYS.ANALYTICS_ACTIVE_CONTRIBUTORS(projectId, dateRange.value),
    queryFn: () => api.getAnalyticsEngagedResidentsMonthlyV2({ dateRange: dateRange.value }),
    select: commonAPIDataSelector,
  });

  const error = contributionError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const loading = isLoadingContributionData;
  if (loading) {
    return <FullSizeLoader withPadding />;
  }

  return props.children({
    contribution: contributionData ?? { benchmark: [], data: [] },
    isRefetchingContributionData: isRefetchingContributionData,
    selectedDateRange: dateRange,
    dateRangeOptions,
    onDateRangeChange: setDateRange,
  });
}
