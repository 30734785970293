import type { RefCallback } from "react";
import { useRef, useState } from "react";

import { useResizeObserver } from "./useResizeObserver";

export function useContainerIsBiggerThan(breakpoint: keyof typeof containerQueries): [RefCallback<Element>, boolean] {
  const containerRef = useRef<Element | null>(null);
  const [matches, setMatches] = useState(false);

  const query = containerQueries[breakpoint];

  useResizeObserver(containerRef, (entries) => {
    for (const entry of entries) {
      setMatches(entry.contentRect.width > query);
    }
  });

  const ref: RefCallback<Element> = function refSetter(node: Element | null) {
    if (node) {
      const container = node.closest(".\\@container");
      if (import.meta.env.DEV && node && !container) {
        console.error("Can not call useContainerIsBiggerThan on an element that is not inside a container");
      }

      containerRef.current = container;
    }
  };

  return [ref, matches];
}

const containerQueries = {
  "@xs": 320,
  "@sm": 384,
  "@md": 448,
  "@lg": 512,
  "@xl": 576,
  "@2xl": 672,
  "@3xl": 768,
  "@4xl": 896,
  "@5xl": 1024,
  "@6xl": 1152,
  "@7xl": 1280,
};
