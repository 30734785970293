import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { Button } from "components/Button/Button";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Form } from "components/Form/Form";
import { FormInput } from "components/Form/FormInput";
import { FormSelect } from "components/Form/FormSelect";
import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import { createRequiredStringRule } from "helpers/rules";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

type DeclineReason =
  | "double-booking"
  | "maintenance"
  | "policy-violation"
  | "emergency"
  | "priority"
  | "incorrect-info"
  | "other";

const declineReasonsIds: DeclineReason[] = [
  "double-booking",
  "maintenance",
  "policy-violation",
  "emergency",
  "priority",
  "incorrect-info",
  "other",
];

interface Reason {
  id: DeclineReason;
  label: string;
}

interface FormValues {
  reason: Reason;
  detail?: string;
}

type DeclineBookingModalProps = ModalBaseProps & {
  bookingId: string;
  assetId: string;
};

export function DeclineBookingModal({
  bookingId,
  assetId,
  isOpened,
  onOpenChange,
}: DeclineBookingModalProps): React.ReactNode {
  const api = useApi();
  const queryClient = useQueryClient();
  const projectId = useProjectId();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();
  const form = useForm<FormValues>();

  const reason = useWatch({ control: form.control, name: "reason.id" });

  const deleteReservation = useMutation({
    mutationFn: (reason: string) =>
      api.deleteBookableAssetsBookingsByIdV1(bookingId, assetId, { reason }).then((x) => x.data),
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("page.bookings.asset-detail.decline-booking.success"),
      });

      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.BOOKINGS_ASSET_SLOTS(projectId, assetId) });
      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.BOOKINGS_ASSET_BOOKINGS(projectId, assetId),
      });
      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.BOOKINGS_USER_RESERVATIONS(projectId),
      });
      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.BOOKINGS_RESERVATION_DETAILS(projectId, assetId, bookingId),
      });
    },
    onError: () =>
      showFlashToast({
        type: "error",
        title: t("page.bookings.asset-detail.decline-booking.error"),
      }),
  });

  async function onSubmit(values: FormValues) {
    await deleteReservation.mutateAsync(values.reason.id === "other" ? values.detail! : values.reason.label);

    onOpenChange(false);
  }

  const declineReasons: Reason[] = declineReasonsIds.map((reason) => ({
    id: reason,
    label: t(`page.bookings.asset-detail.decline-booking.reasons.${reason}`),
  }));

  return (
    <Modal.Root
      title={t("page.bookings.asset-detail.decline-booking.title")}
      description={t("page.bookings.asset-detail.decline-booking.description")}
      size="sm"
      {...{ isOpened, onOpenChange }}
    >
      <Form className="w-full" formMethods={form} onSubmit={onSubmit}>
        <FormSelect<FormValues, Reason>
          name="reason"
          placeholder={t("page.bookings.asset-detail.decline-booking.reasons.placeholder")}
          items={declineReasons}
          keySelector={(x) => x.id}
          renderOption={(x) => x.label}
          rules={{
            required: t("components.form.error.required", {
              inputName: t("page.bookings.asset-detail.decline-booking.reasons.placeholder"),
            }),
          }}
        />
        {reason === "other" ? (
          <FormInput<FormValues, "detail">
            name="detail"
            rules={{
              validate: {
                required: createRequiredStringRule(
                  t,
                  "page.bookings.asset-detail.decline-booking.reasons.other.detail.name",
                ),
              },
            }}
          />
        ) : null}
        <Modal.Actions>
          <Modal.Close>
            <Button styling="secondary" isLoading={deleteReservation.isPending}>
              {t("common.action.cancel")}
            </Button>
          </Modal.Close>
          <Button type="submit" styling="danger" isLoading={deleteReservation.isPending}>
            {t("page.bookings.asset-detail.booking-details.cancel")}
          </Button>
        </Modal.Actions>
      </Form>
    </Modal.Root>
  );
}
