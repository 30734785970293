import type { LanguageDto } from "api/types";
import { Button } from "components/Button/Button";
import { FormSplitContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { FormSelect } from "components/Form/FormSelect";
import { createRequiredStringRule } from "helpers/rules";
import type { AutomatedSurveyQuestionCategory } from "modules/system-settings/automated-surveys/constants";
import type { FieldArrayWithId, UseFieldArrayRemove, UseFieldArrayUpdate } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MAX_LENGTH_QUESTION } from "../constants";
import type { FormValues } from "../Layout";

interface Props {
  language: LanguageDto["id"];
  disabled: boolean;
  categories: AutomatedSurveyQuestionCategory[];
  question: FieldArrayWithId<FormValues, "questions", "id">;
  idx: number;
  showDelete?: boolean;
  updateField: UseFieldArrayUpdate<FormValues, "questions">;
  removeField: UseFieldArrayRemove;
}

export function FormQuestion({
  language,
  disabled,
  categories,
  question,
  idx,
  showDelete,
  updateField,
  removeField,
}: Props): React.ReactNode {
  const { t } = useTranslation();

  function onBlurQuestion(type: "ratingQuestion" | "openQuestion" | "explanation", value: string) {
    updateField(idx, {
      ...question,
      [type]: {
        ...question[type],
        [language]: value || "",
      },
    });
  }

  return (
    <div className="flex flex-col gap-6">
      <FormSplitContent>
        <FormField label={t("page.automated-surveys.new.form.category.label")} required>
          <FormSelect<FormValues, AutomatedSurveyQuestionCategory>
            name={`questions.${idx}.category`}
            items={categories}
            defaultValue={question.category}
            keySelector={(x) => x.id}
            renderOption={(x) => {
              switch (x.id) {
                case "building":
                  return t("page.automated-surveys.question-type.building");
                case "community":
                  return t("page.automated-surveys.question-type.community");
                case "service":
                  return t("page.automated-surveys.question-type.service");
                case "feelAtHome":
                  return t("page.automated-surveys.question-type.feeling-at-home");
                case "other":
                  return t("page.automated-surveys.types.other");
              }
            }}
            // This update forces a re-render, so that we can show the delete buttons on the right questions
            onChange={(cat) => updateField(idx, { ...question, category: cat! })}
            rules={{
              required: t("components.form.error.required", {
                inputName: t("page.automated-surveys.new.form.category.label"),
              }),
            }}
            disabled={disabled}
          />
        </FormField>
        <FormField label={t("page.automated-surveys.new.form.rating-question.label")} required>
          <FormInput<FormValues>
            name={`questions.${idx}.ratingQuestion.${language}`}
            onBlur={(e) => onBlurQuestion("ratingQuestion", e.target.value)}
            placeholder={t("page.automated-surveys.new.form.rating-question.placeholder")}
            rules={{
              maxLength: {
                message: t("components.form.error.max-length", { length: MAX_LENGTH_QUESTION }),
                value: MAX_LENGTH_QUESTION,
              },
              validate: {
                required: createRequiredStringRule(t, "page.automated-surveys.new.form.rating-question.label"),
              },
            }}
          />
        </FormField>
      </FormSplitContent>
      <FormField label={t("page.automated-surveys.new.form.open-question.label")}>
        <FormInput<FormValues>
          name={`questions.${idx}.openQuestion.${language}`}
          onBlur={(e) => onBlurQuestion("openQuestion", e.target.value)}
          placeholder={t("page.automated-surveys.new.form.open-question.placeholder")}
          rules={{
            maxLength: {
              message: t("components.form.error.max-length", { length: MAX_LENGTH_QUESTION }),
              value: MAX_LENGTH_QUESTION,
            },
          }}
        />
      </FormField>
      <FormField label={t("page.automated-surveys.new.form.question-explanation.label")}>
        <FormInput<FormValues>
          name={`questions.${idx}.explanation.${language}`}
          onBlur={(e) => onBlurQuestion("explanation", e.target.value)}
          placeholder={t("page.automated-surveys.new.form.question-explanation.placeholder")}
          rules={{
            maxLength: {
              message: t("components.form.error.max-length", { length: MAX_LENGTH_QUESTION }),
              value: MAX_LENGTH_QUESTION,
            },
          }}
        />
      </FormField>
      {showDelete && (
        <div className="self-end">
          <Button styling="danger" onClick={() => removeField(idx)}>
            {t("page.automated-surveys.new.question.delete")}
          </Button>
        </div>
      )}
    </div>
  );
}
