import type { LanguageDto } from "api/types";
import { atom } from "jotai";

const key = "AoP.language";

const internalLanguageAtom = atom<string | null>(localStorage.getItem(key));

export const languageAtom = atom<string | null, [LanguageDto["id"]], unknown>(
  (get) => get(internalLanguageAtom),
  (get, set, newLanguage) => {
    set(internalLanguageAtom, newLanguage);
    localStorage.setItem(key, newLanguage);
  },
);
