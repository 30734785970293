import { useEffect } from "react";

export function useDocumentTitle(title: string | undefined): void {
  useEffect(() => {
    if (title) {
      document.title = `${title} | Area of People`;
    } else {
      document.title = `Area of People`;
    }
  }, [title]);
}
