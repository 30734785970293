import { AnimatePresence } from "framer-motion";
import type { Dispatch, PropsWithChildren, SetStateAction } from "react";
import React, { useContext, useState } from "react";

import type { NotificationPayload } from "./Notification";
import { Notification } from "./Notification";

const NotificationContext = React.createContext<Dispatch<SetStateAction<NotificationPayload | null>>>(() => {});

export function useNotification(): (props: NotificationPayload) => void {
  return useContext(NotificationContext);
}

export function NotificationProvider({ children }: PropsWithChildren<{}>): React.ReactNode {
  const [current, setCurrent] = useState<NotificationPayload | null>(null);

  return (
    <NotificationContext.Provider value={setCurrent}>
      <div className="fixed inset-x-0 top-0 z-40">
        <AnimatePresence>
          {current != null && <Notification {...current} onDismiss={() => setCurrent(null)} />}
        </AnimatePresence>
      </div>
      {children}
    </NotificationContext.Provider>
  );
}
