import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useParams } from "react-router-dom";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const { id: queueId } = useParams<{ id: string }>();

  const {
    data: queueDetails,
    isFetching: isLoadingQueueDetails,
    error: queueDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.AUTOMATED_SURVEY_QUEUE_DETAILS(projectId, queueId!),
    queryFn: () => api.getAutomatedSurveysQueuesDetailsV1(queueId!),
    select: commonAPIDataSelector,
  });

  const error = queueDetailsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const loading = isLoadingQueueDetails;
  if (loading) {
    return <FullSizeLoader withPadding />;
  }

  return props.children({ queueDetails });
}
