import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { Button } from "components/Button/Button";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import React from "react";
import { useParams } from "react-router-dom";

export function SurveyResults(): React.ReactNode {
  const { id: surveyId } = useParams<{ id: string }>();
  const api = useApi();
  const {
    data = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ["survey-export", surveyId],
    queryFn: () => api.getSurveysExportV1(surveyId!),
    select: commonAPIDataSelector,
  });

  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  const header = "Id,Question,Answer,Respondent,Building,Address,Type,Floor";

  const itemsToCsv = data.map((x) => {
    return `${x.id},"${x.question?.replace('"', '""')}","${x.answer?.replace('"', '""') || ""}",${x.respondent},${
      x.building
    },${x.address},${x.addressType || ""},${x.floor || ""}`;
  });

  const content = [header, ...itemsToCsv].join("\n");

  return (
    <DocumentPaper
      title={`Survey export (ID=${surveyId})`}
      subTitle={
        <Button styling="secondary" onClick={() => navigator.clipboard.writeText(content)}>
          Copy content to clipboard
        </Button>
      }
      theme="minimal"
    >
      {content.split("\n").map((line) => (
        <React.Fragment key={line}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </DocumentPaper>
  );
}
