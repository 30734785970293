import type { FieldPath, FieldPathValue, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";

export function FormHiddenInput<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({ name, value }: { name: TName; value?: FieldPathValue<TFormValues, TName> }): React.ReactNode {
  const { field } = useController<TFormValues, TName>({ name, defaultValue: value });

  return <input type="hidden" {...field} />;
}
