import { useEffect, useRef } from "react";

interface Interval {
  clearInterval: () => void;
}

export function useInterval(callback: () => void, delay: number): Interval {
  const intervalToken = useRef<ReturnType<typeof setTimeout>>();
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current!();
    }
    intervalToken.current = setInterval(tick, delay);

    return () => intervalToken.current && clearInterval(intervalToken.current);
  }, [delay]);

  return { clearInterval: () => intervalToken.current && clearInterval(intervalToken.current) };
}
