import { LoadingIcon } from "components/Icons/Icons";

export function NextButton({
  isLoading,
  onClick,
  children,
}: {
  isLoading?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
}): React.ReactNode {
  return (
    <button
      data-testid="next-step-button"
      className="relative rounded-br-lg rounded-tl-lg bg-aop-basic-blue-500 p-4 text-white disabled:bg-aop-basic-blue-600"
      type={onClick ? "button" : "submit"}
      disabled={isLoading}
      onClick={onClick}
    >
      <span className="text-body-bold">
        {isLoading ? (
          <LoadingIcon className="absolute left-1/2 top-1/2 w-5 -translate-x-1/2 -translate-y-1/2 text-white" />
        ) : null}
        <span className={isLoading ? "invisible" : undefined}>{children}</span>
      </span>
    </button>
  );
}
