import type { CommentDto } from "api/types";
import { Button } from "components/Button/Button";
import { CommentFieldWithAvatar } from "components/CommentField/CommentField";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { LoadingIcon } from "components/Icons/Icons";
import type { FormImage } from "components/ImageInput/useImageInput";
import { useImageInput } from "components/ImageInput/useImageInput";
import { parseISO } from "date-fns";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { sortBy } from "lodash-es";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Comment } from "./Comment";

interface Props {
  messageId: string;
  hasMoreComments: boolean;
  isLoadingComments: boolean;
  comments: CommentDto[];
  onLoadMore: () => void;
  commentPost: ({
    comment,
    files,
    failureMessage,
    parentId,
  }: {
    comment: string;
    files: FormImage[];
    parentId?: string;
    failureMessage: string;
  }) => Promise<CommentDto>;
  editComment: ({
    comment,
    commentId,
    files,
    failureMessage,
  }: {
    comment: string;
    commentId: string;
    files: FormImage[];
    failureMessage: string;
  }) => void;
  deleteComment: (commentId: string) => void;
  onLikeComment: (commentId: string) => void;
  onUnlikeComment: (commentId: string) => void;
  onViewCommentLikes: (data: { commentId: string; likeCount: number }) => void;
  isGreyedOut: boolean;
}

export function CommentList({
  messageId,
  hasMoreComments,
  isLoadingComments,
  comments,
  onLoadMore,
  commentPost,
  editComment,
  deleteComment,
  onLikeComment,
  onUnlikeComment,
  onViewCommentLikes,
  isGreyedOut,
}: Props): React.ReactNode {
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const [editingComment, setEditingComment] = useState<string | undefined>(undefined);
  const [deletingComment, setDeletingComment] = useState<string | undefined>(undefined);
  const [images, setImages] = useState<FormImage[]>([]);
  const { addImages, removeImage, removeImages } = useImageInput({ onChange: setImages });
  const { getValues, setValue, watch, register } = useForm<{ commentValue: string }>();

  const commentsByDate = useMemo(() => sortBy(comments, (x) => parseISO(x.postedAt).valueOf()), [comments]);

  useEffect(() => {
    register("commentValue");
  }, [register]);

  const commentValue = watch("commentValue");

  async function onReply() {
    const { commentValue } = getValues();

    if (editingComment) {
      editComment({
        comment: commentValue,
        commentId: editingComment,
        files: images,
        failureMessage: t("component.community-post.comments.edit-error"),
      });
      setEditingComment(undefined);
    } else {
      await commentPost({
        comment: commentValue,
        files: images,
        failureMessage: t("component.community-post.comments.error"),
      });
    }

    removeImages();
    setValue("commentValue", "");
  }

  const onEdit = useCallback(
    (comment: CommentDto) => {
      setValue("commentValue", comment.content || "");
      if (comment.image) {
        setImages([comment.image]);
      }
      setEditingComment(comment.id);
    },
    [setValue, setImages, setEditingComment],
  );

  const onDelete = useCallback((comment: CommentDto) => {
    setDeletingComment(comment.id);
  }, []);

  const onCancelEdit = useCallback(() => {
    setValue("commentValue", "");
    removeImages();
    setEditingComment(undefined);
  }, [removeImages, setValue, setEditingComment]);

  const onChangeContent = useCallback((value: string) => setValue("commentValue", value), [setValue]);

  const onRemoveImage = useCallback(
    (imageToBeRemoved: FormImage) => {
      removeImage(imageToBeRemoved);
    },
    [removeImage],
  );

  return (
    <div>
      {hasMoreComments && (
        <Button styling="tertiary" data-testid="comments-load-more" onClick={onLoadMore}>
          <span className="text-caption">{t("component.community-post.comments.see-more")}</span>
        </Button>
      )}
      {isLoadingComments ? <LoadingIcon className="inset-0 mx-auto my-4 w-6" /> : null}
      <div>
        {commentsByDate.map((comment) => (
          <Comment
            key={comment.id}
            comment={comment}
            isGreyedOut={isGreyedOut}
            onEdit={onEdit}
            onDelete={onDelete}
            messageId={messageId}
            editComment={editComment}
            createComment={commentPost}
            onLike={onLikeComment}
            onUnlike={onUnlikeComment}
            onViewCommentLikes={() => onViewCommentLikes({ commentId: comment.id, likeCount: comment.totalLikesCount })}
          />
        ))}
      </div>
      {!isGreyedOut && !isLoadingComments && (
        <form>
          <div className="flex items-end">
            <CommentFieldWithAvatar
              user={sessionUser}
              value={commentValue}
              onChange={onChangeContent}
              images={images}
              onSubmit={onReply}
              isEdit={editingComment !== undefined}
              onCancel={onCancelEdit}
              allowedAttachments={["image"]}
              onRemoveImage={onRemoveImage}
              onAddImages={addImages}
            />
          </div>
        </form>
      )}
      <ConfirmModal
        isOpen={!!deletingComment}
        id="delete-comment-modal"
        title={t("component.community-post.comments.delete.modal.title")}
        description={t("component.community-post.comments.delete.modal.description")}
        isLoading={false}
        theme="danger"
        onReject={() => setDeletingComment(undefined)}
        onOpenChange={(state) => {
          if (!state) {
            setDeletingComment(undefined);
          }
        }}
        rejectBtnProps={{
          "data-testid": "delete-comment-modal-cancel",
        }}
        onResolve={() => {
          deleteComment(deletingComment!);
          setDeletingComment(undefined);
        }}
        resolveBtnProps={{
          text: t("common.action.delete"),
          "data-testid": "delete-comment-modal-confirm",
        }}
        shouldCloseOnEsc
        data-testid="cancel-edit-modal"
      />
    </div>
  );
}
