import { ErrorPage } from "components/Error/ErrorPage";
import type { PermissionSelector } from "hooks/usePermission";
import { usePermission } from "hooks/usePermission";

interface Props {
  permission: PermissionSelector;
  children: React.ReactNode;
}

export function RequireAuth({ permission, children }: Props): React.ReactNode {
  const hasPermission = usePermission();

  return hasPermission(permission) ? children : <ErrorPage status={403} />;
}
