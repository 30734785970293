export const portfolioRoutes = {
  overview: "",
  newProject: "new",
  userLookup: "users-lookup",
  createMassMessage: "message/new",
  tickets: "tickets",
  ticketSettings: "ticket-settings",
  integrations: "integrations",
  integrationDetails: "integrations/:id",
} as const;
