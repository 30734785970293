import type { ConstraintListItemDto } from "api/types";

export function addressTagsToDictionary(
  tags: ConstraintListItemDto[] = [],
): Partial<Record<ConstraintListItemDto["type"], ConstraintListItemDto["key"]>> {
  return Object.fromEntries(tags.map((x) => [x.type, x.key]));
}

export function formatAddress(x: { streetName: string; houseNumber: string; houseNumberSuffix?: string }): string {
  return `${x.streetName} ${x.houseNumber} ${x.houseNumberSuffix}`.trim();
}
