import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type {
  ConnectedProjectDto,
  SystemGroupCreateRequest,
  SystemGroupUpdateRequest,
  TranslateRequest,
} from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { toPermissionsRequest } from "helpers/system-groups/permissions";
import { toTranslationsRequest } from "helpers/system-groups/translations";
import { isDefined } from "helpers/util";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useProjectId } from "hooks/Network/useProjectId";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "routes";

import type { FormValues, LayoutProps } from "./Layout";
import { getDefaultFormValues } from "./Manager";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader({ children }: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const { id: groupId } = useParams<{ id: string }>();
  const isEdit = isDefined(groupId);
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { uploadFormImage, isUploadingImage } = useUploadImage();
  const { data: projects = [] } = useConnectedProjects();

  const {
    data: group,
    isFetching: isLoadingGroup,
    error: groupError,
  } = useQuery({
    queryKey: QUERY_KEYS.PLATFORM_GROUP_DETAILS(groupId!),
    queryFn: () => api.getSystemGroupsDetailsV1(groupId!),
    enabled: isEdit,
    select: commonAPIDataSelector,
  });

  const {
    data: languages = [],
    isFetching: isLoadingLanguages,
    error: languagesError,
  } = useQuery({
    queryKey: QUERY_KEYS.LANGUAGES_ALL,
    queryFn: () => api.getLanguagesAllV1(),
    select: commonAPIDataSelector,
  });

  const {
    data: defaultGroupsPermissions = [],
    isFetching: isLoadingDefaultGroupsPermissions,
    error: defaultGroupsPermissionsError,
  } = useQuery({
    queryKey: QUERY_KEYS.ROLE_TYPE_DEFAULT_PERMISSIONS(projectId),
    queryFn: () => api.getPermissionsDefaultTypeV1(),
    select: commonAPIDataSelector,
  });

  const {
    data: roles = [],
    isFetching: isLoadingRoles,
    error: rolesError,
  } = useQuery({
    queryKey: QUERY_KEYS.PERMISSION_ROLES(projectId),
    queryFn: () => api.getPermissionsRolesV1(),
    select: commonAPIDataSelector,
  });

  const { mutateAsync: onTranslate, isPending: isTranslating } = useMutation({
    mutationFn: (payload: TranslateRequest) => api.postTranslationsTranslateV1(payload).then((x) => x.data),
  });

  const { mutateAsync: createGroup, isPending: isSubmittingCreate } = useMutation({
    mutationFn: (payload: SystemGroupCreateRequest) => api.postSystemGroupsV1(payload),
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("page.platform-groups.create.success"),
      });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PLATFORM_GROUPS });
      navigate(routes.platformGroups.list());
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("page.platform-groups.create.error"),
      });
    },
  });

  const { mutateAsync: editGroup, isPending: isSubmittingEdit } = useMutation({
    mutationFn: (payload: SystemGroupUpdateRequest) => api.putSystemGroupsV1(groupId!, payload),
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("page.platform-groups.edit.success"),
      });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PLATFORM_GROUPS });
      navigate(routes.platformGroups.list());
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("page.platform-groups.edit.error"),
      });
    },
  });

  async function onSubmit(payload: FormValues) {
    const image = await uploadFormImage(payload.image![0]);
    if (!image) {
      showFlashToast({
        type: "success",
        title: t("page.platform-groups.create.success"),
      });

      return;
    }

    const communityGroupRequest: SystemGroupCreateRequest | SystemGroupUpdateRequest = {
      type: payload.type,
      translations: toTranslationsRequest(languages, payload.nameTranslations, payload.descriptionTranslations),
      icon: payload.icon!,
      imageId: image.id,
      defaultPostingAudience: payload.audience,
      permissions: toPermissionsRequest(payload.permissions),
    };

    if (isEdit) {
      await editGroup({
        ...communityGroupRequest,
      });
    } else {
      await createGroup({
        ...communityGroupRequest,
        excludedProjectIds: getExcludedProjectIds(projects, payload.projects),
        postingLevel: payload.postingLevel,
      });
    }
  }

  const error = languagesError || defaultGroupsPermissionsError || rolesError || groupError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const isLoading = isLoadingLanguages || isLoadingDefaultGroupsPermissions || isLoadingRoles || isLoadingGroup;
  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  const defaultValues = getDefaultFormValues(roles, languages, projects, defaultGroupsPermissions, group);

  return children({
    languages: languages ?? [],
    projects: projects ?? [],
    defaultValues,
    onTranslate,
    isTranslating,
    onSubmit,
    isSubmitting: isUploadingImage || isSubmittingCreate || isSubmittingEdit,
  });
}

function getExcludedProjectIds(projects: ConnectedProjectDto[], enabledProjects: ConnectedProjectDto[]): string[] {
  const projectIds = projects.map((project) => project.id);
  const enabledProjectsIds = enabledProjects.map((project) => project.id);

  return projectIds.filter((x) => !enabledProjectsIds.includes(x));
}
