import type { ConnectedProjectDto } from "api/types";
import { useSuperTokensUserId } from "authentication/AuthenticationStateProvider";
import { isDefined } from "helpers/util";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { getLocalStorageValue, updateLocalStorage } from "hooks/useLocalStorage";
import { uniq } from "lodash-es";
import { useConfig } from "providers/ConfigProvider";
import { useMemo } from "react";

export function useRecentProjects(
  projectId: string,
  showCurrentProject: boolean,
  recentProjectCount: 3,
): {
  recentProjects: ConnectedProjectDto[];
  isLoading: boolean;
  projects: ConnectedProjectDto[];
  isSearchAvailable: boolean;
} {
  const { data: projects = [], isLoading } = useConnectedProjects();
  const envLongName = useConfig("envLongName");
  const userId = useSuperTokensUserId();

  const recentProjectsKey = envLongName && userId ? `${envLongName}.${userId}.recent-projects` : undefined;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const localStorageProjectList =
    isLoading || !recentProjectsKey ? [] : getLocalStorageValue<string[]>(recentProjectsKey, []);

  const recentProjectIds = useMemo(() => {
    if (!recentProjectsKey) {
      return [];
    }

    if (!localStorageProjectList?.length) {
      const initialState = [projectId];
      updateLocalStorage(recentProjectsKey, initialState);

      return initialState;
    } else if (localStorageProjectList[0] !== projectId) {
      const cleanedLocalStorageProjectList = localStorageProjectList.filter((x) => projects.some((p) => p.id === x));
      const projectIds = uniq([projectId, ...cleanedLocalStorageProjectList]);
      updateLocalStorage(recentProjectsKey, projectIds);

      return projectIds;
    } else {
      return localStorageProjectList;
    }
  }, [recentProjectsKey, projectId, localStorageProjectList, projects]);

  const recentProjects = useMemo(() => {
    const topRecentProjects = recentProjectIds
      .filter((x) => showCurrentProject || x !== projectId)
      .slice(0, recentProjectCount);

    return topRecentProjects.map((recentProjectId) => projects.find((p) => p.id === recentProjectId)).filter(isDefined);
  }, [projectId, projects, recentProjectIds, showCurrentProject, recentProjectCount]);

  return useMemo(
    () => ({ recentProjects, isLoading, projects, isSearchAvailable: projects.length > 3 }),
    [recentProjects, isLoading, projects],
  );
}
