import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { TicketCategoryRequest, UserRoleDto, UserRoleRepairCategoryPermissionsDto } from "api/types";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { toTranslationsRequest } from "helpers/languages";
import { toPermissionsRequest } from "helpers/permissions";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

import type { TicketCategoryDetailsFields } from "./Layout";

export function mapPermissionsWithRoles(
  roles: UserRoleDto[],
  permissions: UserRoleRepairCategoryPermissionsDto[],
): (UserRoleRepairCategoryPermissionsDto & { name: string })[] {
  return permissions.map((data) => {
    const role = roles.find(({ id }) => id === data.roleId);

    if (role == null) {
      console.error("User roles or default groups permissions are invalid");
    }

    return { ...data, name: role?.name || "[ROLE_NAME]" };
  });
}

export function useSubmitHandlers(): {
  createTicketCategory: (body: TicketCategoryRequest) => void;
  editTicketCategory: (data: { id: string; body: TicketCategoryRequest }) => void;
  isSubmitting: boolean;
} {
  const projectId = useProjectId();
  const slug = useSlug();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const {
    mutate: createTicketCategory,
    isSuccess: isTicketCategoryCreated,
    isPending: isSubmittingCreate,
  } = useMutation({
    mutationFn: api.postTicketCategoriesV1,
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("page.repair-category.create-or-edit.create-mode.success-msg"),
      });

      navigate(routes.ticketCategories.list({ slug }));

      return queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKET_CATEGORIES(projectId) });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("page.repair-category.create-or-edit.error-msg"),
      });
    },
  });
  const {
    mutate: editTicketCategory,
    isSuccess: isTicketCategoryEdited,
    isPending: isSubmittingEdit,
  } = useMutation({
    mutationFn: ({ id, body }: { id: string; body: TicketCategoryRequest }) => api.putTicketCategoriesV1(id, body),
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("page.repair-category.create-or-edit.edit-mode.success-msg"),
      });

      navigate(routes.ticketCategories.list({ slug }));

      return queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKET_CATEGORIES(projectId) });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("page.repair-category.create-or-edit.error-msg"),
      });
    },
  });

  useEffect(() => {
    if (isTicketCategoryCreated || isTicketCategoryEdited) {
      navigate(routes.ticketCategories.list({ slug }));
    }
  }, [isTicketCategoryCreated, isTicketCategoryEdited, navigate, slug]);

  return {
    createTicketCategory,
    editTicketCategory,
    isSubmitting: isSubmittingCreate || isSubmittingEdit,
  };
}

export function transformFormDataToAPI(formData: TicketCategoryDetailsFields): TicketCategoryRequest {
  return {
    audience: formData.audience,
    translations: toTranslationsRequest(formData.translations),
    defaultAssigneeId: formData.defaultAssignee?.id,
    disabled: formData.disabled == "true",
    notificationType: formData.notificationType,
    closeAfterInactiveDayAmount:
      formData.closeAfterInactiveDayAmount == "0" ? undefined : Number(formData.closeAfterInactiveDayAmount),
    permissions: toPermissionsRequest(formData.permissions),
  };
}
