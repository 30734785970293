import { forwardRef } from "react";
import { twJoin } from "tailwind-merge";

export interface SwitchProps {
  size?: "md" | "lg";
  innerLabel?: string;
  icon?: React.ReactNode;
  isChecked: boolean;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  "data-testid"?: string;
  labels?: {
    on: string;
    off: string;
  };
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(function Switch(
  { size, innerLabel, icon, isChecked, disabled, onChange, "data-testid": dataTestId, labels },
  ref,
): React.ReactNode {
  const thumbColor = size === "lg" ? "green" : "white";

  return (
    <div className="inline-flex items-center gap-2">
      {labels && <span className={twJoin("text-caption", !isChecked && "font-old-bold")}>{labels.off}</span>}
      <label
        data-testid={dataTestId}
        className={twJoin("relative flex items-center", disabled ? "cursor-not-allowed" : "cursor-pointer")}
      >
        <input
          ref={ref}
          type="checkbox"
          className="peer sr-only"
          disabled={disabled}
          onChange={onChange}
          checked={isChecked}
        />
        <div
          className={twJoin(
            "rounded-full bg-grey-600 peer-checked:bg-green-500 peer-disabled:bg-grey-300 peer-disabled:peer-checked:bg-green-200",
            "ring-grey-600 ring-offset-2 peer-focus-visible:ring-1",
            size === "lg" ? "h-10 w-40" : "h-8 w-[60px]",
          )}
        />
        <div
          className={twJoin(
            "absolute flex items-center justify-center rounded-full bg-grey-100 text-grey-500 transition-all peer-checked:text-white",
            size === "lg"
              ? "left-[5px] top-1 size-8 peer-checked:translate-x-[118px]"
              : "left-[3px] top-1 size-6 peer-checked:translate-x-[30px]",
            thumbColor === "white"
              ? "peer-checked:bg-white"
              : "peer-checked:bg-green-700 peer-disabled:peer-checked:bg-green-700/40",
          )}
        >
          {icon}
        </div>
        {innerLabel ? (
          <span className="absolute left-12 top-2.5 text-center text-caption-bold text-white transition-all peer-checked:left-2 peer-checked:text-white">
            {innerLabel}
          </span>
        ) : null}
      </label>
      {labels && <span className={twJoin("text-caption", isChecked && "font-old-bold")}>{labels.on}</span>}
    </div>
  );
});
