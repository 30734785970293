import type { DocumentDto } from "api/types";
import type { Dispatch, SetStateAction } from "react";
import { useCallback } from "react";

export type FormDocument = DocumentDto | InputFile;

interface InputFile {
  file: File;
}

interface Props {
  maximumFiles?: number;
  onChange: Dispatch<SetStateAction<FormDocument[]>>;
}

interface DocumentInput {
  readonly addDocuments: (files: FileList | null) => void;
  readonly removeDocument: (documentToRemove: FormDocument) => void;
  readonly removeDocuments: () => void;
}

export function useDocumentInput({ maximumFiles = 5, onChange }: Props): DocumentInput {
  const addDocuments = useCallback(
    (files: FileList | null) => {
      if (!files) {
        return;
      }

      const uploadedDocuments = Array.from(files)
        .slice(0, maximumFiles)
        .map((file) => ({ file }));

      onChange((oldDocuments) => [...oldDocuments, ...uploadedDocuments].slice(-maximumFiles));
    },
    [maximumFiles, onChange],
  );

  const removeDocument = useCallback(
    (documentToRemove: FormDocument) => {
      if (isDocumentUploaded(documentToRemove)) {
        onChange((oldDocuments) =>
          oldDocuments.filter((document) => !isDocumentUploaded(document) || documentToRemove.id !== document.id),
        );
      } else {
        onChange((oldDocuments) =>
          oldDocuments.filter((document) => isDocumentUploaded(document) || documentToRemove.file !== document.file),
        );
      }
    },
    [onChange],
  );

  const removeDocuments = useCallback(() => onChange([]), [onChange]);

  return {
    addDocuments,
    removeDocument,
    removeDocuments,
  } as const;
}

export function isDocumentUploaded(document?: FormDocument): document is DocumentDto {
  return document ? "id" in document : false;
}
