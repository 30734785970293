import type { InfiniteData } from "@tanstack/react-query";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ProjectHeaderName } from "api/ApiClientProvider";
import { useApi } from "api/hooks/useApi";
import type { PortfolioAdminTicketDtoPaginationResultDto, PortfolioMinimalTicketDto } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { useModalState } from "components/Modal/Modal";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import {
  getLocalStorageValue,
  getSessionStorageValue,
  useUpdateLocalStorage,
  useUpdateSessionStorage,
} from "hooks/useLocalStorage";
import { useQueryParam } from "hooks/useQueryParam";
import { isArray, isBoolean } from "lodash-es";
import type { TicketSorting, TicketTabs } from "modules/tickets/constants";
import {
  CLOSED_TICKET_TAB,
  IN_PROGRESS_TICKET_TAB,
  NEW_TICKET_TAB,
  REMINDER_TICKET_TAB,
  ticketSortingValues,
  ticketVisibilityValues,
} from "modules/tickets/constants";
import { ticketStorageKeys } from "modules/tickets/filters";
import { useTicketQueries } from "queries/tickets";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import type { ApiQueryParams } from "types/api-types";

import type { LayoutProps } from "./Layout";

interface Props {
  children: (props: LayoutProps) => React.ReactNode;
}

const TABS = [NEW_TICKET_TAB, IN_PROGRESS_TICKET_TAB, CLOSED_TICKET_TAB, REMINDER_TICKET_TAB];

type TicketsSortingParams = { [key in TicketTabs]: TicketSorting };
type AllTicketsQueryParams = ApiQueryParams<"getAdminPortfolioTicketsV1">;
export type TicketsFilters = Omit<AllTicketsQueryParams, "Limit" | "Offset" | "SortBy" | "ResidentFocusMode">;
export type OnUpdateParams = <T extends keyof TicketsFilters | "Tab" | "ResidentFocusMode">(
  key: T,
  value: T extends keyof AllTicketsQueryParams ? AllTicketsQueryParams[T] : string,
) => void;

export function Loader({ children }: Props): React.ReactNode {
  const api = useApi();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();
  const searchInputRef = useRef<HTMLInputElement>(null);

  const TICKET_FILTERS_KEY = ticketStorageKeys.portfolio_filters();
  const TICKET_TAB_SORT_KEY = ticketStorageKeys.tabSort();
  const TICKET_TAB_RESIDENT_FOCUS_KEY = ticketStorageKeys.residentFocusMode();

  const [queryParamOpenTicket, setQueryParamOpenTicket] = useQueryParam("openTicket");

  const parsedQueryParamOpenTicket = useMemo(() => {
    if (!queryParamOpenTicket) {
      return;
    }
    try {
      // Encoded string so we can just just one query param and no JSON parsing
      const decodedString = atob(queryParamOpenTicket);

      const [projectId, ticketId] = decodedString.split("|");
      if (!projectId || !ticketId) {
        return;
      }

      return { projectId, ticketId };
    } catch (error) {
      return;
    }
  }, [queryParamOpenTicket]);
  useEffect(() => {
    // Clear the query param immediately on page load
    setQueryParamOpenTicket(null);
  }, [setQueryParamOpenTicket]);

  const [queryParamTab, setTab] = useQueryParam("tab");
  const defaultTab = queryParamTab && TABS.includes(queryParamTab as any) ? (queryParamTab as TicketTabs) : undefined;

  const [storedResidentFocus, setResidentFocus] = useState<boolean>(() =>
    getSessionStorageValue<boolean>(TICKET_TAB_RESIDENT_FOCUS_KEY, false),
  );
  useUpdateSessionStorage(TICKET_TAB_RESIDENT_FOCUS_KEY, storedResidentFocus);

  const [sorting, setSorting] = useState<TicketsSortingParams>(() => {
    const storage = getLocalStorageValue<Partial<TicketsSortingParams>>(TICKET_TAB_SORT_KEY, {});

    return {
      new: storage.new && ticketSortingValues.includes(storage.new) ? storage.new : "oldest",
      inProgress:
        storage.inProgress && ticketSortingValues.includes(storage.inProgress)
          ? storage.inProgress
          : "mostRecentActivity",
      closed: storage.closed && ticketSortingValues.includes(storage.closed) ? storage.closed : "mostRecentActivity",
      reminder: "mostRecentReminder",
    };
  });
  useUpdateLocalStorage(TICKET_TAB_SORT_KEY, sorting);

  const [filters, updateFilters] = useState<TicketsFilters>(() => {
    const storage = getSessionStorageValue<Partial<TicketsFilters>>(TICKET_FILTERS_KEY, {});

    return {
      Ratings: isArray(storage.Ratings) ? storage.Ratings : [],
      Filter: storage.Filter && ticketVisibilityValues.includes(storage.Filter) ? storage.Filter : undefined,
      AssignedToEmails: isArray(storage.AssignedToEmails) ? storage.AssignedToEmails : undefined,
      UnreadOnly: isBoolean(storage.UnreadOnly) ? storage.UnreadOnly : false,
      Search: typeof storage.Search === "string" ? storage.Search : "",
      Tab: storage.Tab && TABS.includes(storage.Tab) ? storage.Tab : undefined,
      ProjectIds: isArray(storage.ProjectIds) ? storage.ProjectIds : undefined,
    };
  });
  useUpdateSessionStorage(TICKET_FILTERS_KEY, filters);

  const tab = filters.Tab || "new";

  const residentFocusMode = tab === "inProgress" && storedResidentFocus;
  const sortBy = (residentFocusMode ? "leastRecentActivity" : sorting[tab] || "newest") satisfies TicketSorting;

  const clearFilters = useCallback(() => {
    updateFilters({
      Ratings: [],
      Filter: undefined,
      UnreadOnly: false,
      Search: "",
      AssignedToEmails: [],
    });

    // Uncontrolled input
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
  }, []);

  const onUpdateSorting = useCallback(
    (value: TicketSorting) => {
      setSorting((oldSorting) => ({
        ...oldSorting,
        [tab]: value,
      }));
    },
    [tab],
  );

  const onUpdateParams = useCallback<OnUpdateParams>((key, value) => {
    if (key === "ResidentFocusMode") {
      setResidentFocus(!!value);
    } else {
      updateFilters((ticketFeedQuery) => ({
        ...ticketFeedQuery,
        [key]: value,
      }));
    }
  }, []);

  const filterCount = useMemo(() => {
    const { Ratings, Filter, UnreadOnly, ProjectIds, AssignedToEmails } = filters;

    return (
      (Ratings?.length ? 1 : 0) +
      (Filter ? 1 : 0) +
      (UnreadOnly ? 1 : 0) +
      (ProjectIds?.length ? 1 : 0) +
      (AssignedToEmails?.length ? 1 : 0)
    );
  }, [filters]);

  const query = {
    ...filters,
    SortBy: sortBy,
    Tab: tab,
    ResidentFocusMode: residentFocusMode,
  } satisfies AllTicketsQueryParams;
  const ticketQueries = useTicketQueries();
  const {
    data: ticketData,
    hasNextPage: hasMoreTickets,
    isLoading: isLoadingTickets,
    isFetchingNextPage: isLoadingMoreTickets,
    fetchNextPage: loadMoreTickets,
    error: ticketsError,
  } = useInfiniteQuery(ticketQueries.listPerPortfolio(query));

  const { data: tabsStats, error: tabsStatsError } = useQuery({
    // exclude tab and sortby to prevent refetching because they do not affect the amount of tickets
    queryKey: QUERY_KEYS.PORTFOLIO_TICKETS_TABS_AMOUNT({
      ...query,
      Tab: undefined,
      SortBy: undefined,
      ResidentFocusMode: false,
      AssignedToEmails: undefined,
    }),
    queryFn: () =>
      api.getAdminPortfolioTicketsStatsAllTabsV1({
        ...query,
        Tab: undefined,
        SortBy: undefined,
        ResidentFocusMode: false,
        AssignedToEmails: undefined,
      }),
    select: commonAPIDataSelector,
  });

  const { data: totalTicketsInTab, error: totalTicketsInTabError } = useQuery({
    queryKey: QUERY_KEYS.PORTFOLIO_TICKETS_TAB_TOTAL(tab),
    queryFn: () => api.getAdminPortfolioTicketsStatsDetailsV1(tab),
    select: commonAPIDataSelector,
    enabled: filterCount > 0 || !!filters.Search?.trim() || residentFocusMode,
  });

  const { data: allTicketIds } = useQuery({
    queryKey: QUERY_KEYS.PORTFOLIO_TICKETS_IDS(query),
    queryFn: () => api.getAdminPortfolioTicketsIdsV1(query),
    select: commonAPIDataSelector,
    enabled: tab === "new",
  });

  const deleteTicket = useMutation({
    mutationFn: ({ ticketId, projectId }: { ticketId: string; projectId: string }) =>
      api.deleteAdminTicketsByIdV1(ticketId, {
        headers: {
          [ProjectHeaderName]: projectId,
        },
      }),
    onSuccess: () => {
      showFlashToast({ type: "success", title: t("page.tickets.delete.success") });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.tickets.delete.error") });
    },
  });

  const markTicketAsRead = useMutation({
    mutationFn: () => api.postAdminPortfolioTicketsReadV1({ ...query }),
    onSuccess: () => {
      showFlashToast({ type: "success", title: t("page.tickets.mark-all-as-read.success") });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.tickets.mark-all-as-read.error") });
    },
  });

  const totalTickets = ticketData?.pages[0].total;
  const tickets = useMemo(() => ticketData?.pages.flatMap((x) => x.items) ?? [], [ticketData]);

  useEffect(() => {
    if (defaultTab) {
      onUpdateParams("Tab", defaultTab);
      setTab(null);
    }
  }, [defaultTab, onUpdateParams, setTab]);

  if (tabsStats && tabsStats.reminderTickets === 0 && tab === "reminder") {
    onUpdateParams("Tab", "new");
  }

  const openTicketModal = useModalState<PortfolioMinimalTicketDto>(
    parsedQueryParamOpenTicket
      ? {
          isOpen: true,
          data: parsedQueryParamOpenTicket,
        }
      : undefined,
  );

  const [session, setSession] = useState(() => {
    const result = getSessionStorageValue<
      | {
          items: PortfolioMinimalTicketDto[];
          missed: PortfolioMinimalTicketDto[];
          created: number;
        }
      | undefined
    >("ticket-session", undefined);

    if (result) {
      // Clear session if it's older than 24 hours
      const oneDay = 1000 * 60 * 60 * 24;
      if (result.created + oneDay < Date.now()) {
        return undefined;
      }
    }

    return result;
  });
  const sessionFinishedModal = useModalState<{ missedItems: number }>();
  useUpdateSessionStorage("ticket-session", session);

  if (openTicketModal.isOpen) {
    if (!session && openTicketModal.data && allTicketIds) {
      const openTicketIndex = allTicketIds.findIndex((x) => x.ticketId === openTicketModal.data?.ticketId);

      setSession({
        items: allTicketIds.slice(openTicketIndex),
        missed: allTicketIds.slice(0, openTicketIndex),
        created: Date.now(),
      });
    }
  } else if (session) {
    setSession(undefined);
  }

  function goToNextTicket() {
    if (!session) {
      return;
    }

    if (!openTicketModal.data) {
      return;
    }

    const currentTicketIndex = session.items.findIndex((x) => x.ticketId === openTicketModal.data?.ticketId);
    const nextTicket = session.items[currentTicketIndex + 1];
    if (nextTicket) {
      openTicketModal.open(nextTicket);
    } else {
      openTicketModal.requestClose();
      setSession(undefined);
      sessionFinishedModal.open({ missedItems: session.missed.length });
    }
  }

  // Mark ticket as read when it's opened without network request
  useEffect(() => {
    if (openTicketModal.data?.ticketId) {
      queryClient.setQueryData(
        QUERY_KEYS.PORTFOLIO_TICKETS_LIST(query),
        (oldInfiniteData: InfiniteData<PortfolioAdminTicketDtoPaginationResultDto> | undefined) =>
          oldInfiniteData === undefined
            ? undefined!
            : {
                ...oldInfiniteData,
                pages: [
                  ...oldInfiniteData.pages.map((page) => ({
                    ...page,
                    items: page.items.map((ticket) =>
                      ticket.id === openTicketModal.data?.ticketId ? { ...ticket, hasUnreadActivity: false } : ticket,
                    ),
                  })),
                ],
              },
      );
    }
  }, [openTicketModal?.data?.ticketId, query, queryClient]);

  if (ticketsError || totalTicketsInTabError || tabsStatsError) {
    return <ErrorPage error={ticketsError} />;
  }

  return (
    <>
      {children({
        tab,
        residentFocusMode,
        sorting: sortBy,
        onUpdateSorting,
        queryParams: filters,
        onUpdateParams,
        totalTickets,
        totalTicketsInTab:
          filterCount > 0 || residentFocusMode || filters.Search?.trim() ? totalTicketsInTab?.tickets : totalTickets,
        tabsStats,
        tickets,
        isLoadingTickets,
        hasMoreTickets,
        loadMoreTickets,
        isLoadingMoreTickets,
        clearFilters,
        filterCount,
        deleteTicket: deleteTicket.mutateAsync,
        markAsRead: markTicketAsRead.mutateAsync,
        searchInputRef,
        showNextTicketBtn: !!session && tab === "new",
        goToNextTicket,
        openTicketModal,
        sessionFinishedModal,
        leftInSession: session
          ? session.items.length - session.items.findIndex((x) => x.ticketId === openTicketModal.data?.ticketId)
          : undefined,
      })}
    </>
  );
}
