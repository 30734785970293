import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { AIAnalysisModalState } from "components/AIAnalysisModal/AIAnalysisModal";
import { AIAnalysisModal } from "components/AIAnalysisModal/AIAnalysisModal";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useTranslation } from "react-i18next";

type MessagesSentimentModal = {
  startDate: string;
  endDate: string;
  isOpen: boolean;
  onOpenChange: (state: boolean) => void;
};

export function MessagesSentimentModal({
  startDate,
  endDate,
  isOpen,
  onOpenChange,
}: MessagesSentimentModal): React.ReactNode {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const api = useApi();
  const { data, isPending, isError } = useQuery({
    queryKey: QUERY_KEYS.PROJECT_SUMMARY(projectId),
    queryFn: () => api.getProjectSummaryV1({ from: startDate, to: endDate }),
    select: commonAPIDataSelector,
    enabled: isOpen,
    staleTime: 1000 * 60 * 3, // 30 minutes
  });

  let state: AIAnalysisModalState = "loading";
  if (!isPending && !isError && data) {
    state = "success";
  } else if (!isPending && isError) {
    state = "error";
  } else if (!isPending && !isError && !data) {
    state = "unavailable";
  }

  return (
    <AIAnalysisModal
      data={{
        sentiment: data?.sentiment || 0,
        summary: data?.communitySummary || "",
        hashtags: data?.hashtags,
      }}
      config={{
        success: {
          title: t("page.message-feed.sentiment.modal.title"),
        },
        unavailable: {
          title: t("page.message-feed.sentiment.no-data.notification"),
        },
        error: {
          title: t("page.message-feed.sentiment.failed.notification"),
        },
      }}
      {...{ state, isOpen, onOpenChange }}
    />
  );
}
