import type { AddressDto } from "api/types";
import iconDownload01 from "assets/icons/download-01.svg";
import { Button } from "components/Button/Button";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import { formatAddress } from "helpers/address";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { convertToCsv, downloadCsvAsExcel } from "modules/admin/helpers/sheet";
import type React from "react";
import { useCallback, useMemo } from "react";
import { Async } from "react-async";
import { useTranslation } from "react-i18next";

type AddressesExportModalProps = ModalBaseProps & {
  addresses: AddressDto[];
};

export function AddressesExportModal({
  addresses,
  isOpened,
  onOpenChange,
}: AddressesExportModalProps): React.ReactNode {
  const { t } = useTranslation();
  const sessionUser = useSessionUser();

  const mappedAddresses = useMemo(
    () =>
      addresses.map((x) => ({
        ...x,
        address: formatAddress(x),
        registeredUsers: x.users.filter((u) => u.registeredAt),
        notRegisteredUsers: x.users.filter((u) => !u.registeredAt),
        searchableString: `${formatAddress(x)}|||${x.postalCode}|||${x.building.name})|||${x.floor}|||${
          x.typeConstraint?.key ?? ""
        }|||${x.city}|||${x.locationCode}|||${x.users.map((x) => x.fullName).join("|||")}|||${x.users
          .map((x) => x.email)
          .join("|||")}`.toLowerCase(),
      })),
    [addresses],
  );

  const csv = useCallback(
    async () => ({
      activated: await convertToCsv(
        mappedAddresses.flatMap((a) =>
          a.registeredUsers.map((u) => ({
            building: a.building.name,
            address: formatAddress(a),
            email: u.email,
            fullName: u.fullName,
            locationCode: a.locationCode,
          })),
        ),
        { skipHeader: true },
      ),
      notActivated: await convertToCsv(
        mappedAddresses.flatMap((a) =>
          a.notRegisteredUsers.map((u) => ({
            building: a.building.name,
            address: formatAddress(a),
            email: u.email,
            fullName: u.fullName,
            locationCode: a.locationCode,
          })),
        ),
        { skipHeader: true },
      ),
    }),
    [mappedAddresses],
  );

  return (
    <Modal.Root isScrollable {...{ isOpened, onOpenChange }}>
      <Async promiseFn={csv} onReject={console.error}>
        <Async.Resolved<{ activated: string; notActivated: string }>>
          {(x) => (
            <div className="flex flex-col gap-10">
              <div className="flex flex-col gap-2">
                <span className="text-body-bold">{t("page.addresses.list.onboarded-users")}</span>
                <pre className="min-h-32 overflow-auto rounded-lg bg-grey-100 px-2 py-1">
                  <code>{x.activated}</code>
                </pre>
                <Button
                  className="self-end"
                  onClick={() => downloadCsvAsExcel(`activated-users-${sessionUser.project.name}`, x.activated)}
                  icon={<Icon name={iconDownload01} size={16} />}
                >
                  {t("page.addresses.list.export-as-xlsx")}
                </Button>
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-body-bold">{t("page.addresses.list.invited-users")}</span>

                <pre className="min-h-32 overflow-auto rounded-lg bg-grey-100 px-2 py-1">
                  <code>{x.notActivated}</code>
                </pre>
                <Button
                  className="self-end"
                  onClick={() => downloadCsvAsExcel(`non-activated-users-${sessionUser.project.name}`, x.notActivated)}
                  icon={<Icon name={iconDownload01} size={16} />}
                >
                  {t("page.addresses.list.export-as-xlsx")}
                </Button>
              </div>
            </div>
          )}
        </Async.Resolved>
        <Async.Pending>
          <div className="w-52 max-w-sm py-12">
            <FullSizeLoader />
          </div>
        </Async.Pending>
        <Async.Rejected>
          <p className="p-8">Something went wrong...</p>
        </Async.Rejected>
      </Async>
    </Modal.Root>
  );
}
