import { useCallback, useEffect } from "react";

export function useKey(key: KeyboardEvent["key"], callback: () => void, shouldListen = true): void {
  const keydown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === key) {
        event.preventDefault();
        event.stopPropagation();
        callback();
      }
    },
    [key, callback],
  );

  useEffect(() => {
    if (shouldListen) {
      document.addEventListener("keydown", keydown);
    } else {
      document.removeEventListener("keydown", keydown);
    }

    return () => {
      document.removeEventListener("keydown", keydown);
    };
  }, [keydown, shouldListen]);
}
