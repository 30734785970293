import type { EventDetailsDto } from "api/types";
import { List } from "components/List/List";
import { User } from "components/User/User";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import type React from "react";
import { useTranslation } from "react-i18next";

interface EventDetailsParticipantsSectionProps {
  event: EventDetailsDto;
}

export function EventDetailsParticipantsSection({ event }: EventDetailsParticipantsSectionProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className="h-full rounded-lg bg-white p-4" data-testid="event-participants">
      <h2 className="block text-headline3">{t("page.event.details.participants.title")}</h2>
      <p data-testid="event-participants-number" className="block text-caption text-grey-700">
        {t("page.event.details.participants.details", { count: event.participantCount })}
      </p>
      <div className="mt-4" data-testid="event-participants-list">
        <List getKey={(item) => item.id} list={event.participants}>
          {(user) => (
            <UserNameLink key={user.id} user={user}>
              <User avatar={user.avatar} deletedAt={user.deletedAt} title={user.fullName} subtitle={user.locatedAt} />
            </UserNameLink>
          )}
        </List>
      </div>
    </div>
  );
}
