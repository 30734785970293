import { countBy } from "lodash-es";

import type { Column, Data } from "./parser";

export interface Validation {
  valid: boolean;
  getInvalidError: (row: number, columnName: string) => string | undefined;
  getMissingError: (row: number, columnName: string) => string | undefined;
  getWarnings: (row: number, columnName: string) => string | undefined;
}

type DataLocation = `${number}-${string}`;

export function validate<TColumns extends readonly Column<any>[]>(columns: TColumns, data: Data<TColumns>): Validation {
  const missingColumns = new Map<DataLocation, string>();
  const invalidColumns = new Map<DataLocation, string>();
  const warningColumns = new Map<DataLocation, string>();

  const uniques: { [columnName: string]: { [value: string]: number } } = {};

  for (let y = 0; y < data.length; y++) {
    const row = data[y];
    for (const { rules, warnings, name } of columns) {
      const columnName = name as keyof typeof row;
      const value = row[columnName];

      if (rules) {
        if ("required" in rules && !value) {
          missingColumns.set(`${y}-${columnName}`, rules.required);
          continue;
        }

        if ("match" in rules && value) {
          if (!rules.match.regex.test(value)) {
            invalidColumns.set(`${y}-${columnName}`, rules.match.error);
            continue;
          }
        }

        if ("oneOf" in rules && value) {
          if (!isOneOf(value, rules.oneOf.values, rules.oneOf.ignoreCase)) {
            invalidColumns.set(`${y}-${columnName}`, rules.oneOf.error);
            continue;
          }
        }

        if ("notOneOf" in rules && value) {
          if (isOneOf(value, rules.notOneOf.values, rules.notOneOf.ignoreCase)) {
            invalidColumns.set(`${y}-${columnName}`, rules.notOneOf.error);
            continue;
          }
        }

        if ("unique" in rules) {
          if (!uniques[columnName]) {
            uniques[columnName] = countBy(data, (x) =>
              rules.unique.ignoreCase ? x[columnName]?.toLowerCase() : x[columnName],
            );
            continue;
          }

          const uniqueValue = rules.unique.ignoreCase ? value?.toLowerCase() : value;
          if (uniqueValue && uniques[columnName][uniqueValue] > 1 && "unique" in rules) {
            invalidColumns.set(`${y}-${columnName}`, rules.unique.error);
            continue;
          }
        }
      }

      if (warnings) {
        if ("unique" in warnings) {
          if (!uniques[columnName]) {
            uniques[columnName] = countBy(data, (x) => x[columnName]);
          }

          if (value && uniques[columnName][value] > 1 && "unique" in warnings) {
            warningColumns.set(`${y}-${columnName}`, warnings.unique);
          }
        }
      }
    }
  }

  function getInvalidError(row: number, columnName: string) {
    return invalidColumns.get(`${row}-${columnName}`);
  }

  function getMissingError(row: number, columnName: string) {
    return missingColumns.get(`${row}-${columnName}`);
  }

  function getWarnings(row: number, columnName: string) {
    return warningColumns.get(`${row}-${columnName}`);
  }

  return {
    valid: missingColumns.size === 0 && invalidColumns.size === 0,
    getInvalidError,
    getMissingError,
    getWarnings,
  };
}

function isOneOf(value: string, values: readonly string[], ignoreCase = false) {
  value = ignoreCase ? value.toLowerCase() : value;

  return values.some((x) => {
    x = ignoreCase ? x.toLowerCase() : x;

    return x === value;
  });
}
