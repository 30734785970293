import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { createRequiredStringRule } from "helpers/rules";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface HomeDnaFormValues {
  enabled: boolean;
  clientTenant: string;
  clientSecret: string;
  buyerGln: string;
  buyerName: string;
  contractorGln: string;
  contractorName: string;
}

export function HomeDnaForm({
  defaultValues,
  isSubmitting,
  onSubmit,
}: {
  defaultValues: Partial<HomeDnaFormValues>;
  isSubmitting: boolean;
  onSubmit: (data: HomeDnaFormValues) => void;
}): React.ReactNode {
  const { t } = useTranslation();
  const formMethods = useForm<HomeDnaFormValues>({ defaultValues });
  const enabled = useWatch({ control: formMethods.control, name: "enabled" });

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <FormContent maxWidth="3xl">
        <FormField label={""} htmlFor="enabled">
          <FormCheckbox<HomeDnaFormValues, "enabled">
            id="enabled"
            name="enabled"
            label={t("page.integration.homedna.form.enabled")}
            onChange={(e) => {
              if (enabled && !e.target.checked) {
                void formMethods.trigger();
              }

              formMethods.setValue("enabled", e.target.checked);
            }}
          />
        </FormField>

        <FormField label={t("page.integration.homedna.form.client-tenant")} required htmlFor="clientTenant">
          <FormInput<HomeDnaFormValues, "clientTenant">
            id="clientTenant"
            name="clientTenant"
            rules={{
              validate: {
                required: enabled
                  ? createRequiredStringRule(t, "page.integration.homedna.form.client-tenant")
                  : () => undefined,
              },
            }}
            placeholder=""
            className="max-w-[38ch]"
            disabled={!enabled}
          />
        </FormField>

        <FormField label={t("page.integration.homedna.form.client-secret")} required htmlFor="clientSecret">
          <FormInput<HomeDnaFormValues, "clientSecret">
            id="clientSecret"
            name="clientSecret"
            type="password"
            rules={{
              validate: {
                required: enabled
                  ? createRequiredStringRule(t, "page.integration.homedna.form.client-secret")
                  : () => undefined,
              },
            }}
            placeholder=""
            className="max-w-[38ch]"
            disabled={!enabled}
          />
        </FormField>

        <FormField label={t("page.integration.homedna.form.buyer-gln")} required htmlFor="buyerGln">
          <FormInput<HomeDnaFormValues, "buyerGln">
            id="buyerGln"
            name="buyerGln"
            rules={{
              validate: {
                required: enabled
                  ? createRequiredStringRule(t, "page.integration.homedna.form.buyer-gln")
                  : () => undefined,
                mustOnlyContainNumbers: (value) => {
                  if (!enabled || !value) {
                    return undefined;
                  }

                  return /^\d+$/.test(value) ? undefined : t("components.form.error.must-only-contain-numbers");
                },
                mustBeExactly13Digits: (value) => {
                  if (!enabled || !value) {
                    return undefined;
                  }

                  return value.length === 13
                    ? undefined
                    : t("components.form.error.must-be-exactly-digits", {
                        digits: 13,
                      });
                },
              },
            }}
            placeholder="0000000000000"
            className="max-w-[16ch]"
            disabled={!enabled}
          />
        </FormField>

        <FormField label={t("page.integration.homedna.form.buyer-name")} required htmlFor="buyerName">
          <FormInput<HomeDnaFormValues, "buyerName">
            id="buyerName"
            name="buyerName"
            rules={{
              validate: {
                required: enabled
                  ? createRequiredStringRule(t, "page.integration.homedna.form.buyer-name")
                  : () => undefined,
              },
              maxLength: {
                message: t("components.form.error.max-length", {
                  length: 100,
                }),
                value: 100,
              },
            }}
            placeholder=""
            className="max-w-[38ch]"
            disabled={!enabled}
          />
        </FormField>

        <FormField label={t("page.integration.homedna.form.contractor-gln")} required htmlFor="contractorGln">
          <FormInput<HomeDnaFormValues, "contractorGln">
            id="contractorGln"
            name="contractorGln"
            rules={{
              validate: {
                required: enabled
                  ? createRequiredStringRule(t, "page.integration.homedna.form.contractor-gln")
                  : () => undefined,
                mustOnlyContainNumbers: (value) => {
                  if (!enabled || !value) {
                    return undefined;
                  }

                  return /^\d+$/.test(value) ? undefined : t("components.form.error.must-only-contain-numbers");
                },
                mustBeExactly13Digits: (value) => {
                  if (!enabled || !value) {
                    return undefined;
                  }

                  return value.length === 13
                    ? undefined
                    : t("components.form.error.must-be-exactly-digits", {
                        digits: 13,
                      });
                },
              },
            }}
            placeholder="0000000000000"
            className="max-w-[16ch]"
            disabled={!enabled}
          />
        </FormField>

        <FormField label={t("page.integration.homedna.form.contractor-name")} required htmlFor="contractorName">
          <FormInput<HomeDnaFormValues, "contractorName">
            id="contractorName"
            name="contractorName"
            rules={{
              validate: {
                required: enabled
                  ? createRequiredStringRule(t, "page.integration.homedna.form.contractor-name")
                  : () => undefined,
              },
              maxLength: {
                message: t("components.form.error.max-length", {
                  length: 100,
                }),
                value: 100,
              },
            }}
            placeholder=""
            className="max-w-[38ch]"
            disabled={!enabled}
          />
        </FormField>

        <Button type="submit" isLoading={isSubmitting}>
          {t("page.integration.homedna.submit")}
        </Button>
      </FormContent>
    </Form>
  );
}
