import type { AssetBookingDto, BookableAssetDetailDto } from "api/types";
import iconChevronLeft from "assets/icons/chevron-left.svg";
import iconChevronRight from "assets/icons/chevron-right.svg";
import iconClock from "assets/icons/clock.svg";
import { IconButton } from "components/Button/IconButton";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { addDays, addMonths, differenceInDays, isSameDay, isSameYear, parse, startOfDay } from "date-fns";
import { dayOfWeekIndex } from "helpers/date";
import { ToggleCalendarButton } from "modules/bookings/components/ToggleCalendarButton";
import { daysOptions } from "modules/bookings/constants";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import { getAllowedViewableDateRange } from "../helpers";
import type { LayoutProps } from "../pages/AssetDetail/Layout";
import { ReservationListModal } from "./ReservationListModal";

const MAX_AMOUNT_BOOKINGS_SHOWN = 3;

type Day = {
  date: Date;
  bookings: AssetBookingDto[];
  isCurrentMonth: boolean;
  isSelected: boolean;
  isToday: boolean;
  isDisabled?: boolean;
};

export function CalendarMonthView({
  assetDetails,
  startDate,
  endDate,
  bookings,
  onChangeMonth,
  onChangeView,
  onSelectBooking,
  onOpenCancelModal,
}: {
  assetDetails: BookableAssetDetailDto;
  startDate: LayoutProps["startDate"];
  endDate: LayoutProps["endDate"];
  bookings: LayoutProps["bookings"];
  onChangeMonth: (date: Date) => void;
  onChangeView: (date?: Date) => void;
  onSelectBooking: (booking: AssetBookingDto) => void;
  onOpenCancelModal: (booking: AssetBookingDto) => void;
}): React.ReactNode {
  const { t } = useTranslation();
  const [selectedDay, setSelectedDay] = useState<Day>();
  const mobileBookingListRef = useRef<HTMLDivElement>(null);
  const [isAllBookingsModalOpen, setIsAllBookingModalOpen] = useState<{
    open: boolean;
    date?: Date;
    bookings?: AssetBookingDto[];
  }>({
    open: false,
  });

  function isDisabledDay(day: Date) {
    if (assetDetails.availableFrom && day < startOfDay(new Date(assetDetails.availableFrom))) {
      return true;
    }

    if (assetDetails.publishAt && day < startOfDay(new Date(assetDetails.publishAt))) {
      return true;
    }

    if (assetDetails.maxDaysInAdvance != null && day > addDays(new Date(), assetDetails.maxDaysInAdvance)) {
      return true;
    }

    if (assetDetails.unpublishAt && day > new Date(assetDetails.unpublishAt)) {
      return true;
    }

    const bookableDay = assetDetails.bookableDays.find((x) => x.day === daysOptions[dayOfWeekIndex(day)]);
    if (bookableDay && !bookableDay.enabled) {
      return true;
    }

    return false;
  }

  const lastMonthDays = Array.from({ length: dayOfWeekIndex(startDate) }, (_, i) => {
    const date = addDays(startDate, -dayOfWeekIndex(startDate) + i);

    return {
      date,
      bookings: [],
      isCurrentMonth: false,
      isSelected: false,
      isToday: false,
    } satisfies Day;
  });

  const currentMonthDays = Array.from({ length: differenceInDays(endDate, startDate) + 1 }, (_, i) => {
    const date = addDays(startDate, i);

    return {
      date,
      bookings: bookings.filter((x) => isSameDay(parse(x.date, "yyyy-MM-dd", new Date()), date)),
      isCurrentMonth: true,
      isDisabled: isDisabledDay(date),
      isSelected: false,
      isToday: isSameDay(addDays(startDate, i), new Date()),
    } satisfies Day;
  });

  const nextMonthDays = Array.from({ length: 6 - dayOfWeekIndex(endDate) }, (_, i) => {
    const date = addDays(endDate, i + 1);

    return {
      date,
      bookings: [],
      isCurrentMonth: false,
      isSelected: false,
      isToday: false,
    } satisfies Day;
  });

  const days = [...lastMonthDays, ...currentMonthDays, ...nextMonthDays];

  const has6Rows = days.length > 35;

  function changeDates(goto: "next" | "previous") {
    if (goto === "next") {
      onChangeMonth(addMonths(startDate, 1));
    } else {
      onChangeMonth(addMonths(startDate, -1));
    }
  }

  function changeView() {
    onChangeView();
  }

  function selectDay(day: Day) {
    setSelectedDay(day);
    mobileBookingListRef?.current?.scrollIntoView({ behavior: "smooth" });
  }

  const { minViewDate, maxViewDate } = getAllowedViewableDateRange(assetDetails);

  return (
    <div className="flex h-full flex-col gap-4">
      <div className="my-2 flex w-full flex-col-reverse items-center justify-center gap-4 md:my-0 md:flex-row md:justify-between">
        <div className="flex items-center gap-2">
          <IconButton
            title={t("page.bookings.asset-detail.month-view.previous")}
            onClick={() => changeDates("previous")}
            disabled={startDate < minViewDate}
            size="sm"
          >
            <Icon name={iconChevronLeft} size={16} />
          </IconButton>
          <span className="min-w-[100px] text-center capitalize">
            <FormattedDate date={startDate} format="month" />
            {!isSameYear(startDate, new Date()) && <span> {startDate.getFullYear()}</span>}
          </span>
          <IconButton
            disabled={endDate >= maxViewDate}
            title={t("page.bookings.asset-detail.month-view.next")}
            onClick={() => changeDates("next")}
            size="sm"
          >
            <Icon name={iconChevronRight} size={16} />
          </IconButton>
        </div>
        <div>
          <ToggleCalendarButton isWeek={false} onToggleView={changeView} />
        </div>
      </div>
      <div className="lg:flex lg:h-full lg:flex-col">
        <div className="ring-1 ring-black/5 sm:ring-0 lg:flex lg:flex-auto lg:flex-col">
          <div className="grid grid-cols-7 gap-px border-b border-grey-300 bg-grey-100 text-center text-overline leading-old-6 text-grey-600 sm:text-caption lg:flex-none">
            {daysOptions.map((option) => {
              const translation = t(`common.date.weekday.${option}`);

              return (
                <div key={option} className="bg-white py-2.5 sm:py-4">
                  <span className="capitalize">{translation[0]}</span>
                  <span className="sr-only sm:not-sr-only">{translation.slice(1, 3)}</span>
                  <span className="sr-only 2xl:not-sr-only">{translation.slice(3)}</span>
                </div>
              );
            })}
          </div>
          <div className="flex bg-grey-100 text-overline leading-old-6 text-grey-600 lg:flex-auto">
            <div
              className={twJoin(
                "hidden w-full lg:grid lg:grid-cols-7 lg:gap-px",
                has6Rows ? "lg:grid-rows-6" : "lg:grid-rows-5",
              )}
            >
              {days.map((day) => (
                <div
                  key={day.date.toISOString()}
                  className={twJoin(
                    day.isCurrentMonth ? "bg-white" : "bg-white/20 text-grey-400",
                    "relative min-h-[120px] px-1 py-2",
                  )}
                >
                  <time
                    dateTime={day.date.toISOString()}
                    className={twJoin(
                      "flex size-6 items-center justify-center",
                      day.isToday ? "rounded-full bg-aop-basic-blue-500 font-old-semibold text-white" : undefined,
                    )}
                  >
                    {day.date.getDate()}
                  </time>
                  {day.bookings.length > 0 && (
                    <ol className="mt-2">
                      {day.bookings.slice(0, day.bookings.length === 3 ? 3 : 2).map((booking) => (
                        <li key={booking.id} className="truncate">
                          <button
                            type="button"
                            className="group flex items-center gap-3"
                            onClick={() => onSelectBooking(booking)}
                          >
                            <span className="hidden flex-none text-grey-500 group-hover:text-aop-basic-blue-600 xl:block">
                              {booking.startTime.slice(0, 5)}
                            </span>
                            <p className="flex-auto truncate font-old-semibold text-black group-hover:text-aop-basic-blue-600">
                              {booking.author?.fullName || t("page.bookings.asset-detail.author-fallback")}
                            </p>
                          </button>
                        </li>
                      ))}
                      {day.bookings.length > MAX_AMOUNT_BOOKINGS_SHOWN && (
                        <li>
                          <button
                            type="button"
                            className="text-grey-500 hover:text-grey-700"
                            onClick={() =>
                              setIsAllBookingModalOpen({
                                open: true,
                                date: day.date,
                                bookings: day.bookings,
                              })
                            }
                          >
                            {t("page.bookings.asset-detail.month-view.more", { count: day.bookings.length - 2 })}
                          </button>
                        </li>
                      )}
                    </ol>
                  )}
                </div>
              ))}
            </div>
            <div
              className={twJoin("grid w-full grid-cols-7 gap-px lg:hidden", has6Rows ? "grid-rows-6" : "grid-rows-5")}
            >
              {days.map((day) => (
                <button
                  key={day.date.toISOString()}
                  type="button"
                  className={twJoin(
                    day.isCurrentMonth ? "bg-white" : "bg-grey-100/50",
                    (day.isSelected || day.isToday) && "font-old-semibold",
                    day.isSelected && "text-white",
                    !day.isSelected && day.isToday && "text-aop-basic-blue-600",
                    !day.isSelected && day.isCurrentMonth && !day.isToday && "text-black",
                    !day.isSelected && !day.isCurrentMonth && !day.isToday && "text-grey-500",
                    "flex h-14 flex-col px-3 py-2 hover:bg-grey-100/50 focus:z-10",
                  )}
                  onClick={() => selectDay(day)}
                >
                  <span
                    className={twJoin(
                      day.isSelected && "flex size-6 items-center justify-center rounded-full",
                      day.isSelected && day.isToday && "bg-aop-basic-blue-600",
                      day.isSelected && !day.isToday && "bg-black",
                      "ml-auto",
                    )}
                  >
                    {day.date.getDate()}
                  </span>
                  <span className="sr-only">{day.bookings.length} events</span>
                  {day.bookings.length > 0 && (
                    <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                      {day.bookings.map((event) => (
                        <span key={event.id} className="mx-0.5 mb-1 size-1.5 rounded-full bg-grey-400" />
                      ))}
                    </span>
                  )}
                </button>
              ))}
            </div>
          </div>
        </div>
        {selectedDay && selectedDay.bookings.length > 0 && (
          <div className="px-4 py-10 sm:px-6 lg:hidden" ref={mobileBookingListRef}>
            <ol className="divide-y divide-grey-100/50 overflow-hidden rounded-lg bg-white text-caption ring-1 ring-black/5">
              {selectedDay.bookings.map((booking) => (
                <li key={booking.id} className="group flex p-4 pr-6">
                  <div className="flex-auto text-left">
                    <p className="flex items-center gap-2 font-old-semibold text-black">
                      {booking.author?.fullName || t("page.bookings.asset-detail.author-fallback")}
                    </p>
                    <span className="mt-2 flex items-center text-grey-600">
                      <Icon name={iconClock} size={20} className="mr-2 text-grey-400" aria-hidden="true" />
                      {booking.startTime.slice(0, 5)} - {booking.endTime.slice(0, 5)}
                    </span>
                  </div>
                </li>
              ))}
            </ol>
          </div>
        )}
      </div>
      <ReservationListModal
        isOpened={isAllBookingsModalOpen.open}
        onOpenChange={(state) => setIsAllBookingModalOpen((x) => ({ ...x, open: state }))}
        bookings={isAllBookingsModalOpen.bookings || []}
        date={isAllBookingsModalOpen.date}
        onOpenCancelModal={(booking) => {
          setIsAllBookingModalOpen((x) => ({ ...x, open: false }));
          onOpenCancelModal(booking);
        }}
      />
    </div>
  );
}
