import type { ApiQueryParams } from "types/api-types";

export type CalendarTab = "events" | "reservations";

export const EVENTS_TAB: CalendarTab = "events";
export const RESERVATIONS_TAB: CalendarTab = "reservations";

export const TABS: CalendarTab[] = [EVENTS_TAB, RESERVATIONS_TAB];

export type EventsFilters = Omit<ApiQueryParams<"getEventsV1">, "Offset" | "Limit">;
export type ReservationsFilters = Omit<ApiQueryParams<"getBookableAssetsMyBookingsV1">, "Offset" | "Limit">;

export type UpdatableEventFilter = "Search" | "CreatedByMe" | "IsSignedUp" | "Category";

export interface CalendarData<T> {
  items: T[];
  total?: number;
  isLoading: boolean;
  hasMore?: boolean;
  loadMore: () => void;
  isLoadingMore: boolean;
}
