import type {
  ChatReplyDto,
  MessageV2Dto,
  ReminderStatusDto,
  SimpleProjectDto,
  UserDetailsWithAddressInterestsAndHelpDto,
} from "api/types";
import { Button } from "components/Button/Button";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import type { FormImage } from "components/ImageInput/useImageInput";
import { PageGrid } from "components/PageGrid/PageGrid";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useSlug } from "hooks/useSlug";
import { ChatWindow } from "modules/chats/components/ChatWindow";
import { CommunityPost } from "modules/messages/pages/List/components/CommunityPost/CommunityPost";
import { UserDetails } from "modules/users/components/UserDetails";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { routes } from "routes";

import { UserDeleteModal } from "./components/UserDeleteModal";
import { UserSetDeletionDateModal } from "./components/UserSetDeletionDateModal";

export interface UserDetailsLayoutProps {
  userDetails: UserDetailsWithAddressInterestsAndHelpDto;
  userProjects: SimpleProjectDto[] | undefined;
  userPosts: MessageV2Dto[] | undefined;
  isLoadingUserPosts: boolean;
  onUserDelete: () => Promise<unknown>;
  chatReplies: ChatReplyDto[];
  hasMoreReplies: boolean | undefined;
  fetchMoreReplies: () => void;
  isLoadingMoreReplies: boolean;
  sendChat: ({ message, files }: { message: string; files: FormImage[] }) => Promise<ChatReplyDto>;
  signUpReminders: ReminderStatusDto | undefined;
  sendReminder: () => void;
  isSubmittingModal: boolean;
  deletedBy?: UserDetailsWithAddressInterestsAndHelpDto;
}

export function Layout({
  userDetails,
  userProjects,
  userPosts,
  isLoadingUserPosts,
  onUserDelete,
  chatReplies,
  hasMoreReplies,
  fetchMoreReplies,
  isLoadingMoreReplies,
  sendChat,
  signUpReminders,
  sendReminder,
  isSubmittingModal,
  deletedBy,
}: UserDetailsLayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const { id: userIdParam } = useParams<{ id: string }>();
  const [isDeleteModalOpened, deleteModalOpenHandlers] = useBool(false);
  const [isSetDeletionDateModalOpened, setDeletionModalOpenHandlers] = useBool(false);

  function closeModals() {
    deleteModalOpenHandlers.setFalse();
    setDeletionModalOpenHandlers.setFalse();
  }

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.user-detail.title")}
      subTitle={t("page.user-detail.subtitle")}
      actions={
        userDetails.canBeEdited || userDetails.canBeDeleted || sessionUser.isSuperAdmin ? (
          <>
            {sessionUser.isAdmin && userDetails.canBeDeleted && (
              <Button data-testid="delete-user-btn" styling="danger" onClick={deleteModalOpenHandlers.setTrue}>
                {t("page.user-detail.actions.delete-user")}
              </Button>
            )}
            {sessionUser.isAdmin && userDetails.canBeEdited && (
              <Button type="link" data-testid="edit-user-btn" href={routes.users.edit({ slug, id: userDetails.id })}>
                {t("page.user-detail.actions.edit-user-details")}
              </Button>
            )}
            {sessionUser.isSuperAdmin && !userDetails.deletedAt && (
              <Button
                type="link"
                data-testid="notification-settings-btn"
                href={routes.adminNotifications.otherUserSettings({ slug, userId: userIdParam! })}
                state={{ fromPage: routes.users.details({ id: userIdParam!, slug }) }}
                styling="secondary"
              >
                {t("page.user-detail.actions.edit-user-notifications")}
              </Button>
            )}
          </>
        ) : undefined
      }
    >
      <PageGrid.Grid>
        <PageGrid.Item size="66%" isDesktopIndiffernt>
          <div className="flex w-full grow flex-col gap-10">
            {sessionUser.id !== userIdParam && (
              <ChatWindow
                canChat={userDetails.chatEnabled && !userDetails.deletedAt}
                chatReplies={chatReplies}
                hasMoreReplies={hasMoreReplies}
                fetchMoreReplies={fetchMoreReplies}
                isLoadingMoreReplies={isLoadingMoreReplies}
                sendReply={sendChat as (args: { message: string; files: FormImage[] }) => Promise<ChatReplyDto>}
                size="sm"
              />
            )}
            <div className="flex flex-col gap-3">
              {isLoadingUserPosts ? (
                <FullSizeLoader />
              ) : (
                <>
                  <span className="text-caption-bold">{t("page.user-detail.posts-section")}</span>
                  {userPosts && userPosts.length > 0 ? (
                    <div className="flex flex-col gap-5">
                      {userPosts.map((post) => (
                        <CommunityPost key={post.id} message={post} isExpanded={false} />
                      ))}
                    </div>
                  ) : (
                    <span className="text-caption">{t("page.user-detail.no-posts")}</span>
                  )}
                </>
              )}
            </div>
          </div>
        </PageGrid.Item>
        <PageGrid.Item size="33%" isDesktopIndiffernt>
          <UserDetails
            user={userDetails}
            projects={userProjects ?? []}
            reminderStatus={signUpReminders}
            sendReminder={sendReminder}
            deletedBy={deletedBy}
          />
        </PageGrid.Item>
      </PageGrid.Grid>
      {/* Step 1 */}
      <UserDeleteModal
        isOpened={isDeleteModalOpened}
        onOpenChange={deleteModalOpenHandlers.set}
        deletionDate={userDetails.deleteAfter}
        onDeleteUser={async () => {
          await onUserDelete();
          closeModals();
        }}
        isDeletingUser={isSubmittingModal}
        onClickSetDeletionDate={setDeletionModalOpenHandlers.setTrue}
      />
      {/* Step 2 - Optional */}
      <UserSetDeletionDateModal
        isOpened={isSetDeletionDateModalOpened}
        onOpenChange={setDeletionModalOpenHandlers.set}
        deletionDate={userDetails.deleteAfter}
      />
    </DocumentPaper>
  );
}
