import { useCallback, useRef } from "react";

interface onIntersectProps extends IntersectionObserverInit {
  onIntersect: () => void;
}

function useOnIntersection({
  threshold = 0,
  root = null,
  rootMargin = "0%",
  onIntersect,
}: onIntersectProps): (node: any) => void {
  const unsubscribeObservers = useRef<() => void>();

  const intersectionHandler = useCallback(
    ([entry]: IntersectionObserverEntry[]): void => {
      if (entry.isIntersecting) {
        onIntersect();
      }
    },
    [onIntersect],
  );

  const ref = useCallback(
    (node: any) => {
      const hasIOSupport = !!window.IntersectionObserver;

      if (!hasIOSupport || !node) return;

      const observerParams = { threshold, root, rootMargin };
      const observer = new IntersectionObserver(intersectionHandler, observerParams);

      observer.observe(node);

      unsubscribeObservers.current?.();
      unsubscribeObservers.current = () => observer.unobserve(node);
    },
    [threshold, root, rootMargin, intersectionHandler],
  );

  return ref;
}

export { useOnIntersection };
