import iconMessageSquare02 from "assets/icons/message-square-02.svg";
import iconThumbsUp from "assets/icons/thumbs-up.svg";
import { Button } from "components/Button/Button";
import { Icon } from "components/Icon/Icon";
import { twResolve } from "helpers/tw-resolve";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface LikeButtonProps {
  isActive: boolean;
  onClick: () => void;
}

const baseClassName = "rounded-full p-1 px-3 h-auto";

export function LikeButton({ isActive, onClick }: LikeButtonProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <Button
      styling="tertiary"
      className={baseClassName}
      icon={
        <Icon name={iconThumbsUp} className={twJoin("shrink-0", isActive ? "fill-aop-basic-blue-500/80" : undefined)} />
      }
      data-testid="like-btn"
      {...{ onClick }}
    >
      {t("component.community-post.like")}
    </Button>
  );
}

interface LikeCountButtonProps {
  isActive: boolean;
  isGreyedOut: boolean;
  total: number;
  onClick: () => void;
}

export function LikeCountButton({ total, isActive, onClick, isGreyedOut }: LikeCountButtonProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div
      className={twJoin(
        "relative whitespace-nowrap",
        isActive &&
          "after:absolute after:-bottom-2 after:left-0 after:h-px after:w-full after:bg-aop-basic-blue-500 after:content-['']",
      )}
    >
      <Button
        styling="tertiary"
        className={twResolve(baseClassName, isGreyedOut && "text-grey-500")}
        data-testid="like-count-btn"
        {...{ onClick }}
      >
        {t("component.community-post.like-count", { count: total })}
      </Button>
    </div>
  );
}

interface CommentButtonProps {
  isActive: boolean;
  isGreyedOut: boolean;
  total: number;
  onClick: () => void;
}

export function CommentButton({ isActive, isGreyedOut, total, onClick }: CommentButtonProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div
      className={twJoin(
        "relative whitespace-nowrap",
        isActive &&
          "after:absolute after:-bottom-2 after:left-0 after:h-px after:w-full after:bg-aop-basic-blue-500 after:content-['']",
      )}
    >
      <Button
        styling="tertiary"
        className={twResolve(baseClassName, isGreyedOut && "text-grey-500")}
        data-testid="comment-btn"
        icon={<Icon name={iconMessageSquare02} size={16} />}
        {...{ onClick }}
      >
        {t("component.community-post.comments.total", { count: total })}
      </Button>
    </div>
  );
}
