export const documentsRoutes = {
  rootList: "",
  rootCreate: "new",
  rootEdit: ":folderId/edit-root",
  documentsList: ":folderId",
  folderCreate: ":folderId/new-folder",
  folderEdit: ":folderId/edit",
  fileCreate: ":folderId/new-file",
  fileEdit: ":folderId/:fileId/edit",
} as const;
