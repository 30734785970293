import { twJoin } from "tailwind-merge";

interface Props {
  projects: { id: string; name: string }[];
  limit?: number;
  canWrap?: boolean;
}

export function ProjectList({ projects, canWrap, limit = 0 }: Props): React.ReactNode {
  let projectsSlice = projects;
  if (limit > 0) {
    projectsSlice = projects.slice(0, limit);
  }

  return (
    <div className={twJoin("flex gap-2", canWrap ? "flex-wrap" : undefined)}>
      {projectsSlice.map((x) => (
        <span className="truncate rounded-full bg-blue-100 px-3 py-1 text-caption" key={x.id}>
          {x.name}
        </span>
      ))}
      {limit > 0 && projects.length - limit > 0 && (
        <span className="truncate rounded-full bg-blue-400 px-3 py-1 text-caption text-white">
          +{projects.length - limit}
        </span>
      )}
    </div>
  );
}
