import { QueryClient } from "@tanstack/react-query";

import { isHttpError } from "./Network/errors";

export function createQueryClient(): QueryClient {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // Staletime of 50ms to prevent queries called in two separate components from going to the API in the same second
        staleTime: 50,
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
          // Don't retry on not found or rate limit errors
          if (isHttpError(error) && (error.status === 404 || error.status === 429)) {
            return false;
          }

          // Retry once
          if (failureCount === 0) {
            return true;
          }

          return false;
        },
      },
    },
  });
}
