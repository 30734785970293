import { useConfig } from "providers/ConfigProvider";

import { useSessionUser } from "./Network/useSessionUser";

export const useAIToolingManager = (): { isAvailable: boolean } => {
  const sessionUser = useSessionUser();
  const isAIToolingAvailable = useConfig("isAiToolingAvailable");

  // Only super admins and admins when feature flag is enabled can access AI Tooling
  return {
    isAvailable: (isAIToolingAvailable && sessionUser.isAdmin) || sessionUser.isSuperAdmin,
  };
};
