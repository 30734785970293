import { twJoin } from "tailwind-merge";

export function PhoneStatusBar({ fill }: { fill: "white" | "black" }): React.ReactNode {
  return (
    <div className="flex items-center py-3 pl-[18px] pr-3">
      <span className={twJoin("text-[11px]", fill === "white" ? "text-white" : undefined)}>12:34</span>
      <svg className="ml-0.5" width={8.8} height={8.8} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.39966 7.69328L7.6497 0.18457L0.140991 3.43461H4.39966V7.69328Z"
          fill={fill}
        />
      </svg>
      <svg
        className="ml-auto mr-1"
        width={9.9}
        height={7.7}
        viewBox="0 0 9 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.89502 0.431213H7.18264C6.7892 0.431213 6.47025 0.75016 6.47025 1.1436V5.41792C6.47025 5.81136 6.7892 6.1303 7.18264 6.1303H7.89502C8.28846 6.1303 8.60741 5.81136 8.60741 5.41792V1.1436C8.60741 0.75016 8.28846 0.431213 7.89502 0.431213ZM3.9769 1.85599H4.68929C5.08273 1.85599 5.40167 2.17493 5.40167 2.56837V5.41792C5.40167 5.81136 5.08273 6.1303 4.68929 6.1303H3.9769C3.58346 6.1303 3.26451 5.81136 3.26451 5.41792V2.56837C3.26451 2.17493 3.58346 1.85599 3.9769 1.85599ZM0.771163 2.92456H1.48355C1.87699 2.92456 2.19594 3.24351 2.19594 3.63695V5.41792C2.19594 5.81136 1.87699 6.1303 1.48355 6.1303H0.771163C0.377723 6.1303 0.0587769 5.81136 0.0587769 5.41792V3.63695C0.0587769 3.24351 0.377723 2.92456 0.771163 2.92456Z"
          fill={fill}
        />
      </svg>
      <svg width={12.1} height={12.1} viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.97999 3.80378C8.6186 2.6409 6.89866 2.00647 5.10011 2.00647C3.29623 2.00647 1.57152 2.64469 0.208456 3.81385L0.0926952 3.91315C-0.000918677 3.99344 -0.00629221 4.13617 0.0810207 4.22324L0.883841 5.02383C0.961765 5.10154 1.08637 5.10684 1.17065 5.03604L1.2701 4.95248C2.34357 4.05061 3.69133 3.55972 5.10011 3.55972C6.5041 3.55972 7.84755 4.04725 8.91942 4.94352L9.01884 5.02665C9.10314 5.09714 9.22748 5.0917 9.30527 5.01413L10.1082 4.21342C10.1956 4.12623 10.1901 3.98326 10.0962 3.90304L9.97999 3.80378ZM5.10012 4.47829C6.2334 4.47829 7.32152 4.85425 8.20793 5.55048L8.33321 5.64888C8.43308 5.72732 8.44185 5.87514 8.35196 5.96478L7.5464 6.7681C7.4719 6.8424 7.35401 6.85093 7.26952 6.78814L7.17132 6.71516C6.57264 6.27022 5.85073 6.03155 5.10012 6.03155C4.34488 6.03155 3.61875 6.27319 3.01808 6.72323L2.91979 6.79688C2.83529 6.86019 2.71697 6.85185 2.64224 6.77733L1.83699 5.97432C1.74728 5.88486 1.7558 5.73742 1.85521 5.65882L1.97977 5.56035C2.8683 4.85791 3.96136 4.47829 5.10012 4.47829ZM5.1001 6.95012C5.55567 6.95012 5.99834 7.06983 6.38838 7.2981L6.54641 7.39058C6.66623 7.4607 6.6873 7.6248 6.58906 7.72276L5.23975 9.06833C5.15687 9.15098 5.02249 9.15098 4.93961 9.06833L3.59896 7.73141C3.50116 7.63388 3.52155 7.47062 3.64035 7.39998L3.79661 7.30707C4.19023 7.07303 4.63852 6.95012 5.1001 6.95012Z"
          fill={fill}
        />
      </svg>
      <svg width={25.3} height={12.1} viewBox="0 0 23 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          opacity={0.35}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.04757 1.65027H17.5829C18.5665 1.65027 19.3639 2.44763 19.3639 3.43123V8.06174C19.3639 9.04534 18.5665 9.84271 17.5829 9.84271H4.04757C3.06397 9.84271 2.2666 9.04534 2.2666 8.06174V3.43123C2.2666 2.44763 3.06397 1.65027 4.04757 1.65027ZM4.04755 2.36266C3.45739 2.36266 2.97897 2.84108 2.97897 3.43124V8.06175C2.97897 8.65191 3.45739 9.13033 4.04755 9.13033H17.5829C18.173 9.13033 18.6515 8.65191 18.6515 8.06175V3.43124C18.6515 2.84108 18.173 2.36266 17.5829 2.36266H4.04755Z"
          fill={fill}
        />
        <path
          opacity={0.4}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0762 4.00098C20.7106 4.32524 21.1448 4.98512 21.1448 5.74643C21.1448 6.50773 20.7106 7.16761 20.0762 7.49188V4.00098Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.40373 3.07507H17.2267C17.6201 3.07507 17.9391 3.39402 17.9391 3.78746V7.70558C17.9391 8.09902 17.6201 8.41797 17.2267 8.41797H4.40373C4.01029 8.41797 3.69135 8.09902 3.69135 7.70558V3.78746C3.69135 3.39402 4.01029 3.07507 4.40373 3.07507Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}
