import { formatDate } from "components/FormattedDate/FormattedDate";
import type { i18n } from "i18next";
import type { VictoryAxisProps, VictoryLabelProps } from "victory";
import { VictoryAxis, VictoryLabel } from "victory";

import { dataColors, fontStyling } from "../../theme";

const axisStyling: VictoryAxisProps["style"] = {
  axis: {
    display: "none",
  },
  axisLabel: {
    padding: 52,
  },
  tickLabels: {
    ...fontStyling,
    fill: dataColors.tickColor,
  },
};

export function createDateTickFormat(
  i18n: i18n,
  columnCount: number,
  scale: number,
): (tick: string, index: number) => string {
  return (tick: string, index: number) => {
    // Hide half of the tick labels when all labels don't fit in the chart
    if (columnCount > 8 && scale < 1.4 && index % 2 === 1) {
      return "";
    }

    return formatDate(i18n, "monthYearShort", tick);
  };
}

// Normally we shouldn't create React elements in a regular function,
// but Victory relies on the Component instance of the child.
// It is a stateless component so it should be fine.
export function createXAxis(
  props?: Omit<VictoryAxisProps, "fixLabelOverlap" | "tickLabelComponent" | "style">,
  labelProps?: VictoryLabelProps,
): React.ReactNode {
  return (
    <VictoryAxis
      fixLabelOverlap
      tickLabelComponent={<VictoryLabel dy={16} {...labelProps} />}
      style={axisStyling}
      {...props}
    />
  );
}

export function createYAxis(
  props?: Omit<VictoryAxisProps, "crossAxis" | "dependentAxis" | "tickLabelComponent" | "style">,
): React.ReactNode {
  return (
    <VictoryAxis
      crossAxis={false}
      dependentAxis
      tickLabelComponent={<VictoryLabel dx={-16} />}
      style={axisStyling}
      fixLabelOverlap
      {...props}
    />
  );
}
