export type PostAudience = {
  id: string;
  name: string;
  type: "interest" | "helpCategory" | "realEstate" | "connection" | "undefined" | "none";
  canCreatePost?: boolean;
  canCreateAnnouncement?: boolean;
  membersCount?: number;
};

export enum ResidentPostType {
  AskForHelp = "askHelp",
  Inform = "inform",
  SellItem = "sellItem",
}

export const MAX_AMOUNT_IMAGES_PER_POST = 20;
