import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { ProjectEditRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { usePermission } from "hooks/usePermission";
import { canManageProjectManagement } from "modules/project/permissions";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import type { LayoutProps } from "./Layout";

export function Loader(props: {
  isEditMode: boolean;
  children: (props: LayoutProps) => React.ReactNode;
}): React.ReactNode {
  const projectId = useProjectId();
  const navigate = useNavigate();
  const api = useApi();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const hasPermission = usePermission();
  const queryClient = useQueryClient();
  const {
    data: projectDetails,
    isFetching: isLoadingProjectDetails,
    error: projectDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.PROJECT_DETAILS(projectId),
    queryFn: () => api.getProjectDetailsV1(),
    select: commonAPIDataSelector,
    enabled: props.isEditMode,
  });
  const sessionUser = useSessionUser();
  const { data: projects = [], isPending: isLoadingProjects, error: projectsError } = useConnectedProjects();
  const {
    data: languages,
    isFetching: isFetchingLanguages,
    error: languagesError,
  } = useQuery({
    queryKey: QUERY_KEYS.LANGUAGES_BASE,
    queryFn: () => api.getLanguagesBaseV1(),
    select: commonAPIDataSelector,
    enabled: hasPermission(canManageProjectManagement),
  });
  const createProject = useMutation({
    mutationFn: api.postProjectV1,
    async onSuccess(result) {
      showFlashToast({ title: t("page.projects.new.notification.success"), type: "success" });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CONNECTED_PROJECTS });
      navigate(`/@/${result.data.slug}`);
    },
    onError() {
      showFlashToast({ title: t("page.projects.new.notification.failed"), type: "error" });
    },
  });
  const editProject = useMutation({
    mutationFn: (data: ProjectEditRequest) => api.putProjectV1(projectId, data),
    onSuccess(result) {
      showFlashToast({ title: t("page.projects.edit.notification.success"), type: "success" });

      if (result.data.slug !== projectDetails?.slug) {
        window.location.href = `/@/${result.data.slug}`;
      } else {
        navigate(`/@/${result.data.slug}`);
        void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SELF(projectId) });
        void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CONNECTED_PROJECTS });
      }
    },
    onError() {
      showFlashToast({ title: t("page.projects.edit.notification.failed"), type: "error" });
    },
  });

  const closeProject = useMutation({
    mutationFn: (deleteUsers: boolean) =>
      api.deleteProjectByIdV1(projectId, {
        deleteUsers,
      }),
    onSuccess() {
      showFlashToast({ title: t("page.projects.close-project.notification.success"), type: "success" });

      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PROJECT_DETAILS(projectId) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CONNECTED_PROJECTS });
    },
    onError() {
      showFlashToast({ title: t("page.projects.close-project.notification.failed"), type: "error" });
    },
  });

  const error = projectDetailsError || languagesError || projectsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const isLoading = isLoadingProjectDetails || isFetchingLanguages || isLoadingProjects;
  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  const defaultLanguage = sessionUser.language;

  return props.children({
    isEditMode: props.isEditMode,
    defaultValues: props.isEditMode
      ? {
          city: projectDetails!.city,
          owner: projectDetails!.owner,
          maintenance: projectDetails!.maintenance,
          language: languages?.find((x) => x.id === projectDetails!.languageId) || defaultLanguage,
          name: projectDetails!.name,
          slug: projectDetails!.slug,
          type: projectDetails!.type,
          isActivated: projectDetails!.activeState === "active",
        }
      : {
          language: languages?.[0] || defaultLanguage,
          type: "addressBased",
        },
    languages: languages || [],
    projects,
    projectState: projectDetails?.activeState || "unknown",
    isSubmitting: editProject.isPending || createProject.isPending,
    closeProject: closeProject.mutateAsync,
    isClosingProject: closeProject.isPending,
    onSubmit(data) {
      if (props.isEditMode) {
        editProject.mutate({
          city: data.city,
          maintenance: data.maintenance,
          name: data.name,
          owner: data.owner,
          projectType: data.type,
          slug: data.slug,
          activeState: data.isActivated ? "active" : projectDetails?.activeState || "unknown",
          appBranding: projectDetails?.appBranding || "areaOfPeople",
        });
      } else {
        createProject.mutate({
          city: data.city,
          languageId: data.language.id,
          maintenance: data.maintenance,
          name: data.name,
          owner: data.owner,
          projectType: data.type,
          cloneProjectId: data.cloneProject?.id,
          appBranding: "areaOfPeople",
        });
      }
    },
  });
}
