import * as Sentry from "@sentry/react";
import { useApiClient } from "api/hooks/useApi";
import type { Api, LanguageDto } from "api/types";
import { ConnectionFailurePage } from "components/Error/ConnectionFailurePage";
import { ErrorPage } from "components/Error/ErrorPage";
import { useBool } from "hooks/useBool";
import i18next from "i18next";
import FetchBackend from "i18next-http-backend";
import { useAtomValue } from "jotai";
import { useCallback, useEffect } from "react";
import { Async } from "react-async";
import { initReactI18next, useTranslation } from "react-i18next";
import { languageAtom } from "state/app-language";

const SUPPORTED_LANGUAGES = ["en", "nl", "de"] as const;
type SupportedLanguage = (typeof SUPPORTED_LANGUAGES)[number];
const navigatorLanguage = window.navigator.language.split("-")[0];
const DEFAULT_LANGUAGE = SUPPORTED_LANGUAGES.includes(navigatorLanguage as SupportedLanguage)
  ? (navigatorLanguage as SupportedLanguage)
  : "en";

interface Props {
  language?: SupportedLanguage;
  children: React.ReactNode;
}

export function TranslationsProvider({ children, language: overrideLanguage }: Props): React.ReactNode {
  const storedLanguage = useAtomValue(languageAtom);
  let language: SupportedLanguage =
    overrideLanguage ||
    // Assertion to make typescript happy, but it can be any string
    (storedLanguage as SupportedLanguage | null) ||
    DEFAULT_LANGUAGE;
  if (!SUPPORTED_LANGUAGES.includes(language)) {
    language = DEFAULT_LANGUAGE;
  }

  const api = useApiClient();
  const [connectionFailure, connectionFailureHandlers] = useBool(false);

  useEffect(() => {
    function failedLoadingHandler(language: string, namespace: string, message: string) {
      connectionFailureHandlers.setTrue();
      Sentry.captureException(`Failed to load translation for ${language}: ${namespace}/${message}`);
    }

    function missingKeyHandler(languages: string[], namespace: string, key: string) {
      Sentry.captureException(`Missing translation for ${languages.join(",")}: ${namespace}/${key}`);
    }

    i18next.on("failedLoading", failedLoadingHandler);
    i18next.on("missingKey", missingKeyHandler);

    return () => {
      i18next.off("failedLoading", failedLoadingHandler);
      i18next.off("missingKey", missingKeyHandler);
    };
  }, [connectionFailureHandlers]);

  const loadTranslationsCallback = useCallback(() => {
    return loadTranslations(api, language);
  }, [api, language]);

  return (
    <Async promiseFn={loadTranslationsCallback}>
      <Async.Resolved>
        {connectionFailure ? (
          <ConnectionFailurePage poEditorCode={language} />
        ) : (
          <TranslationsProviderInternal language={language}>{children}</TranslationsProviderInternal>
        )}
      </Async.Resolved>
      <Async.Rejected>{(error) => <ErrorPage error={error} />}</Async.Rejected>
    </Async>
  );
}

function TranslationsProviderInternal({
  children,
  language,
}: {
  children: React.ReactNode;
  language: LanguageDto["id"];
}) {
  const { i18n, ready } = useTranslation();

  useEffect(() => {
    if (ready && i18n.language !== language) {
      selectedLanguage = language;
      document.documentElement.setAttribute("lang", language);

      void i18n.changeLanguage(language);
    }
  }, [ready, i18n, language]);

  return children;
}

let retries = 0;
let selectedLanguage: LanguageDto["id"] = DEFAULT_LANGUAGE;
let loadedTranslationPromise: Promise<any>;
function loadTranslations(api: Api<null>, language: LanguageDto["id"]) {
  selectedLanguage = language;
  document.documentElement.setAttribute("lang", language);

  if (loadedTranslationPromise) {
    return loadedTranslationPromise;
  }

  loadedTranslationPromise = i18next
    .use(FetchBackend)
    .use(initReactI18next)
    .init({
      lng: selectedLanguage,
      fallbackLng: false,
      load: "currentOnly",
      backend: {
        request: async (
          _: unknown,
          __: unknown,
          ___: unknown,
          callback: (error: unknown, data: { data: string; status: number }) => unknown,
        ) => {
          try {
            const result = await api.api.getTranslationsDetailsV1("dashboard", selectedLanguage);

            // Load local translations in development
            if (import.meta.env.DEV) {
              const devTranslations = await import("./english.json");
              const translationHelpers = await import("helpers/translations");
              const devData = translationHelpers.parseTranslations(devTranslations.default);
              result.data = { ...devData, ...result.data };
            }

            retries = 0;

            callback(null, {
              data: JSON.stringify(result.data),
              status: result.status || 200,
            });
          } catch (error) {
            retries++;

            callback(error, {
              data: "",
              // return 499 status code so i18next stops retrying
              status: retries > 2 ? 499 : (error as Response | undefined)?.status || 0,
            });
          }
        },
      },
      react: {
        useSuspense: false,
      },
      pluralSeparator: "_",
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
    });

  return loadedTranslationPromise;
}
