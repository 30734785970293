import { useProjectContext } from "providers/ProjectContext";

export function useSlug<T extends boolean = false>(
  args: { optional: T } = { optional: false as T },
): T extends true ? string | undefined : string {
  const project = useProjectContext();

  if (!args.optional && !project.projectSlug) {
    throw new Error("Slug is not in URL");
  }

  return project.projectSlug as T extends true ? string | undefined : string;
}
