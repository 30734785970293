import { useInfiniteQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { parseISO } from "date-fns";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import type { ReservationsFilters } from "modules/calendar/constants";
import { QUERY_KEYS } from "query-keys";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

const RESERVATIONS_PAGE = 8;

export function Loader({ children }: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const { i18n } = useTranslation();

  const [reservationsSearch, setReservasionsSearch] = useState("");

  const upcomingReservationsFilters: ReservationsFilters = {
    TimeFrame: "futureOrToday",
  };

  const pastReservationsFilters: ReservationsFilters = {
    TimeFrame: "past",
  };

  const {
    data: upcomingReservationsData,
    hasNextPage: hasMoreUpcomingReservations,
    fetchNextPage: fetchMoreUpcomingReservations,
    isFetchingNextPage: isLoadingMoreUpcomingReservations,
    isLoading: isLoadingUpcomingReservations,
    error: upcomingReservationsError,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.BOOKINGS_USER_RESERVATIONS(projectId, upcomingReservationsFilters),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getBookableAssetsMyBookingsV1({
          TimeFrame: "futureOrToday",
          Offset: pageParam * RESERVATIONS_PAGE,
          Limit: pageParam === 0 ? RESERVATIONS_PAGE : undefined,
        })
        .then((items) => commonAPIDataSelector(items)),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
  });

  const {
    data: pastReservationsData,
    hasNextPage: hasMorePastReservations,
    fetchNextPage: fetchMorePastReservations,
    isFetchingNextPage: isLoadingMorePastReservations,
    isLoading: isLoadingPastReservations,
    error: pastReservationsError,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.BOOKINGS_USER_RESERVATIONS(projectId, pastReservationsFilters),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getBookableAssetsMyBookingsV1({
          TimeFrame: "past",
          Offset: pageParam * RESERVATIONS_PAGE,
          Limit: RESERVATIONS_PAGE,
        })
        .then((items) => commonAPIDataSelector(items)),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
  });

  const upcomingReservations = useMemo(
    () => upcomingReservationsData?.pages.flatMap((x) => x.items) ?? [],
    [upcomingReservationsData],
  );

  const pastReservations = useMemo(
    () => pastReservationsData?.pages.flatMap((x) => x.items) ?? [],
    [pastReservationsData],
  );

  const mappedUpcomingReservations = useMemo(
    () =>
      upcomingReservations.map((reservation) => ({
        ...reservation,
        searchable:
          `${reservation.asset.name}|||${formatDate(i18n, "month", parseISO(reservation.date))}|||${reservation.date}|||${reservation.startTime}|||${reservation.endTime}|||${reservation.asset.locationSpecification ?? ""}`.toLowerCase(),
      })),
    [upcomingReservations, i18n],
  );

  const mappedPastReservations = useMemo(
    () =>
      pastReservations.map((reservation) => ({
        ...reservation,
        searchable:
          `${reservation.asset.name}|||${formatDate(i18n, "month", parseISO(reservation.date))}|||${reservation.date}|||${reservation.startTime}|||${reservation.endTime}|||${reservation.asset.locationSpecification ?? ""}`.toLowerCase(),
      })),
    [pastReservations, i18n],
  );

  const filteredUpcomingReservations = useMemo(
    () => mappedUpcomingReservations.filter((reservation) => reservation.searchable.includes(reservationsSearch)),
    [reservationsSearch, mappedUpcomingReservations],
  );

  const filteredPastReservations = useMemo(
    () => mappedPastReservations.filter((reservation) => reservation.searchable.includes(reservationsSearch)),
    [reservationsSearch, mappedPastReservations],
  );

  const error = upcomingReservationsError || pastReservationsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  return children({
    onSearch: setReservasionsSearch,
    upcoming: {
      items: filteredUpcomingReservations,
      hasMore: hasMoreUpcomingReservations,
      isLoading: isLoadingUpcomingReservations,
      loadMore: fetchMoreUpcomingReservations,
      isLoadingMore: isLoadingMoreUpcomingReservations,
    },
    past: {
      items: filteredPastReservations,
      hasMore: hasMorePastReservations,
      isLoading: isLoadingPastReservations,
      loadMore: fetchMorePastReservations,
      isLoadingMore: isLoadingMorePastReservations,
    },
  });
}
