import { twJoin } from "tailwind-merge";

export interface ToggleButtonGroupOption<T, TLabel extends React.ReactNode = React.ReactNode> {
  value: T;
  label: TLabel;
}

export function ToggleButtonGroup<
  T extends number | string | boolean,
  TLabel extends React.ReactNode = React.ReactNode,
>({
  selected,
  options,
  onChange,
  fillWidth,
}: {
  selected: ToggleButtonGroupOption<T, TLabel>;
  options: readonly ToggleButtonGroupOption<T, TLabel>[];
  onChange: (option: ToggleButtonGroupOption<T, TLabel>) => void;
  fillWidth?: boolean;
}): React.ReactNode {
  return (
    <div
      className={twJoin("inline-flex gap-1 self-start rounded-lg bg-grey-100 p-1", fillWidth ? "w-full" : undefined)}
    >
      {options.map((x) => (
        <InternalButton
          key={x.value.toString()}
          isEnabled={x.value === selected.value}
          onClick={() => onChange(x)}
          fillWidth={fillWidth}
        >
          {x.label}
        </InternalButton>
      ))}
    </div>
  );
}

function InternalButton({
  onClick,
  children,
  isEnabled,
  fillWidth,
}: React.PropsWithChildren<{ onClick: () => void; isEnabled: boolean; fillWidth?: boolean }>): React.ReactNode {
  return (
    <button
      type="button"
      onClick={onClick}
      className={twJoin(
        "flex cursor-pointer items-center justify-center self-start truncate rounded-[6px] py-2.5 text-center text-caption-bold",
        isEnabled ? "bg-white" : undefined,
        fillWidth ? "w-full" : "px-6",
      )}
    >
      {children}
    </button>
  );
}
