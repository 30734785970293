import Fuse from "fuse.js";
import { isDefined } from "helpers/util";
import { uniq } from "lodash-es";

export function parseData<T extends readonly Column<any>[]>(rows: object[], expectedColumns: T): Data<T> {
  const searchableColumns = expectedColumns.flatMap((x) => [
    { alias: x.name, column: x.name },
    ...(x.alias ?? []).map((y) => ({
      alias: y,
      column: x.name,
    })),
  ]);
  const fuse = new Fuse(searchableColumns, { keys: ["alias"], threshold: 0.2 });

  const columnMapping = Object.fromEntries(
    uniq(rows.flatMap((x) => Object.keys(x)))
      .map((dataColumnName) => {
        const result = fuse.search(dataColumnName)[0]?.item;

        if (result) {
          return [result.column, dataColumnName] as [string, string];
        }
      })
      .filter(isDefined),
  );
  const columns = expectedColumns.map((x) => ({
    ...x,
    dataColumnName: columnMapping[x.name],
  }));

  return rows.map(
    (row, i) =>
      ({
        id: i,
        uploadStatus: "NONE",

        ...Object.fromEntries(
          columns.map((column) => {
            let value = (row as any)[column.dataColumnName as keyof typeof row];
            if (column.transform && value) {
              value = column.transform(value);
            }

            if (typeof value !== "string" && typeof value !== "undefined" && typeof value !== "number") {
              throw new Error(`Can not parse row ${i}, got column with unsupported type: ${typeof value}`);
            }

            if (typeof value === "number") {
              value = value.toString();
            } else if (typeof value === "string") {
              value = value.trim();
            }

            if (column.valueAlias) {
              if (value in column.valueAlias) {
                value = column.valueAlias[value];
              }
            }

            return [column.name, value || column.defaultValue] as [T[number]["name"], string | undefined];
          }),
        ),
      }) as Data<T>[number],
  );
}

export type Data<T extends readonly Column<any>[]> = ({ [key in T[number]["name"]]: string | undefined } & {
  id: number;
} & {
  uploadStatus: UploadStatus;
})[];

type UploadStatus = "UPLOADING" | "SUCCESS" | "ERROR" | "NONE";

export type Column<T = string | number> = {
  name: string;
  alias?: readonly string[];
  transform?: (value: T) => T;
  defaultValue?: T;
  rules?:
    | { required: string }
    | { unique: { error: string; ignoreCase?: boolean } }
    | { oneOf: { error: string; values: readonly string[]; ignoreCase?: boolean } }
    | { notOneOf: { error: string; values: readonly string[]; ignoreCase?: boolean } }
    | { match: { error: string; regex: RegExp } };
  warnings?: { unique: string };
  valueAlias?: { [alias: string]: string };
  render?: (value: T) => React.ReactNode;
};
