import iconAtSign from "assets/icons/at-sign.svg";
import iconPhone from "assets/icons/phone.svg";
import { Icon } from "components/Icon/Icon";
import { RichTextView } from "components/RichTextView/RichTextView";
import { useTranslation } from "react-i18next";

import { AppHeader } from "./AppCommon";
import { PhoneInner } from "./PhoneInner";

export interface AppServicePartnerDetailsViewProps {
  name: string;
  description: string;
  slogan?: string;
  headerImage?: string;
  profileImage?: string;
  email?: string;
  phoneNumber?: string;
  projectTintColor: string;
}

export function AppServicePartnerDetailsView({
  name,
  slogan,
  description,
  headerImage,
  profileImage,
  email,
  phoneNumber,
  projectTintColor,
}: AppServicePartnerDetailsViewProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <PhoneInner>
      <AppHeader
        bgImage={headerImage}
        profileImage={
          <div
            className="size-full bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: profileImage != null ? `url(${profileImage})` : "none" }}
          />
        }
      />
      <div className="relative bg-white px-2.5 pb-2 pt-2.5">
        <div className="float-right h-6 w-24" />

        <div className="line-clamp-3 flex flex-col gap-0.5 break-all">
          <span className="text-[14px] font-old-semibold leading-[16px]">{name || "-"}</span>
          <span className="text-[10px] leading-[12px] text-grey-500">{slogan || "-"}</span>
        </div>

        <div className="my-2 flex items-center gap-1 bg-white">
          <div className="flex flex-1 justify-center rounded-sm bg-grey-100 p-1.5 text-center">
            <Icon
              name={iconPhone}
              size={20}
              style={{
                color: phoneNumber ? projectTintColor || "#23175A" : "#9797b5",
              }}
            />
          </div>
          <div className="flex flex-1 justify-center rounded-sm bg-grey-100 p-1.5 text-center">
            <Icon
              name={iconAtSign}
              size={20}
              style={{
                color: email ? projectTintColor || "#23175A" : "#9797b5",
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt-4 bg-white">
        <div className="whitespace-pre-line break-all px-4 pb-4 pt-2">
          <RichTextView title="description preview">{description || "-"}</RichTextView>
        </div>
      </div>
      {phoneNumber || email ? (
        <div className="my-4">
          {phoneNumber ? (
            <div className="flex flex-col items-start justify-between border-b border-grey-100 bg-white px-3 py-2">
              <span className="truncate text-[11px] text-app-grey">{t("model.service.phone-number")}</span>
              <span className="break-words text-[12px] text-black">{phoneNumber || "-"}</span>
            </div>
          ) : null}
          {email ? (
            <div className="flex flex-col items-start justify-between bg-white px-3 py-2">
              <span className="truncate text-[11px] text-app-grey">{t("model.service.email")}</span>
              <span className="break-words text-[12px] text-black">{email || "-"}</span>
            </div>
          ) : null}
        </div>
      ) : null}
    </PhoneInner>
  );
}
