import { useCallback, useEffect } from "react";

const exceptions = [".ant-picker-dropdown"];

export function useClickOutside(ref: React.RefObject<HTMLElement>, callback: () => void, conditional = true): void {
  const handleClickOutside = useCallback(
    (e: MouseEvent | TouchEvent) => {
      const el = ref?.current;

      if (!el || el.contains(e.target as Node)) {
        return;
      }

      if (exceptions.some((selector) => document.querySelector(selector)?.contains(e.target as Node))) {
        return;
      }

      callback();
    },
    [callback, ref],
  );

  useEffect(() => {
    if (conditional) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside, conditional]);
}
