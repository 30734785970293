import type { ServicePartnerPageDetailsDto } from "api/types";
import alertCircleIcon from "assets/icons/alert-circle.svg";
import homeIcon from "assets/icons/home-02.svg";
import { Icon } from "components/Icon/Icon";
import { useSessionUser } from "hooks/Network/useSessionUser";
import type React from "react";
import { useTranslation } from "react-i18next";

import { ServiceDetailsBlockCard } from "../ServiceDetails/ServiceDetailsBlockCard";

interface IqbiDetailInfoCardProps {
  serviceDetails: ServicePartnerPageDetailsDto;
}

export function IqbiDetailsInfoCard({ serviceDetails }: IqbiDetailInfoCardProps): React.ReactNode {
  const { t } = useTranslation();
  const sessionUser = useSessionUser();

  const iqbiStatus = serviceDetails.partnerProperties.iqbi?.status;

  // Hide address if user has not given consent or is waiting for consent approval
  if (iqbiStatus === "noConsent" || iqbiStatus === "waitingForConsentApproval") return null;

  // If user consent request has been approved, but user has not verified the address
  if (iqbiStatus === "notVerified") {
    return (
      <ServiceDetailsBlockCard title={t("page.service-details.iqbi-address.title")}>
        <div className="flex items-center gap-2">
          <Icon name={alertCircleIcon} size={22} className="text-red-500" />
          <p>{t("page.service-details.iqbi-consumption.no-access")}</p>
        </div>
      </ServiceDetailsBlockCard>
    );
  }

  return (
    <ServiceDetailsBlockCard title={t("page.service-details.iqbi-address.title")}>
      <div className="flex items-center gap-2">
        <Icon name={homeIcon} size={28} />
        <p className="text-grey-900">
          {sessionUser.address ? (
            <>
              {sessionUser.address.location}
              <br />
              {sessionUser.address.zipCode} {sessionUser.address.city}
            </>
          ) : (
            sessionUser.company?.name
          )}
        </p>
      </div>
    </ServiceDetailsBlockCard>
  );
}
