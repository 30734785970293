import { Anchor } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Form } from "components/Form/Form";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { removeFromHeaders } from "helpers/headers";
import { createRequiredStringRule } from "helpers/rules";
import { sleep } from "helpers/sleep";
import { useBool } from "hooks/useBool";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { routes } from "routes";
import { sendPasswordResetEmail } from "supertokens-web-js/recipe/emailpassword";

import inboxPng from "../assets/inbox.png";
import { AuthenticationPage } from "../components/AuthenticationPage";
import { emailRegex } from "../helpers/constants";

enum PageState {
  Initial,
  Sent,
}

export function ForgotPasswordPage(): React.ReactNode {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const showFlashToast = useFlashToast();

  const [isSubmitting, submittingHandlers] = useBool();
  const [state, setState] = useState(PageState.Initial);
  const [submittedEmail, setSubmittedEmail] = useState<string | undefined>();

  const initialEmail = location?.state?.email || "";

  const formMethods = useForm<FormValues>({
    defaultValues: {
      email: initialEmail,
    },
  });
  const { setError } = formMethods;

  const sendResetLink = useCallback(
    async (email: string, resend?: boolean) => {
      submittingHandlers.setTrue();

      // Wait for 1.5 seconds to make sure the user actually sees the spinner
      const sleepPromise = sleep(1500);

      try {
        const response = await sendPasswordResetEmail({
          formFields: [
            {
              id: "email",
              value: email,
            },
          ],
          options: {
            preAPIHook: (x) => {
              const url = new URL(x.url);
              url.searchParams.set("languageId", i18n.language);

              return Promise.resolve({
                ...x,
                url: url.toString(),
                requestInit: removeFromHeaders(x.requestInit, "Authorization"),
              });
            },
          },
        });

        setSubmittedEmail(email);

        if (response.status === "FIELD_ERROR") {
          // This should never happen because we do validation on the client side
          setError("email", { type: "manual", message: response.formFields[0].error });
        } else if (response.status === "PASSWORD_RESET_NOT_ALLOWED") {
          // Can only occur when we start using social login.
          // This can happen due to automatic account linking.
          // Please read our account linking docs
          console.error("Password reset not allowed");
          showFlashToast({ type: "error", title: t("common.generic-error") });
        } else {
          await sleepPromise;
          setState(PageState.Sent);

          if (resend) {
            showFlashToast({ type: "success", title: t("page.forgot-password.sent.resend-toast") });
          }
        }
      } catch (err: any) {
        setError("email", {
          type: "manual",
          message: t("common.generic-error"),
        });
        setState(PageState.Initial);
      } finally {
        submittingHandlers.setFalse();
      }
    },
    [i18n.language, setError, showFlashToast, submittingHandlers, t],
  );

  return (
    <AuthenticationPage
      header={
        state === PageState.Sent ? (
          <div className="flex items-center gap-4">
            <img src={inboxPng} alt="Inbox" className="w-12" />
            {t("page.forgot-password.sent.title")}
          </div>
        ) : (
          t("page.forgot-password.title")
        )
      }
      body={
        state === PageState.Initial ? (
          t("page.forgot-password.description")
        ) : (
          <div>
            <p>{t("page.forgot-password.sent.description")}</p>
            <br />
            <p>{t("page.forgot-password.sent.remark")}</p>
          </div>
        )
      }
    >
      {state === PageState.Initial && (
        <Form formMethods={formMethods} onSubmit={(data) => sendResetLink(data.email)}>
          <div className="flex flex-col gap-8">
            <FormField label={t("page.forgot-password.email")}>
              <FormInput
                id="email"
                name="email"
                type="email"
                required
                rules={{
                  validate: {
                    required: createRequiredStringRule(t, "page.login.form.email.label"),
                    isValidEmail(email) {
                      if (!new RegExp(emailRegex).test(email)) {
                        return t("page.login.errors.email-invalid-pattern");
                      }
                    },
                  },
                }}
                placeholder={t("page.login.form.email.placeholder")}
                autoComplete="username"
                autoCapitalize="none"
                spellCheck="false"
                aria-required
              />
            </FormField>
            <Button type="submit" className="w-full" isLoading={isSubmitting}>
              {t("page.forgot-password.send-reset-link")}
            </Button>
          </div>
          <div className="mt-4 flex justify-center text-caption">
            <Anchor to={routes.authentication.login()} state={{ email: initialEmail }} isBold>
              {t("page.forgot-password.cancel")}
            </Anchor>
          </div>
        </Form>
      )}
      {state === PageState.Sent && (
        <div className="flex flex-col gap-8">
          <Button
            styling="ghostPrimary"
            type="link"
            className="font-old-semibold"
            href={routes.authentication.login()}
            state={{ email: submittedEmail }}
          >
            {t("page.forgot-password.sent.home")}
          </Button>
          <div className="flex items-center justify-start gap-2 [&>*]:text-overline">
            <p className="shrink-0">{t("page.forgot-password.sent.no-email.description")}</p>
            <Button
              styling="ghostPrimary"
              onClick={() => sendResetLink(formMethods.getValues("email"), true)}
              className="font-old-bold"
              isLoading={isSubmitting}
            >
              {t("page.verify-email.resend")}
            </Button>
          </div>
        </div>
      )}
    </AuthenticationPage>
  );
}

interface FormValues {
  email: string;
}
