import type { ConnectedProjectDto } from "api/types";

export type AppBranding = ConnectedProjectDto["appBranding"];

const brandingLinks = {
  areaOfPeople: "https://area-of-people.app.link",
  bpd: "https://aop-bpd.app.link",
} as const satisfies Record<AppBranding, string>;

export function getDeepLinkUrl<T extends `/${string}`>(
  branding: string,
  path: T,
): `${(typeof brandingLinks)[keyof typeof brandingLinks]}${T}` {
  const item = brandingLinks[branding as AppBranding] || brandingLinks.areaOfPeople;

  return `${item}${path}`;
}
