import {
  addSeconds,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  formatDuration as formatDurationDateFns,
  intervalToDuration,
  sub,
} from "date-fns";
import type { i18n } from "i18next";
import { useTranslation } from "react-i18next";

import { fallbackLanguageCode, locales } from "../../helpers/hardcoded-translations";

interface FormattedDurationProps {
  duration: Partial<DaysDuration> | DateDuration;
  formatTokens: DurationToken[];
  short?: boolean;
}
export function FormattedDuration({ duration, formatTokens, short = false }: FormattedDurationProps): React.ReactNode {
  const { i18n } = useTranslation();

  return <span>{formatDuration(i18n, duration, formatTokens, short)}</span>;
}

export function formatDuration(
  { language, t }: i18n,
  duration: FormattedDurationProps["duration"],
  formatTokens: FormattedDurationProps["formatTokens"],
  short = false,
): string {
  return formatDurationForLanguage(t, language, duration, formatTokens, short);
}

type DurationToken = "days" | "hours" | "minutes" | "seconds";

interface DaysDuration {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

interface DateDuration {
  start: Date;
  end: Date;
}

function formatDurationForLanguage(
  t: i18n["t"],
  languageId: string,
  duration: Partial<DaysDuration> | DateDuration,
  formatTokens: DurationToken[],
  short: boolean,
): string {
  duration = "start" in duration ? intervalToDuration(duration) : normalizeDuration(duration);

  if (!(languageId in locales)) {
    console.warn(
      `Language ${languageId} not found in hardcoded translations, falling back to language id ${fallbackLanguageCode}`,
    );
  }

  const locale = locales[languageId as keyof typeof locales] || locales[fallbackLanguageCode];

  if (short) {
    locale.formatDistance = ((token, count) => {
      switch (token) {
        case "xSeconds":
          return t("common.duration-short.seconds", { count });
        case "xMinutes":
          return t("common.duration-short.minutes", { count });
        case "xHours":
          return t("common.duration-short.hours", { count });
        case "xDays":
          return t("common.duration-short.days", { count });
        default:
          return "";
      }
    }) satisfies typeof locale.formatDistance;
  }

  const formattedDuration = formatDurationDateFns(duration, { format: formatTokens, locale });
  if (formattedDuration !== "") {
    return formattedDuration;
  }

  return formatDurationDateFns(
    { [formatTokens[formatTokens.length - 1]]: 0 },
    { format: formatTokens, locale, zero: true },
  );
}

function normalizeDuration(duration: Partial<DaysDuration>): DaysDuration {
  return secondsToDuration(durationToSeconds(duration));
}

function durationToSeconds(duration: Partial<DaysDuration>): number {
  const start = new Date(0);
  let end = new Date(0);

  if (duration.days) {
    end = addSeconds(end, duration.days * 24 * 60 * 60);
  }

  if (duration.hours) {
    end = addSeconds(end, duration.hours * 60 * 60);
  }

  if (duration.minutes) {
    end = addSeconds(end, duration.minutes * 60);
  }

  if (duration.seconds) {
    end = addSeconds(end, duration.seconds);
  }

  return differenceInSeconds(end, start);
}

// Custom implementation of date-fns' intervalToDuration, without weeks, months and years.
// This is done because months and years do not have a guaranteed amount of days. Displaying this wouldn't make sense.
function secondsToDuration(seconds: number) {
  const start = new Date(0);
  const end = new Date(seconds * 1000);

  const duration = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  duration.days = Math.abs(differenceInDays(start, end));

  const remainingHours = sub(start, { days: -duration.days });
  duration.hours = Math.abs(differenceInHours(remainingHours, end));

  const remainingMinutes = sub(remainingHours, { hours: -duration.hours });
  duration.minutes = Math.abs(differenceInMinutes(remainingMinutes, end));

  const remainingSeconds = sub(remainingMinutes, { minutes: -duration.minutes });
  duration.seconds = Math.abs(differenceInSeconds(remainingSeconds, end));

  return duration;
}
