import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import iconMenu01 from "assets/icons/menu-01.svg";
import { Icon } from "components/Icon/Icon";
import type { ReactNode } from "react";
import { twJoin } from "tailwind-merge";

interface Props {
  id: string;
  title?: string;
  disabled: boolean;
  children: ReactNode;
}

export function DynamicRepeaterItem({ id, title, disabled, children }: Props): React.ReactNode {
  const { attributes, listeners, active, isDragging, setNodeRef, transform, transition } = useSortable({
    id: id,
    disabled: disabled,
  });

  const style = { transform: CSS.Transform.toString(transform), transition };

  return (
    <div
      className={twJoin(
        "relative flex cursor-default flex-col gap-6 rounded-lg bg-white px-4 pb-8 pt-4",
        active && !isDragging ? "shadow-none ring-1 ring-grey-100" : "shadow-sm",
      )}
      ref={setNodeRef}
      style={style}
    >
      {isDragging && <div className="absolute left-0 top-0 z-10 size-full rounded-lg bg-blue-100" />}
      <div className="flex flex-row-reverse justify-between">
        <Icon
          name={iconMenu01}
          {...attributes}
          {...listeners}
          className={twJoin(
            "text-grey-400",
            // eslint-disable-next-line no-nested-ternary
            !disabled ? (isDragging ? "cursor-grabbing" : "cursor-grab") : undefined,
          )}
        />
        {title && <span className="text-body-bold">{title}</span>}
      </div>
      {children}
    </div>
  );
}
