import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormHiddenInput } from "components/Form/FormHiddenInput";
import { FormInput } from "components/Form/FormInput";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { createRequiredStringRule } from "helpers/rules";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type CustomUrlModalProps = ModalBaseProps & {
  isLoading: boolean;
  onSubmit: (id: string | undefined, maintainer: string) => Promise<void>;
  defaultValues?: FormValues;
};

interface FormValues {
  id: string;
  maintainer: string;
}

export function MaintainerModal({
  isOpened,
  onOpenChange,
  isLoading,
  onSubmit,
  defaultValues,
}: CustomUrlModalProps): React.ReactNode {
  const { t } = useTranslation();
  const form = useForm<FormValues>({
    defaultValues: defaultValues,
  });

  async function handleSubmit() {
    const formValues = form.getValues();
    form.reset();
    await onSubmit(formValues.id, formValues.maintainer);

    onOpenChange(false);
  }

  return (
    <Modal.Root
      title={t("page.plus-button-links.maintainer.modal.title")}
      size="sm"
      // isClosable={false}
      {...{ onOpenChange, isOpened }}
    >
      <Form formMethods={form} onSubmit={handleSubmit}>
        <FormContent className="mb-0" maxWidth="none">
          <FormHiddenInput<FormValues> name="id" />
          <FormField htmlFor="url" label="" hideLabel required>
            <FormInput<FormValues>
              name="maintainer"
              id="maintainer"
              placeholder={t("page.plus-button-links.maintainer.modal.input-placeholder")}
              rules={{
                validate: {
                  required: createRequiredStringRule(t, "page.plus-button-links.maintainer.modal.input-name"),
                },
              }}
            />
          </FormField>
          <Modal.Actions>
            <Modal.Close>
              <Button styling="secondary">{t("common.action.cancel")}</Button>
            </Modal.Close>
            <Button type="submit" styling="primary" isLoading={isLoading}>
              {t("common.action.save")}
            </Button>
          </Modal.Actions>
        </FormContent>
      </Form>
    </Modal.Root>
  );
}
