import type { PostHog } from "posthog-js";
import posthog from "posthog-js";

export function initializePostHog(): PostHog | undefined {
  if (import.meta.env.VITE_DISABLE_POSTHOG) {
    return;
  }

  if (!window.POSTHOG_KEY || !window.POSTHOG_HOST) {
    console.error("No PostHog key set, PostHog will be disabled");

    return;
  }

  posthog.init(window.POSTHOG_KEY, {
    api_host: window.POSTHOG_HOST,
    person_profiles: "identified_only",
    autocapture: false,
  });

  return posthog;
}
