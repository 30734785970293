import { useEffect, useRef, useState } from "react";

/**
 * Detect whether a key was just pressed. Will be false after timeout.
 * @param key The key to detect (e.g. "Enter", "Escape", "Tab")
 * @param timeout The time in milliseconds to wait before resetting the state
 */
export function useKeyUpPressed(key: string, timeout: number): boolean {
  const isKeyPressedRef = useRef(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === key) {
        isKeyPressedRef.current = true;

        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        const newTimeoutId = setTimeout(() => {
          isKeyPressedRef.current = false;
        }, timeout);

        setTimeoutId(newTimeoutId);
      }
    };

    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keyup", handleKeyUp);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [key, timeout, timeoutId]);

  return isKeyPressedRef.current;
}
