import { isDefined } from "helpers/util";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

interface SetParamOptions {
  replace: boolean;
}

export function useQueryParam<T extends string>(
  paramName: T,
): readonly [string | null, (newValue: string | null, options?: SetParamOptions) => void] {
  const [value, setValue] = useQueryParams<{ [key in T]: string }>();

  const update = useCallback(
    (value: string | null, options?: SetParamOptions) =>
      setValue({ [paramName]: value } as { [key in T]: string }, options),
    [setValue, paramName],
  );

  return [value[paramName] ?? null, update] as const;
}

export function useQueryParams<T extends Record<string, string | string[]>>(
  allowedKeys?: readonly (keyof T)[],
): readonly [Partial<T>, (newValue: T, options?: SetParamOptions) => void] {
  const [query, setSearchParams] = useSearchParams();

  const setParam = useCallback(
    (params: T, options?: SetParamOptions) => {
      const newQuery = new URLSearchParams(window.location.search);

      for (const [paramName, newValue] of Object.entries(params)) {
        if (newValue == null || (Array.isArray(newValue) ? newValue.length === 0 : newValue.trim() === "")) {
          newQuery.delete(paramName);
        } else {
          if (Array.isArray(newValue)) {
            const paramValues = newValue.map((x) => x?.trim()).filter(isDefined);
            newQuery.set(paramName, paramValues.join(","));
          } else {
            const trimmedValue = newValue?.trim();
            if (trimmedValue) {
              newQuery.set(paramName, trimmedValue);
            }
          }
        }
      }

      setSearchParams(newQuery, options);
    },
    [setSearchParams],
  );

  const paramValue = useMemo(() => {
    const items: Partial<T> = {};

    for (const key of Array.from(query.keys())) {
      if (items[key]) {
        continue;
      }

      if (allowedKeys && !allowedKeys.includes(key)) {
        continue;
      }

      const values = query.getAll(key);
      if (values.length > 1) {
        const value = values?.map((x) => x.trim() || undefined).filter(isDefined);
        if (value) {
          items[key as keyof T] = value as T[keyof T];
        }
      } else {
        const value = values[0]?.trim() || undefined;
        if (value) {
          items[key as keyof T] = value as T[keyof T];
        }
      }
    }

    return items;
  }, [query, allowedKeys]);

  return [paramValue, setParam] as const;
}
