import { LoadingIcon } from "components/Icons/Icons";
import { motion } from "framer-motion";
import { useBool } from "hooks/useBool";
import React, { useContext, useEffect } from "react";

interface Context {
  loading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
}

const SpinnerContext = React.createContext<Context>({
  loading: true,
  startLoading: () => {},
  stopLoading: () => {},
});

export function GlobalLoadingSpinner({ children }: React.PropsWithChildren<{}>): React.ReactNode {
  const [loading, loadingHandlers] = useBool(true);

  return (
    <SpinnerContext.Provider
      value={{
        loading,
        startLoading: loadingHandlers.setTrue,
        stopLoading: loadingHandlers.setFalse,
      }}
    >
      {loading && (
        <div className="absolute z-50 w-full bg-aop-off-white">
          {/* framer motion animation beacuse delaying with css animation is complicated with tailwind */}
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{
              delay: 2.5,
            }}
            className="flex h-screen w-full items-center justify-center"
          >
            <LoadingIcon className="w-12" />
          </motion.div>
        </div>
      )}
      {children}
    </SpinnerContext.Provider>
  );
}

export function StopGlobalLoadingSpinner(): null {
  const { stopLoading } = useContext(SpinnerContext);

  useEffect(() => {
    stopLoading();
  }, [stopLoading]);

  return null;
}
