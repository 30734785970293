import type { DocumentDto, ImageDto } from "api/types";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import type { Dispatch, SetStateAction } from "react";
import { useCallback } from "react";

export type FormImage = ImageDto | InputFile;

interface InputFile {
  file: File;
  url: string;
}

interface Props {
  maximumFiles?: number;
  onChange: Dispatch<SetStateAction<FormImage[]>>;
}

interface ImageInput {
  readonly addImage: (file: File) => void;
  readonly addImages: (files: FileList | null) => void;
  readonly replaceImage: (oldImage: FormImage, newFile: File) => void;
  readonly removeImage: (imageToRemove: FormImage) => void;
  readonly removeImages: () => void;
}

export function useImageInput({ maximumFiles = 1, onChange }: Props): ImageInput {
  const addImages = useCallback(
    (files: FileList | File[] | null) => {
      if (!files) {
        return;
      }

      const uploadedImages = Array.from(files)
        .slice(0, maximumFiles)
        .map((file) => ({
          url: URL.createObjectURL(file),
          file,
        }));

      onChange((oldImages = []) => {
        const newImages = [...oldImages, ...uploadedImages].filter((image) => Boolean(image));

        if (newImages.length > maximumFiles) {
          const removedImages = newImages.splice(0, newImages.length - maximumFiles);

          revokeImageUrlsIfRequired(removedImages);
        }

        return newImages;
      });
    },
    [maximumFiles, onChange],
  );

  const addImage = useCallback((file: File) => addImages([file]), [addImages]);

  const replaceImage = useCallback(
    (oldImage: FormImage, newFile: File) => {
      if (!newFile) {
        return;
      }

      onChange((oldImages) => {
        const newImages = [];

        for (const image of oldImages) {
          if (oldImage.url === image.url) {
            revokeImageUrlsIfRequired([image]);
            newImages.push({ url: URL.createObjectURL(newFile), file: newFile });
          } else {
            newImages.push(image);
          }
        }

        return newImages;
      });
    },
    [onChange],
  );

  const removeImage = useCallback(
    (imageToRemove: FormImage) => {
      onChange((oldImages) => {
        const newImages = [];

        for (const image of oldImages) {
          if (imageToRemove.url === image.url) {
            revokeImageUrlsIfRequired([image]);
          } else {
            newImages.push(image);
          }
        }

        return newImages;
      });
    },
    [onChange],
  );

  const removeImages = useCallback(
    () =>
      onChange((oldImages) => {
        revokeImageUrlsIfRequired(oldImages);

        return [];
      }),
    [onChange],
  );

  return { addImage, addImages, replaceImage, removeImage, removeImages } as const;
}

export function isDocumentUploaded(document?: FormDocument): document is DocumentDto {
  return document ? "id" in document : false;
}

export function isImageUploaded(image?: FormImage): image is ImageDto {
  return image ? "id" in image : false;
}

function revokeImageUrlsIfRequired(images: FormImage[]) {
  for (const image of images) {
    if (!isImageUploaded(image)) {
      URL.revokeObjectURL(image.url);
    }
  }
}
