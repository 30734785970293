import { useEffect, useMemo, useState } from "react";

export function useScreenIsBiggerThan(breakpoint: keyof typeof queries): boolean {
  const query = queries[breakpoint];
  const media = useMemo(() => window.matchMedia(query), [query]);
  const [matches, setMatches] = useState(() => media.matches);

  useEffect(() => {
    function listener() {
      setMatches(media.matches);
    }

    media.addEventListener("change", listener);

    return () => {
      media.removeEventListener("change", listener);
    };
  }, [media]);

  return matches;
}

const queries = {
  xs: "(min-width: 480px)",
  sm: "(min-width: 640px)",
  md: "(min-width: 768px)",
  lg: "(min-width: 1024px)",
  xl: "(min-width: 1280px)",
  "2xl": "(min-width: 1536px)",
} as const;
