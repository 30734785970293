import type { AdminTicketDetailsDto } from "api/types";
import iconMail01 from "assets/icons/mail-01.svg";
import iconPhone from "assets/icons/phone.svg";
import { Button } from "components/Button/Button";
import { Icon } from "components/Icon/Icon";
import { FlagIcon } from "components/Icons/Icons";
import { UserAvatarLink } from "components/UserAvatarLink/UserAvatarLink";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { formatAddress } from "helpers/address";
import { useLanguages } from "helpers/languages";
import { useSlug } from "hooks/useSlug";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

export function TicketResidentInfo({
  ticket,
  isGray,
}: {
  ticket: AdminTicketDetailsDto;
  isGray?: boolean;
}): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const { data: languages } = useLanguages();

  const language = languages?.find((x) => x.id === ticket.user.languageId);

  return (
    <div className={twJoin("flex flex-col gap-4 rounded-lg p-4", isGray ? "bg-grey-100" : "bg-white")}>
      <span className="text-left text-body-bold">{t("page.tickets.details.user.reporter")}</span>
      <div className="flex flex-row items-center gap-2">
        <div className="size-20 self-center">
          <UserAvatarLink user={ticket.user} />
        </div>
        <div className="flex flex-col">
          <UserNameLink user={ticket.user}>
            <span className="font-old-semibold">{ticket.user.fullName}</span>
          </UserNameLink>
          <div className="text-grey-700">
            {ticket.company ? <p>{ticket.company.name}</p> : null}
            {ticket.address ? <p>{formatAddress(ticket.address)}</p> : null}
            {ticket.address ? (
              <p>
                {ticket.address.building.name}
                {ticket.address.floor ? ` - ${ticket.address.floor}` : ""}
              </p>
            ) : null}
          </div>
        </div>
      </div>
      {ticket.user.canViewProfile ? (
        <Button
          type="link"
          styling="secondary"
          href={routes.users.details({ slug, id: ticket.user.id })}
          target="_blank"
        >
          {t("page.tickets.details.user.link")}
        </Button>
      ) : null}
      <div>
        <span className="text-caption-bold">{t("page.tickets.details.user.contact-details")}</span>
        <hr className="my-2 text-grey-300" />
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <span className="shrink-0">
              <FlagIcon languageId={ticket.user.languageId} />
            </span>
            {language ? <span className="break-all text-caption">{language.description}</span> : null}
          </div>
          <div className="flex items-center gap-2">
            <span className="shrink-0">
              <Icon name={iconMail01} className="text-grey-600" />
            </span>
            <span className="text-caption">{ticket.user.email || "-"}</span>
          </div>
          <div className="flex items-center gap-2">
            <span className="shrink-0">
              <Icon name={iconPhone} className="text-grey-600" />
            </span>
            <span className="text-caption">{ticket.user.phoneNumber || "-"}</span>
          </div>
        </div>
      </div>
      {ticket.address?.typeConstraint ? (
        <div>
          <span className="text-caption-bold">{t("page.tickets.details.user.address-type")}</span>
          <hr className="my-2 text-grey-300" />
          <span className="text-caption">{ticket.address?.typeConstraint?.key}</span>
        </div>
      ) : null}
    </div>
  );
}
