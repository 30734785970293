import type {
  BookableAssetCreateRequest,
  BookableAssetDetailDto,
  BookableAssetUpdateRequest,
  BookableDayDto,
  LanguageDto,
} from "api/types";
import { parseISO } from "date-fns";
import { createFormTranslations } from "helpers/languages";
import { isDefined, parseAsNumber } from "helpers/util";
import type { BookableDay, Specification } from "modules/bookings/constants";
import {
  daysOptions,
  defaultAmountSeats,
  defaultBookableDayEndTime,
  defaultBookableDayStartTime,
} from "modules/bookings/constants";
import { getMinutesOfDayFromTime, getTimeFromMinutesOfDay } from "modules/bookings/helpers";
import { toTranslationsRequest } from "modules/bookings/translations";

import type { CreateOrEditAssetFormValues } from "./Layout";
import type { AssetObserver } from "./Loader";

export const getDefaultFormValues = (
  languages: LanguageDto[],
  assetObservers: AssetObserver[],
  userEmail: string,
  assetDetails?: BookableAssetDetailDto,
): CreateOrEditAssetFormValues => {
  return {
    audience: assetDetails?.audience || [],
    assetObserver: assetDetails?.projectConnection
      ? assetObservers.find((p) => p.id === assetDetails.projectConnection?.id)!
      : assetObservers[0],
    nameTranslations: createFormTranslations({
      languages,
      translations: assetDetails?.translations,
      translationKey: "name",
    }),
    descriptionTranslations: createFormTranslations({
      languages,
      translations: assetDetails?.translations,
      translationKey: "description",
    }),
    images: assetDetails?.images ?? [],
    specifications: mapAvailableSpecifications({
      sizeSpecification: assetDetails?.sizeSpecification,
      pricePerHourSpecification: assetDetails?.pricePerHourSpecification,
      capacitySpecification: assetDetails?.capacitySpecification,
      locationSpecification: assetDetails?.locationSpecification,
    }),
    sizeSpecification: assetDetails?.sizeSpecification,
    pricePerHourSpecification: assetDetails?.pricePerHourSpecification?.toString(),
    capacitySpecification: assetDetails?.capacitySpecification?.toString(),
    locationSpecification: assetDetails?.locationSpecification,
    regulationsApplicable: !!assetDetails?.regulationDocument,
    regulationDocument: assetDetails?.regulationDocument ? [assetDetails.regulationDocument] : [],
    bookableDays: mapBookableDays(assetDetails?.bookableDays || []),
    timeslot: assetDetails?.timeslot,
    canBookMultipleSlots:
      assetDetails?.seats && assetDetails.seats > 1 ? false : assetDetails?.canBookMultipleSlots ?? true,
    notificationEnabled: assetDetails?.notificationEnabled ?? false,
    notificationEmail: assetDetails?.notificationEmail ?? userEmail,
    canBookSameDay: assetDetails?.canBookSameDay ?? true,
    maxDaysInAdvance: assetDetails?.maxDaysInAdvance?.toString(),
    hoursForMutations: assetDetails?.hoursForMutations?.toString(),
    requireBookingReason: assetDetails?.requireBookingReason ?? false,
    publishAt: assetDetails?.publishAt ? parseISO(assetDetails?.publishAt) : "",
    unpublishAt: assetDetails?.unpublishAt ? parseISO(assetDetails?.unpublishAt) : "",
    availableFrom: assetDetails?.availableFrom ? parseISO(assetDetails?.availableFrom) : "",
    seats: assetDetails?.seats || defaultAmountSeats,
  };
};

const mapAvailableSpecifications = ({
  sizeSpecification,
  pricePerHourSpecification,
  capacitySpecification,
  locationSpecification,
}: {
  sizeSpecification?: string;
  pricePerHourSpecification?: number;
  capacitySpecification?: number;
  locationSpecification?: string;
}) => {
  const specifications: Specification[] = [];
  if (sizeSpecification) {
    specifications.push("sizeSpecification");
  }
  if (pricePerHourSpecification) {
    specifications.push("pricePerHourSpecification");
  }
  if (capacitySpecification) {
    specifications.push("capacitySpecification");
  }
  if (locationSpecification) {
    specifications.push("locationSpecification");
  }

  return specifications;
};

const mapBookableDays = (bookableDays: BookableAssetDetailDto["bookableDays"]): BookableDay[] => {
  if (bookableDays.length === 0) {
    return daysOptions.map((d) => ({
      day: d,
      allDay: false,
      enabled: true,
      times: [
        {
          startTime: getMinutesOfDayFromTime(defaultBookableDayStartTime, "start"),
          endTime: getMinutesOfDayFromTime(defaultBookableDayEndTime, "end"),
        },
      ],
    }));
  }

  return bookableDays
    .sort((bookableDayA, bookableDayB) => daysOptions.indexOf(bookableDayA.day) - daysOptions.indexOf(bookableDayB.day))
    .map((currBookableDay) => {
      const bookableTimes = currBookableDay.times
        ? currBookableDay.times.map((currBookableTime) => {
            return {
              startTime: getMinutesOfDayFromTime(currBookableTime.startTime, "start"),
              endTime: getMinutesOfDayFromTime(currBookableTime.endTime, "end"),
            };
          })
        : [];

      return {
        ...currBookableDay,
        times: bookableTimes,
      };
    });
};

export const getBookableAssetRequest = (
  languages: LanguageDto[],
  images: string[],
  documentId: string | undefined,
  values: CreateOrEditAssetFormValues,
): BookableAssetCreateRequest | BookableAssetUpdateRequest => {
  return {
    audience: values.audience,
    translations: toTranslationsRequest(languages, values.nameTranslations, values.descriptionTranslations),
    images,
    sizeSpecification: values.sizeSpecification,
    pricePerHourSpecification: parseAsNumber(values.pricePerHourSpecification),
    capacitySpecification: parseAsNumber(values.capacitySpecification),
    locationSpecification: values.locationSpecification,
    regulationDocumentId: values.regulationsApplicable ? documentId : undefined,
    bookableDays: formatBookableDaysPayload(values.bookableDays),
    timeslot: values.timeslot || "allDay",
    canBookMultipleSlots: values.canBookMultipleSlots,
    notificationEnabled: values.notificationEnabled,
    notificationEmail: values.notificationEnabled ? values.notificationEmail : undefined,
    canBookSameDay: values.canBookSameDay,
    maxDaysInAdvance: parseAsNumber(values.maxDaysInAdvance),
    hoursForMutations: parseAsNumber(values.hoursForMutations),
    requireBookingReason: values.requireBookingReason,
    publishAt: values.publishAt && values.publishAt > new Date() ? values.publishAt.toISOString() : undefined,
    availableFrom: values.availableFrom ? values.availableFrom.toISOString() : undefined,
    unpublishAt: values.unpublishAt ? values.unpublishAt.toISOString() : undefined,
    seats: values.seats || defaultAmountSeats,
    enabled: true,
  };
};

export const formatBookableDaysPayload = (bookableDays: BookableDay[]): BookableDayDto[] => {
  return [...bookableDays].map((currBookableDay) => {
    const currBookableTimes = currBookableDay.times.filter(
      (currBookableTime) => isDefined(currBookableTime.startTime) && isDefined(currBookableTime.endTime),
    ) as { startTime: number; endTime: number }[];

    return {
      ...currBookableDay,
      times: currBookableTimes.map((currBookableTime) => ({
        startTime: getTimeFromMinutesOfDay(currBookableTime.startTime),
        endTime: getTimeFromMinutesOfDay(currBookableTime.endTime),
      })),
    };
  });
};
