import { useEffect } from "react";

export function useOnPaste(listener: (data: string) => void, when = true): void {
  useEffect(() => {
    function paste(event: ClipboardEvent) {
      if (!when) {
        return;
      }

      event.preventDefault();

      const clipboardContent = event.clipboardData?.getData?.("text")?.trim();
      if (clipboardContent) {
        listener(clipboardContent);
      }
    }

    if (when) {
      window.addEventListener("paste", paste as EventListener);

      return () => {
        return window.removeEventListener("paste", paste as EventListener);
      };
    }
  }, [listener, when]);
}
