import type { AddressDto, CompanyDto } from "api/types";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormInput } from "components/Form/FormInput";
import { FormSelect } from "components/Form/FormSelect";
import { FormTextArea } from "components/Form/FormTextArea";
import type { FormImage } from "components/ImageInput/useImageInput";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { formatAddress } from "helpers/address";
import { validateSize } from "helpers/file-size";
import { createRequiredStringRule } from "helpers/rules";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface LayoutProps {
  isEditMode: boolean;
  defaultValues?: Partial<FormValues>;
  isSubmitting: boolean;
  existingCompanies: CompanyDto[];
  addresses: AddressDto[];
  onSubmit: (data: FormValues) => void;
}

export function Layout({
  addresses,
  existingCompanies,
  isEditMode,
  defaultValues,
  isSubmitting,
  onSubmit,
}: LayoutProps): React.ReactNode {
  const formMethods = useForm<FormValues>({ defaultValues });
  const { t } = useTranslation();

  return (
    <DocumentPaper
      theme="constrained"
      title={isEditMode ? t("page.companies.edit.title") : t("page.companies.new.title")}
    >
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <CreateEditForm
          isEditMode={isEditMode}
          isSubmitting={isSubmitting}
          existingCompanies={existingCompanies}
          addresses={addresses}
        />
      </Form>
    </DocumentPaper>
  );
}

function CreateEditForm({
  isEditMode,
  isSubmitting,
  addresses,
  existingCompanies,
}: {
  isEditMode: boolean;
  isSubmitting: boolean;
  addresses: LayoutProps["addresses"];
  existingCompanies: LayoutProps["existingCompanies"];
}) {
  const { t } = useTranslation();

  const existingCompanyNames = new Set(existingCompanies.map((x) => x.name.toString().trim().toLowerCase()));

  return (
    <FormContent>
      <FormField label={t("page.companies.form.name")} required htmlFor="name">
        <FormInput<FormValues, "name">
          id="name"
          name="name"
          rules={{
            validate: {
              required: createRequiredStringRule(t, "page.companies.form.name"),
              isDuplicate(value: string) {
                if (existingCompanyNames.has(value.trim().toLowerCase())) {
                  return t("page.companies.form.name.error.duplicate");
                }
              },
            },
          }}
        />
      </FormField>
      <FormField label={t("page.companies.form.description")} htmlFor="description">
        <FormTextArea id="description" name="description" data-testid="company-description" />
      </FormField>
      <FormField label={t("page.companies.form.address")} required>
        <FormSelect<FormValues, AddressDto>
          name="address"
          data-testid="company-address"
          items={addresses}
          placeholder={t("page.companies.form.address.placeholder")}
          keySelector={(x) => x.id || "undefined"}
          renderOption={(x) => formatAddress(x)}
          groupSelector={(x) => x.building.name}
          rules={{
            required: t("components.form.error.required", {
              inputName: t("page.companies.form.address"),
            }),
          }}
        />
      </FormField>
      <FormField htmlFor="logo" label={t("page.companies.form.logo")}>
        <FormImageInput<FormValues, "logo">
          name="logo"
          id="logo"
          data-testid="company-logo"
          rules={{
            validate: {
              size(image) {
                if (image) {
                  return validateSize(t, image);
                }
              },
            },
          }}
        />
      </FormField>
      <FormField htmlFor="background" label={t("page.companies.form.background")}>
        <FormImageInput<FormValues, "background">
          name="background"
          id="background"
          rules={{
            validate: {
              size(image) {
                if (image) {
                  return validateSize(t, image);
                }
              },
            },
          }}
        />
      </FormField>

      <Button type="submit" isLoading={isSubmitting}>
        {isEditMode ? t("page.companies.edit.submit") : t("page.companies.new.submit")}
      </Button>
    </FormContent>
  );
}

interface FormValues {
  name: string;
  description?: string;
  logo: FormImage[];
  background: FormImage[];
  address: AddressDto;
}
