import type { AssetBookingDto, BookableAssetDetailDto, BookableAssetStatisticsDto, BookableSlotDto } from "api/types";
import iconDownload01 from "assets/icons/download-01.svg";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { InfoIcon } from "components/InfoIcon/InfoIcon";
import { PageGrid } from "components/PageGrid/PageGrid";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { addDays, format, isEqual, parse, parseISO, startOfMonth, startOfWeek } from "date-fns";
import { dayOfWeekIndex } from "helpers/date";
import { useBool } from "hooks/useBool";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { BookingDetailModal } from "modules/bookings/components/BookingDetailModal";
import { CalendarMonthView } from "modules/bookings/components/CalendarMonthView";
import { CalendarWeekView } from "modules/bookings/components/CalendarWeekView";
import { DeclineBookingModal } from "modules/bookings/components/DeclineBookingModal";
import { daysOptions } from "modules/bookings/constants";
import { getDateFromTimeString, isTimeEndOfDay } from "modules/bookings/helpers";
import { canManageAnyBookableAsset } from "modules/bookings/permissions";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

import { ExportBookingsModal } from "../ListAssets/components/ExportBookingsModal";

export interface LayoutProps {
  assetDetails: BookableAssetDetailDto;
  isLoadingBookings: boolean;
  bookings: AssetBookingDto[];
  isLoadingAssetStatistics: boolean;
  assetStatistics: BookableAssetStatisticsDto | undefined;
  startDate: Date;
  endDate: Date;
  view: "week" | "month";
  onChangeView: (view: "week" | "month", startDate: Date) => void;
  exportBookings: (includeHistory: boolean) => void;
  isExporting: boolean;
}

export function Layout({
  assetDetails,
  isLoadingBookings,
  bookings,
  isLoadingAssetStatistics,
  assetStatistics,
  startDate,
  endDate,
  view,
  onChangeView,
  exportBookings,
  isExporting,
}: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const hasPermission = usePermission();

  const [isExportModalOpened, exportModalOpenHandler] = useBool(false);
  const [activeBooking, setActiveBooking] = useState<AssetBookingDto | undefined>(undefined);
  const [isDeclineModalOpened, declineModalOpenHandlers] = useBool(false);
  const [isDetailModalOpened, detailModalOpenHandlers] = useBool(false);

  const getLastContinuousBooking = useCallback(
    (bookings: AssetBookingDto[], curr: AssetBookingDto): AssetBookingDto => {
      const helperDate = new Date();
      const nextBooking = bookings.find((booking) =>
        isEqual(parse(booking.startTime, "HH:mm:ss", helperDate), parse(curr.endTime, "HH:mm:ss", helperDate)),
      );

      if (nextBooking) {
        return getLastContinuousBooking(bookings, nextBooking);
      }

      return curr;
    },
    [],
  );

  const assetStatus: { status: BookableSlotDto["state"]; until?: Date } = useMemo(() => {
    const currDate = new Date();
    const weekday = daysOptions[dayOfWeekIndex(currDate)];
    const currBookableDay = assetDetails.bookableDays.find((day) => day.day === weekday)!;

    if (!currBookableDay.enabled || !currBookableDay.times || currBookableDay.times.length === 0) {
      return {
        status: "unavailable",
      };
    }

    // The bookable time current timestamp is in
    const currBookableTime = currBookableDay.times.find(
      (time) =>
        getDateFromTimeString(time.startTime) <= currDate &&
        getDateFromTimeString(time.endTime, isTimeEndOfDay(time.endTime) ? addDays(currDate, 1) : currDate) >= currDate,
    );

    const isNotPublished = assetDetails.publishAt && parseISO(assetDetails.publishAt) > currDate;
    const isNotAvailable = assetDetails.availableFrom && parseISO(assetDetails.availableFrom) > currDate;
    const isUnpublished = assetDetails.unpublishAt && parseISO(assetDetails.unpublishAt) < currDate;
    const isWithinBookableTime = !!currBookableTime;

    if (isNotPublished || isNotAvailable || isUnpublished || !isWithinBookableTime) {
      return {
        status: "unavailable",
      };
    }

    const todayBookings = bookings.filter((booking) => booking.date === format(currDate, "yyyy-MM-dd"));
    const presentBooking = todayBookings.find(
      (booking) =>
        getDateFromTimeString(booking.startTime) <= currDate && getDateFromTimeString(booking.endTime) >= currDate,
    );
    if (presentBooking) {
      const lastContinuousBooking = getLastContinuousBooking(todayBookings, presentBooking);

      return {
        status: "booked",
        until: getDateFromTimeString(lastContinuousBooking.endTime),
      };
    }

    const nextBooking = todayBookings.find((booking) => getDateFromTimeString(booking.startTime) > currDate);
    // If currDate is within a bookable time and there's no booking before the bookable time ends
    if (currBookableTime && (!nextBooking || nextBooking.startTime > currBookableTime.endTime)) {
      return {
        status: "available",
        until: getDateFromTimeString(currBookableTime.endTime),
      };
    }

    return {
      status: "available",
      until: nextBooking ? getDateFromTimeString(nextBooking.startTime) : undefined,
    };
  }, [assetDetails, bookings, getLastContinuousBooking]);

  const onClickBooking = (booking: AssetBookingDto) => {
    setActiveBooking(booking);
    detailModalOpenHandlers.setTrue();
  };

  return (
    <DocumentPaper
      title={assetDetails.name}
      subTitle={
        <Breadcrumbs
          pages={[
            {
              name: t("page.bookings.list-assets.title"),
              to: routes.bookings.list({ slug }),
            },
            {
              name: assetDetails.name,
            },
          ]}
        />
      }
      theme="minimal"
      actions={
        <div className="flex flex-col items-center gap-2 md:flex-row">
          {hasPermission(canManageAnyBookableAsset) && (
            <Button
              icon={<Icon name={iconDownload01} />}
              styling="secondary"
              onClick={exportModalOpenHandler.setTrue}
              isLoading={isExporting}
            >
              {t("page.bookings.asset-detail.actions.export")}
            </Button>
          )}
          <Button type="link" href={routes.bookings.bookAsset({ slug, aid: assetDetails.id })} styling="primary">
            {t("page.bookings.asset-detail.actions.book")}
          </Button>
        </div>
      }
    >
      <div className="flex flex-col gap-2">
        <PageGrid.Grid>
          <PageGrid.Item size="33%">
            <AssetAvailabilityCard status={assetStatus.status} until={assetStatus.until} />
          </PageGrid.Item>
          <PageGrid.Item size="33%">
            <AssetLastRenterCard
              booking={assetStatistics?.mostRecentBooking}
              isLoading={isLoadingAssetStatistics}
              onViewDetails={(booking) => {
                setActiveBooking(booking);
                detailModalOpenHandlers.setTrue();
              }}
            />
          </PageGrid.Item>
        </PageGrid.Grid>
        <div className="flex flex-col gap-4 bg-white px-5 py-4 xs:rounded-lg">
          <h2 className="text-headline3 leading-old-headline4">{t("page.bookings.asset-detail.calendar.title")}</h2>
          {view === "week" ? (
            <CalendarWeekView
              assetDetails={assetDetails}
              bookings={bookings}
              startOfWeek={startDate}
              endOfWeek={endDate}
              onChangeWeek={(date) => onChangeView("week", date)}
              onChangeView={() => onChangeView("month", startOfMonth(new Date()))}
              isLoadingBookings={isLoadingBookings}
              onSelectBooking={(booking) => {
                setActiveBooking(booking);
                detailModalOpenHandlers.setTrue();
              }}
            />
          ) : (
            <CalendarMonthView
              assetDetails={assetDetails}
              bookings={bookings}
              endDate={endDate}
              startDate={startDate}
              onChangeMonth={(date) => onChangeView("month", date)}
              onChangeView={(date) => onChangeView("week", startOfWeek(date || new Date(), { weekStartsOn: 1 }))}
              onSelectBooking={onClickBooking}
              onOpenCancelModal={(booking) => {
                setActiveBooking(booking);
                declineModalOpenHandlers.setTrue();
              }}
            />
          )}
        </div>
      </div>
      {activeBooking && (
        <>
          <BookingDetailModal
            isOpened={isDetailModalOpened}
            onOpenChange={(state) => {
              if (!state) {
                setActiveBooking(undefined);
              }

              detailModalOpenHandlers.set(state);
            }}
            booking={activeBooking}
            onDeleteBooking={() => {
              detailModalOpenHandlers.setFalse();
              declineModalOpenHandlers.setTrue();
            }}
          />
          <DeclineBookingModal
            bookingId={activeBooking.id}
            assetId={activeBooking.asset.id}
            isOpened={isDeclineModalOpened}
            onOpenChange={(state) => {
              if (!state) {
                setActiveBooking(undefined);
              }

              declineModalOpenHandlers.set(state);
            }}
          />
        </>
      )}
      <ExportBookingsModal
        onExport={exportBookings}
        isOpened={isExportModalOpened}
        onOpenChange={exportModalOpenHandler.set}
      />
    </DocumentPaper>
  );
}

function AssetInsightsCard({
  title,
  tooltip,
  children,
}: {
  title: string;
  tooltip: string;
  children: React.ReactNode;
}) {
  return (
    <div className="flex h-full flex-col gap-2 rounded-lg bg-white p-4">
      <div className="flex justify-between">
        <span className="text-body-bold">{title}</span>
        <InfoIcon tooltip={tooltip} />
      </div>
      {children}
    </div>
  );
}

function AssetAvailabilityCard({ status, until }: { status: BookableSlotDto["state"]; until?: Date }) {
  const { t } = useTranslation();

  return (
    <AssetInsightsCard
      title={t("page.bookings.asset-detail.insights.status.title")}
      tooltip={t("page.bookings.asset-detail.insights.status.tooltip")}
    >
      <div
        className={twJoin(
          "flex w-full justify-center rounded-full p-2",
          // eslint-disable-next-line no-nested-ternary
          status === "unavailable"
            ? "bg-grey-100 text-grey-500"
            : status === "booked"
              ? "bg-red-100 text-red-600"
              : "bg-green-100 text-green-700",
        )}
      >
        {
          // eslint-disable-next-line no-nested-ternary
          status === "unavailable" ? (
            <span className="text-caption-bold">
              {t("page.bookings.asset-detail.insights.status.option.unavailable")}
            </span>
          ) : status === "booked" ? (
            <span className="text-caption-bold">
              {t("page.bookings.asset-detail.insights.status.option.occupied", {
                time: format(until!, "HH:mm"),
              })}
            </span>
          ) : (
            <span className="text-caption-bold">
              {until
                ? t("page.bookings.asset-detail.insights.status.option.available-until", {
                    time: format(until, "HH:mm"),
                  })
                : t("page.bookings.asset-detail.insights.status.option.available-all-day")}
            </span>
          )
        }
      </div>
    </AssetInsightsCard>
  );
}

function AssetLastRenterCard({
  booking,
  isLoading,
  onViewDetails,
}: {
  booking?: AssetBookingDto;
  isLoading: boolean;
  onViewDetails: (booking: AssetBookingDto) => void;
}) {
  const { t } = useTranslation();

  return (
    <AssetInsightsCard
      title={t("page.bookings.asset-detail.insights.last-renter.title")}
      tooltip={t("page.bookings.asset-detail.insights.last-renter.tooltip")}
    >
      {
        // eslint-disable-next-line no-nested-ternary
        isLoading ? (
          <LoadingIcon className="size-4" />
        ) : booking && booking.author ? (
          <div className="flex cursor-pointer items-center gap-2">
            <div className="size-8" onClick={() => onViewDetails(booking)}>
              <UserAvatar img={booking.author.avatar} isUserDeleted={!!booking.author.deletedAt} />
            </div>
            <Button styling="tertiary" onClick={() => onViewDetails(booking)}>
              {booking.author.fullName}
            </Button>
          </div>
        ) : (
          <span className="text-caption-bold">
            {t("page.bookings.asset-detail.insights.last-renter.not-available")}
          </span>
        )
      }
    </AssetInsightsCard>
  );
}
