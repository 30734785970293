import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { EditTicketCommentRequest, ResponsibilitySuggestionRatingRequest, TicketCommentRequest } from "api/types";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import type { ApiResponseType } from "types/api-types";

const useAddComment = (ticketId: string) => {
  const api = useApi();
  const projectId = useProjectId();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (payload: { data: TicketCommentRequest; fetchAllActivities?: boolean }) =>
      api.postTicketsCommentsV1(ticketId, payload.data),
    onSuccess() {
      queryClient.setQueryData(
        QUERY_KEYS.TICKETS_DETAILS(projectId, ticketId),
        (oldData: ApiResponseType<"getAdminTicketsDetailsV1"> | undefined) => {
          if (!oldData) {
            return;
          }

          return {
            ...oldData,
            data: {
              ...oldData.data,
              activityCount: (oldData.data.activityCount || 0) + 1,
            },
          };
        },
      );
      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.TICKETS_DETAILS_ACTIVITIES(projectId, ticketId),
      });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_DETAILS(projectId, ticketId) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });
    },
    onError(_, payload) {
      showFlashToast({
        type: "error",
        title:
          payload.data.accessType === "internal"
            ? t("page.tickets.details.note.error")
            : t("page.tickets.details.reply.error"),
      });

      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_DETAILS(projectId, ticketId) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });
    },
  });
};

const useUpdateComment = (ticketId: string) => {
  const api = useApi();
  const projectId = useProjectId();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();

  return useMutation({
    mutationFn: (payload: { commentId: string; data: EditTicketCommentRequest }) =>
      api.putTicketsCommentsV1(ticketId, payload.commentId, payload.data),
    onSuccess() {
      showFlashToast({ type: "success", title: t("page.tickets.details.edit-note.success") });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_DETAILS(projectId, ticketId) });
    },
    onError() {
      showFlashToast({ type: "success", title: t("page.tickets.details.edit-note.error") });
    },
  });
};

const useUpdateAssignee = (ticketId: string) => {
  const api = useApi();
  const projectId = useProjectId();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();

  return useMutation({
    mutationFn: (payload: { assigneeId: string; note?: string }) => api.putAdminTicketsAssigneeV1(ticketId, payload),
    onMutate(payload) {
      // Optimistic update
      queryClient.setQueryData(
        QUERY_KEYS.TICKETS_DETAILS(projectId, ticketId),
        (oldData: ApiResponseType<"getAdminTicketsDetailsV1"> | undefined) => {
          if (!oldData) {
            return;
          }

          return {
            ...oldData,
            data: {
              ...oldData.data,
              assignee: oldData.data.possibleAssignees.find((x) => x.id === payload.assigneeId)!,
            },
          };
        },
      );
    },
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_DETAILS(projectId, ticketId) });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });
    },
    async onError() {
      showFlashToast({
        type: "error",
        title: t("page.tickets.details.assignee-change.error"),
      });

      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_DETAILS(projectId, ticketId) });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });
    },
  });
};

const useUpdateStatus = (ticketId: string) => {
  const api = useApi();
  const projectId = useProjectId();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();

  return useMutation({
    mutationFn: (payload: { statusId: string; silent?: boolean }) => api.putAdminTicketsStatusV1(ticketId, payload),
    onMutate(payload) {
      // Optimistic update
      queryClient.setQueryData(
        QUERY_KEYS.TICKETS_DETAILS(projectId, ticketId),
        (oldData: ApiResponseType<"getAdminTicketsDetailsV1"> | undefined) => {
          if (!oldData) {
            return;
          }

          return {
            ...oldData,
            data: {
              ...oldData.data,
              status: oldData.data.possibleStatuses.find((x) => x.id === payload.statusId)!,
            },
          };
        },
      );
    },
    onSuccess() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_DETAILS(projectId, ticketId) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });
    },
    onError() {
      showFlashToast({
        type: "error",
        title: t("page.tickets.details.status-change.error"),
      });

      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_DETAILS(projectId, ticketId) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });
    },
  });
};

const useUpdateResponsibilitySuggestion = (ticketId: string) => {
  const api = useApi();
  const projectId = useProjectId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ResponsibilitySuggestionRatingRequest) =>
      api.postAdminTicketsResponsibilitySuggestionV1(ticketId, data),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.TICKETS_RESPONSIBILITY_SUGGESTION(projectId, ticketId),
      });
    },
  });
};

const useGetTicketCopilotSuggestion = (ticketId: string) => {
  const api = useApi();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (query: { additionalInfo: string } | undefined) =>
      api.postTicketsSuggestionV1(ticketId, query).then((res) => res.data),
    onError: () => {
      showFlashToast({ type: "error", title: t("page.tickets.details.copilot.generate.error") });
    },
  });
};

export const ticketMutations = {
  useAddComment,
  useUpdateComment,
  useUpdateAssignee,
  useUpdateStatus,
  useUpdateResponsibilitySuggestion,
  useGetTicketCopilotSuggestion,
};
