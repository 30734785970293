import type { BookableAssetDto } from "api/types";
import iconUsersCheck from "assets/icons/users-check.svg";
import { Button } from "components/Button/Button";
import { Card, CardActions, CardContent, CardDescription, CardHeader, CardImage } from "components/Card/Card";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { isDefined } from "helpers/util";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { Specification } from "modules/bookings/components/Specification";
import { canViewSchedule } from "modules/bookings/permissions";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

interface BookableAssetCardProps {
  asset: BookableAssetDto;
  "data-testid"?: string;
  onEdit: () => void;
  onDelete: () => void;
}

export function BookableAssetCard({
  asset,
  "data-testid": dataTestId,
  onEdit,
  onDelete,
}: BookableAssetCardProps): React.ReactNode {
  const slug = useSlug();
  const { i18n, t } = useTranslation();
  const sessionUser = useSessionUser();
  const hasPermission = usePermission();

  const assetDetailPath = routes.bookings.assetDetail({ slug, id: asset.id });
  const bookAssetPath = routes.bookings.bookAsset({ slug, aid: asset.id });
  const publishDate = asset.publishAt ? new Date(asset.publishAt) : undefined;
  const unpublishDate = asset.unpublishAt ? new Date(asset.unpublishAt) : undefined;
  const actions: ContextMenuAction[] = useMemo(() => {
    const results: ContextMenuAction[] = [];

    if (asset.canEdit) {
      results.push({
        dataTestId: "context-menu-edit-btn",
        text: t("common.action.edit"),
        callback: onEdit,
      });
    }

    if (asset.canDelete) {
      results.push({
        dataTestId: "context-menu-delete-btn",
        text: t("common.action.delete"),
        callback: onDelete,
      });
    }

    return results;
  }, [asset.canEdit, asset.canDelete, onEdit, onDelete, t]);

  return (
    <Card data-testid={dataTestId} link={hasPermission(canViewSchedule) ? assetDetailPath : bookAssetPath}>
      <CardImage
        image={asset.images[0]}
        labels={[
          publishDate && publishDate > new Date() && (
            <Label theme="blue">
              {t("page.bookings.list-assets.asset.publish-date", {
                date: formatDate(i18n, "datetimeShort", publishDate),
              })}
            </Label>
          ),
          unpublishDate && (
            <Label theme="blue">
              {unpublishDate > new Date()
                ? t("page.bookings.list-assets.asset.unpublish-date", {
                    date: formatDate(i18n, "datetimeShort", unpublishDate),
                  })
                : t("page.bookings.list-assets.asset.unpublished")}
            </Label>
          ),
          asset.hasAudience && sessionUser.isAdmin && (
            <Label theme="blue">
              <Icon size={16} name={iconUsersCheck} />
            </Label>
          ),
        ].filter(isDefined)}
      />
      <CardContent>
        <CardHeader
          title={asset.name}
          titleLink={hasPermission(canViewSchedule) ? assetDetailPath : bookAssetPath}
          menuActions={actions}
        />

        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <Specification specification="sizeSpecification" value={asset.sizeSpecification} />
            <Specification specification="pricePerHourSpecification" value={asset.pricePerHourSpecification} />
            <Specification specification="capacitySpecification" value={asset.capacitySpecification} />
          </div>
          <Specification specification="locationSpecification" value={asset.locationSpecification} />
        </div>

        <CardDescription description={asset.description} />

        <CardActions>
          <Button type="link" data-testid="book-asset-btn" href={bookAssetPath} styling="primary">
            <span className="text-body-bold">{t("page.bookings.list-assets.asset.book")}</span>
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}
