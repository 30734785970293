import type { LanguageDto } from "api/types";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormHiddenInput } from "components/Form/FormHiddenInput";
import { FormInput } from "components/Form/FormInput";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { createRequiredStringRule } from "helpers/rules";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { CreateOrEditFormFields } from "./";

export interface LayoutProps {
  onSubmit: (data: CreateOrEditFormFields) => void;
  languages: LanguageDto[];
  defaultFormValues: CreateOrEditFormFields;
  isEditMode: boolean;
  isSubmitting: boolean;
}

export function Layout(props: LayoutProps): React.ReactNode {
  const { defaultFormValues } = props;
  const { t } = useTranslation();
  const formMethods = useForm<CreateOrEditFormFields>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  });

  return (
    <DocumentPaper
      theme="constrained"
      title={
        props.isEditMode
          ? t("page.document.folder.create-or-edit.edit-mode.title")
          : t("page.document.folder.create-or-edit.create-mode.title")
      }
    >
      <Form formMethods={formMethods} onSubmit={props.onSubmit}>
        <FormContent>
          {props.languages.map((lng) => {
            return (
              <FormField
                key={lng.id}
                htmlFor={`name-translations-${lng.id}`}
                label={`${t("model.document.folder.name")} (${lng.poEditorCode})`}
                required
              >
                <FormInput<CreateOrEditFormFields>
                  data-testid="documents-folder-name"
                  name={`nameTranslations.${lng.id}`}
                  id={`name-translations-${lng.id}`}
                  rules={{
                    validate: {
                      required: createRequiredStringRule(t, "model.document.folder.name"),
                    },
                    maxLength: {
                      message: t("components.form.error.max-length", {
                        length: NAME_MAX_LENGTH,
                      }),
                      value: NAME_MAX_LENGTH,
                    },
                  }}
                />
              </FormField>
            );
          })}
          <FormHiddenInput<CreateOrEditFormFields> name="parentFolderId" />
        </FormContent>
        <Button type="submit" isLoading={props.isSubmitting} data-testid="documents-folder-submit">
          {props.isEditMode ? t("common.action.edit") : t("common.action.create")}
        </Button>
      </Form>
    </DocumentPaper>
  );
}

const NAME_MAX_LENGTH = 40;
