import { useBool } from "hooks/useBool";
import type { MutableRefObject } from "react";
import { useEffect } from "react";
import { useRef } from "react";

// Used to detect whether an element has entered the viewport (once)
export const useViewportCollision = (ref: MutableRefObject<HTMLElement | null>): boolean => {
  const [isInViewport, inViewportHandlers] = useBool(false);
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    observer.current = new IntersectionObserver(([entry]) => {
      // Ignore entry going out of viewport
      if (!entry.isIntersecting) return;

      inViewportHandlers.set(entry.isIntersecting);
    });

    observer.current.observe(ref.current);

    return () => {
      observer.current?.disconnect();
    };
  }, [inViewportHandlers, ref]);

  return isInViewport;
};
