import type { QuickReplyRepairRequestStatusDto } from "api/types";
import { sortBy } from "lodash-es";

const statusValueOrder = ["new", "inProgress", "closed", "rated", "rejected", "autoClosed"];

export function sortStatuses(
  statuses: QuickReplyRepairRequestStatusDto[] | undefined,
): QuickReplyRepairRequestStatusDto[] {
  if (!statuses) {
    return [];
  }

  return sortBy(
    statuses,
    (s) => (s.defaultStatusId ? statusValueOrder.indexOf(s.defaultStatusId) : Infinity),
    (s) => (s.isDefault ? 0 : 1),
  );
}
