import type { SelfDto } from "api/types";

import { useProjectId } from "./useProjectId";
import type { SessionUserInitialOptions } from "./useSessionUserInitial";
import { useSessionUserInitial } from "./useSessionUserInitial";

export function useSessionUser(): SelfDto {
  const projectId = useProjectId();
  const { data: user } = useSessionUserInitial(projectId);

  if (!user) {
    throw new Error(`Session user has not been fetched yet. Please use 'useSessionUserInitial' instead.`);
  }

  return user;
}

export function useSessionUserOptional(
  options?: SessionUserInitialOptions,
): [SelfDto | undefined, ReturnType<typeof useSessionUserInitial>] {
  const projectId = useProjectId();
  const query = useSessionUserInitial(projectId, options);

  return [query.data, query];
}
