import { twJoin } from "tailwind-merge";
import type { FlyoutProps } from "victory";

interface Props<T> extends FlyoutProps {
  children: (data: T, index: number | undefined) => React.ReactNode;
  containerWidth: number;
  offsetY: number;
  offsetX: number;
  // Index of the group, if topIndexes is supplied
  groupIndex?: number;
  // List of the items with the highest values if the data is provided with multiple points
  topIndexes?: number[];
}

export function HoverLabel<T = string>({
  x = 0,
  y = 0,
  datum,
  children,
  offsetX,
  offsetY,
  containerWidth,
  index,
  groupIndex,
  topIndexes,
}: Props<T>): React.ReactNode {
  if (isNaN(x)) {
    return null;
  }

  if (topIndexes != null && topIndexes[index || 0] !== groupIndex) {
    return null;
  }

  const shouldShowLeft = x > containerWidth / 2;

  return (
    <foreignObject
      x={x + offsetX * (shouldShowLeft ? -1 : 1)}
      y={y + offsetY}
      width={400}
      height={200}
      style={{ overflow: "visible" }}
    >
      <div
        className={twJoin(
          "relative inline-block rounded-2px bg-grey-900 p-2 text-caption text-white",
          shouldShowLeft ? "-translate-x-full" : undefined,
        )}
      >
        {children(datum as any, index)}
      </div>
    </foreignObject>
  );
}
