import type { AdminTicketDetailsDto, SelfDto, TicketStatusDto, UserDto } from "api/types";
import iconCalendar from "assets/icons/calendar.svg";
import iconStar01 from "assets/icons/star-01.svg";
import iconUsers01 from "assets/icons/users-01.svg";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { Select } from "components/Select/Select";
import { TicketStatus } from "components/Ticket/TicketStatus";
import { parseISO } from "date-fns";
import { daysBetween } from "helpers/date";
import { Trans, useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface TicketInfoHeaderProps {
  ticket: AdminTicketDetailsDto;
  sessionUser: SelfDto;
  isChangingStatus: boolean;
  onChangeStatus: (status: TicketStatusDto) => void;
  onChangeAssignee: (assignee: UserDto) => void;
  changedAssignee?: UserDto;
  showProject: boolean;
}

export function TicketInfoHeader({
  ticket,
  sessionUser,
  isChangingStatus,
  onChangeStatus,
  onChangeAssignee,
  changedAssignee,
  showProject,
}: TicketInfoHeaderProps): React.ReactNode {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex flex-col gap-6 rounded-lg border-b border-grey-100 bg-white p-4">
      <div className="flex flex-wrap gap-x-6 gap-y-2">
        {ticket.visibility !== "private" ? (
          <DetailInfo title={t("page.tickets.details.info.type")}>
            <div className="flex items-center gap-1 rounded bg-grey-100 p-2">
              <Icon name={iconUsers01} className="text-blue-500" />
              <span className="text-caption-bold text-blue-600">{t("page.tickets.details.info.type.collective")}</span>
            </div>
          </DetailInfo>
        ) : null}
        <DetailInfo title={t("page.tickets.details.info.id")}>
          <div className="gap-1 rounded bg-blue-100 px-2 py-1.5">
            <span className="text-caption-bold">{ticket.id}</span>
          </div>
        </DetailInfo>
        <DetailInfo title={t("page.tickets.details.info.open-since")}>
          <div className="gap-1 rounded bg-grey-100 px-2 py-1.5">
            <span className="text-caption-bold">
              {t("page.tickets.details.info.open-since.content", {
                count: daysBetween(parseISO(ticket.createdAt), new Date()),
              })}
            </span>
          </div>
        </DetailInfo>
        <DetailInfo title={t("page.tickets.details.info.status")} smallGap>
          {isChangingStatus ? (
            <LoadingIcon className="my-1.5 ml-3 w-5" />
          ) : (
            <Select
              disabled={ticket.possibleStatuses.length === 0 || !ticket.canChangeStatus}
              selected={ticket.status}
              items={ticket.possibleStatuses}
              keySelector={(x) => x.id}
              renderOption={(x) => <TicketStatus description={x.name} labelColor={x.color} />}
              onChange={onChangeStatus}
            />
          )}
        </DetailInfo>
        <DetailInfo title={t("page.tickets.details.info.assignee")} smallGap>
          <Select
            placeholder={t("page.tickets.details.info.assignee.unassigned")}
            disabled={!ticket.canChangeAssignee}
            selected={changedAssignee || ticket.assignee}
            items={ticket.possibleAssignees}
            keySelector={(x) => x.id}
            renderOption={(x) => x.fullName}
            onChange={onChangeAssignee}
          />
        </DetailInfo>
        {showProject ? (
          <DetailInfo title={t("page.tickets.details.info.project")}>
            <div className="gap-1 rounded bg-grey-100 px-2 py-1.5">
              <span className="text-caption-bold">{sessionUser.project.name}</span>
            </div>
          </DetailInfo>
        ) : null}
        <DetailInfo title={t("page.tickets.details.info.category")}>
          <div className="gap-1 rounded bg-grey-100 px-2 py-1.5">
            <span className="text-caption-bold">{ticket.category.name}</span>
          </div>
        </DetailInfo>
        {ticket.rating ? (
          <DetailInfo title={t("page.tickets.details.info.rating")}>
            <span>
              <span className="inline-flex items-center gap-1 rounded bg-yellow-100 px-2 py-1.5">
                <span className="flex gap-0.5">
                  <Icon name={iconStar01} className="fill-current text-yellow-500" />
                </span>
                <span className="text-body-bold">{ticket.rating ?? "-"}</span>
              </span>
            </span>
          </DetailInfo>
        ) : null}
      </div>
      <div className="flex items-center gap-2">
        <Icon name={iconCalendar} size={16} />
        <span className="text-caption">
          <Trans
            i18nKey="page.tickets.details.created-at"
            values={{
              date: formatDate(i18n, "datetime", ticket.createdAt),
              name: ticket.createdBy.fullName,
            }}
            components={{
              bold: <b />,
            }}
          />
        </span>
      </div>
    </div>
  );
}

function DetailInfo({ title, children, smallGap }: { title: string; children: React.ReactNode; smallGap?: boolean }) {
  return (
    <div className={twJoin("flex flex-col", smallGap ? "gap-1" : "gap-1.5")}>
      <span className="text-overline font-old-semibold text-grey-700">{title}</span>
      <div className="flex">{children}</div>
    </div>
  );
}
