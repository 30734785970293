import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { ProjectConnectionCreateRequest, ProjectConnectionEditRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { createFormTranslations, toTranslationsRequest, useLanguages } from "helpers/languages";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { isDefined } from "helpers/util";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useProjectId } from "hooks/Network/useProjectId";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "routes";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const { id: connectionId } = useParams<{ id: string }>();
  const isEditMode = isDefined(connectionId);
  const { t } = useTranslation();
  const api = useApi();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const showFlashToast = useFlashToast();
  const {
    data: connectionDetails,
    isFetching: isLoadingConnectionDetails,
    error: connectionDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.PROJECT_CONNECTION_DETAILS(connectionId!),
    queryFn: () => api.getProjectConnectionsDetailsV1(connectionId!),
    select: commonAPIDataSelector,
    enabled: isEditMode,
  });
  const { uploadFormImage, isUploadingImage } = useUploadImage();
  const connectionsQuery = { Limit: 1000, Offset: 0, IncludeAllFromProfile: true };
  const {
    data: connections,
    isLoading: isLoadingConnections,
    error: connectionsError,
  } = useQuery({
    queryKey: QUERY_KEYS.PROJECT_CONNECTION_LIST_INCLUDE_ALL(projectId, connectionsQuery),
    queryFn: () => api.getProjectConnectionsV1(connectionsQuery),
    select: commonAPIDataSelector,
    enabled: !isEditMode,
  });
  const { data: languages = [], isFetching: isFetchingLanguages, error: languagesError } = useLanguages();
  const { data: projects = [], isPending: isLoadingProjects, error: projectsError } = useConnectedProjects();
  const createProjectConnection = useMutation({
    mutationFn: (connection: ProjectConnectionCreateRequest) => api.postProjectConnectionsV1(connection),
    onSuccess() {
      showFlashToast({
        title: t("page.project-connections.actions.create.notification.success"),
        type: "success",
      });
      navigate(routes.projectConnections.list());
    },
    onError() {
      showFlashToast({
        title: t("page.project-connections.actions.create.notification.error"),
        type: "error",
      });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PROJECT_CONNECTIONS });
    },
  });

  const editProjectConnection = useMutation({
    mutationFn: (connection: ProjectConnectionEditRequest) => api.putProjectConnectionsV1(connectionId!, connection),
    onSuccess() {
      showFlashToast({
        title: t("page.project-connections.actions.edit.notification.success"),
        type: "success",
      });
      navigate(routes.projectConnections.list());
    },
    onError() {
      showFlashToast({
        title: t("page.project-connections.actions.edit.notification.error"),
        type: "error",
      });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PROJECT_CONNECTIONS });
    },
  });

  const isLoading = isLoadingConnectionDetails || isLoadingProjects || isLoadingConnections || isFetchingLanguages;
  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  const error = connectionDetailsError || projectsError || connectionsError || languagesError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  return props.children({
    isEditMode,
    defaultFormValues: connectionDetails
      ? {
          name: connectionDetails.name,
          description: connectionDetails.internalDescription,
          contentTranslations: createFormTranslations({
            languages,
            translations: connectionDetails.contentTranslations,
          }),
          backgroundImage: connectionDetails.backgroundImage ? [connectionDetails.backgroundImage] : [],
          projects: connectionDetails.projects,
        }
      : {},
    languages,
    projects: projects.map((x) => ({ ...x, baseLanguageId: x.projectBaseLanguageId })),
    otherConnections: isEditMode
      ? connections?.items.filter((x) => x.id !== connectionId) ?? []
      : connections?.items ?? [],
    isSubmitting: isUploadingImage || editProjectConnection.isPending || createProjectConnection.isPending,
    onSubmit: async (data) => {
      const backgroundImage = await uploadFormImage(data.backgroundImage[0]);
      if (backgroundImage == null) {
        throw new Error("Background image is required");
      }

      if (isEditMode) {
        editProjectConnection.mutate({
          name: data.name,
          description: data.description,
          contentTranslations: toTranslationsRequest(data.contentTranslations),
          backgroundImageId: backgroundImage.id,
        });
      } else {
        createProjectConnection.mutate({
          name: data.name,
          description: data.description,
          projectIds: data.projects.map((x) => x.id),
          contentTranslations: toTranslationsRequest(data.contentTranslations),
          backgroundImageId: backgroundImage.id,
        });
      }
    },
  });
}
