export type AutomatedSurveyType = "other" | "feelAtHome";

export const automatedSurveyTypes: AutomatedSurveyType[] = ["feelAtHome"];

export type AutomatedSurveyQuestionCategoryId = "other" | "feelAtHome" | "building" | "community" | "service";

export interface AutomatedSurveyQuestionCategory {
  id: AutomatedSurveyQuestionCategoryId;
  mandatory: boolean;
}

export const automatedSurveyQuestionCategories: AutomatedSurveyQuestionCategory[] = [
  { id: "other", mandatory: false },
  { id: "feelAtHome", mandatory: true },
  { id: "building", mandatory: true },
  { id: "community", mandatory: true },
  { id: "service", mandatory: true },
];
