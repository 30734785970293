import type { HttpResponse } from "api/types";

export function isHttpError(error: unknown): error is HttpResponse<unknown, unknown> {
  if (error && typeof error === "object" && "error" in error && "status" in error) {
    return true;
  }

  return false;
}

export function parseErrorMessage(error: unknown): string | undefined {
  if (isHttpError(error) && error.status !== 400) {
    return undefined;
  }

  const errorPayload = (error as any)?.error || error;

  if (!errorPayload) {
    return undefined;
  }

  if (typeof errorPayload === "string") {
    return errorPayload;
  }

  if (typeof errorPayload === "object") {
    let errorDetails = errorPayload;
    if ("errors" in errorPayload && typeof errorPayload.errors === "object") {
      errorDetails = errorPayload.errors;
    } else if ("error" in errorPayload && typeof errorPayload.error === "object") {
      errorDetails = errorPayload.error;
    }

    return Object.values(errorDetails)
      .flat()
      .map((x) => `- ${x as any}`)
      .join("\n");
  }

  return undefined;
}
