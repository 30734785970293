import { sleep } from "./sleep";

type RetryOptions = {
  maxAttempts: number;
  delay: (attempt: number) => number;
};

type RetryPolicy = {
  execute<TFn extends (...args: any[]) => any>(fn: TFn): Promise<Awaited<ReturnType<TFn>>>;
};

export function createRetryPolicy(options: RetryOptions): RetryPolicy {
  if (options.maxAttempts < 1) {
    throw new Error("maxAttempts must be greater than or equal to 1");
  }

  return {
    async execute(fn) {
      let lastError: unknown;
      for (let i = 0; i < options.maxAttempts; i++) {
        if (i > 0) {
          await sleep(options.delay(i));
        }

        try {
          return await fn();
        } catch (error) {
          lastError = error;
        }
      }

      throw lastError;
    },
  };
}
