export function parseFileContent(blob: Blob): Promise<string | ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target?.result) {
        resolve(e.target?.result);
      } else {
        reject(new Error("File does not have any content"));
      }
    };

    reader.onerror = () => {
      reject(new Error("Something went wrong parsing file contents"));
    };

    reader.readAsBinaryString(blob);
  });
}

export function downloadFile(blob: Blob, filename: string): void {
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();

  a.parentNode?.removeChild(a);
  window.URL.revokeObjectURL(url);
}
