import { ErrorPage } from "components/Error/ErrorPage";
import { canAccessAnalytics } from "components/ProjectSidebar/permissions";
import { RequireAuth } from "components/Routing/RequireAuth";
import { AnalyticsPage } from "modules/analytics/pages/Overview";
import type { RouteObject } from "react-router-dom";

import { AdoptionDetailPage } from "./pages/Details/Adoption";
import { ContributionDetailPage } from "./pages/Details/Contribution";
import { EngagementDetailPage } from "./pages/Details/Engagement";
import { analyticsRoutes } from "./routes";

export const analyticsRoute = [
  {
    path: analyticsRoutes.overview,
    element: (
      <RequireAuth permission={canAccessAnalytics}>
        <AnalyticsPage />
      </RequireAuth>
    ),
  },
  {
    path: analyticsRoutes.adoptionDetails,
    element: (
      <RequireAuth permission={canAccessAnalytics}>
        <AdoptionDetailPage />
      </RequireAuth>
    ),
  },
  {
    path: analyticsRoutes.contributionDetails,
    element: (
      <RequireAuth permission={canAccessAnalytics}>
        <ContributionDetailPage />
      </RequireAuth>
    ),
  },
  {
    path: analyticsRoutes.engagementDetails,
    element: (
      <RequireAuth permission={canAccessAnalytics}>
        <EngagementDetailPage />
      </RequireAuth>
    ),
  },
  {
    path: "*",
    element: <ErrorPage status={404} />,
  },
] satisfies RouteObject[];
