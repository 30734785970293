import { twResolve } from "helpers/tw-resolve";

export function Icon({
  name,
  size = 16,
  className,
  style,
  ...otherProps
}: {
  name: string;
  size?: number | string;
  style?: React.CSSProperties;
  className?: string;
}): React.ReactNode {
  return (
    <span className={twResolve("shrink-0 fill-transparent", className)} {...otherProps}>
      <svg width={size} height={size}>
        <use xlinkHref={`#${name}`} />
      </svg>
    </span>
  );
}
