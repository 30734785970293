import type { LanguageDto } from "api/types";
import { twResolve } from "helpers/tw-resolve";

interface Props {
  className?: string;
}

interface ChevronProps {
  orientation: "down" | "up" | "left" | "right";
}

const rotations = {
  down: 0,
  up: 180,
  left: 90,
  right: -90,
} as const;

export function ChevronIcon({ className, orientation }: Props & ChevronProps): React.ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path
        className="origin-center transition-transform"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M19 9l-7 7-7-7"
        transform={`rotate(${rotations[orientation]})`}
      />
    </svg>
  );
}

export function LoadingIcon({ className }: Props): React.ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      className={twResolve("text-aop-dark-blue-500", className)}
    >
      <g strokeWidth={4} strokeLinecap="round" stroke="currentColor">
        <line y1="17" y2="29" transform="translate(32,32) rotate(180)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values="1;.85;.7;.65;.55;.45;.35;.25;.15;.1;0;1"
            repeatCount="indefinite"
          />
        </line>
        <line y1="17" y2="29" transform="translate(32,32) rotate(210)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values="0;1;.85;.7;.65;.55;.45;.35;.25;.15;.1;0"
            repeatCount="indefinite"
          />
        </line>
        <line y1="17" y2="29" transform="translate(32,32) rotate(240)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values=".1;0;1;.85;.7;.65;.55;.45;.35;.25;.15;.1"
            repeatCount="indefinite"
          />
        </line>
        <line y1="17" y2="29" transform="translate(32,32) rotate(270)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values=".15;.1;0;1;.85;.7;.65;.55;.45;.35;.25;.15"
            repeatCount="indefinite"
          />
        </line>
        <line y1="17" y2="29" transform="translate(32,32) rotate(300)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values=".25;.15;.1;0;1;.85;.7;.65;.55;.45;.35;.25"
            repeatCount="indefinite"
          />
        </line>
        <line y1="17" y2="29" transform="translate(32,32) rotate(330)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values=".35;.25;.15;.1;0;1;.85;.7;.65;.55;.45;.35"
            repeatCount="indefinite"
          />
        </line>
        <line y1="17" y2="29" transform="translate(32,32) rotate(0)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values=".45;.35;.25;.15;.1;0;1;.85;.7;.65;.55;.45"
            repeatCount="indefinite"
          />
        </line>
        <line y1="17" y2="29" transform="translate(32,32) rotate(30)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values=".55;.45;.35;.25;.15;.1;0;1;.85;.7;.65;.55"
            repeatCount="indefinite"
          />
        </line>
        <line y1="17" y2="29" transform="translate(32,32) rotate(60)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values=".65;.55;.45;.35;.25;.15;.1;0;1;.85;.7;.65"
            repeatCount="indefinite"
          />
        </line>
        <line y1="17" y2="29" transform="translate(32,32) rotate(90)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values=".7;.65;.55;.45;.35;.25;.15;.1;0;1;.85;.7"
            repeatCount="indefinite"
          />
        </line>
        <line y1="17" y2="29" transform="translate(32,32) rotate(120)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values=".85;.7;.65;.55;.45;.35;.25;.15;.1;0;1;.85"
            repeatCount="indefinite"
          />
        </line>
        <line y1="17" y2="29" transform="translate(32,32) rotate(150)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values="1;.85;.7;.65;.55;.45;.35;.25;.15;.1;0;1"
            repeatCount="indefinite"
          />
        </line>
      </g>
    </svg>
  );
}

export function TableSortIcon({ sorted, desc }: { sorted?: boolean; desc?: boolean }): React.ReactNode {
  return (
    <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.69734 4L4.5 0.763763L7.30266 4H1.69734Z"
        stroke="currentColor"
        fill={sorted && desc ? "currentColor" : "none"}
      />
      <path
        d="M7.30266 8L4.5 11.2362L1.69734 8L7.30266 8Z"
        stroke="currentColor"
        fill={sorted && !desc ? "currentColor" : "none"}
      />
    </svg>
  );
}

export function FlagIcon({ size = 16, languageId }: { size?: number; languageId: LanguageDto["id"] }): React.ReactNode {
  // https://flagicons.lipis.dev/
  switch (languageId) {
    case "de":
      return (
        <svg width={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
          <path fill="#ffce00" d="M0 320h640v160H0z" />
          <path d="M0 0h640v160H0z" />
          <path fill="#d00" d="M0 160h640v160H0z" />
        </svg>
      );
    case "nl":
      return (
        <svg width={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
          <path fill="#21468b" d="M0 0h640v480H0z" />
          <path fill="#fff" d="M0 0h640v320H0z" />
          <path fill="#ae1c28" d="M0 0h640v160H0z" />
        </svg>
      );
    case "en":
      return (
        <svg width={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
          <path fill="#012169" d="M0 0h640v480H0z" />
          <path
            fill="#FFF"
            d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"
          />
          <path
            fill="#C8102E"
            d="m424 281 216 159v40L369 281h55zm-184 20 6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"
          />
          <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z" />
          <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z" />
        </svg>
      );
    default:
      // empty icon
      return (
        <svg width={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
          <path fill="#fff" fill-rule="evenodd" stroke="#adb5bd" stroke-width="1.1" d="M.5.5h638.9v478.9H.5z" />
          <path fill="none" stroke="#adb5bd" stroke-width="5" d="m.5.5 639 479M639.5.5l-639 479" />
        </svg>
      );
  }
}

export function UploadImageIcon({ size = 16 }: { size?: number }): React.ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 16 15">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14.667 7.667v2.066c0 1.494 0 2.24-.29 2.811-.257.502-.665.91-1.166 1.165C12.64 14 11.893 14 10.4 14H5.6c-1.493 0-2.24 0-2.81-.29a2.667 2.667 0 01-1.166-1.166c-.29-.57-.29-1.317-.29-2.81V6.266c0-1.494 0-2.24.29-2.811.256-.502.664-.91 1.165-1.165C3.36 2 4.107 2 5.6 2h2.733m4.334 3.333v-4m-2 2h4m-4 4.667a2.667 2.667 0 11-5.334 0 2.667 2.667 0 015.334 0z"
      />
    </svg>
  );
}

export function UploadDocumentIcon({ size = 16 }: { size?: number }): React.ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size} fill="none" viewBox="0 0 14 16">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M3.5 6.667V3a1 1 0 012 0v3.667a2 2 0 11-4 0V4m6.333-2.667h1.8c1.12 0 1.68 0 2.108.218a2 2 0 01.874.874c.218.428.218.988.218 2.108v6.934c0 1.12 0 1.68-.218 2.108a2 2 0 01-.874.874c-.428.218-.988.218-2.108.218H5.367c-1.12 0-1.68 0-2.108-.218a2 2 0 01-.874-.874c-.218-.428-.218-.988-.218-2.108V11"
      />
    </svg>
  );
}
