import type { DocumentMediaProps } from "components/Media/Media";
import { DocumentMedia } from "components/Media/Media";
import { useCombinedRefs } from "hooks/useCombinedRef";
import type { ForwardedRef } from "react";
import type React from "react";
import { forwardRef, useMemo, useRef, useState } from "react";

import type { FormDocument } from "./useDocumentFile";
import { useDocumentInput } from "./useDocumentFile";

export interface DocumentInputProps
  extends Pick<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    "aria-invalid" | "id"
  > {
  ref?: ForwardedRef<HTMLInputElement>;
  value: FormDocument[];
  accept?: string;
  onChange: (files: FormDocument[]) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  withPreview?: boolean;
  texts?: DocumentMediaProps["texts"];
  disabled?: boolean;
}

/* todo: add ability to add/delete/edit multiple files */
export const DocumentInput = forwardRef<HTMLInputElement, DocumentInputProps>(function DocumentInput(
  { value: documents, onChange, withPreview, texts, ...props },
  ref,
) {
  const onDocumentChange: React.Dispatch<React.SetStateAction<FormDocument[]>> = useMemo(() => {
    return (files: FormDocument[] | ((oldFiles: FormDocument[]) => FormDocument[])) => {
      onChange?.(Array.isArray(files) ? files : files(documents));
    };
  }, [documents, onChange]);

  const innerRef = useRef<HTMLInputElement>(null);
  const combinedRef = useCombinedRefs(innerRef, ref);
  const { addDocuments, removeDocuments } = useDocumentInput({ maximumFiles: 1, onChange: onDocumentChange });
  const [dragging, setDragging] = useState(false);
  const openFileDialog = () => combinedRef.current?.click();
  const deleteAllFiles = () => {
    if (combinedRef.current) {
      combinedRef.current.value = "";
      removeDocuments();
      props.onBlur?.();
    }
  };

  return (
    <div
      className="flex gap-2"
      onDrop={(event) => {
        if (event.dataTransfer.files && combinedRef.current) {
          combinedRef.current.files = event.dataTransfer.files;
          addDocuments(event.dataTransfer.files);
          setDragging(false);

          event.preventDefault();
        }
      }}
      onDragOver={(event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "copy";
      }}
      onDragEnter={() => setDragging(true)}
      onDragLeave={() => setDragging(false)}
    >
      <DocumentMedia
        className={dragging ? "border-aop-basic-blue-500" : undefined}
        document={documents[0]}
        aria-invalid={props["aria-invalid"]}
        onAdd={openFileDialog}
        onDelete={deleteAllFiles}
        withPreview={withPreview}
        texts={texts}
        disabled={props.disabled}
      />
      <input
        tabIndex={-1}
        type="file"
        {...props}
        ref={combinedRef}
        className="sr-only"
        onChange={(event) => addDocuments(event.target.files)}
        disabled={props.disabled}
      />
    </div>
  );
});
