import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { useImageResolver } from "api/hooks/useImageResolver";
import { Anchor } from "components/Anchor/Anchor";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { GrowLabel } from "components/GrowLabel/GrowLabel";
import { InfoIcon } from "components/InfoIcon/InfoIcon";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { NoData } from "modules/analytics/components/NoData";
import { canListAnySurvey } from "modules/surveys/permissions";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

import { Speedometer } from "./Speedometer";

export function HomeFeelingAtHomeWidget(): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const sessionUser = useSessionUser();
  const { t, i18n } = useTranslation();
  const api = useApi();
  const { data, isLoading, isRefetching, error, refetch } = useQuery({
    queryKey: QUERY_KEYS.ANALYTICS_FEELING_AT_HOME(projectId),
    queryFn: () => api.getAnalyticsFeelingAtHomeV1(),
    select: commonAPIDataSelector,
  });
  const resolveImage = useImageResolver();
  const hasPermission = usePermission();

  const noData = data?.totalFeelingAtHomeScore === undefined;

  return (
    <div>
      <div className="relative flex h-max gap-8 overflow-hidden rounded-lg bg-aop-dark-blue-500 px-4 py-8 text-white shadow-sm @5xl:max-w-fit">
        <div className="flex justify-between gap-4">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-2">
              <span className="text-body-bold">
                {t("page.home.feel-at-home.title", { project: sessionUser.project.name })}
              </span>
              <span className="text-headline1">
                {t("page.home.feel-at-home.description", { name: sessionUser.firstName })} 👋
              </span>
            </div>
            <div className="relative flex flex-1 flex-col gap-8 overflow-hidden rounded-lg border border-grey-300 bg-white p-4 text-black">
              {
                // eslint-disable-next-line no-nested-ternary
                isLoading || isRefetching ? (
                  <div className="absolute inset-0 bg-white">
                    <FullSizeLoader withPadding />
                  </div>
                ) : noData || error ? (
                  <div className="absolute inset-0 flex items-center bg-white">
                    <NoData
                      horizontal
                      error={error}
                      noDataMessage={t("page.home.feel-at-home.score.no-data")}
                      onReload={refetch}
                    />
                  </div>
                ) : null
              }
              <div className="flex flex-wrap gap-8 @3xl:flex-nowrap">
                <div className="flex flex-1 flex-col justify-between gap-2 md:gap-8">
                  {/* width is the max size of the speedometer + rating */}
                  <div className="flex max-w-[386px] gap-4">
                    <span className="text-headline2 leading-[30px] sm:leading-old-10">
                      {t("page.home.feel-at-home.score.new-title")}
                    </span>
                    <div className="flex items-center">
                      <InfoIcon tooltip={t("page.home.feel-at-home.score.new-title.tooltip")} />
                    </div>
                  </div>
                  <div className="flex items-end gap-4">
                    <div className="-mb-2 w-24 @md:w-32 @xl:w-40">
                      <Speedometer score={(data?.totalFeelingAtHomeScore?.rating || 0) / 10} />
                    </div>
                    <div className="inline-flex items-end">
                      <span className="-mb-3 text-[80px] font-old-semibold leading-none @lg:text-[108px]">
                        {data?.totalFeelingAtHomeScore?.rating.toFixed(1) || "-"}
                      </span>
                      <span className="text-[30px] leading-none sm:text-[40px]">/10</span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-1 gap-4 @3xl:flex-none @3xl:flex-col">
                  <div className="flex flex-1 flex-col gap-1 rounded-lg border-[0.5px] border-grey-300 p-2 md:flex-none">
                    <span className="text-caption-bold text-grey-700">
                      {data?.lastFeelingAtHomeScore
                        ? t("page.home.feel-at-home.score.last-month", {
                            month: formatDate(i18n, "month", data.lastFeelingAtHomeScore.month),
                          })
                        : "-"}
                    </span>
                    <div className="flex items-center gap-2">
                      <span className="text-[40px] font-old-semibold leading-none">
                        {data?.lastFeelingAtHomeScore?.rating.toFixed(1) || "-"}
                      </span>
                      {data?.lastFeelingAtHomeScore !== undefined && data?.totalFeelingAtHomeScore !== undefined ? (
                        <GrowLabel
                          newValue={data.lastFeelingAtHomeScore.rating || 0}
                          oldValue={data.totalFeelingAtHomeScore.rating}
                          type="number"
                          decimalPlaces={1}
                        />
                      ) : null}
                    </div>
                    {data?.hasFeelAtHomeDetailsLink && hasPermission(canListAnySurvey) && (
                      <span className="text-caption">
                        <Anchor
                          isBold
                          to={{
                            pathname: routes.surveys.fah({ slug }),
                            search: `?date=${data?.lastFeelingAtHomeScore?.month.substring(0, 7)}`,
                          }}
                        >
                          {t("page.home.feel-at-home.survey-link")}
                        </Anchor>
                      </span>
                    )}
                  </div>

                  <div className="flex flex-1 flex-col gap-1 rounded-lg border-[0.5px] border-grey-300 p-2 md:flex-none">
                    <span className="text-caption-bold text-grey-700">
                      {t("page.home.feel-at-home.score.benchmark")}
                    </span>
                    <div className="flex items-center gap-2">
                      <span className="text-[40px] font-old-semibold leading-none">
                        {data?.benchmarkFeelingAtHomeScore?.rating?.toFixed(1) || "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="relative -bottom-2 -right-4 hidden h-full w-[240px] shrink items-end justify-end @5xl:flex">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 277 378">
              <defs>
                <clipPath id="clip0_102_615">
                  <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M33.384 187.958v153.108H98.89V225.838c0-7.714 6.284-13.967 14.037-13.967h102.939c7.753 0 14.037 6.253 14.037 13.967v115.228h65.507V187.958L164.397 36.573 33.384 187.958z"
                    clipRule="evenodd"
                  />
                </clipPath>
                <filter
                  id="filter0_d_81_193"
                  width="328.176"
                  height="377.798"
                  x=".309"
                  y=".202"
                  colorInterpolationFilters="sRGB"
                  filterUnits="userSpaceOnUse"
                >
                  <feFlood floodOpacity={0} result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  />
                  <feMorphology in="SourceAlpha" radius={1} result="effect1_dropShadow_81_193" />
                  <feOffset dy={4} />
                  <feGaussianBlur stdDeviation={3} />
                  <feColorMatrix values="0 0 0 0 0.290196 0 0 0 0 0.290196 0 0 0 0 0.407843 0 0 0 0.2 0" />
                  <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_81_193" />
                  <feBlend in="SourceGraphic" in2="effect1_dropShadow_81_193" result="shape" />
                </filter>
              </defs>
              <foreignObject x="0" y="0" width="277" height="378" clipPath="url(#clip0_102_615)">
                <div
                  className="size-full bg-aop-dark-blue-500 bg-cover bg-center bg-no-repeat"
                  style={{
                    backgroundImage: sessionUser.project.backgroundImage
                      ? `url(${resolveImage(sessionUser.project.backgroundImage, "intrinsic")})`
                      : undefined,
                  }}
                />
              </foreignObject>
              <g filter="url(#filter0_d_81_193)">
                <path
                  fill="#fff"
                  fillRule="evenodd"
                  d="M164.397 1.202c4.087 0 7.971 1.772 10.637 4.853L320.085 173.66a13.925 13.925 0 013.4 9.114v172.26c0 7.714-6.284 13.967-14.037 13.967h-93.581c-7.752 0-14.037-6.253-14.037-13.967V239.805h-74.865v115.228c0 7.714-6.285 13.967-14.037 13.967H19.347c-7.753 0-14.038-6.253-14.038-13.967v-172.26a13.93 13.93 0 013.4-9.114L153.76 6.055a14.069 14.069 0 0110.637-4.853zM33.384 187.958v153.108H98.89V225.838c0-7.714 6.284-13.967 14.037-13.967h102.939c7.753 0 14.037 6.253 14.037 13.967v115.228h65.507V187.958L164.397 36.573 33.384 187.958z"
                  clipRule="evenodd"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
