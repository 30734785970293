import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { QuickReplyMessageCommentDto, QuickReplySelfUserDto } from "api/types";
import iconCornerDownRight from "assets/icons/corner-down-right.svg";
import iconThumbsUp from "assets/icons/thumbs-up.svg";
import { Button } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { formatDistance } from "components/FormattedDistance/FormattedDistance";
import { Gallery } from "components/Gallery/Gallery";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Tooltip } from "components/Tooltip/Tooltip";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { parseISO } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import { useBool } from "hooks/useBool";
import { sortBy } from "lodash-es";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface Props {
  token: string;
  isLoadingMore?: boolean;
  canLoadMore?: boolean;
  user: QuickReplySelfUserDto;
  comments: QuickReplyMessageCommentDto[];
  commentCount?: number;
  isReadonly?: boolean;
  canComment?: boolean;
  onEdit: (comment: QuickReplyMessageCommentDto) => void;
  onDelete: (comment: QuickReplyMessageCommentDto) => void;
  onLoadMore?: () => void;
  onLike: (commentId: string) => void;
  onUnlike: (commentId: string) => void;
}

function CommunityPostCommentSection({
  token,
  isLoadingMore,
  canLoadMore,
  user,
  comments,
  commentCount,
  canComment,
  onEdit,
  onDelete,
  onLoadMore,
  onLike,
  onUnlike,
}: Props): React.ReactNode {
  const { t } = useTranslation();
  const [isReplyModalOpen, replyModalHandler] = useBool(false);
  const commentsByDate = useMemo(() => sortBy(comments, (x) => parseISO(x.postedAt).valueOf()), [comments]);

  function handleLike(comment: QuickReplyMessageCommentDto) {
    if (comment.hasLiked) {
      onUnlike(comment.id);
    } else {
      onLike(comment.id);
    }
  }

  return (
    <>
      <div>
        <div className="relative flex px-2 pb-0 pt-4 text-caption text-aop-basic-blue-500">
          {canLoadMore && onLoadMore ? (
            <Button styling="tertiary" onClick={onLoadMore} data-testid="comments-load-more">
              <span className="text-caption">{t("component.community-post-comments.load-more")}</span>
            </Button>
          ) : null}

          {isLoadingMore ? <LoadingIcon className="absolute inset-0 mx-auto my-4 w-6" /> : null}

          {commentCount !== undefined && (
            <span data-testid="comments-public-comment-amount" className="ml-auto text-caption text-grey-500">
              {t("component.community-post-comments.comment-count", { count: commentCount })}
            </span>
          )}
        </div>

        <div>
          <AnimatePresence>
            {commentsByDate.map((comment) => (
              <div className="flex flex-col gap-2" key={comment.id}>
                <Comment
                  token={token}
                  user={user}
                  comment={comment}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  onReply={replyModalHandler.setTrue}
                  showReplyButton={canComment}
                  onClickLike={() => handleLike(comment)}
                />
                {comment.latestReply && (
                  <div className="ml-10">
                    <Comment
                      token={token}
                      user={user}
                      comment={comment.latestReply}
                      onEdit={replyModalHandler.setTrue}
                      onDelete={onDelete}
                      onClickLike={() => handleLike(comment.latestReply!)}
                    />
                  </div>
                )}
              </div>
            ))}
          </AnimatePresence>
        </div>
      </div>
      <ConfirmModal
        isOpen={isReplyModalOpen}
        id="reply-comment-modal"
        title={t("component.community-post.comments.replies.modal.title")}
        description={t("component.community-post.comments.replies.modal.description")}
        isLoading={false}
        theme="info"
        onReject={replyModalHandler.setFalse}
        rejectBtnProps={{
          "data-testid": "reply-comment-modal-cancel",
        }}
        onOpenChange={replyModalHandler.set}
        onResolve={replyModalHandler.setFalse}
        resolveBtnProps={{
          text: t("common.action.ok"),
          "data-testid": "reply-comment-modal-confirm",
        }}
        shouldCloseOnEsc
      />
    </>
  );
}

function Comment({
  token,
  comment,
  user,
  onEdit,
  onDelete,
  onReply,
  showReplyButton,
  onClickLike,
}: {
  token: string;
  comment: QuickReplyMessageCommentDto;
  user: QuickReplySelfUserDto;
  onEdit: (comment: QuickReplyMessageCommentDto) => void;
  onDelete: (comment: QuickReplyMessageCommentDto) => void;
  onReply?: () => void;
  showReplyButton?: boolean;
  onClickLike: () => void;
}) {
  const { i18n, t } = useTranslation();
  const api = useApi();
  const showFlashToast = useFlashToast();

  const [hideTranslation, hideTranslationHandlers] = useBool(true);
  const translation = useQuery({
    queryKey: ["QUICK_REPLY_COMMENT", comment.id, "TRANSLATIONS", user.language.id],
    queryFn: () => api.getQuickReplyMessageReplyTranslationsDetailsV1(token, comment.id, user.language.id),
    retry: false,
    enabled: false,
  });

  const hasTranslation = translation.data && !hideTranslation;
  const hasActionButtons = comment.canEdit || comment.canDelete;
  const translateButton =
    comment.content && comment.languageIsoCode !== user.language.id && !comment.deletedAt ? (
      <Button
        styling="ghostPrimary"
        isLoading={translation.isLoading}
        onClick={async () => {
          hideTranslationHandlers.toggle();

          if (!translation.data) {
            try {
              await translation.refetch();
            } catch (error) {
              showFlashToast({ type: "error", title: t("component.community-post.comments.translate-error") });
            }
          }
        }}
      >
        {hasTranslation
          ? t("component.community-post.comments.translate.original")
          : t("component.community-post.comments.translate")}
      </Button>
    ) : null;

  const content =
    // eslint-disable-next-line no-nested-ternary
    comment.deletedAt && !comment.content
      ? t("component.community-post-comments.comment-deleted")
      : hasTranslation
        ? translation.data.data.content
        : comment.content;

  return (
    <motion.article
      className={twJoin("my-2 flex rounded-3px pb-2 first:pt-0", comment.deletedAt && "text-grey-400")}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, height: 0, marginBottom: "0px" }}
      data-testid="comment-root"
    >
      <div className={twJoin("mr-2 size-8", comment.deletedAt ? "opacity-40" : undefined)}>
        <UserAvatar img={comment.author.avatar} isUserDeleted={!!comment.author.deletedAt} />
      </div>
      <div className="grid flex-1 grid-cols-1 pt-0.5">
        <div className="rounded-lg bg-grey-100/50 p-2">
          <div className={twJoin("flex items-start", comment.deletedAt ? "text-grey-400" : undefined)}>
            <div className="flex flex-wrap items-center">
              <span
                className={twJoin("mr-2 truncate text-body-bold", comment.deletedAt ? "text-grey-400" : "text-black")}
              >
                {comment.author.fullName}
              </span>

              <span className="text-caption text-grey-500">
                {formatDistance(i18n, { start: new Date(comment.postedAt) })}
              </span>

              {comment.updatedAt ? (
                <Tooltip tooltip={<FormattedDate date={comment.updatedAt} format="datetime" />}>
                  <span className="relative ml-1 cursor-default text-caption italic text-grey-500 after:absolute after:inset-x-0 after:bottom-0 after:hidden after:h-px after:w-full after:bg-grey-500 after:content-[''] hover:after:block">
                    ({t("model.repair-request.comment-edited")})
                  </span>
                </Tooltip>
              ) : null}
            </div>
          </div>

          {content && (
            <div className="flex flex-col gap-2">
              <p
                className={twJoin(
                  "max-w-prose whitespace-pre-line",
                  comment.deletedAt && comment.content ? "line-through" : undefined,
                )}
                data-testid="comment-content"
              >
                <LinkFormatter>{content}</LinkFormatter>
              </p>
              {translateButton}
            </div>
          )}

          {comment.images.length > 0 ? (
            <div className="max-w-full pt-2">
              <Gallery images={comment.images} isDeleted={!!comment.deletedAt} />
            </div>
          ) : null}
        </div>
        {hasActionButtons ? (
          <div className="-ml-1 flex items-center gap-2 pt-2 text-grey-500">
            {comment.canLike && (
              <div className="flex items-center">
                <Button
                  styling="ghostSecondary"
                  size="sm"
                  className="flex gap-1"
                  data-testid="like-comment"
                  onClick={onClickLike}
                  title={t("common.action.like")}
                  icon={
                    <Icon
                      name={iconThumbsUp}
                      className={twJoin("shrink-0", comment.hasLiked ? "fill-grey-700" : undefined)}
                    />
                  }
                >
                  {comment.likeCount > 0 &&
                    t("component.community-post.comment.likes.total", { count: comment.likeCount })}
                </Button>
              </div>
            )}
            {showReplyButton && (
              <div className="flex items-center">
                <Button
                  styling="ghostSecondary"
                  size="sm"
                  title={t("component.community-post.comments.reply")}
                  data-testid="reply-comment"
                  onClick={onReply}
                >
                  <Icon name={iconCornerDownRight} size={16} />
                </Button>
              </div>
            )}
            {comment.canEdit ? (
              <Button styling="ghostSecondary" size="sm" data-testid="edit-comment" onClick={() => onEdit(comment)}>
                {t("common.action.edit")}
              </Button>
            ) : null}
            {comment.canDelete ? (
              <Button styling="ghostSecondary" size="sm" data-testid="delete-comment" onClick={() => onDelete(comment)}>
                {t("common.action.delete")}
              </Button>
            ) : null}
          </div>
        ) : null}
      </div>
    </motion.article>
  );
}

const CommunityPostCommentSectionMemo = memo(CommunityPostCommentSection);

export { CommunityPostCommentSectionMemo as CommunityPostCommentSection };
