import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { AdminNotificationDto, AdminNotificationStatusDto, HttpResponse } from "api/types";
import arrowNarrowUpIcon from "assets/icons/arrow-narrow-up.svg";
import CheckIcon from "assets/icons/check.svg";
import filterIcon from "assets/icons/filter-funnel-01.svg";
import settingsIcon from "assets/icons/settings-01.svg";
import iconX from "assets/icons/x.svg";
import { Button } from "components/Button/Button";
import { CheckboxMultiSelect } from "components/CheckboxMultiSelect/CheckboxMultiSelect";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { ErrorPage } from "components/Error/ErrorPage";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { NotificationIcon } from "components/NotificationIcon/NotificationIcon";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { PillButton } from "components/PillButton/PillButton";
import { Switch } from "components/Switch/Switch";
import { addDays, addSeconds } from "date-fns";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { resolveOldLink } from "helpers/old-url-resolver";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { getSessionStorageValue, useUpdateSessionStorage } from "hooks/useLocalStorage";
import { useOnIntersection } from "hooks/useOnIntersection";
import { usePermission } from "hooks/usePermission";
import { useScrollToPageTop } from "hooks/useScrollToPageTop";
import { useSlug } from "hooks/useSlug";
import { cloneDeep, isEqual } from "lodash-es";
import { usePostHog } from "posthog-js/react";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";
import type { ApiQueryParams } from "types/api-types";

import { canEditOwnNotificationSettings } from "../../permissions";

const ITEM_AMOUNT = 40;

const FILTERS_STORAGE_KEY = "admin_notifications_filters";

interface Filters {
  unreadOnly: boolean;
  types: Exclude<ApiQueryParams<"getAdminNotificationsV1">["Types"], undefined>;
}

export function AdminNotificationsPage(): React.ReactNode {
  const slug = useSlug();
  const projectId = useProjectId();
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const [isMarkAsReadModalOpen, markAsReadModalHandlers] = useBool();
  const hasPermission = usePermission();
  const postHog = usePostHog();

  const api = useApi();
  const queryClient = useQueryClient();

  const { data: notificationSettings } = useQuery({
    queryKey: QUERY_KEYS.NOTIFICATION_SETTINGS_USER(projectId, sessionUser.id),
    queryFn: () => api.getNotificationSettingsDetailsV1(sessionUser.id),
    select: commonAPIDataSelector,
  });

  const options = useMemo(
    () =>
      [
        {
          label: "Messages",
          type: "message",
          disabled: !notificationSettings?.generalMessageNew.dashboardEnabled,
        },
        {
          label: "Message Comments",
          type: "messageComment",
          disabled: !notificationSettings?.generalMessageCommentNew.dashboardEnabled,
        },
        {
          label: "Messages in Groups",
          type: "groupMessage",
          disabled: false,
        },
        {
          label: "Tickets",
          type: "ticket",
          disabled: !notificationSettings?.ticketUnassignedUpdates.some((x) => x.dashboardEnabled),
        },
        {
          label: "Tickets Assigned",
          type: "ticketAssignee",
          disabled: !notificationSettings?.ticketAssignToMe.dashboardEnabled,
        },
        {
          label: "Ticket Comments",
          type: "ticketComment",
          disabled: !notificationSettings?.ticketAssignedCommentNew.dashboardEnabled,
        },
      ] as const,
    [
      notificationSettings?.generalMessageCommentNew.dashboardEnabled,
      notificationSettings?.generalMessageNew.dashboardEnabled,
      notificationSettings?.ticketAssignToMe.dashboardEnabled,
      notificationSettings?.ticketAssignedCommentNew.dashboardEnabled,
      notificationSettings?.ticketUnassignedUpdates,
    ],
  );

  const [filtersOpen, filtersOpenHandlers] = useBool(false);

  const [filters, setFilters] = useState<Filters>(() => {
    const storage = getSessionStorageValue<Filters | undefined>(FILTERS_STORAGE_KEY, undefined);

    return {
      unreadOnly: storage?.unreadOnly ?? false,
      types: storage?.types ?? [],
    };
  });
  useUpdateSessionStorage(FILTERS_STORAGE_KEY, filters);

  const query = {
    ExcludeRead: filters.unreadOnly,
    Types: filters.types,
  } satisfies ApiQueryParams<"getAdminNotificationsV1">;
  const {
    data,
    isPending,
    error,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    dataUpdatedAt: listUpdatedAt,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.ADMIN_NOTIFICATION_LIST(projectId, query),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getAdminNotificationsV1({
          ...query,
          Offset: pageParam * ITEM_AMOUNT,
          Limit: ITEM_AMOUNT,
        })
        .then((items) => commonAPIDataSelector(items)),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
    staleTime: 30 * 1000,
    gcTime: 30 * 1000,
  });

  const markRead = useMutation({
    mutationFn: api.deleteAdminNotificationsReadByIdV1,
    onMutate(id) {
      const newData = cloneDeep(data);
      if (newData?.pages) {
        for (const page of newData.pages) {
          for (const item of page.items) {
            if (item.id === id) {
              item.isRead = true;
              queryClient.setQueryData(QUERY_KEYS.ADMIN_NOTIFICATION_LIST(projectId, query), newData);
              queryClient.setQueryData(
                QUERY_KEYS.ADMIN_NOTIFICATION_STATUS(projectId),
                (oldResponse?: HttpResponse<AdminNotificationStatusDto> | undefined) =>
                  oldResponse === undefined
                    ? undefined!
                    : {
                        ...oldResponse,
                        data: {
                          amount: oldResponse.data.amount > 0 ? oldResponse.data.amount - 1 : 0,
                          hasUnread: oldResponse.data.amount - 1 > 0,
                        },
                      },
              );

              return;
            }
          }
        }
      }
    },
    onSuccess() {
      invalidateOtherQueries();
    },
    onError() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ADMIN_NOTIFICATION_STATUS(projectId) });
    },
  });

  function invalidateOtherQueries() {
    // Invalidates all queries except the currently active one, because we already do an optimistic update for that one
    void queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.ADMIN_NOTIFICATION_LIST(projectId),
      predicate: (x) => {
        const key = x.queryKey as ReturnType<(typeof QUERY_KEYS)["ADMIN_NOTIFICATION_LIST"]>;
        const queryValue = key[2];

        return !isEqual(queryValue, query);
      },
    });
  }

  const markAllAsRead = useMutation({
    mutationFn: () => api.deleteAdminNotificationsReadV1(),
    onMutate() {
      const newData = cloneDeep(data);
      if (newData?.pages) {
        for (const page of newData.pages) {
          for (const item of page.items) {
            item.isRead = true;
          }
        }
      }

      queryClient.setQueryData(QUERY_KEYS.ADMIN_NOTIFICATION_LIST(projectId, query), newData);
      queryClient.setQueryData(
        QUERY_KEYS.ADMIN_NOTIFICATION_STATUS(projectId),
        (oldResponse?: HttpResponse<AdminNotificationStatusDto> | undefined) =>
          oldResponse === undefined
            ? undefined!
            : {
                ...oldResponse,
                data: {
                  amount: 0,
                  hasUnread: false,
                },
              },
      );
    },
    onSuccess() {
      invalidateOtherQueries();
    },
    onError() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ADMIN_NOTIFICATION_STATUS(projectId) });
    },
  });

  const allItems = data?.pages.flatMap((page) => page.items) ?? [];

  const sevenDaysAgo = addDays(new Date(), -7);

  const itemsFromThisWeek = allItems.filter((x) => new Date(x.sentAt) > sevenDaysAgo);
  const itemsFromBefore = allItems.slice(itemsFromThisWeek.length);

  const ref = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isFetchingNextPage && hasNextPage) {
        void fetchNextPage();
      }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage]),
  });

  const filterCount = filters.types.length > 0 ? 1 : 0;

  const { data: adminNotificationStats, dataUpdatedAt: statusUpdatedAt } = useQuery({
    queryKey: QUERY_KEYS.ADMIN_NOTIFICATION_STATUS(projectId),
    queryFn: () => api.getAdminNotificationsStatusV1(),
    select: commonAPIDataSelector,
  });

  const scrollToTop = useScrollToPageTop();
  const lastAdminNotificationStats = useRef<number | undefined>();
  const [showPill, showPillHandlers] = useBool();

  useEffect(() => {
    if (!adminNotificationStats) {
      return;
    }

    if (
      statusUpdatedAt &&
      listUpdatedAt &&
      addSeconds(new Date(statusUpdatedAt), -2) > new Date(listUpdatedAt) &&
      lastAdminNotificationStats.current !== undefined &&
      lastAdminNotificationStats.current < adminNotificationStats.amount
    ) {
      showPillHandlers.setTrue();
    }

    lastAdminNotificationStats.current = adminNotificationStats.amount;
  }, [adminNotificationStats, listUpdatedAt, showPillHandlers, statusUpdatedAt]);

  const onClickMarkAllAsRead = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    markAsReadModalHandlers.setTrue();
    postHog?.capture("clicked_maar_admin_notifications");
  };

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.admin-notifications.title")}
      tabTitle={t("page.admin-notifications.title.tab")}
      actions={
        hasPermission(canEditOwnNotificationSettings) && (
          <Button
            type="link"
            href={routes.adminNotifications.selfSettings({ slug })}
            state={{ fromPage: routes.adminNotifications.list({ slug }) }}
          >
            <span className="flex items-center gap-1">
              <Icon name={settingsIcon} size={16} />
              {t("page.admin-notifications.settings")}
            </span>
          </Button>
        )
      }
    >
      {showPill &&
        createPortal(
          <PillButton
            className="fixed right-8 top-1/2 z-0 -translate-y-1/2"
            onClick={() => {
              scrollToTop();
              showPillHandlers.setFalse();
              void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ADMIN_NOTIFICATION_LIST(projectId, query) });
            }}
          >
            <Icon name={arrowNarrowUpIcon} size={16} />
            <span className="text-caption-bold">{t("page.admin-notifications.new-notifications")}</span>
          </PillButton>,
          document.body,
        )}
      <div>
        <div className="my-6 flex flex-col gap-4 overflow-y-visible rounded-lg bg-white px-5 py-3">
          <div className="flex flex-wrap gap-x-8 gap-y-4">
            <div className="mr-auto flex flex-row items-center gap-2">
              <Button styling="secondary" onClick={filtersOpenHandlers.toggle}>
                <span className="flex items-center gap-2">
                  <Icon name={filterIcon} size={16} />
                  {t("page.admin-notifications.filters-btn")}

                  {filterCount > 0 && (
                    <span className="size-5 rounded-full bg-aop-basic-blue-500 text-center text-white">
                      {filterCount}
                    </span>
                  )}
                </span>
              </Button>
            </div>
            <div className="flex items-center gap-2">
              <span>{t("page.admin-notifications.filters.unread-only")}</span>
              <Switch
                isChecked={filters.unreadOnly}
                onChange={() =>
                  setFilters((oldState) => ({
                    ...oldState,
                    unreadOnly: !filters.unreadOnly,
                  }))
                }
              />
            </div>

            <Button onClick={onClickMarkAllAsRead} styling="secondary" icon={<Icon name={CheckIcon} size={16} />}>
              {t("page.admin-notifications.mark-all-as-read")}
            </Button>
          </div>

          {filtersOpen && (
            <div className="flex w-full flex-col">
              <div className="w-full max-w-96">
                <CheckboxMultiSelect
                  placeholder={t("page.admin-notifications.filters.types.placeholder")}
                  items={options}
                  keySelector={(x) => x.type}
                  renderOption={(x) => x.label}
                  disabledItems={options.filter((x) => x.disabled)}
                  disabledItemsStillSelectable
                  renderValue={(value) => (
                    <span className="block max-w-80 truncate pr-5">{value.map((x) => x.label).join(", ")}</span>
                  )}
                  selected={options.filter((o) => filters.types.includes(o.type))}
                  onChange={(x) => setFilters((oldState) => ({ ...oldState, types: x.map((y) => y.type) }))}
                />
              </div>

              <div className="self-center justify-self-end">
                <Button styling="tertiary" icon={<Icon name={iconX} />} onClick={filtersOpenHandlers.setFalse}>
                  <span className="text-caption">{t("page.admin-notifications.filters.close-btn")}</span>
                </Button>
              </div>
            </div>
          )}
        </div>
        {
          // eslint-disable-next-line no-nested-ternary
          isPending ? (
            <FullSizeLoader withPadding />
          ) : allItems && allItems.length === 0 ? (
            <div className="rounded-lg bg-white p-8 text-grey-700">
              <h2 className="text-headline3">{t("page.admin-notifications.empty.title")}</h2>
              <p className="text-headline4">{t("page.admin-notifications.empty.description")}</p>
            </div>
          ) : (
            <>
              {itemsFromThisWeek.length > 0 ? (
                <div>
                  <div className="rounded-t-lg bg-white py-4 pl-8 font-old-bold">
                    {t("page.admin-notifications.this-week")}
                  </div>
                  {itemsFromThisWeek.map((x) => (
                    <NotificationItem
                      key={x.id}
                      notification={x}
                      slug={slug}
                      onMarkAsRead={() => markRead.mutate(x.id)}
                    />
                  ))}
                </div>
              ) : null}

              {itemsFromBefore.length > 0 ? (
                <div>
                  <div
                    className={twJoin(
                      "bg-white py-4 pl-8 font-old-bold",
                      itemsFromThisWeek.length === 0 ? "rounded-t-lg" : "",
                    )}
                  >
                    {t("page.admin-notifications.before-this-week")}
                  </div>
                  {itemsFromBefore.map((x) => (
                    <NotificationItem
                      key={x.id}
                      notification={x}
                      slug={slug}
                      onMarkAsRead={() => markRead.mutate(x.id)}
                    />
                  ))}
                </div>
              ) : null}
            </>
          )
        }
        {hasNextPage && (
          <div className="p-4" ref={ref}>
            <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
          </div>
        )}
      </div>
      <ConfirmModal
        id="notification-mark-all-as-read-modal"
        title={t("page.admin-notifications.modal.mark-all-as-read.title")}
        description={t("page.admin-notifications.modal.mark-all-as-read.description")}
        isLoading={markAllAsRead.isPending}
        theme="danger"
        onOpenChange={markAsReadModalHandlers.set}
        onReject={(e) => {
          e.preventDefault();
          e.stopPropagation();
          markAsReadModalHandlers.setFalse();
        }}
        rejectBtnProps={{
          "data-testid": "notification-mark-all-as-read-modal-cancel",
        }}
        onResolve={() => {
          markAllAsRead.mutate();
          markAsReadModalHandlers.setFalse();
        }}
        resolveBtnProps={{
          text: t("page.admin-notifications.modal.mark-all-as-read.confirm"),
          "data-testid": "notification-mark-all-as-read-modal-confirm",
        }}
        isOpen={isMarkAsReadModalOpen}
        shouldCloseOnEsc
      />
    </DocumentPaper>
  );
}

function NotificationItem({
  notification,
  slug,
  onMarkAsRead,
}: {
  notification: AdminNotificationDto;
  slug: string;
  onMarkAsRead: () => void;
}) {
  return (
    <Link
      to={resolveOldLink(notification.buttonUrl, slug)}
      className={twJoin("group flex items-start justify-start bg-white p-3 even:bg-aop-light-grey-100")}
      onClick={() => {
        if (!notification.isRead) {
          onMarkAsRead();
        }
      }}
    >
      <div className="mr-2 flex w-4 py-4">
        {!notification.isRead && <div className="size-2 rounded-full bg-red-500" />}
      </div>
      <div className="rounded-full border border-grey-100 bg-white p-2 text-grey-700 transition-colors">
        <NotificationIcon type={notification.type} />
      </div>
      <div className="flex flex-col items-start justify-start px-2">
        <span
          className="whitespace-pre-wrap text-left text-caption text-black group-hocus:underline [&>b]:font-old-semibold"
          dangerouslySetInnerHTML={{
            __html: notification.message,
          }}
        />

        <span className="text-overline text-grey-500 transition-colors">
          <FormattedDate format="datetimeRelative" date={notification.sentAt} />
        </span>
      </div>
    </Link>
  );
}
