import type { ForwardedRef, MutableRefObject } from "react";
import { useEffect, useRef } from "react";

/**
 * Combine refs when using forwardRef, so refs can be accessed in the 'inner' component
 * @param refs list of refs from forwardRef and 'inner' useRef
 */
export function useCombinedRefs<T>(...refs: ForwardedRef<T>[]): MutableRefObject<T | null> {
  const targetRef = useRef<T | null>(null);

  useEffect(() => {
    for (const ref of refs) {
      if (!ref) {
        return;
      }

      if (typeof ref === "function") {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    }
  }, [refs]);

  return targetRef;
}

export function mergeRefs<T = any>(
  ...refs: Array<React.MutableRefObject<T> | React.LegacyRef<T> | undefined | null>
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}
