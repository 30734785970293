export function isValidHttpUrl(input: string): boolean {
  // eslint-disable-next-line no-useless-escape
  const urlRegex = /^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/={}]*)/;

  return urlRegex.test(input);
}

export function isValidPhoneNumber(input: string): boolean {
  // Phone number validation is hard:
  // https://github.com/google/libphonenumber/blob/master/FALSEHOODS.md

  // Checks:
  // Optionally starts with +
  // Can only contain dashes, parentheses, numbers or whitespace
  // Needs to have at least 3 of those
  return /^[+]?[-()\d\s]{3,}$/im.test(input);
}

export function isValidEmail(input: string): boolean {
  // Email validation is also hard

  // Checks:
  // Should contain @ and .
  // Uses non whitespace characters
  return /^\S+@\S+\.\S+$/.test(input);
}

export function isValidColor(value: string): boolean {
  // ##ffffff is only allowed, because <input type="color" /> doesn't accept anything else
  // should start with #
  return /^#([0-9A-Fa-f]{6})$/.test(value);
}

export function isValidString(value: string): boolean {
  if (typeof value !== "string") {
    return false;
  }

  return !!value.trim();
}

export function isNumerical(value: string): boolean {
  return /^\d+$/.test(value);
}
