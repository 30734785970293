import type { BookableAssetTranslationDto, LanguageDto } from "api/types";
import type { FormTranslations } from "helpers/languages";

export function toTranslationsRequest(
  languages: LanguageDto[],
  nameTranslations: FormTranslations,
  descriptionTranslations: FormTranslations,
): BookableAssetTranslationDto[] {
  return languages.map<BookableAssetTranslationDto>((lng) => ({
    languageId: lng.id,
    name: nameTranslations[lng.id],
    description: descriptionTranslations[lng.id],
  }));
}
