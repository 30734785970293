import type { MultiSelectProps } from "components/MultiSelect/MultiSelect";
import { MultiSelect } from "components/MultiSelect/MultiSelect";
import type { FieldPath, FieldPathValue, FieldValues, RegisterOptions } from "react-hook-form";
import { useController } from "react-hook-form";

import { FormErrorWrapper } from "./FormErrorWrapper";

export interface FormMultiSelectProps<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> extends Omit<MultiSelectProps<FieldPathValue<TFormValues, TName>[number]>, "selected" | "onChange"> {
  name: TName;
  rules?: RegisterOptions<TFormValues, TName>;
  defaultValue?: FieldPathValue<TFormValues, TName>;
}

export function FormMultiSelect<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({ name, rules, defaultValue, ...props }: FormMultiSelectProps<TFormValues, TName>): React.ReactNode {
  const {
    field,
    fieldState: { error },
  } = useController<TFormValues, TName>({ name, rules, defaultValue });

  return (
    <FormErrorWrapper name={name}>
      <MultiSelect
        {...props}
        selected={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        aria-invalid={!!error}
      />
    </FormErrorWrapper>
  );
}
