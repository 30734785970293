import { twResolve } from "helpers/tw-resolve";
import { Link } from "react-router-dom";

export interface Tab<T extends string | number> {
  id: T;
  name: string;
  count?: number;
  mobileName?: string;
  icon?: React.ReactNode;
  isSecondary?: boolean;
  isHidden?: boolean;
}

interface ContentTabsProps<T extends string | number> {
  onTabChange: (tabId: T) => void;
  tabs: Tab<T>[];
  activeTabId: T;
  children: React.ReactNode;
}

export function ContentTabs<T extends string | number>({
  onTabChange,
  tabs,
  activeTabId,
  children,
}: ContentTabsProps<T>): React.ReactNode {
  return (
    <div className="flex size-full flex-col">
      <nav className="flex h-full overflow-x-auto bg-aop-light-grey-100">
        {tabs.map((tab) => {
          const isActive = activeTabId === tab.id;

          return (
            <Link
              className={twResolve(
                "cursor-pointer whitespace-nowrap border-x border-t-4",
                isActive
                  ? "border-x-transparent border-t-aop-basic-blue-500 bg-white"
                  : "border-x border-x-grey-100 border-t-transparent first:border-l-0 hover:bg-white",
                tab.isSecondary && "border-transparent bg-aop-basic-blue-100 hover:bg-[#A8ECF9]",
                tab.isHidden && "hidden",
              )}
              key={`tab_${tab.id}`}
              to={`?tab=${tab.id}`}
              onClick={(e) => {
                e.preventDefault();
                onTabChange(tab.id);
              }}
            >
              <span
                className={twResolve(
                  "inline-block p-4 text-center",
                  isActive ? "text-body-bold text-black" : "text-caption text-grey-600",
                  tab.isSecondary && "font-old-semibold text-black",
                )}
              >
                <span className="flex items-center gap-2">
                  {tab.icon && <span>{tab.icon}</span>}
                  <span className="hidden md:block">{tab.name}</span>
                  {!!tab.count && (
                    <span className="hidden h-6 items-center justify-center rounded-full border border-grey-300 bg-white px-2 text-caption leading-none text-grey-900 md:flex">
                      {tab.count}
                    </span>
                  )}
                  <span className="block md:hidden">{tab.mobileName || tab.name}</span>
                </span>
              </span>
            </Link>
          );
        })}
      </nav>
      <div className="mt-0 rounded-b-md bg-white">{children}</div>
    </div>
  );
}
