import type { ProjectId } from "providers/ProjectContext";
import { GLOBAL_QUERY_KEYS } from "query-keys-global";
import { PROJECT_QUERY_KEYS } from "query-keys-project";

function prefixQueryKeysWithProjectId<T extends { [key: string]: readonly any[] | ((...args: any) => readonly any[]) }>(
  queryKeys: T,
): {
  [K in keyof T]: T[K] extends (...args: any) => any
    ? (projectId: string, ...args: Parameters<T[K]>) => [ProjectId, ...ReturnType<T[K]>]
    : (projectId: string) => T[K] extends readonly any[] ? [ProjectId, ...T[K]] : [ProjectId, T[K]];
} {
  return Object.fromEntries(
    Object.entries(queryKeys).map(([key, value]) => {
      if (typeof value === "function") {
        // eslint-disable-next-line prefer-spread
        return [key, (projectId: string, ...args: any[]) => [projectId, ...(value as any).apply(null, args)] as const];
      }

      return [key, (projectId: string) => [projectId, ...value]] as const;
    }),
  );
}

export const QUERY_KEYS = {
  ...GLOBAL_QUERY_KEYS,
  ...prefixQueryKeysWithProjectId(PROJECT_QUERY_KEYS),
};
