import { sum } from "lodash-es";
import type { NonEmptyArray } from "types/utility-types";

import { isDefined } from "./util";

export function avg(items: NonEmptyArray<number>): number;
export function avg(items: number[]): number | undefined;
export function avg(items: number[]): number | undefined {
  if (items.length === 0) {
    return undefined;
  }

  return sum(items) / items.length;
}

export function avgBy<T>(
  items: T[],
  variableSelector: (x: T) => number | undefined,
  countSelector: (x: T) => number,
): number | undefined {
  const values = items
    .map((x) => ({
      variable: variableSelector(x),
      count: countSelector(x),
    }))
    .filter((x) => isDefined(x.variable)) as {
    variable: number;
    count: number;
  }[];
  if (values.length === 0) {
    return undefined;
  }

  const totalCount = sum(values.map((x) => x.count));
  if (totalCount === 0) {
    return undefined;
  }

  const totalVariables = sum(values.map((x) => x.variable * x.count));

  return totalVariables / totalCount;
}
