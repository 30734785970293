import { ErrorPage } from "components/Error/ErrorPage";
import { canAccessRegularSurveys } from "components/ProjectSidebar/permissions";
import type { RouteObject } from "react-router-dom";

import { RequireAuth } from "../../components/Routing/RequireAuth";
import { SurveysCreateOrEditPage } from "./pages/CreateOrEdit";
import { SurveyDetailPage } from "./pages/Details";
import { FeelingAtHomeDetailsPage } from "./pages/FeelingAtHomeDetails";
import { SurveysListPage } from "./pages/List";
import { canListAnySurvey, canManageSurvey } from "./permissions";
import { surveyRoutes } from "./routes";

export const surveysRoute = [
  {
    path: surveyRoutes.list,
    element: (
      <RequireAuth permission={canAccessRegularSurveys}>
        <SurveysListPage />
      </RequireAuth>
    ),
  },
  {
    path: surveyRoutes.details,
    element: (
      <RequireAuth permission={canListAnySurvey}>
        <SurveyDetailPage />
      </RequireAuth>
    ),
  },
  {
    path: surveyRoutes.new,
    element: (
      <RequireAuth permission={canManageSurvey}>
        <SurveysCreateOrEditPage />
      </RequireAuth>
    ),
  },
  {
    path: surveyRoutes.edit,
    element: (
      <RequireAuth permission={canManageSurvey}>
        <SurveysCreateOrEditPage />
      </RequireAuth>
    ),
  },
  {
    path: surveyRoutes.fah,
    element: (
      <RequireAuth permission={canListAnySurvey}>
        <FeelingAtHomeDetailsPage />
      </RequireAuth>
    ),
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
