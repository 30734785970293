import { autoCompleteDisabledProperties } from "helpers/auto-complete-helpers";
import { twResolve } from "helpers/tw-resolve";
import type { ForwardedRef } from "react";
import type React from "react";
import { forwardRef } from "react";
import { twJoin } from "tailwind-merge";

export interface InputProps
  extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "prefix"> {
  ref?: ForwardedRef<HTMLInputElement>;
  className?: string;
  prefix?: React.ReactNode;
  postfix?: React.ReactNode;
  isError?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { className, prefix, value, postfix, isError, disabled, readOnly, ...props },
  ref,
) {
  const Component = prefix ? "div" : "label";

  return (
    <Component className="relative">
      {prefix && <InputExtra position="start">{prefix}</InputExtra>}
      <input
        {...props}
        value={value}
        className={twResolve(
          "h-10 w-full appearance-none rounded-lg border border-grey-300 px-2 py-1.5 text-body leading-input placeholder:text-grey-500 hover:border-aop-dark-blue-500 focus-visible:border-aop-dark-blue-500 focus-visible:outline-none",
          (disabled || readOnly) && "border-grey-300 bg-grey-100 text-grey-700 hover:border-grey-300",
          isError && "border-red-600",
          prefix && "pl-8",
          postfix && "pr-8",
          className,
        )}
        // Prevent default scrolling behaviour for number input
        onWheel={props.type === "number" ? (e) => e.currentTarget.blur() : undefined}
        aria-invalid={isError}
        {...{ readOnly, ref, disabled }}
        {...(props.autoComplete === undefined || props.autoComplete === "off" ? autoCompleteDisabledProperties : {})}
      />
      {postfix && <InputExtra position="end">{postfix}</InputExtra>}
    </Component>
  );
});

interface InputExtraProps {
  children: React.ReactNode;
  position: "start" | "end";
}

const InputExtra = ({ children, position }: InputExtraProps) => {
  return (
    <div
      className={twJoin(
        "absolute top-0 flex h-full w-8 items-center justify-center text-grey-400",
        position === "start" && "left-0",
        position === "end" && "right-0",
      )}
    >
      {children}
    </div>
  );
};
