import iconCalendar from "assets/icons/calendar.svg";
import iconCalendarMinus02 from "assets/icons/calendar-minus-02.svg";
import { AnchorButton } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import type { DateAndTimePickerProps, DatePickerValue } from "components/DateAndTimePicker/DateAndTimePicker";
import { DateAndTimePicker } from "components/DateAndTimePicker/DateAndTimePicker";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { addMinutes } from "date-fns";
import { useBool } from "hooks/useBool";
import { useEffect } from "react";
import type { FieldPath, FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import { FormErrorWrapper } from "./FormErrorWrapper";

type Props<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> = UseControllerProps<TFormValues, TName> &
  Pick<DateAndTimePickerProps, "min" | "max"> & {
    labelWhenNoValue?: string;
    canEdit: boolean;
    onChange?: (value: DatePickerValue) => void;
  };

export function FormScheduleInput<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  onChange,
  canEdit,
  labelWhenNoValue,
  ...props
}: Props<TFormValues, TName>): React.ReactNode {
  const { t } = useTranslation();
  const [isScheduleOpen, scheduleHandler] = useBool();

  const {
    field: { onChange: fieldOnChange, ...field },
    fieldState,
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });

  useEffect(() => {
    if (isScheduleOpen && !field.value) {
      fieldOnChange(field.value || defaultValue || addMinutes(new Date(), 5));
    }
  }, [defaultValue, field.value, fieldOnChange, isScheduleOpen]);

  const onClickDiscard = () => {
    fieldOnChange("");
    scheduleHandler.setFalse();
  };

  return (
    <FormErrorWrapper name={name} encircle>
      <div className={twJoin("flex flex-col items-start gap-2")}>
        <div className="flex items-center gap-2">
          <Icon name={iconCalendar} />
          {field.value && (
            <span className="flex flex-wrap gap-x-1 text-body">
              {isScheduleOpen ? (
                t("component.schedule-input.state.scheduled")
              ) : (
                <FormattedDate date={field.value} format="datetime" />
              )}
            </span>
          )}
          {!field.value && (
            <span className="flex flex-wrap gap-x-1 text-body">
              {labelWhenNoValue || t("component.schedule-input.state.immediate")}
            </span>
          )}
          {!isScheduleOpen && (
            <AnchorButton data-testid="schedule-btn" isBold onClick={scheduleHandler.toggle} disabled={!canEdit}>
              {t("common.action.edit")}
            </AnchorButton>
          )}
        </div>
        {isScheduleOpen && (
          <div className="flex w-full max-w-sm flex-col gap-2">
            <DateAndTimePicker
              data-testid="scheduled-date-time"
              value={field.value}
              {...props}
              type="datetime"
              onChange={fieldOnChange}
              error={fieldState.error != null}
              aria-invalid={fieldState.error != null}
            />
            <Button
              onClick={onClickDiscard}
              icon={<Icon name={iconCalendarMinus02} />}
              styling="secondary"
              className="w-full"
            >
              {t("component.schedule-input.discard-btn")}
            </Button>
          </div>
        )}
      </div>
    </FormErrorWrapper>
  );
}
