import { format, parse, parseISO } from "date-fns";

type AnyDate = number | string | Date;

/**
 * Strip time from any date and return as local date with time set to 00:00:00.
 */
export function stripTime(date: AnyDate): Date {
  date = asDate(date);

  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

/**
 * Calculate the number of whole days between two dates, can be negative if `start` is after `end`.
 */
export function daysBetween(start: AnyDate, end: AnyDate): number {
  const timeDiff = asDate(end).getTime() - asDate(start).getTime();

  const timeDiffInDays = timeDiff / (1000 * 3600 * 24);
  if (timeDiffInDays < 0) {
    const result = Math.ceil(timeDiffInDays);

    // If the result is -0, return 0
    return result === 0 ? 0 : result;
  }

  return Math.floor(timeDiffInDays);
}

/**
 * Get the index of the day of the week (0-6) for the given date.
 * 0 is Monday, 6 is Sunday.
 */
export function dayOfWeekIndex(date: AnyDate): number {
  const day = asDate(date).getDay();
  if (day === 0) {
    return 6;
  }

  return day - 1;
}

function asDate(date: AnyDate): Date {
  return typeof date === "string" ? parseISO(date) : new Date(date);
}

export function tryParseISO(date?: string | null): Date | undefined {
  if (!date) {
    return undefined;
  }

  try {
    const d = parseISO(date);
    if (isNaN(d.getTime())) {
      return undefined;
    }

    return d;
  } catch (error) {
    return undefined;
  }
}

export function tryParse(format: string, dateInFormat?: string | null): Date | undefined {
  if (!dateInFormat) {
    return undefined;
  }

  try {
    const d = parse(dateInFormat, format, new Date());
    if (isNaN(d.getTime())) {
      return undefined;
    }

    return d;
  } catch (error) {
    return undefined;
  }
}

export function asUtc(date: AnyDate): Date {
  const d = asDate(date);

  return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
}

export function timeWithoutSeconds(time: string): string {
  return format(parse(time, "HH:mm:ss", new Date()), "HH:mm");
}
