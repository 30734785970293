import { Button } from "components/Button/Button";
import type { FormInputProps } from "components/Form/FormInput";
import { GroupIconMedia } from "components/Media/Media";
import { Modal } from "components/Modal/Modal";
import { isDefined } from "helpers/util";
import { useBool } from "hooks/useBool";
import { get } from "lodash-es";
import { GroupIconPicker } from "modules/community-groups/components/GroupIconPicker";
import type { GroupIconType } from "modules/community-groups/components/GroupIcons";
import { useEffect, useState } from "react";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useFormContext, useFormState, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormErrorWrapper } from "./FormErrorWrapper";

export interface FormIconPickerProps<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> extends FormInputProps<TFormValues, TName> {
  titleText?: string;
  openText?: string;
  cancelText?: string;
  selectText?: string;
}

export function FormGroupIconPicker<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({ name, ...props }: FormIconPickerProps<TFormValues, TName>): React.ReactNode {
  const { t } = useTranslation();
  const { register, setValue } = useFormContext<TFormValues>();
  const { errors } = useFormState<TFormValues>();
  const selectedIcon = (useWatch({ name }) as GroupIconType) || "";
  const error = get(errors, name);
  const [modalIcon, setModalCharIcon] = useState<GroupIconType | null>(null);
  const [isOpen, modalOpenHandlers] = useBool(false);

  useEffect(() => {
    setModalCharIcon(selectedIcon);
  }, [selectedIcon]);

  function openModal() {
    modalOpenHandlers.setTrue();
    setModalCharIcon(selectedIcon ? selectedIcon : null);
  }

  const title = props.titleText || t("component.group-icon-picker-input.title");

  return (
    <FormErrorWrapper className="flex items-start gap-4" name={name} data-testid={props["data-testid"]}>
      <GroupIconMedia
        icon={selectedIcon}
        onAdd={openModal}
        onEdit={openModal}
        aria-invalid={isDefined(error)}
        data-testid="icon-char-media"
        data-edit-testid="icon-char-action-open"
        data-add-testid="icon-char-action-open"
      />
      <input type="hidden" readOnly {...register(name, props.rules)} className="hidden" />
      <Modal.Root title={title} isOpened={isOpen} onOpenChange={modalOpenHandlers.set}>
        <div className="h-[295px] w-full overflow-auto border border-grey-300 sm:p-6">
          <GroupIconPicker selectedIcon={modalIcon} onChange={setModalCharIcon} />
        </div>
        <Modal.Actions>
          <Button
            type="button"
            styling="secondary"
            onClick={() => {
              setModalCharIcon(null);
              modalOpenHandlers.setFalse();
            }}
          >
            {props.cancelText || t("common.action.cancel")}
          </Button>
          <Button
            type="button"
            styling="primary"
            disabled={modalIcon == null}
            onClick={() => {
              if (modalIcon != null) {
                setValue(name, modalIcon as any, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });

                modalOpenHandlers.setFalse();
              }
            }}
            data-testid="icon-char-action-submit"
          >
            {props.selectText || t("common.action.select")}
          </Button>
        </Modal.Actions>
      </Modal.Root>
    </FormErrorWrapper>
  );
}
