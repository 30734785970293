import type { ConstraintListItemDto, UserRoleDocumentFolderPermissionsDto } from "api/types";
import type { FormTranslations } from "helpers/languages";
import type { FormPermissions } from "helpers/permissions";

import { Layout } from "./Layout";
import { Loader } from "./Loader";

export function RootCreateOrEdit(): React.ReactNode {
  return <Loader>{(data) => <Layout {...data} />}</Loader>;
}

export interface FormValues {
  nameTranslations: FormTranslations;
  descriptionTranslations?: FormTranslations;
  audience: ConstraintListItemDto[];
  permissions: FormPermissions<UserRoleDocumentFolderPermissionsDto>;
  iconChar: string;
}

export interface RouteParams extends Record<string, string | undefined> {
  folderId: string;
}
