import type { TicketStatusDto } from "api/types";
import type { TFunction } from "i18next";

export function getTicketStatusTranslation(t: TFunction, type: TicketStatusDto["type"]): string {
  switch (type) {
    case "new":
      return t("model.ticket-status.type.new");
    case "inProgress":
      return t("model.ticket-status.type.in-progress");
    case "rated":
      return t("model.ticket-status.type.rated");
    case "closed":
      return t("model.ticket-status.type.closed");
    case "autoClosed":
      return t("model.ticket-status.type.auto-closed");
    case "rejected":
      return t("model.ticket-status.type.rejected");
    default:
      return type;
  }
}
