import type { EventDetailsDto } from "api/types";
import { PageGrid } from "components/PageGrid/PageGrid";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { useTranslation } from "react-i18next";

import { EventDetails } from "./components/EventDetails";
import { EventDetailsParticipantsSection } from "./components/EventDetailsParticipantsSection";

export interface LayoutProps {
  eventDetails: EventDetailsDto;
}
export function Layout(props: LayoutProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <DocumentPaper theme="minimal" title={t("page.event.details.title")}>
      <PageGrid.Grid>
        <PageGrid.Item size="66%">
          <EventDetails event={props.eventDetails} />
        </PageGrid.Item>
        <PageGrid.Item size="33%">
          <EventDetailsParticipantsSection event={props.eventDetails} />
        </PageGrid.Item>
      </PageGrid.Grid>
    </DocumentPaper>
  );
}
