import { useDocumentTitle } from "hooks/useDocumentTitle";

import type { PaperProps } from "./Paper";
import { Paper } from "./Paper";

export function DocumentPaper(props: PaperProps & { title?: string; tabTitle?: string }): React.ReactNode {
  useDocumentTitle(props.tabTitle || props.title);

  return <Paper {...props} />;
}
