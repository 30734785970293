import { motion } from "framer-motion";

interface SpeedometerProps {
  /**
   * Number between 0 and 1
   */
  score: number;
  fastAnimation?: boolean;
}

const MIN_ROTATION_DEG = -114;
const MAX_ROTATION_DEG = 54;
const TOTAL_ROTATION_DEG = MAX_ROTATION_DEG - MIN_ROTATION_DEG;

export function Speedometer({ score, fastAnimation }: SpeedometerProps): React.ReactNode {
  if (score > 1) {
    console.warn("Speedometer score should be between 0 and 1");
    score = 1;
  }

  if (score < 0) {
    console.warn("Speedometer score should be between 0 and 1");
    score = 0;
  }

  // Bit hacky but this makes it so 2, 4, 6, and 8 are just in the next segment
  if (score > 0.75 && score < 0.96) {
    score += 0.04;
  } else if (score > 0.5 && score < 0.98) {
    score += 0.02;
  }

  const rotation = MIN_ROTATION_DEG + TOTAL_ROTATION_DEG * score;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 160 86">
      <path
        className="fill-green-600"
        d="M157.274 59.294a79.998 79.998 0 00-26.928-41.466L115.242 36.48a56 56 0 0118.85 29.026l23.182-6.212z"
      />
      <path
        className="fill-green-600"
        d="M156 80c2.209 0 4.01-1.792 3.9-3.998a80.01 80.01 0 00-8.705-32.49c-1.008-1.965-3.464-2.617-5.377-1.512l-13.856 8c-1.914 1.105-2.555 3.545-1.59 5.533a55.997 55.997 0 015.485 20.47C136.015 78.207 137.791 80 140 80h16z"
      />
      <path
        className="fill-green-500"
        d="M144.718 32.972a79.999 79.999 0 00-40.002-29.058L97.301 26.74a56 56 0 0128.002 20.34l19.415-14.108z"
      />
      <path
        className="fill-yellow-500"
        d="M104.721 3.915a79.999 79.999 0 00-49.442 0l7.416 22.826a56 56 0 0134.61 0l7.416-22.826z"
      />
      <path
        className="fill-orange"
        d="M55.279 3.915a80 80 0 00-40 29.062l19.416 14.107a56 56 0 0128-20.343L55.279 3.915z"
      />
      <path
        className="fill-red-500"
        d="M18.515 35.328c-1.788-1.298-4.298-.907-5.506.943A80 80 0 00.1 76c-.11 2.208 1.69 4 3.9 4h16c2.21 0 3.985-1.793 4.143-3.997a56.001 56.001 0 018.318-25.602c1.168-1.875.785-4.37-1.002-5.668l-12.944-9.405z"
      />
      <path
        className="fill-red-500"
        d="M15.279 32.977a80 80 0 00-14.84 38.66l23.868 2.51a56 56 0 0110.388-27.063L15.28 32.977z"
      />
      <motion.path
        className="!origin-[80px_80px] fill-aop-dark-blue-500"
        key={rotation}
        fillRule="evenodd"
        d="M75.918 75.514L114.197 21 86.133 81.38a6.924 6.924 0 01-.524 1.133c-1.778 3.077-5.545 4.228-8.414 2.57-2.87-1.657-3.754-5.495-1.977-8.572l.005-.009c.207-.356.44-.686.695-.99z"
        clipRule="evenodd"
        initial={{
          rotate: MIN_ROTATION_DEG,
        }}
        animate={{
          rotate: rotation,
        }}
        transition={{
          delay: fastAnimation ? 0.1 : 0.3,
          duration: fastAnimation ? 0.8 : 2,
          ease: "easeOut",
        }}
      />
      <circle cx="80" cy="80" r="4" fill="#F3F9F6" />
    </svg>
  );
}
