import { LoadingIcon } from "components/Icons/Icons";
import type { FormImage } from "components/ImageInput/useImageInput";
import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useTranslation } from "react-i18next";

import { TicketResidentCommentField } from "./TicketResidentCommentField";

type TicketResidentRejectModalProps = ModalBaseProps & {
  onSubmit: (value: string, image: FormImage | undefined) => void | Promise<void>;
};

export function TicketResidentRejectModal({
  isOpened,
  onOpenChange,
  onSubmit,
}: TicketResidentRejectModalProps): React.ReactNode {
  const [isSubmitting, submittingHandlers] = useBool();

  const { t } = useTranslation();
  const sessionUser = useSessionUser();

  async function submit({ value, image }: { value: string; image: FormImage | undefined }) {
    if (isSubmitting) {
      return;
    }

    try {
      submittingHandlers.setTrue();

      if (value) {
        await onSubmit?.(value, image);
      } else {
        onOpenChange(false);
      }
    } finally {
      submittingHandlers.setFalse();
    }
  }

  return (
    <Modal.Root
      title={t("page.tickets.reject-modal.title")}
      description={t("page.tickets.reject-modal.description")}
      shouldCloseOnEsc={!isSubmitting}
      shouldCloseOnClickOutside={!isSubmitting}
      data-testid="ticket-reject-modal"
      {...{ isOpened, onOpenChange }}
    >
      {isSubmitting ? (
        <LoadingIcon className="mx-auto mb-6 mt-8 w-8" />
      ) : (
        <TicketResidentCommentField
          canComment
          user={sessionUser}
          onComment={(payload) => submit({ value: payload.content, image: payload.image })}
        />
      )}
    </Modal.Root>
  );
}
