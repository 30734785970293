import { ErrorPage } from "components/Error/ErrorPage";
import { canAccessAutomatedSurveys } from "components/ProjectSidebar/permissions";
import type { RouteObject } from "react-router-dom";

import { RequireAuth } from "../../components/Routing/RequireAuth";
import { AutomatedSurveysListPage } from "./pages/List";
import { AutomatedSurveyQueueDetailsPage } from "./pages/QueueDetails";
import { canListAutomatedSurveyQueues } from "./permissions";
import { automatedSurveyQueueRoutes } from "./routes";

export const automatedSurveyQueuesRoute = [
  {
    path: automatedSurveyQueueRoutes.list,
    element: (
      <RequireAuth permission={canAccessAutomatedSurveys}>
        <AutomatedSurveysListPage />
      </RequireAuth>
    ),
  },
  {
    path: automatedSurveyQueueRoutes.queueDetails,
    element: (
      <RequireAuth permission={canListAutomatedSurveyQueues}>
        <AutomatedSurveyQueueDetailsPage />
      </RequireAuth>
    ),
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
