import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type { SurveyDto } from "api/types";
import { Anchor } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Pagination } from "components/Pagination/Pagination";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { SearchInput } from "components/SearchInput/SearchInput";
import { Table } from "components/Table/Table";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { SurveyExportModal } from "modules/surveys/components/SurveyExportModal";
import { canManageSurvey } from "modules/surveys/permissions";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

export interface LayoutProps {
  isLoading: boolean;
  surveys: SurveyDto[];
  page: number;
  totalPages: number;
  totalSurveys?: number;
  searchQuery: string;
  deferredSearchQuery: string;
  onPageChange: (page: number) => void;
  onSearch: (value: string) => void;
  onDelete: (surveyId: SurveyDto) => Promise<unknown>;
}

export function Layout({
  surveys,
  isLoading,
  totalPages,
  totalSurveys,
  page,
  searchQuery,
  deferredSearchQuery,
  onPageChange,
  onDelete,
  onSearch,
}: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const hasPermission = usePermission();
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<SurveyDto>("survey-delete-modal");
  const [surveyExportModalId, setSurveyExportModalId] = useState<string | undefined>();

  const columns = useMemo(() => {
    const helper = createColumnHelper<SurveyDto>();

    return [
      helper.accessor("title", {
        header: t("page.surveys.table.title"),
        cell: (cell) =>
          cell.row.original.canView ? (
            <Anchor to={routes.surveys.details({ slug, id: cell.row.original.id })}>
              <span className="line-clamp-2">{cell.getValue()}</span>
            </Anchor>
          ) : (
            <span className="line-clamp-2">{cell.getValue()}</span>
          ),
      }),
      // TODO(dev): Remove column if "poll" once is considered irrelevant.
      helper.accessor("type", {
        header: t("page.surveys.table.type"),
        cell: (cell) => (
          <span>
            {cell.getValue() === "poll" ? t("page.surveys.table.type.poll") : t("page.surveys.table.type.survey")}
          </span>
        ),
      }),
      helper.accessor("createdBy.fullName", {
        header: t("page.surveys.table.user"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("group.name", {
        header: t("page.surveys.table.group"),
        cell: (cell) => <span>{cell.getValue() || t("page.surveys.table.group.general")}</span>,
      }),
      helper.accessor("startsAt", {
        header: t("page.surveys.table.start"),
        cell: (cell) => {
          const date = cell.getValue();

          return (
            <span className="whitespace-nowrap">
              {date ? <FormattedDate date={date} format="dateMonthYearShort" /> : <span>-</span>}
            </span>
          );
        },
      }),
      helper.accessor("endsAt", {
        header: t("page.surveys.table.end"),
        cell: (cell) => {
          const date = cell.getValue();

          return (
            <span className="whitespace-nowrap">
              {date ? <FormattedDate date={date} format="dateMonthYearShort" /> : <span>-</span>}
            </span>
          );
        },
      }),
      helper.accessor("questionCount", {
        header: t("page.surveys.table.questions"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("answerCount", {
        header: t("page.surveys.table.responses"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("id", {
        header: "",
        cell: (cell) => {
          const id = cell.getValue();
          const actions: ContextMenuAction[] = [];

          if (cell.row.original.answerCount && cell.row.original.answerCount > 0)
            actions.push({
              callback: () => setSurveyExportModalId(id),
              text: t("page.surveys.table.context.export"),
            });

          if (cell.row.original.canDelete) {
            actions.push({
              callback: () => openDeleteModal(cell.row.original),
              text: t("common.action.delete"),
            });
          }

          return (
            <div className="flex justify-end px-2">
              <ContextMenu actions={actions} />
            </div>
          );
        },
      }),
    ];
  }, [openDeleteModal, slug, t]);

  const tableInstance = useReactTable<SurveyDto>({
    columns,
    data: surveys,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.surveys.list.title")}
      subTitle={t("page.surveys.list.subtitle")}
      header={
        <div className="flex items-center justify-between">
          <SearchInput
            value={searchQuery}
            onChange={(e) => onSearch(e.target.value)}
            placeholder={t("page.surveys.list.search.placeholder")}
          />
          {totalSurveys != null ? (
            <p className="pl-2 text-right text-grey-700">
              {t("page.surveys.list.survey-count", { count: totalSurveys })}
            </p>
          ) : null}
        </div>
      }
      actions={
        hasPermission(canManageSurvey) ? (
          <Button type="link" data-testid="create-new-survey-btn" href={routes.surveys.new({ slug })}>
            {t("page.surveys.list.create")}
          </Button>
        ) : null
      }
    >
      {
        // eslint-disable-next-line no-nested-ternary
        surveys.length ? (
          <Table table={tableInstance} isLoading={isLoading}>
            <Pagination count={totalPages} onChange={onPageChange} currentIndex={page} />
          </Table>
        ) : isLoading ? null : (
          <div className="rounded-lg bg-white p-5">
            <p>{deferredSearchQuery ? t("page.surveys.list.no-result") : t("page.surveys.list.empty")}</p>
          </div>
        )
      }
      <DeleteModal
        title={t("page.surveys.actions.delete.title")}
        description={t("page.surveys.actions.delete.description")}
        onDelete={onDelete}
        deleteBtnProps={{
          "data-testid": "survey-action-delete",
        }}
        {...deleteModalProps}
      />
      <SurveyExportModal
        isOpened={!!surveyExportModalId}
        onOpenChange={() => setSurveyExportModalId(undefined)}
        surveyId={surveyExportModalId}
        surveyName={surveyExportModalId ? surveys.find((x) => x.id === surveyExportModalId)?.title : undefined}
      />
    </DocumentPaper>
  );
}
