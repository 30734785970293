import React, { useContext } from "react";

export interface ProjectContextData {
  projectId: ProjectId;
  projectSlug: string;
  /**
   * @param projectId ID of the project to switch to
   * @param redirect whether to redirect to the home page or set to `false` to stay on the page
   * @param forceUpdateProject whether to ignore if the project exists. AVOID USING THIS, should only be used for project creation
   */
  changeProject(projectId: string, redirect: boolean, forceUpdateProject?: "FORCE" | undefined): void;
}

export const ProjectContext = React.createContext<ProjectContextData>({
  projectId: undefined!,
  projectSlug: undefined!,
  changeProject: () => {},
});

export function useProjectContext(): ProjectContextData {
  return useContext(ProjectContext);
}

/**
 * Branded type for type safety. In javascript just a string.
 */
export type ProjectId = string & { __brand: "projectId" };
