import type { DragEndEvent, DragStartEvent } from "@dnd-kit/core";
import { useMutation } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type {
  AutomatedSurveyDto,
  AutomatedSurveyEditRequest,
  AutomatedSurveyRequest,
  LanguageDto,
  SimpleProjectDto,
  TranslateRequest,
} from "api/types";
import warningIcon from "assets/icons/alert-circle.svg";
import chevronLeftIcon from "assets/icons/chevron-left.svg";
import iconGlobe02 from "assets/icons/globe-02.svg";
import trashIcon from "assets/icons/trash-01.svg";
import { AnchorButton } from "components/Anchor/Anchor";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { DynamicRepeater } from "components/DynamicRepeater/DynamicRepeater";
import { DynamicRepeaterItem } from "components/DynamicRepeater/DynamicRepeaterItem";
import { DynamicRepeaterItemOverlay } from "components/DynamicRepeater/DynamicRepeaterItemOverlay";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent, FormSplitContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { FormMultiSelect } from "components/Form/FormMultiSelect";
import { FormSelect } from "components/Form/FormSelect";
import { FormTextArea } from "components/Form/FormTextArea";
import { Icon } from "components/Icon/Icon";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { ToggleButtonGroup } from "components/ToggleButtonGroup/ToggleButtonGroup";
import { getFlagIcon } from "helpers/flags";
import { createFormTranslations, type FormTranslations, toTranslationsRequest } from "helpers/languages";
import { createRequiredStringRule } from "helpers/rules";
import { parseAsNumber, sortAlphabetically } from "helpers/util";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { sortBy } from "lodash-es";
import { useSystemSettingBreadcrumbs } from "modules/system-settings/util/useSystemSettingBreadcrumbs";
import { useMemo, useState } from "react";
import { useFieldArray, useForm, useFormContext, useWatch } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "routes";

import type {
  AutomatedSurveyQuestionCategory,
  AutomatedSurveyQuestionCategoryId,
  AutomatedSurveyType,
} from "../../constants";
import { automatedSurveyQuestionCategories, automatedSurveyTypes } from "../../constants";
import { FormQuestion } from "./components/FormQuestion";
import { MAX_LENGTH_QUESTION } from "./constants";

interface Question {
  qId?: string;
  category: AutomatedSurveyQuestionCategory;
  explanation: FormTranslations;
  ratingQuestion: FormTranslations;
  openQuestion: FormTranslations;
}

export interface FormValues {
  name: FormTranslations;
  description: FormTranslations;
  thanksMessage: FormTranslations;
  interval: string;
  minSampleSize: string;
  type: AutomatedSurveyType;
  projects: SimpleProjectDto[];
  active: boolean;
  questions: Question[];
}

export interface LayoutProps {
  defaultFormValues: FormValues;
  onCreate: (payload: AutomatedSurveyRequest) => Promise<AutomatedSurveyDto>;
  onEdit: (payload: AutomatedSurveyEditRequest) => Promise<void>;
  isSubmitting: boolean;
  languages: LanguageDto[];
}

const MAX_LENGTH_NAME = 255;
const MAX_LENGTH_DESCRIPTION = 5000;
const MAX_LENGTH_THANK_YOU_MESSAGE = 5000;
const MIN_INTERVAL = 1;
const MIN_SAMPLE_SIZE = 1;

export function Layout({ defaultFormValues, onCreate, onEdit, isSubmitting, languages }: LayoutProps): React.ReactNode {
  const { id: surveyId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sessionUser = useSessionUser();
  const [isSaveAndActivateModalOpen, saveAndActivateModalHandler] = useBool(false);
  const [isGoBackModalOpen, goBackModalHandler] = useBool(false);
  const [isMissingCatModalOpen, missingCatModalHandler] = useBool(false);
  const [missingCategories, setMissingCategories] = useState<string[]>([]);
  const form = useForm<FormValues>({ defaultValues: defaultFormValues });

  const breadcrumbs = useSystemSettingBreadcrumbs({
    module: "automated-surveys",
    status: surveyId ? "edit" : "create",
  });

  function getQuestionCategoryLabel(x: AutomatedSurveyQuestionCategoryId) {
    switch (x) {
      case "building":
        return t("page.automated-surveys.question-type.building");
      case "community":
        return t("page.automated-surveys.question-type.community");
      case "service":
        return t("page.automated-surveys.question-type.service");
      case "feelAtHome":
        return t("page.automated-surveys.question-type.feeling-at-home");
      case "other":
        return t("page.automated-surveys.types.other");
    }
  }

  function checkMandatoryQuestionCategories() {
    const questions = form.getValues("questions");
    const missingCategories = [];
    const mandatoryCategories = automatedSurveyQuestionCategories.filter((category) => category.mandatory);
    for (let i = 0; i < mandatoryCategories.length; i++) {
      let count = 0;
      for (let j = 0; j < questions.length; j++) {
        if (questions[j].category.id === mandatoryCategories[i].id) {
          count++;
        }
      }
      if (count === 0) {
        missingCategories.push(getQuestionCategoryLabel(mandatoryCategories[i].id));
      }
    }

    return { isValid: !missingCategories.length, missingCategories };
  }

  async function handleCreateAutomatedSurvey() {
    saveAndActivateModalHandler.setFalse();
    const values = form.getValues();

    const payload = {
      titleTranslations: toTranslationsRequest(values.name),
      descriptionTranslations: toTranslationsRequest(values.description),
      thankYouMessageTranslations: toTranslationsRequest(values.thanksMessage),
      weeklyInterval: parseAsNumber(values.interval) || 0,
      usersSampleSize: parseAsNumber(values.minSampleSize) || 0,
      category: values.type,
      questionGroups: values.questions.map((value, idx) => ({
        ratingQuestionTemplateTranslations: toTranslationsRequest(value.ratingQuestion),
        openQuestionTemplateTranslations: toTranslationsRequest(value.openQuestion),
        explanationTranslations: toTranslationsRequest(value.explanation),
        type: value.category.id,
        order: idx,
      })),
      enabledProjectsIds: values.projects.map((project) => project.id),
      isLocked: values.active,
    } satisfies AutomatedSurveyRequest;

    await onCreate(payload);
  }

  async function handleEditAutomatedSurvey() {
    saveAndActivateModalHandler.setFalse();
    const values = form.getValues();

    const payload = {
      id: surveyId!,
      titleTranslations: toTranslationsRequest(values.name),
      descriptionTranslations: toTranslationsRequest(values.description),
      thankYouMessageTranslations: toTranslationsRequest(values.thanksMessage),
      weeklyInterval: parseAsNumber(values.interval) || 0,
      usersSampleSize: parseAsNumber(values.minSampleSize) || 0,
      category: values.type,
      questionGroups: values.questions.map((value, idx) => ({
        id: value.qId!,
        ratingQuestionTemplateTranslations: toTranslationsRequest(value.ratingQuestion),
        explanationTranslations: toTranslationsRequest(value.explanation),
        openQuestionTemplateTranslations: toTranslationsRequest(value.openQuestion),
        type: value.category.id,
        order: idx,
      })),
      enabledProjectsIds: values.projects.map((project) => project.id),
      isLocked: values.active,
    } satisfies AutomatedSurveyEditRequest;

    await onEdit(payload);
  }

  async function handleSave() {
    const mandatoryCategoriesCheck = checkMandatoryQuestionCategories();
    if (!mandatoryCategoriesCheck.isValid) {
      setMissingCategories(mandatoryCategoriesCheck.missingCategories);
      missingCatModalHandler.setTrue();
    } else if (!defaultFormValues.active && form.getValues("active")) {
      saveAndActivateModalHandler.setTrue();
    } else {
      if (surveyId) {
        await handleEditAutomatedSurvey();
      } else {
        await handleCreateAutomatedSurvey();
      }
    }
  }

  // We don't actually store whether we have a language, so we derive it instead. And it's possible it's only partially filled in.
  const defaultUsedLanguages = Array.from(
    new Set([
      ...Object.entries(defaultFormValues.name)
        .filter((x) => x[1])
        .map((x) => x[0]),
      ...Object.entries(defaultFormValues.description)
        .filter((x) => x[1])
        .map((x) => x[0]),
      ...Object.entries(defaultFormValues.thanksMessage)
        .filter((x) => x[1])
        .map((x) => x[0]),
      ...Object.entries(defaultFormValues.questions[0]?.ratingQuestion || {})
        .filter((x) => x[1])
        .map((x) => x[0]),
      ...Object.entries(defaultFormValues.questions[0]?.openQuestion || {})
        .filter((x) => x[1])
        .map((x) => x[0]),
      ...Object.entries(defaultFormValues.questions[0]?.explanation || {})
        .filter((x) => x[1])
        .map((x) => x[0]),
    ]),
  ) as LanguageDto["id"][];

  const [usedLanguages, setUsedLanguages] = useState(
    defaultUsedLanguages.length === 0 ? [sessionUser.language.id] : defaultUsedLanguages,
  );

  const defaultLanguage =
    defaultUsedLanguages.find((x) => x == sessionUser.language.id) ||
    defaultUsedLanguages[0] ||
    sessionUser.language.id;
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const options = useMemo(
    () =>
      sortBy(
        languages
          .filter((x) => usedLanguages.includes(x.id))
          .map((l) => ({
            label: (
              <span className="flex flex-row items-center gap-2">
                {getFlagIcon({ languageId: l.id, width: 16 })}
                <span>{l.description}</span>
              </span>
            ),
            value: l.id,
          })),
        // User language (or main language) first, then English, then the rest
        // eslint-disable-next-line no-nested-ternary
        (x) => (x.value === defaultLanguage ? -1 : x.value === "en" ? 0 : 1),
      ),
    [defaultLanguage, languages, usedLanguages],
  );

  return (
    <DocumentPaper
      theme={selectedLanguage === defaultLanguage ? "constrained" : "minimal-constrained"}
      title={surveyId ? t("page.automated-surveys.edit.title") : t("page.automated-surveys.new.title")}
      subTitle={<Breadcrumbs pages={breadcrumbs} />}
      header={
        <div className="flex flex-wrap justify-between gap-2">
          <div className="flex flex-wrap items-center gap-4">
            <p>{t("page.automated-surveys.new.form.configured-languages")}</p>
            <ToggleButtonGroup
              options={options}
              onChange={(x) => setSelectedLanguage(x.value)}
              selected={options.find((x) => x.value === selectedLanguage) || options[0]}
            />
          </div>
          {languages.length > usedLanguages.length && (
            <div className="flex flex-row items-center gap-2">
              <span>{t("page.automated-surveys.new.form.add-language")}</span>
              <div className="flex flex-row flex-wrap gap-2">
                {languages
                  .filter((x) => !usedLanguages.includes(x.id))
                  .map((l) => (
                    <Button
                      key={l.id}
                      styling="secondary"
                      onClick={() => {
                        setUsedLanguages([...usedLanguages, l.id]);
                        setSelectedLanguage(l.id);
                      }}
                      icon={getFlagIcon({ languageId: l.id, width: 16 })}
                    >
                      {l.description}
                    </Button>
                  ))}
              </div>
            </div>
          )}
        </div>
      }
    >
      <Form formMethods={form} onSubmit={handleSave}>
        {selectedLanguage === defaultLanguage ? (
          <QuestionFormContent
            defaultFormValues={defaultFormValues}
            defaultLanguage={defaultLanguage}
            isSubmitting={isSubmitting}
            usedLanguages={usedLanguages}
            languages={languages}
            onChangeLanguage={setSelectedLanguage}
          />
        ) : (
          <TranslatedFormContent
            defaultLanguage={defaultLanguage}
            language={selectedLanguage}
            onRemoveLanguage={() => {
              form.setValue(`name.${selectedLanguage}`, "");
              form.setValue(`description.${selectedLanguage}`, "");
              form.setValue(`thanksMessage.${selectedLanguage}`, "");
              form.getValues("questions").forEach((question, idx) => {
                form.setValue(`questions.${idx}.ratingQuestion.${selectedLanguage}`, "");
                form.setValue(`questions.${idx}.openQuestion.${selectedLanguage}`, "");
                form.setValue(`questions.${idx}.explanation.${selectedLanguage}`, "");
              });
              setUsedLanguages(usedLanguages.filter((x) => x !== selectedLanguage));
              setSelectedLanguage(defaultLanguage);
            }}
            onCloseTranslate={() => setSelectedLanguage(defaultLanguage)}
          />
        )}
      </Form>
      <ConfirmModal
        id="automated-survey-save-active-modal"
        title={t("page.automated-surveys.new.save-active-modal.title")}
        description={t("page.automated-surveys.new.save-active-modal.description")}
        isLoading={false}
        onReject={saveAndActivateModalHandler.setFalse}
        onOpenChange={saveAndActivateModalHandler.set}
        rejectBtnProps={{
          "data-testid": "automated-survey-save-active-modal-cancel",
        }}
        onResolve={() => {
          if (surveyId) {
            void handleEditAutomatedSurvey();
          } else {
            void handleCreateAutomatedSurvey();
          }
          saveAndActivateModalHandler.setFalse();
        }}
        resolveBtnProps={{
          text: t("common.action.confirm"),
          "data-testid": "automated-survey-save-active-modal-confirm",
        }}
        isOpen={isSaveAndActivateModalOpen}
        shouldCloseOnEsc
        data-testid="automated-survey-save-active-modal"
      />
      <ConfirmModal
        id="automated-survey-go-back-modal"
        title={t("page.automated-surveys.new.go-back-modal.title")}
        description={t("page.automated-surveys.new.go-back-modal.description")}
        isLoading={false}
        theme="danger"
        isOpen={isGoBackModalOpen}
        shouldCloseOnEsc
        onReject={goBackModalHandler.setFalse}
        onOpenChange={goBackModalHandler.set}
        rejectBtnProps={{
          "data-testid": "automated-survey-go-back-modal-cancel",
        }}
        onResolve={() => navigate(routes.automatedSurveys.list())}
        resolveBtnProps={{
          text: t("common.action.confirm"),
          "data-testid": "automated-survey-go-back-modal-confirm",
        }}
        data-testid="automated-survey-go-back-modal"
      />
      <ConfirmModal
        id="automated-survey-missing-questions-modal"
        title={t("page.automated-surveys.new.missing-questions-modal.title")}
        description={t("page.automated-surveys.new.missing-questions-modal.description", {
          categories: missingCategories.join(", "),
        })}
        theme="danger"
        isLoading={false}
        onReject={missingCatModalHandler.setFalse}
        onOpenChange={missingCatModalHandler.set}
        rejectBtnProps={{
          "data-testid": "automated-survey-missing-questions-modal-cancel",
        }}
        onResolve={missingCatModalHandler.setFalse}
        resolveBtnProps={{
          text: t("common.action.confirm"),
          "data-testid": "automated-survey-missing-questions-modal-confirm",
        }}
        isOpen={isMissingCatModalOpen}
        shouldCloseOnEsc
        data-testid="automated-survey-missing-questions-modal"
      />
    </DocumentPaper>
  );
}

function QuestionFormContent({
  defaultFormValues,
  defaultLanguage,
  usedLanguages,
  languages,
  isSubmitting,
  onChangeLanguage,
}: {
  defaultFormValues: FormValues;
  defaultLanguage: LanguageDto["id"];
  usedLanguages: LanguageDto["id"][];
  languages: LanguageDto[];
  isSubmitting: boolean;
  onChangeLanguage: (language: LanguageDto["id"]) => void;
}) {
  const sessionUser = useSessionUser();
  const { t } = useTranslation();
  const { data: projects = [] } = useConnectedProjects();

  const [dragItemId, setDragItemId] = useState<number | null>(null);
  const { setValue } = useFormContext<FormValues>();
  const { fields, update, append, remove, move } = useFieldArray<FormValues, "questions">({
    name: "questions",
  });

  function addAllProjects() {
    setValue("projects", projects);
  }

  function clearProjectSelection() {
    setValue("projects", []);
  }

  function handleDragStart(event: DragStartEvent) {
    setDragItemId(event.active.data.current?.sortable.index);
  }

  function handleDragEnd(event: DragEndEvent) {
    setDragItemId(null);
    const { active, over } = event;

    if (over && active.id !== over.id) {
      move(active.data.current?.sortable.index, over?.data.current?.sortable.index);
    }
  }

  return (
    <FormContent maxWidth="2xl">
      <FormField label={t("page.automated-surveys.new.form.name.label")} required>
        <FormInput<FormValues>
          name={`name.${defaultLanguage}`}
          placeholder={t("page.automated-surveys.new.form.name.placeholder")}
          rules={{
            maxLength: {
              message: t("components.form.error.max-length", { length: MAX_LENGTH_NAME }),
              value: MAX_LENGTH_NAME,
            },
            validate: {
              required: createRequiredStringRule(t, "page.automated-surveys.new.form.name.label"),
            },
          }}
        />
      </FormField>
      <FormSplitContent>
        <FormField label={t("page.automated-surveys.new.form.description.label")} required>
          <FormTextArea<FormValues>
            name={`description.${defaultLanguage}`}
            placeholder={t("page.automated-surveys.new.form.description.placeholder")}
            rules={{
              maxLength: {
                message: t("components.form.error.max-length", { length: MAX_LENGTH_DESCRIPTION }),
                value: MAX_LENGTH_DESCRIPTION,
              },
              validate: {
                required: createRequiredStringRule(t, "page.automated-surveys.new.form.description.label"),
              },
            }}
          />
        </FormField>
        <FormField label={t("page.automated-surveys.new.form.thanks-message.label")} required>
          <FormTextArea<FormValues>
            name={`thanksMessage.${defaultLanguage}`}
            placeholder={t("page.automated-surveys.new.form.thanks-message.placeholder")}
            rules={{
              maxLength: {
                message: t("components.form.error.max-length", { length: MAX_LENGTH_THANK_YOU_MESSAGE }),
                value: MAX_LENGTH_THANK_YOU_MESSAGE,
              },
              validate: {
                required: createRequiredStringRule(t, "page.automated-surveys.new.form.thanks-message.label"),
              },
            }}
          />
        </FormField>
      </FormSplitContent>
      <FormSplitContent>
        <FormField label={t("page.automated-surveys.new.form.interval.label")} required>
          <FormInput<FormValues>
            name="interval"
            placeholder={t("page.automated-surveys.new.form.interval.placeholder")}
            inputMode="numeric"
            type="number"
            rules={{
              required: t("components.form.error.required", {
                inputName: t("page.automated-surveys.new.form.interval.label"),
              }),
              min: {
                message: t("page.automated-surveys.new.form.interval.rules.min", { count: MIN_INTERVAL }),
                value: MIN_INTERVAL,
              },
            }}
          />
        </FormField>
        <FormField label={t("page.automated-surveys.new.form.min-sample.label")} required>
          <FormInput<FormValues>
            name="minSampleSize"
            placeholder={t("page.automated-surveys.new.form.min-sample.placeholder")}
            inputMode="numeric"
            type="number"
            rules={{
              required: t("components.form.error.required", {
                inputName: t("page.automated-surveys.new.form.min-sample.label"),
              }),
              min: {
                message: t("page.automated-surveys.new.form.min-sample.rules.min", { count: MIN_SAMPLE_SIZE }),
                value: MIN_SAMPLE_SIZE,
              },
            }}
          />
        </FormField>
        <FormField label={t("page.automated-surveys.new.form.type.label")} required>
          <FormSelect<FormValues, AutomatedSurveyType>
            name="type"
            placeholder={t("page.automated-surveys.new.form.type.placeholder")}
            items={automatedSurveyTypes}
            keySelector={(x) => x}
            renderOption={(x) => {
              switch (x) {
                case "other":
                  return t("page.automated-surveys.types.other");
                case "feelAtHome":
                  return t("page.automated-surveys.types.feeling-at-home");
              }
            }}
            rules={{
              required: t("components.form.error.required", {
                inputName: t("page.automated-surveys.new.form.type.label"),
              }),
            }}
            disabled={defaultFormValues.active}
          />
        </FormField>
      </FormSplitContent>
      <div className="flex flex-col items-start gap-2 sm:flex-row sm:items-end">
        <FormField label={t("page.automated-surveys.new.form.projects.label")} required>
          <FormMultiSelect<FormValues, "projects">
            name="projects"
            placeholder={t("page.automated-surveys.new.form.projects.placeholder")}
            items={projects ? projects : []}
            sortSelectedItems={sortAlphabetically}
            keySelector={(x) => x.id}
            renderOption={(x) => x.name}
            rules={{
              required: t("components.form.error.required", {
                inputName: t("page.automated-surveys.new.form.projects.label"),
              }),
            }}
          />
        </FormField>
        <div className="flex gap-2">
          <Button styling="secondary" onClick={addAllProjects}>
            {t("component.create-message-multiple-projects.form.to.add-all")}
          </Button>
          <Button styling="danger" onClick={clearProjectSelection}>
            {t("component.create-message-multiple-projects.form.to.clear-all")}
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-4 rounded-lg bg-white p-4 ring-1 ring-grey-300">
        <DynamicRepeater
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          itemIds={fields.map((field) => field.id)}
        >
          {fields.map((question, idx) => (
            <DynamicRepeaterItem
              key={question.id}
              id={question.id}
              title={t("page.automated-surveys.new.question.label", { count: idx + 1 })}
              disabled={defaultFormValues.active}
            >
              <FormQuestion
                idx={idx}
                language={defaultLanguage}
                question={question}
                categories={
                  sessionUser.isSuperAdmin
                    ? automatedSurveyQuestionCategories
                    : automatedSurveyQuestionCategories.filter((cat) => cat.id !== "feelAtHome")
                }
                showDelete={
                  !defaultFormValues.active &&
                  (!question.category.mandatory
                    ? true
                    : fields.reduce(
                        (counter, field) => (question.category.id === field.category.id ? (counter += 1) : counter),
                        0,
                      ) > 1)
                }
                updateField={update}
                removeField={remove}
                disabled={defaultFormValues.active}
              />
            </DynamicRepeaterItem>
          ))}
          <DynamicRepeaterItemOverlay
            show={dragItemId !== null}
            title={t("page.automated-surveys.new.question.label", { count: dragItemId! + 1 })}
          >
            <FormQuestion
              idx={dragItemId!}
              language={defaultLanguage}
              question={fields[dragItemId!]}
              categories={automatedSurveyQuestionCategories}
              showDelete={false}
              updateField={() => null}
              removeField={() => null}
              disabled={defaultFormValues.active}
            />
          </DynamicRepeaterItemOverlay>
        </DynamicRepeater>
      </div>
      {!defaultFormValues.active && (
        <Button
          styling="secondary"
          onClick={() =>
            append({
              category: automatedSurveyQuestionCategories[0],
              explanation: createFormTranslations({ languages }),
              ratingQuestion: createFormTranslations({ languages }),
              openQuestion: createFormTranslations({ languages }),
            })
          }
        >
          {t("page.automated-surveys.new.question.add")}
        </Button>
      )}
      <div className="text-right">
        <MissingLanguagesWarning
          languages={languages}
          usedLanguages={usedLanguages}
          defaultLanguage={defaultLanguage}
          onChangeLanguage={onChangeLanguage}
        />
      </div>
      <div className="flex justify-between gap-2">
        <FormCheckbox<FormValues>
          name="active"
          label={t("page.automated-surveys.new.form.active.label")}
          disabled={defaultFormValues.active}
        />
        <Button type="submit" isLoading={isSubmitting}>
          {t("page.automated-surveys.new.form.submit")}
        </Button>
      </div>
    </FormContent>
  );
}

function MissingLanguagesWarning({
  languages,
  usedLanguages,
  defaultLanguage,
  onChangeLanguage,
}: {
  usedLanguages: LanguageDto["id"][];
  languages: LanguageDto[];
  defaultLanguage: LanguageDto["id"];
  onChangeLanguage: (language: LanguageDto["id"]) => void;
}) {
  const name = useWatch<FormValues, "name">({ name: "name" });
  const description = useWatch<FormValues, "description">({ name: "description" });
  const thanksMessage = useWatch<FormValues, "thanksMessage">({ name: "thanksMessage" });
  const questions = useWatch<FormValues, "questions">({ name: "questions" });

  function getLanguagesWithMissingTranslations(formValues: {
    name: FormTranslations;
    description: FormTranslations;
    thanksMessage: FormTranslations;
    questions: Question[];
  }) {
    if (usedLanguages.length === 1) {
      return [];
    }

    const languages = new Set<LanguageDto["id"]>();

    if (formValues.name[defaultLanguage]) {
      for (const language of usedLanguages) {
        if (!formValues.name[language]) {
          languages.add(language);
        }
      }
    }

    if (formValues.description[defaultLanguage]) {
      for (const language of usedLanguages) {
        if (!formValues.description[language]) {
          languages.add(language);
        }
      }
    }

    if (formValues.thanksMessage[defaultLanguage]) {
      for (const language of usedLanguages) {
        if (!formValues.thanksMessage[language]) {
          languages.add(language);
        }
      }
    }

    for (const element of formValues.questions) {
      for (const language of usedLanguages) {
        if (element.ratingQuestion[defaultLanguage] && !element.ratingQuestion[language]) {
          languages.add(language);
        }

        if (element.openQuestion[defaultLanguage] && !element.openQuestion[language]) {
          languages.add(language);
        }

        if (element.explanation[defaultLanguage] && !element.explanation[language]) {
          languages.add(language);
        }
      }
    }

    return Array.from(languages);
  }

  const missingTranslations = getLanguagesWithMissingTranslations({ name, description, thanksMessage, questions });

  if (missingTranslations.length) {
    return (
      <span className="flex flex-row items-center justify-end gap-2 text-yellow-600">
        <Icon name={warningIcon} size={16} />
        <span>
          <Trans
            i18nKey="page.automated-surveys.new.form.missing-translations-warning"
            components={{
              languages: (
                <span>
                  {missingTranslations.map((x, i) => (
                    <span key={x}>
                      {i !== 0 && <span className="mr-1">,</span>}
                      <AnchorButton style="inherit" isBold onClick={() => onChangeLanguage(x)}>
                        {languages.find((l) => l.id === x)?.description}
                      </AnchorButton>
                    </span>
                  ))}
                </span>
              ),
            }}
          />
        </span>
      </span>
    );
  }

  return null;
}

function TranslatedFormContent({
  defaultLanguage,
  language,
  onCloseTranslate,
  onRemoveLanguage,
}: {
  defaultLanguage: LanguageDto["id"];
  language: LanguageDto["id"];
  onCloseTranslate: () => void;
  onRemoveLanguage: () => void;
}) {
  const { t } = useTranslation();

  const { getValues, setValue } = useFormContext();
  const title = useWatch<FormValues, `name.${typeof defaultLanguage}`>({ name: `name.${defaultLanguage}` });
  const description = useWatch<FormValues, `description.${typeof defaultLanguage}`>({
    name: `description.${defaultLanguage}`,
  });
  const thanksMessage = useWatch<FormValues, `thanksMessage.${typeof defaultLanguage}`>({
    name: `thanksMessage.${defaultLanguage}`,
  });
  const questionGroups = useWatch<FormValues, "questions">({ name: "questions" });

  const api = useApi();
  const [translatingFields, setTranslatingFields] = useState<string[]>([]);
  const { mutateAsync: translate } = useMutation({
    mutationFn: (payload: TranslateRequest) => api.postTranslationsTranslateV1(payload).then((x) => x.data),
  });
  async function onTranslate(
    field:
      | "name"
      | "description"
      | "thanksMessage"
      | `questions.${number}.ratingQuestion`
      | `questions.${number}.openQuestion`
      | `questions.${number}.explanation`,
    fromLanguageId: LanguageDto["id"],
    toLanguageId: LanguageDto["id"],
  ) {
    const value = getValues(`${field}.${fromLanguageId}`) as string;
    if (!value) {
      return;
    }

    setTranslatingFields((x) => [...x, field]);

    try {
      const result = await translate({
        languages: [toLanguageId],
        text: value,
      });
      if (result) {
        for (const translation of result) {
          setValue(`${field}.${translation.languages}`, translation.text);
        }
      }
    } finally {
      setTranslatingFields((x) => x.filter((y) => y !== field));
    }
  }

  return (
    <>
      <div className="flex justify-end bg-white p-4">
        <IconButton
          styling="danger"
          onClick={onRemoveLanguage}
          title={t("page.automated-surveys.new.form.remove-language")}
        >
          <Icon name={trashIcon} />
        </IconButton>
      </div>
      <div className="bg-white px-5 py-4 xs:rounded-lg">
        <FormContent maxWidth="2xl">
          <FormField
            label={t("page.automated-surveys.new.form.name.label")}
            required
            actions={
              <Button
                styling="ghostPrimary"
                onClick={() => onTranslate("name", defaultLanguage, language)}
                icon={<Icon name={iconGlobe02} size={16} />}
                isLoading={translatingFields.includes("name")}
              >
                {t("page.automated-surveys.new.form.translate")}
              </Button>
            }
          >
            <div className="flex flex-col gap-2">
              <FormInput<FormValues> readOnly name={`name.${defaultLanguage}`} />
              <FormInput<FormValues>
                disabled={!title}
                name={`name.${language}`}
                rules={{
                  maxLength: {
                    message: t("components.form.error.max-length", { length: MAX_LENGTH_NAME }),
                    value: MAX_LENGTH_NAME,
                  },
                }}
              />
            </div>
          </FormField>

          <FormField
            label={t("page.automated-surveys.new.form.description.label")}
            required
            actions={
              <Button
                styling="ghostPrimary"
                onClick={() => onTranslate("description", defaultLanguage, language)}
                icon={<Icon name={iconGlobe02} size={16} />}
                isLoading={translatingFields.includes("description")}
              >
                {t("page.automated-surveys.new.form.translate")}
              </Button>
            }
          >
            <div className="flex flex-col gap-2">
              <FormTextArea<FormValues> readOnly name={`description.${defaultLanguage}`} />
              <FormTextArea<FormValues>
                disabled={!description}
                id="description"
                name={`description.${language}`}
                rules={{
                  maxLength: {
                    message: t("components.form.error.max-length", { length: MAX_LENGTH_DESCRIPTION }),
                    value: MAX_LENGTH_DESCRIPTION,
                  },
                }}
              />
            </div>
          </FormField>

          <FormField
            label={t("page.automated-surveys.new.form.thanks-message.label")}
            required
            actions={
              <Button
                styling="ghostPrimary"
                onClick={() => onTranslate("thanksMessage", defaultLanguage, language)}
                icon={<Icon name={iconGlobe02} size={16} />}
                isLoading={translatingFields.includes("thanksMessage")}
              >
                {t("page.automated-surveys.new.form.translate")}
              </Button>
            }
          >
            <div className="flex flex-col gap-2">
              <FormTextArea<FormValues> readOnly name={`thanksMessage.${defaultLanguage}`} />
              <FormTextArea<FormValues>
                disabled={!thanksMessage}
                name={`thanksMessage.${language}`}
                rules={{
                  maxLength: {
                    message: t("components.form.error.max-length", { length: MAX_LENGTH_THANK_YOU_MESSAGE }),
                    value: MAX_LENGTH_THANK_YOU_MESSAGE,
                  },
                }}
              />
            </div>
          </FormField>
        </FormContent>
      </div>
      {questionGroups.map((questionGroup, idx) => (
        <div key={idx} className="mt-4 flex flex-col gap-4 bg-white px-5 py-4 xs:rounded-lg">
          <span className="text-body-bold">{t("page.automated-surveys.new.question.label", { count: idx + 1 })}</span>

          <FormContent maxWidth="2xl">
            <FormField
              label={t("page.automated-surveys.new.form.rating-question.label")}
              required
              actions={
                <Button
                  styling="ghostPrimary"
                  onClick={() => onTranslate(`questions.${idx}.ratingQuestion`, defaultLanguage, language)}
                  icon={<Icon name={iconGlobe02} size={16} />}
                  isLoading={translatingFields.includes(`questions.${idx}.ratingQuestion`)}
                >
                  {t("page.automated-surveys.new.form.translate")}
                </Button>
              }
            >
              <div className="flex flex-col gap-2">
                <FormInput<FormValues> readOnly name={`questions.${idx}.ratingQuestion.${defaultLanguage}`} />
                <FormInput<FormValues>
                  disabled={!questionGroup.ratingQuestion[defaultLanguage]}
                  name={`questions.${idx}.ratingQuestion.${language}`}
                  rules={{
                    maxLength: {
                      message: t("components.form.error.max-length", { length: MAX_LENGTH_QUESTION }),
                      value: MAX_LENGTH_QUESTION,
                    },
                  }}
                />
              </div>
            </FormField>
            <FormField
              label={t("page.automated-surveys.new.form.open-question.label")}
              actions={
                <Button
                  styling="ghostPrimary"
                  onClick={() => onTranslate(`questions.${idx}.openQuestion`, defaultLanguage, language)}
                  icon={<Icon name={iconGlobe02} size={16} />}
                  isLoading={translatingFields.includes(`questions.${idx}.openQuestion`)}
                >
                  {t("page.automated-surveys.new.form.translate")}
                </Button>
              }
            >
              <div className="flex flex-col gap-2">
                <FormInput<FormValues> readOnly name={`questions.${idx}.openQuestion.${defaultLanguage}`} />
                <FormInput<FormValues>
                  disabled={!questionGroup.openQuestion[defaultLanguage]}
                  name={`questions.${idx}.openQuestion.${language}`}
                  rules={{
                    maxLength: {
                      message: t("components.form.error.max-length", { length: MAX_LENGTH_QUESTION }),
                      value: MAX_LENGTH_QUESTION,
                    },
                  }}
                />
              </div>
            </FormField>
            <FormField
              label={t("page.automated-surveys.new.form.question-explanation.label")}
              actions={
                <Button
                  styling="ghostPrimary"
                  onClick={() => onTranslate(`questions.${idx}.explanation`, defaultLanguage, language)}
                  icon={<Icon name={iconGlobe02} size={16} />}
                  isLoading={translatingFields.includes(`questions.${idx}.explanation`)}
                >
                  {t("page.automated-surveys.new.form.translate")}
                </Button>
              }
            >
              <div className="flex flex-col gap-2">
                <FormInput<FormValues> readOnly name={`questions.${idx}.explanation.${defaultLanguage}`} />
                <FormInput<FormValues>
                  disabled={!questionGroup.explanation[defaultLanguage]}
                  name={`questions.${idx}.explanation.${language}`}
                  rules={{
                    maxLength: {
                      message: t("components.form.error.max-length", { length: MAX_LENGTH_QUESTION }),
                      value: MAX_LENGTH_QUESTION,
                    },
                  }}
                />
              </div>
            </FormField>
          </FormContent>
        </div>
      ))}
      <div className="mt-8 flex justify-center">
        <Button styling="tertiary" icon={<Icon name={chevronLeftIcon} size={16} />} onClick={onCloseTranslate}>
          {t("page.automated-surveys.new.form.translate.go-back")}
        </Button>
      </div>
    </>
  );
}
