import type { ConstraintListItemDto } from "api/types";
import iconBriefcase02 from "assets/icons/briefcase-02.svg";
import iconBuilding01 from "assets/icons/building-01.svg";
import iconHome02 from "assets/icons/home-02.svg";
import iconLayersThree01 from "assets/icons/layers-three-01.svg";
import { groupBy } from "lodash-es";

type AudienceType = ConstraintListItemDto["type"];

export const mapAudienceByType = (
  listAudience: ConstraintListItemDto[],
): Record<ConstraintListItemDto["type"], ConstraintListItemDto[]> => {
  return groupBy(listAudience, (audience) => audience.type) as Record<
    ConstraintListItemDto["type"],
    ConstraintListItemDto[]
  >;
};

export const flattenAudience = (
  audience: Record<ConstraintListItemDto["type"], ConstraintListItemDto[]>,
): ConstraintListItemDto[] => {
  return Object.values(audience).flat();
};

export const orderAudienceTypes = (audienceTypes: AudienceType[]): AudienceType[] => {
  const desiredAudienceTypeOrder: ConstraintListItemDto["type"][] = ["apartmentType", "building", "floor", "company"];

  return audienceTypes.sort(
    (typeA, typeB) => desiredAudienceTypeOrder.indexOf(typeA) - desiredAudienceTypeOrder.indexOf(typeB),
  );
};

export const getIconByAudienceType = (type: AudienceType | undefined): string | undefined => {
  switch (type) {
    case "floor":
      return iconLayersThree01;
    case "building":
      return iconBuilding01;
    case "company":
      return iconBriefcase02;
    case "apartmentType":
      return iconHome02;
    default:
      return undefined;
  }
};
