import type { Dispatch, SetStateAction } from "react";
import { useMemo, useState } from "react";

export interface UseBoolHandlers {
  set: Dispatch<SetStateAction<boolean>>;
  setTrue: () => void;
  setFalse: () => void;
  toggle: () => void;
}

export function useBool(initialState: boolean | (() => boolean) = false): readonly [boolean, UseBoolHandlers] {
  const [state, setState] = useState(initialState);

  const handlers: UseBoolHandlers = useMemo(
    () => ({
      set: setState,
      setTrue: () => setState(true),
      setFalse: () => setState(false),
      toggle: () => setState((x) => !x),
    }),
    [],
  );

  return [state, handlers] as const;
}
