import { useSlug } from "hooks/useSlug";
import { useEffect } from "react";
import type { To } from "react-router-dom";
import { useNavigate } from "react-router-dom";

interface Props {
  to: (params: { slug: string }) => To;
}

export function Redirect({ to }: Props): null {
  const slug = useSlug();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to({ slug }));
  }, [navigate, to, slug]);

  return null;
}
