import logo from "assets/images/logo-light.svg";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

export function OnboardingScreenPreview({
  imageUrl,
  title,
  subtitle,
  hasNotch,
  styling,
}: {
  logoLight?: string;
  imageUrl: string;
  title: string;
  subtitle?: string;
  hasNotch?: boolean;
  styling: {
    bgColor?: string;
    textColor?: string;
  };
}): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div
      className={twJoin(
        "group relative flex aspect-[0.5] size-full flex-col overflow-hidden rounded-lg bg-black bg-cover bg-center px-2 pb-5 text-white shadow-lg",
        hasNotch ? "pt-14" : "pt-6",
      )}
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.6)), url(${
          imageUrl || ""
        })`,
      }}
    >
      <img
        className="absolute inset-x-0 mx-auto max-h-[50px] w-full max-w-[25%] object-contain"
        src={logo}
        alt="logo"
      />
      <div className="flex flex-1 flex-col justify-end pb-20 text-center font-old-semibold">
        <div className="text-[12px] leading-[15px]">{title}</div>
        {subtitle ? <p className="mt-1 whitespace-pre-wrap text-[8px] leading-[13px]">{subtitle}</p> : null}
      </div>
      <span
        className={twJoin(
          "absolute inset-x-0 bottom-6 mx-2.5 rounded bg-aop-basic-blue-500 text-center text-[12px] font-old-semibold text-white",
          hasNotch ? "py-2" : "py-1.5",
        )}
        style={{
          backgroundColor: styling.bgColor,
          color: styling.textColor,
        }}
      >
        {t("page.onboarding-screens.preview.continue")}
      </span>
    </div>
  );
}
