import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { downloadFile } from "helpers/file-parse";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProject } from "hooks/Network/useProject";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import {
  getLocalStorageValue,
  getSessionStorageValue,
  useUpdateLocalStorage,
  useUpdateSessionStorage,
} from "hooks/useLocalStorage";
import { useQueryParam } from "hooks/useQueryParam";
import { isArray, isBoolean } from "lodash-es";
import type { TicketSorting, TicketTabs } from "modules/tickets/constants";
import {
  CLOSED_TICKET_TAB,
  IN_PROGRESS_TICKET_TAB,
  NEW_TICKET_TAB,
  REMINDER_TICKET_TAB,
  ticketSortingValues,
  ticketVisibilityValues,
} from "modules/tickets/constants";
import { ticketStorageKeys } from "modules/tickets/filters";
import { useConfig } from "providers/ConfigProvider";
import { useTicketQueries } from "queries/tickets";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import type { ApiQueryParams } from "types/api-types";

import type { LayoutProps } from "./Layout";

interface Props {
  children: (props: LayoutProps) => React.ReactNode;
}

const ITEM_AMOUNT = 30;
const TABS = [NEW_TICKET_TAB, IN_PROGRESS_TICKET_TAB, CLOSED_TICKET_TAB, REMINDER_TICKET_TAB];

type TicketsSortingParams = { [key in TicketTabs]: TicketSorting };
type AllTicketsQueryParams = ApiQueryParams<"getAdminTicketsV1">;
export type TicketsFilters = Omit<AllTicketsQueryParams, "Limit" | "Offset" | "SortBy" | "ResidentFocusMode">;
export type OnUpdateParams = <T extends keyof TicketsFilters | "Tab" | "ResidentFocusMode">(
  key: T,
  value: T extends keyof AllTicketsQueryParams ? AllTicketsQueryParams[T] : string,
) => void;

export function Loader({ children }: Props): React.ReactNode {
  const projectId = useProjectId();
  const sessionUser = useSessionUser();
  const api = useApi();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const project = useProject();
  const showFlashToast = useFlashToast();
  const searchInputRef = useRef<HTMLInputElement>(null);

  const dashboardUrl = useConfig("newDashboardRootUrl");

  const TICKET_FILTERS_KEY = ticketStorageKeys.filters(project.id);
  const TICKET_TAB_SORT_KEY = ticketStorageKeys.tabSort();
  const TICKET_TAB_RESIDENT_FOCUS_KEY = ticketStorageKeys.residentFocusMode();

  const [queryParamTab, setTab] = useQueryParam("tab");
  const defaultTab = queryParamTab && TABS.includes(queryParamTab as any) ? (queryParamTab as TicketTabs) : undefined;

  const [storedResidentFocus, setResidentFocus] = useState<boolean>(() =>
    getSessionStorageValue<boolean>(TICKET_TAB_RESIDENT_FOCUS_KEY, false),
  );
  useUpdateSessionStorage(TICKET_TAB_RESIDENT_FOCUS_KEY, storedResidentFocus);

  const [sorting, setSorting] = useState<TicketsSortingParams>(() => {
    const storage = getLocalStorageValue<Partial<TicketsSortingParams>>(TICKET_TAB_SORT_KEY, {});

    return {
      new: storage.new && ticketSortingValues.includes(storage.new) ? storage.new : "oldest",
      inProgress:
        storage.inProgress && ticketSortingValues.includes(storage.inProgress)
          ? storage.inProgress
          : "mostRecentActivity",
      closed: storage.closed && ticketSortingValues.includes(storage.closed) ? storage.closed : "mostRecentActivity",
      reminder: "mostRecentReminder",
    };
  });
  useUpdateLocalStorage(TICKET_TAB_SORT_KEY, sorting);

  const [filters, updateFilters] = useState<TicketsFilters>(() => {
    const storage = getSessionStorageValue<Partial<TicketsFilters>>(TICKET_FILTERS_KEY, {});

    return {
      AssigneeIds: isArray(storage.AssigneeIds) ? storage.AssigneeIds : [],
      Ratings: isArray(storage.Ratings) ? storage.Ratings : [],
      StatusIds: isArray(storage.StatusIds) ? storage.StatusIds : [],
      CategoryIds: isArray(storage.CategoryIds) ? storage.CategoryIds : [],
      Filter: storage.Filter && ticketVisibilityValues.includes(storage.Filter) ? storage.Filter : undefined,
      AddressTypes: isArray(storage.AddressTypes) ? storage.AddressTypes : [],
      UnreadOnly: isBoolean(storage.UnreadOnly) ? storage.UnreadOnly : false,
      Search: typeof storage.Search === "string" ? storage.Search : "",
      Tab: storage.Tab && TABS.includes(storage.Tab) ? storage.Tab : undefined,
    };
  });
  useUpdateSessionStorage(TICKET_FILTERS_KEY, filters);

  const tab = filters.Tab || "new";

  const residentFocusMode = tab === "inProgress" && storedResidentFocus;
  const sortBy = (residentFocusMode ? "leastRecentActivity" : sorting[tab] || "newest") satisfies TicketSorting;

  const clearFilters = useCallback(() => {
    updateFilters({
      AssigneeIds: [],
      Ratings: [],
      StatusIds: [],
      CategoryIds: [],
      Filter: undefined,
      AddressTypes: [],
      UnreadOnly: false,
      Search: "",
    });

    // Uncontrolled input
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
  }, []);

  const onUpdateSorting = useCallback(
    (value: TicketSorting) => {
      setSorting((oldSorting) => ({
        ...oldSorting,
        [tab]: value,
      }));
    },
    [tab],
  );

  const onUpdateParams = useCallback<OnUpdateParams>((key, value) => {
    if (key === "ResidentFocusMode") {
      setResidentFocus(!!value);
    } else {
      updateFilters((ticketFeedQuery) => ({
        ...ticketFeedQuery,
        [key]: value,
      }));
    }
  }, []);

  const filterCount = useMemo(() => {
    const { AssigneeIds, Ratings, StatusIds, CategoryIds, AddressTypes, Filter, UnreadOnly } = filters;

    return (
      // eslint-disable-next-line no-nested-ternary
      (AssigneeIds ? (AssigneeIds.length ? 1 : 0) : 0) +
      // eslint-disable-next-line no-nested-ternary
      (Ratings ? (Ratings.length ? 1 : 0) : 0) +
      // eslint-disable-next-line no-nested-ternary
      (StatusIds ? (StatusIds.length ? 1 : 0) : 0) +
      // eslint-disable-next-line no-nested-ternary
      (CategoryIds ? (CategoryIds.length ? 1 : 0) : 0) +
      // eslint-disable-next-line no-nested-ternary
      (AddressTypes ? (AddressTypes.length ? 1 : 0) : 0) +
      (Filter ? 1 : 0) +
      (UnreadOnly ? 1 : 0)
    );
  }, [filters]);

  const query = {
    ...filters,
    SortBy: sortBy,
    Tab: tab,
    ResidentFocusMode: residentFocusMode,
  } satisfies AllTicketsQueryParams;
  const ticketQueries = useTicketQueries();
  const {
    data: ticketData,
    hasNextPage: hasMoreTickets,
    isLoading: isLoadingTickets,
    isFetchingNextPage: isLoadingMoreTickets,
    fetchNextPage: loadMoreTickets,
    error: ticketsError,
  } = useInfiniteQuery(ticketQueries.listPerProject(query));

  const { data: tabsStats, error: tabsStatsError } = useQuery({
    // exclude tab and sortby to prevent refetching because they do not affect the amount of tickets
    queryKey: QUERY_KEYS.TICKETS_TABS_AMOUNT(projectId, {
      ...query,
      Tab: undefined,
      SortBy: undefined,
      ResidentFocusMode: false,
    }),
    queryFn: () =>
      api.getAdminTicketsStatsAllTabsV1({ ...query, Tab: undefined, SortBy: undefined, ResidentFocusMode: false }),
    select: commonAPIDataSelector,
  });

  const { data: totalTicketsInTab, error: totalTicketsInTabError } = useQuery({
    queryKey: QUERY_KEYS.TICKETS_TAB_TOTAL(projectId, tab),
    queryFn: () => api.getAdminTicketsStatsDetailsV1(tab),
    select: commonAPIDataSelector,
    enabled: filterCount > 0 || !!filters.Search?.trim() || residentFocusMode,
  });

  const {
    data: ticketFilterValues,
    isLoading: isLoadingTicketFilterValues,
    error: ticketFilterValuesError,
  } = useQuery({
    queryKey: QUERY_KEYS.TICKETS_FILTERS_VALUES(projectId),
    queryFn: () => api.getAdminTicketsFiltersV1(),
    select: commonAPIDataSelector,
  });

  const exportTickets = useMutation({
    mutationFn: () =>
      api.getAdminTicketsExportV1({
        ...query,
        Limit: ITEM_AMOUNT,
        Offset: 0,
        Tab: undefined,
      }),
    onSuccess: async (data) => {
      const blob = await data.blob();
      downloadFile(blob, getTicketExportFilename(t("page.tickets.export.filename"), project.name));
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.tickets.export.error") });
    },
  });

  const deleteTicket = useMutation({
    mutationFn: (ticketId: string) => api.deleteAdminTicketsByIdV1(ticketId),
    onSuccess: () => {
      showFlashToast({ type: "success", title: t("page.tickets.delete.success") });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.tickets.delete.error") });
    },
  });

  const markTicketAsRead = useMutation({
    mutationFn: () => api.postAdminTicketsReadV1({ ...query }),
    onSuccess: () => {
      showFlashToast({ type: "success", title: t("page.tickets.mark-all-as-read.success") });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.tickets.mark-all-as-read.error") });
    },
  });

  const createQuickReplyLink = useMutation({
    mutationFn: (ticketId: string) =>
      api
        .postQuickReplyRepairRequestCreateTokenV1({ repairRequestId: ticketId as any, userId: sessionUser.id as any })
        .then((x) => x.data),
    onSuccess: async (token) => {
      const quickReplyMessagePath = routes.quickReplyRepairRequest({ token });
      await navigator.clipboard.writeText(`${dashboardUrl}${quickReplyMessagePath}`);
      showFlashToast({ type: "success", title: t("page.tickets.copy-quick-reply-link.success") });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.tickets.copy-quick-reply-link.error") });
    },
  });

  const totalTickets = ticketData?.pages[0].total;
  const tickets = useMemo(() => ticketData?.pages.flatMap((x) => x.items) ?? [], [ticketData]);

  useEffect(() => {
    if (defaultTab) {
      onUpdateParams("Tab", defaultTab);
      setTab(null);
    }
  }, [defaultTab, onUpdateParams, setTab]);

  if (tabsStats && tabsStats.reminderTickets === 0 && tab === "reminder") {
    onUpdateParams("Tab", "new");
  }

  if (ticketsError || totalTicketsInTabError || tabsStatsError || ticketFilterValuesError) {
    return <ErrorPage error={ticketsError} />;
  }

  if (isLoadingTicketFilterValues) {
    return <FullSizeLoader withPadding />;
  }

  return (
    <>
      {children({
        tab,
        residentFocusMode,
        sorting: sortBy,
        onUpdateSorting,
        queryParams: filters,
        onUpdateParams,
        totalTickets,
        totalTicketsInTab:
          filterCount > 0 || residentFocusMode || filters.Search?.trim() ? totalTicketsInTab?.tickets : totalTickets,
        tabsStats,
        tickets,
        isLoadingTickets,
        hasMoreTickets,
        loadMoreTickets,
        isLoadingMoreTickets,
        ticketFilterValues,
        clearFilters,
        filterCount,
        exportTickets: exportTickets.mutateAsync,
        isDownloading: exportTickets.isPending,
        deleteTicket: deleteTicket.mutateAsync,
        markAsRead: markTicketAsRead.mutateAsync,
        copyQuickReplyLink: createQuickReplyLink.mutate,
        searchInputRef,
      })}
    </>
  );
}

function getTicketExportFilename(filenameTranslation: string, projectName: string): string {
  const cleanProjectName = projectName.replaceAll(/[^a-zA-Z0-9()| _-]+/g, "");
  const date = new Date()
    .toISOString()
    .substring(0, 16)
    .replaceAll(/[T\-:]/g, "");

  return `${filenameTranslation}_${date}_${cleanProjectName}.xlsx`;
}
