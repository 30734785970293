import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { HomeDnaStatusDto, HttpResponse } from "api/types";
import checkIcon from "assets/icons/check.svg";
import slashIcon from "assets/icons/slash-circle-01.svg";
import { Button } from "components/Button/Button";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { isHttpError } from "helpers/Network/errors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";
import type { ApiResponseType } from "types/api-types";

import homeDnaPng from "../../assets/home-dna.png";

export function TicketHomeDnaCard({
  ticketId,
  homeDna,
  withOutline,
}: {
  ticketId: string;
  homeDna: HomeDnaStatusDto;
  withOutline?: boolean;
}): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();

  function overwriteHomeDna(response: Pick<HttpResponse<HomeDnaStatusDto>, "data">) {
    queryClient.setQueryData(
      QUERY_KEYS.TICKETS_DETAILS(projectId, ticketId),
      (oldData: ApiResponseType<"getAdminTicketsDetailsV1"> | undefined) => {
        if (!oldData) {
          return;
        }

        return {
          ...oldData,
          data: {
            ...oldData.data,
            homeDna: response.data,
          },
        };
      },
    );
  }

  const queryClient = useQueryClient();
  const api = useApi();
  const uploadTicketMutation = useMutation({
    mutationFn: () => api.postAdminTicketsHomednaV1(ticketId),
    onSuccess(response) {
      overwriteHomeDna(response);
    },
    onError(error) {
      const errorDetails =
        isHttpError(error) &&
        error.error &&
        typeof error.error === "object" &&
        "errorDetails" in error.error &&
        typeof error.error.errorDetails === "string"
          ? error.error.errorDetails
          : undefined;

      overwriteHomeDna({ data: { state: "failed", errorDetails } });
    },
  });

  return (
    <div
      className={twJoin(
        "flex flex-col gap-4 rounded-lg bg-white p-4",
        withOutline ? "border border-grey-100" : undefined,
      )}
    >
      <div className="flex justify-center">
        <img src={homeDnaPng} className="aspect-[182/35] h-8" />
      </div>
      {uploadTicketMutation.isPending ? (
        <LoadingIcon className="mx-auto my-5 w-5" />
      ) : (
        <div className="flex flex-col items-center gap-4">
          {
            // eslint-disable-next-line no-nested-ternary
            homeDna.state === "notStarted" ? (
              <p className="text-grey-600">{t("page.tickets.homedna.state.not-uploaded")}</p>
            ) : // eslint-disable-next-line no-nested-ternary
            homeDna.state === "failed" ? (
              <div className="flex flex-col gap-1 text-red-600">
                <p className="flex items-center gap-1">
                  <Icon name={slashIcon} className="w-4" />
                  {t("page.tickets.homedna.state.error") + (homeDna.errorDetails ? ":" : "")}
                </p>
                {homeDna.errorDetails ? <p className="text-caption text-red-700">{homeDna.errorDetails}</p> : null}
              </div>
            ) : homeDna.state === "sent" ? (
              <p className="flex items-center gap-1 font-old-semibold text-green-600">
                <Icon name={checkIcon} className="w-4" />
                {t("page.tickets.homedna.state.success")}
              </p>
            ) : null
          }
          {homeDna.state !== "sent" && (
            <div>
              <Button isLoading={uploadTicketMutation.isPending} onClick={() => uploadTicketMutation.mutate()}>
                {t("page.tickets.homedna.actions.send-ticket")}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
