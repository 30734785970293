import type { DateAndTimePickerProps, DatePickerValue } from "components/DateAndTimePicker/DateAndTimePicker";
import { DateAndTimePicker } from "components/DateAndTimePicker/DateAndTimePicker";
import type { FieldPath, FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";

import { FormErrorWrapper } from "./FormErrorWrapper";

type Props<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> = UseControllerProps<TFormValues, TName> &
  Omit<DateAndTimePickerProps, "defaultValue" | "name" | "value" | "onChange"> & {
    type?: "date" | "datetime" | "time";
    onChange?: (value: DatePickerValue) => void;
  };

export function FormDateAndTimePicker<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({
  defaultValue,
  onChange: propOnChange,
  name,
  control,
  rules,
  shouldUnregister,
  "data-testid": dataTestId,
  ...props
}: Props<TFormValues, TName>): React.ReactNode {
  const {
    field: { onChange: fieldOnChange, ...field },
    fieldState,
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });

  return (
    <FormErrorWrapper name={name} data-testid={dataTestId}>
      <DateAndTimePicker
        {...props}
        {...field}
        onChange={(date: DatePickerValue) => {
          fieldOnChange(date);
          propOnChange?.(date);
        }}
        error={fieldState.error != null}
        aria-invalid={fieldState.error != null}
      />
    </FormErrorWrapper>
  );
}
