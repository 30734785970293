import { addDays, parse } from "date-fns";

export function integrationIsNew(created?: string | null): boolean {
  if (!created) {
    return false;
  }

  const createdAt = parse(created, "yyyy-MM-dd", new Date());
  const thirtyDaysAgo = addDays(new Date(), -30);

  return createdAt > thirtyDaysAgo;
}
