import iconChevronDown from "assets/icons/chevron-down.svg";
import iconChevronRight from "assets/icons/chevron-right.svg";
import { Icon } from "components/Icon/Icon";
import type { PropsWithChildren } from "react";
import {
  Accordion as AccordionRAA,
  AccordionItem as AccordionItemRAA,
  AccordionItemButton as AccordionItemButtonRAA,
  AccordionItemHeading as AccordionItemHeadingRAA,
  AccordionItemPanel as AccordionItemPanelRAA,
  AccordionItemState,
} from "react-accessible-accordion";
import { twJoin } from "tailwind-merge";

const CONST = {
  ICON_SIZE: 16,
};

export const Accordion = AccordionRAA; // to prevent import from two places

export const AccordionItem = (props: React.ComponentProps<typeof AccordionItemRAA>): React.ReactNode => (
  <AccordionItemRAA {...props} className="mb-2 last:mb-0" />
);

export const AccordionItemButton = ({
  children,
  isHighlighted = true,
}: PropsWithChildren<{ isHighlighted?: boolean }>): React.ReactNode => {
  return (
    <AccordionItemState>
      {({ expanded }) => (
        <AccordionItemButtonRAA
          className={twJoin(
            "flex h-full items-center justify-start border-2 border-grey-900/0 px-2 hover:bg-grey-100 focus:outline-none focus-visible:border-grey-900",
            expanded ? "bg-grey-100 text-aop-basic-blue-500" : undefined,
          )}
        >
          {expanded ? (
            <Icon name={iconChevronDown} size={CONST.ICON_SIZE} />
          ) : (
            <Icon name={iconChevronRight} size={CONST.ICON_SIZE} />
          )}
          {isHighlighted ? (
            <span className="pl-2 text-body-bold">{children}</span>
          ) : (
            <span className="pl-2">{children}</span>
          )}
        </AccordionItemButtonRAA>
      )}
    </AccordionItemState>
  );
};

export const AccordionItemHeading = (props: React.ComponentProps<typeof AccordionItemHeadingRAA>): React.ReactNode => (
  <AccordionItemHeadingRAA {...props} className="h-10 overflow-hidden rounded-2px" />
);

export const AccordionItemPanel = ({ children }: PropsWithChildren): React.ReactNode => {
  return (
    <AccordionItemState>
      {({ expanded }) => (
        <AccordionItemPanelRAA className={twJoin("py-3", expanded ? "bg-transparent" : "bg-grey-100")}>
          {children}
        </AccordionItemPanelRAA>
      )}
    </AccordionItemState>
  );
};
