import logoDark from "assets/images/logo-dark.svg";
import { NavigationSidebarUserMenu } from "components/NavigationSidebar/NavigationSidebarUserMenu";
import { Sidebar } from "components/Sidebar/Sidebar";
import type { SidebarBodyRootProps } from "components/Sidebar/types";
import { useScreenIsBiggerThan } from "hooks/useScreenIsBiggerThan";
import { twJoin } from "tailwind-merge";

import { usePortfolioSidebarManager } from "./items";
import { PortfolioSidebarProjectsSection } from "./PortfolioSidebarProjectsSection";

interface PortfolioSidebarProps extends Omit<SidebarBodyRootProps, "children"> {}

export function PortfolioSidebar({
  isOpened,
  isCollapsed,
  onToggleOpen,
  onToggleCollapse,
  isFaded,
}: PortfolioSidebarProps): React.ReactNode {
  const { navigationItems } = usePortfolioSidebarManager();
  const isDesktop = useScreenIsBiggerThan("md");

  return (
    <Sidebar.Root {...{ isOpened, isCollapsed, onToggleCollapse, onToggleOpen, isFaded }}>
      <Sidebar.Header>
        <div className={twJoin("flex w-fit items-center justify-start md:h-10", isCollapsed ? "px-2" : "px-8")}>
          <img
            src={logoDark}
            className={twJoin("block transition-[width] duration-200", isCollapsed ? "w-10" : "w-16")}
            alt="Area of People logo"
          />
        </div>
      </Sidebar.Header>
      <Sidebar.Body items={navigationItems} isFirstItemImportant>
        {isDesktop && navigationItems.length > 0 && (
          <>
            <div className="my-4 flex h-10 w-full items-center px-2">
              <hr className={twJoin("w-full border-grey-100")} />
            </div>
            <PortfolioSidebarProjectsSection />
          </>
        )}
      </Sidebar.Body>
      {!isFaded && (
        <Sidebar.Footer>
          <NavigationSidebarUserMenu isPortfolio />
        </Sidebar.Footer>
      )}
    </Sidebar.Root>
  );
}
