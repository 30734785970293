import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type {
  QuickReplyRepairRequestAssigneeChangeRequest,
  QuickReplyRepairRequestCreateCommentRequest,
  QuickReplyRepairRequestEditCommentRequest,
  QuickReplyRepairRequestStatusChangeRequest,
  ResponsibilitySuggestionRatingRequest,
} from "api/types";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";

import { useQuickReplyTicketQueries } from "./queryOptions";

const useAddComment = ({ token }: { token: string }) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();

  return useMutation({
    mutationFn: ({ payload }: { payload: QuickReplyRepairRequestCreateCommentRequest; failureMessage: string }) =>
      api.postQuickReplyRepairRequestReplyV1(token, payload).then((x) => x.data),
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.QUICK_REPLY_REPAIR_DETAILS(token) });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.QUICK_REPLY_REPAIR_ACTIVITIES(token) });
    },
    async onError(_, { failureMessage }) {
      showFlashToast({
        type: "error",
        title: failureMessage,
      });

      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.QUICK_REPLY_REPAIR_DETAILS(token) });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.QUICK_REPLY_REPAIR_ACTIVITIES(token) });
    },
  });
};

const useUpdateComment = ({ token }: { token: string }) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();

  return useMutation({
    mutationFn: ({
      payload,
    }: {
      payload: QuickReplyRepairRequestEditCommentRequest & { commentId: string };
      failureMessage: string;
    }) =>
      api.putQuickReplyRepairRequestReplyV1(token, payload.commentId, {
        content: payload.content,
        imageId: payload.imageId,
      }),
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.QUICK_REPLY_REPAIR_ACTIVITIES(token) });
    },
    onError(_, { failureMessage }) {
      showFlashToast({ type: "success", title: failureMessage });
    },
  });
};

const useUpdateAssignee = ({ token }: { token: string }) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();

  const quickReplyTicketQueries = useQuickReplyTicketQueries();
  const { data: ticket } = useQuery(quickReplyTicketQueries.details(token));

  return useMutation({
    onMutate: ({ request }) => {
      if (!ticket) {
        throw new Error("No data loaded");
      }

      queryClient.setQueryData(QUERY_KEYS.QUICK_REPLY_REPAIR_DETAILS(token), {
        ...ticket,
        assignee: ticket.possibleAssignees!.find((x) => x.id === request.assigneeId),
      });
    },
    mutationFn: ({ request }: { request: QuickReplyRepairRequestAssigneeChangeRequest; failureMessage: string }) =>
      api.putQuickReplyRepairRequestAssigneeV1(token, request).then((res) => res.data),
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.QUICK_REPLY_REPAIR_DETAILS(token) });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.QUICK_REPLY_REPAIR_ACTIVITIES(token) });
    },
    async onError(_, { failureMessage }) {
      showFlashToast({
        type: "error",
        title: failureMessage,
      });

      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.QUICK_REPLY_REPAIR_DETAILS(token) });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.QUICK_REPLY_REPAIR_ACTIVITIES(token) });
    },
  });
};

const useUpdateStatus = ({ token }: { token: string }) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();

  return useMutation({
    mutationFn: ({ request }: { request: QuickReplyRepairRequestStatusChangeRequest; failureMessage: string }) =>
      api.putQuickReplyRepairRequestStatusV1(token, request).then((x) => x.data),

    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.QUICK_REPLY_REPAIR_DETAILS(token) });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.QUICK_REPLY_REPAIR_ACTIVITIES(token) });
    },
    async onError(_, { failureMessage }) {
      showFlashToast({
        type: "error",
        title: failureMessage,
      });

      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.QUICK_REPLY_REPAIR_DETAILS(token) });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.QUICK_REPLY_REPAIR_ACTIVITIES(token) });
    },
  });
};

const useAddImage = ({ token }: { token: string }) => {
  const api = useApi();

  return useMutation({
    mutationFn: ({ file }: { file: File }) =>
      api.postQuickReplyRepairRequestReplyImageV1(token, { File: file }).then((res) => res.data),
  });
};

const useAddDocument = ({ token }: { token: string }) => {
  const api = useApi();

  return useMutation({
    mutationFn: ({ file }: { file: File }) =>
      api.postQuickReplyRepairRequestReplyDocumentV1(token, { File: file }).then((res) => res.data),
  });
};

const useUpdateResponsibilitySuggestion = ({ token }: { token: string }) => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ResponsibilitySuggestionRatingRequest) =>
      api.postQuickReplyRepairRequestResponsibilitySuggestionV1(token, data),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.QUICK_REPLY_REPAIR_RESPONSIBILITY_SUGGESTION(token),
      });
    },
  });
};

const useGetTicketCopilotSuggestion = ({ token }: { token: string }) => {
  const api = useApi();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (query: { additionalInfo: string } | undefined) =>
      api.postQuickReplyRepairRequestAiSuggestionV1(token, query).then((res) => res.data),
    onError: () => {
      showFlashToast({ type: "error", title: t("page.tickets.details.copilot.generate.error") });
    },
  });
};

export const quickReplyTicketMutations = {
  useAddComment,
  useUpdateComment,
  useUpdateAssignee,
  useUpdateStatus,
  useAddImage,
  useAddDocument,
  useUpdateResponsibilitySuggestion,
  useGetTicketCopilotSuggestion,
};
