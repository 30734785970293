import { downloadFile } from "helpers/file-parse";

export async function parseExcel(binaryString: string | ArrayBuffer | null): Promise<unknown[]> {
  if (binaryString === null) {
    throw new Error("No excel file provided");
  }

  const {
    read,
    utils: { sheet_to_json },
  } = await import("xlsx");

  const wb = read(binaryString, { type: "binary" });
  const wsname = wb.SheetNames[0];
  const ws = wb.Sheets[wsname];
  const rows = sheet_to_json(ws);
  if (typeof rows[0] !== "object") {
    throw new Error("Invalid data format, or empty sheet");
  }

  // The UI just can't cope otherwise, even on fast machines
  if (rows.length > 300) {
    throw new Error("Please split into multiple files of 300 rows maximum");
  }

  return rows;
}

export async function downloadCsvAsExcel(fileName: string, value: string): Promise<void> {
  fileName = cleanFileName(fileName);

  const { read, write } = await import("xlsx");

  const workbook = read(value, { type: "string" });
  const workbookBinary = write(workbook, { bookType: "xlsx", type: "binary" });

  const blob = new Blob([stringToBinary(workbookBinary)], { type: "application/octet-stream" });
  downloadFile(blob, `${fileName}.xlsx`);
}

export async function downloadExcel(fileName: string, data: string[][]): Promise<void> {
  fileName = cleanFileName(fileName);

  const workbook = await createWorksheet(fileName, "data", data, { skipHeader: true });

  const { write } = await import("xlsx");
  const workbookBinary = write(workbook, { bookType: "xlsx", type: "binary" });

  const blob = new Blob([stringToBinary(workbookBinary)], { type: "application/octet-stream" });
  downloadFile(blob, `${fileName}.xlsx`);
}

export async function convertToCsv(data: any[], opts: { skipHeader?: boolean } = {}): Promise<string> {
  const sheetName = "data";
  const worksheet = await createWorksheet(sheetName, sheetName, data, opts);

  const {
    utils: { sheet_to_csv },
  } = await import("xlsx");

  return sheet_to_csv(worksheet.Sheets[sheetName]);
}

async function createWorksheet(
  fileName: string,
  sheetName: string,
  data: any[],
  { skipHeader }: { skipHeader?: boolean } = {},
) {
  fileName = cleanFileName(fileName);

  const {
    utils: { book_new, json_to_sheet },
  } = await import("xlsx");

  const workbook = book_new();
  workbook.Props = {
    Title: fileName,
    Author: "Area of People",
    CreatedDate: new Date(),
  };

  workbook.SheetNames.push(sheetName);
  workbook.Sheets[sheetName] = json_to_sheet(data, { skipHeader });

  return workbook;
}

function stringToBinary(value: string) {
  const buffer = new ArrayBuffer(value.length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < value.length; i++) {
    view[i] = value.charCodeAt(i) & 0xff;
  }

  return buffer;
}

function cleanFileName(sheetName: string) {
  return sheetName.replace(/[^a-zA-Z0-9()| _-]+/g, "");
}
