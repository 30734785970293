import { LoadingIcon } from "components/Icons/Icons";
import { motion } from "framer-motion";
import { twResolve } from "helpers/tw-resolve";

export function FullSizeLoader({
  size = "normal",
  withPadding,
  noDelay = false,
}: {
  size?: "small" | "normal";
  withPadding?: boolean;
  noDelay?: boolean;
}): React.ReactNode {
  return (
    <motion.div
      className={twResolve("flex size-full items-center justify-center", withPadding ? "py-4" : undefined)}
      initial={{ opacity: noDelay ? 1 : 0 }}
      animate={{ opacity: 1 }}
      transition={noDelay ? undefined : { delay: 0.4 }}
    >
      <LoadingIcon className={size === "small" ? "w-8" : "w-12"} />
    </motion.div>
  );
}
