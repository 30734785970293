import { useEffect, useState } from "react";
import EmailVerification from "supertokens-web-js/recipe/emailverification";
import MultiFactorAuthentication from "supertokens-web-js/recipe/multifactorauth";
import Session from "supertokens-web-js/recipe/session";

export enum AuthenticationState {
  Loading,
  NogLoggedIn,
  LoggedInButEmailNotVerified,
  LoggedInButMFANotCompleted,
  LoggedIn,
}

export function useAuthenticationState(): AuthenticationState {
  const [state, setState] = useState<AuthenticationState>(AuthenticationState.Loading);

  useEffect(() => {
    let cancelled = false;

    if (state !== AuthenticationState.Loading) {
      return;
    }

    void Session.doesSessionExist().then(async (sessionExists) => {
      if (cancelled) {
        return;
      }

      if (!sessionExists) {
        setState(AuthenticationState.NogLoggedIn);

        return;
      }

      const validationErrors = await Session.validateClaims();
      if (cancelled) {
        return;
      }

      if (validationErrors.length === 0) {
        setState(AuthenticationState.LoggedIn);

        return;
      }

      for (const err of validationErrors) {
        if (err.id === MultiFactorAuthentication.MultiFactorAuthClaim.id) {
          const mfaClaimValue = await Session.getClaimValue({
            claim: MultiFactorAuthentication.MultiFactorAuthClaim,
          });
          if (cancelled) {
            return;
          }

          if (mfaClaimValue === undefined || !("totp" in mfaClaimValue.c)) {
            setState(AuthenticationState.LoggedInButMFANotCompleted);

            return;
          }
        }

        if (err.id === EmailVerification.EmailVerificationClaim.id) {
          setState(AuthenticationState.LoggedInButEmailNotVerified);

          return;
        }
      }

      console.error("Unhandled validation error");
    });

    return () => {
      cancelled = true;
    };
  }, [state]);

  return state;
}

export function useSuperTokensUserId(): string | undefined {
  const [userId, setUserId] = useState<string | undefined>(undefined);

  useEffect(() => {
    let cancelled = false;

    void Session.doesSessionExist().then(async (sessionExists) => {
      if (sessionExists) {
        const userId = await Session.getUserId();

        if (cancelled) {
          return;
        }

        setUserId(userId);
      }
    });

    return () => {
      cancelled = true;
    };
  }, []);

  return userId;
}
