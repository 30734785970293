import type { UseQueryResult } from "@tanstack/react-query";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { ProjectHeaderName } from "api/ApiClientProvider";
import { useApi } from "api/hooks/useApi";
import type { SelfDto } from "api/types";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { QUERY_KEYS } from "query-keys";

export interface SessionUserInitialOptions {
  keepPreviousData?: boolean;
}

export function useSessionUserInitial(
  projectId: string | undefined,
  options?: SessionUserInitialOptions,
): UseQueryResult<SelfDto> {
  const api = useApi();

  return useQuery({
    queryKey: QUERY_KEYS.SELF(projectId),
    queryFn: () => {
      // Initial session user HTTP call at the top of the loader tree doesn't have access to a client with project id set,
      // so we need to set it manually.
      const headers: Record<string, string> =
        projectId == null
          ? {}
          : {
              [ProjectHeaderName]: projectId,
            };

      return api.getSelfV2({ headers });
    },
    staleTime: 1000 * 60 * 5,
    gcTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    select: commonAPIDataSelector,
    placeholderData: options?.keepPreviousData ? keepPreviousData : undefined,
  });
}
