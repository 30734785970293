import type { CommunityFeedAudienceProjectConnectionDto, CommunityFeedAudienceProjectDto } from "api/types";
import iconAlertTriangle from "assets/icons/alert-triangle.svg";
import annoucementIcon from "assets/icons/announcement-03.svg";
import iconMarkerPin01 from "assets/icons/marker-pin-01.svg";
import commentListIcon from "assets/icons/message-chat-circle.svg";
import commentIcon from "assets/icons/message-text-circle-01.svg";
import iconPhone01 from "assets/icons/phone-01.svg";
import shareIcon from "assets/icons/share-06.svg";
import likeIcon from "assets/icons/thumbs-up.svg";
import iconX from "assets/icons/x.svg";
import { Button } from "components/Button/Button";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { Label } from "components/Label/Label";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { DocumentMedia } from "components/Media/Media";
import { Notice } from "components/Notice/Notice";
import { Phone } from "components/Phone/Phone";
import { PhoneInner } from "components/Phone/PhoneInner";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { useBool } from "hooks/useBool";
import { useContainerIsBiggerThan } from "hooks/useContainerIsBiggerThan";
import { CommunityItemGallery } from "modules/messages/pages/List/components/CommunityItemGallery";
import { useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface CreatePostAppPreviewProps {
  sectionTitle: string;
  type: "announcement" | "undefined";
  title: string;
  body: string;
  audience?:
    | { type: "project"; project: CommunityFeedAudienceProjectDto }
    | { type: "projectConnection"; projectConnection: CommunityFeedAudienceProjectConnectionDto };
  group?: string;
  images?: FormImage[];
  documents?: FormDocument[];
}

export function CreatePostAppPreview({
  sectionTitle,
  type,
  title,
  body,
  audience,
  group,
  images,
  documents,
}: CreatePostAppPreviewProps): React.ReactNode {
  const { t } = useTranslation();

  const parentRef = useRef<HTMLDivElement>(null);
  const screenRef = useRef<HTMLDivElement>(null);
  const postRef = useRef<HTMLDivElement>(null);

  const [isPreviewOpen, previewOpenHandlers] = useBool(false);
  const [showPostSizeWarning, postSizeWarningHandler] = useBool(false);

  const calculatePostSize = useCallback(() => {
    if (!postRef.current || !screenRef.current) {
      return;
    }

    if (postRef.current.clientHeight / screenRef.current.clientHeight > 0.75) {
      postSizeWarningHandler.setTrue();
    } else {
      postSizeWarningHandler.setFalse();
    }
  }, [postRef, screenRef, postSizeWarningHandler]);

  useEffect(() => {
    if (!parentRef.current || !postRef.current || !screenRef.current) {
      return;
    }

    // if the element is hidden (in mobile) then we make the parent visible to calculate the size and hide it again
    // no flashing occurs
    if (screenRef.current.clientHeight === 0) {
      parentRef.current.style.display = "flex";
      calculatePostSize();
      parentRef.current.style.display = "hidden";

      return;
    }

    calculatePostSize();
  }, [parentRef, title, body, calculatePostSize]);

  const [ref, is6xl] = useContainerIsBiggerThan("@6xl");

  const previewSection = (
    <div
      className={twJoin(
        "fixed inset-0 z-30 flex flex-1 items-center justify-center bg-grey-900/60 pt-5 @6xl:static @6xl:flex-auto @6xl:flex-col @6xl:justify-start @6xl:bg-transparent",
        isPreviewOpen ? "visible" : "invisible @6xl:visible",
      )}
      onClick={previewOpenHandlers.setFalse}
    >
      <div className="relative flex size-full flex-col items-center justify-center">
        <div className="relative w-full max-w-72">
          <Button
            styling="tertiary"
            className="absolute right-0 top-4 -translate-y-full translate-x-full text-white @6xl:hidden"
            onClick={previewOpenHandlers.setFalse}
          >
            <Icon name={iconX} size={24} />
          </Button>
          <div ref={parentRef} className={isPreviewOpen ? "flex flex-col" : "hidden @6xl:flex @6xl:flex-col"}>
            <Phone>
              <PhoneInner noBackground>
                <div ref={screenRef} className="relative flex size-full flex-col items-center bg-aop-off-white">
                  <span className="mt-8 text-body-bold text-aop-dark-blue-500">
                    {t("component.create-post-app-preview.community")}
                  </span>
                  <div
                    ref={postRef}
                    className="mt-2 flex w-full flex-col gap-4 overflow-y-hidden bg-white p-4 pb-0 shadow-sm"
                  >
                    {type === "announcement" ? (
                      <div className="flex items-center gap-2">
                        <Label theme="purple" size="small">
                          <Icon name={annoucementIcon} size={16} />
                          <span className="font-old-semibold">
                            {t("component.create-post-app-preview.announcement")}
                          </span>
                        </Label>
                      </div>
                    ) : null}
                    {type === "undefined" && audience?.type === "projectConnection" ? (
                      <div className="flex items-center gap-2">
                        <Label theme="gray" size="small">
                          <Icon name={iconMarkerPin01} size={16} />
                          <span className="font-old-semibold">{audience.projectConnection.name}</span>
                        </Label>
                      </div>
                    ) : null}

                    <div className="flex grow items-center">
                      <div className="size-8 shrink-0">
                        <UserAvatar hideDeletedIcon />
                      </div>
                      <div className="ml-2 text-overline">
                        <span className="line-clamp-1">
                          <span className="font-old-semibold text-black">
                            {t("component.create-post-app-preview.username")}
                          </span>
                          {group && (
                            <>
                              {" "}
                              <span className="italic">
                                {t("component.community-post.posted-in-group", { group: group })}
                              </span>
                            </>
                          )}
                        </span>
                        <span className="mt-1 line-clamp-1 text-[10px] text-grey-500">
                          <FormattedDate format="datetime" date={new Date()} />
                        </span>
                      </div>
                    </div>

                    {title && <span className="text-caption-bold">{title}</span>}
                    {body && (
                      <span className="whitespace-pre-line text-overline leading-[14px]">
                        <LinkFormatter>{body}</LinkFormatter>
                      </span>
                    )}
                    {images && images.length > 0 && <CommunityItemGallery {...{ images }} />}
                    {documents && documents.length > 0 && (
                      <div className="flex flex-wrap gap-2">
                        {documents.map((document, idx) => (
                          <DocumentMedia key={`preview-document-${idx}`} {...{ document }} />
                        ))}
                      </div>
                    )}
                    {body && (
                      <span className="text-[10px] font-old-semibold text-grey-500">
                        {t("component.create-post-app-preview.translate")}
                      </span>
                    )}
                    <span className="text-[10px] text-grey-500">
                      {t("component.create-post-app-preview.first-like")}
                    </span>
                    <div className="flex w-full items-center justify-between border-t border-grey-100 py-1 text-grey-500">
                      <div className="flex items-center gap-2">
                        <div className="flex items-center gap-0.5">
                          <Icon name={likeIcon} size={16} />
                          <span className="font-old-semibold">{t("component.create-post-app-preview.like")}</span>
                        </div>
                        <div className="flex items-center gap-0.5">
                          <Icon name={commentIcon} size={16} />
                          <span className="font-old-semibold">{t("component.create-post-app-preview.comment")}</span>
                        </div>
                        <div className="flex items-center gap-0.5">
                          <Icon name={commentListIcon} size={16} />
                          <span className="font-old-semibold text-grey-500">0</span>
                        </div>
                      </div>
                      <Icon name={shareIcon} size={16} />
                    </div>
                  </div>
                </div>
              </PhoneInner>
            </Phone>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="mb-8 flex size-full flex-col @xl:block" ref={ref}>
      <span className="hidden text-headline3 leading-old-headline4 @6xl:block">{sectionTitle}</span>
      <Button styling="tertiary" className="flex gap-1 py-3 @6xl:hidden" onClick={previewOpenHandlers.setTrue}>
        <Icon name={iconPhone01} />
        {sectionTitle}
      </Button>
      {is6xl ? previewSection : createPortal(previewSection, document.body)}
      {showPostSizeWarning && (
        <div className="my-4">
          <Notice icon={iconAlertTriangle} message={t("component.create-post-app-preview.warning")} />
        </div>
      )}
    </div>
  );
}
