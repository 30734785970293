import { ConfigProvider } from "antd";
import germanLocale from "antd/es/locale/de_DE";
import englishLocale from "antd/es/locale/en_GB";
import dutchLocale from "antd/es/locale/nl_NL";
import type { LanguageDto } from "api/types";
import { useSessionUser } from "hooks/Network/useSessionUser";

interface Props {
  children: React.ReactNode;
}

// We need to map between AntD (xx_YY) and date-fns locales (xx)
if (dutchLocale.DatePicker) {
  dutchLocale.DatePicker.lang.locale = "nl";
}

if (germanLocale.DatePicker) {
  germanLocale.DatePicker.lang.locale = "de";
}

export function DateTimePickerConfigProvider({ children }: Props): React.ReactNode {
  const user = useSessionUser();

  const userLocale = getDateTimePickerLocale(user.language.id);

  return (
    <ConfigProvider
      locale={userLocale}
      theme={{
        token: {
          colorPrimary: "#009fe3",
          colorPrimaryBg: "#e5f2ff",
          borderRadius: 8,
          fontFamily:
            "Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
          colorError: "#bf2c24",
          colorErrorText: "#bf2c24",
          colorErrorBorderHover: "#bf2c24",
        },
        components: {
          DatePicker: {
            activeBorderColor: "#5f5f86",
            hoverBorderColor: "#5f5f86",
            activeShadow: undefined,
            errorActiveShadow: undefined,
            cellActiveWithRangeBg: "#009fe3",
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
}

function getDateTimePickerLocale(lang?: LanguageDto["id"]) {
  switch (lang) {
    case "nl":
    case "dutch":
      return dutchLocale;
    case "de":
    case "german":
      return germanLocale;
    default:
      return englishLocale;
  }
}
