import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import type { QuickReplyRepairRequestCommentDto, QuickReplyRepairRequestEditCommentRequest } from "api/types";
import {
  type QuickReplyRepairRequestCreateCommentRequest,
  type QuickReplyRepairRequestStatusDto,
  type UserDto,
} from "api/types";
import { Button } from "components/Button/Button";
import type { BaseCommentFieldProps } from "components/CommentField/CommentField";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { ErrorPage } from "components/Error/ErrorPage";
import { Gallery } from "components/Gallery/Gallery";
import type { FormImage } from "components/ImageInput/useImageInput";
import { isDocumentUploaded, isImageUploaded } from "components/ImageInput/useImageInput";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { TicketCommentField } from "components/Ticket/TicketCommentField/TicketCommentField";
import type { CommentPayload, CommentType } from "components/Ticket/TicketCommentField/TicketCommentFieldPure";
import { TicketLeaveCommentModal } from "components/Ticket/TicketLeaveCommentModal";
import { TicketStatusChangeModal } from "components/Ticket/TicketStatusChangeModal";
import { sortStatuses } from "helpers/status-helpers";
import { useBool } from "hooks/useBool";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { useQueryParam } from "hooks/useQueryParam";
import { useSetAtom } from "jotai";
import { StopGlobalLoadingSpinner } from "providers/GlobalLoadingSpinner";
import { quickReplyTicketMutations } from "queries/quickReplyRepairRequests";
import { useQuickReplyTicketQueries } from "queries/quickReplyRepairRequests/queryOptions";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { routes } from "routes";
import { languageAtom } from "state/app-language";
import { twJoin } from "tailwind-merge";

import { QuickReplyMenuBar } from "../components/QuickReplyMenuBar";
import { TicketActivitiesSection } from "../components/Ticket/TicketActivitySection";
import { TicketInfoHeader } from "../components/Ticket/TicketInfoHeader";
import { TicketRemsCard } from "../components/Ticket/TicketRemsCard";
import { TicketResidentInfo } from "../components/Ticket/TicketResidentInfo";

export function QuickReplyRepairRequestPage(): React.ReactNode {
  const { token } = useParams<{ token: string }>();
  const response = useData(token!);
  const setLanguage = useSetAtom(languageAtom);

  useEffect(() => {
    if (response.data) {
      setLanguage(response.data.loggedInUser.language.id);
    }
  }, [response.data, setLanguage]);

  if (!response.data) {
    if (response.isLoading) {
      return null;
    }

    return <ErrorPage status={(response.error as unknown as Response)?.status || 0} />;
  }

  return (
    <>
      <StopGlobalLoadingSpinner />
      <QuickReplyRepairRequestPageInternal {...response} />
    </>
  );
}

function QuickReplyRepairRequestPageInternal({
  token,
  data,
  activities,
  hasMoreComments,
  isFetchingMoreActivities,
  isChangingStatus,
  translation,
  translationIsLoading,
  onHideTranslation,
  onTranslate,
  reply,
  editReply,
  fetchMoreComments,
  changeStatusMutation,
  changeAssigneeMutation,
  uploadImageMutation,
  uploadDocumentMutation,
}: ReturnType<typeof useData>) {
  const { t } = useTranslation();
  const [editingComment, setEditingComment] = useState<QuickReplyRepairRequestCommentDto | undefined>(undefined);
  const [changedAssignee, setChangedAssignee] = useState<UserDto>();

  const [isChangeStatusModal, changeStatusModalHandler] = useBool();
  const [isLeaveCommentModalOpened, leaveCommentModalOpenHandlers] = useBool();
  const [targetStatusId, setTargetStatusId] = useQueryParam("targetStatus");
  const [headerParam] = useQueryParam("header");
  const { loggedInUser } = data || {};

  const readonly = data?.deletedAt != null;
  const canChangeStatus = !readonly && !!data?.possibleStatuses?.length;
  const canChangeAssignee = !readonly && !!data?.possibleAssignees?.length;
  const showHeader = headerParam !== "false";

  useDocumentTitle(t("page.messages-email.title"));

  const sortedStatuses = useMemo(() => sortStatuses(data?.possibleStatuses), [data?.possibleStatuses]);
  const targetStatus = useMemo(
    () => (targetStatusId ? sortedStatuses.find((x) => x.id === targetStatusId) : undefined),
    [sortedStatuses, targetStatusId],
  );

  useEffect(() => {
    if (targetStatusId && targetStatusId === data?.status.id) {
      setTargetStatusId(null, { replace: true });
    }
  }, [data?.status.id, setTargetStatusId, targetStatusId]);

  async function onCreateComment({ content, images, documents, type }: CommentPayload) {
    {
      await onReply({
        content,
        image: images.length > 0 ? images[0] : undefined,
        document: documents.length > 0 ? documents[0] : undefined,
        internal: type === "internal",
      });

      const isStatusNewOrRejected =
        data?.status.defaultStatusId && ["new", "rejected"].includes(data.status.defaultStatusId);
      if (type !== "internal" && isStatusNewOrRejected && canChangeStatus) {
        changeStatusModalHandler.setTrue();
      }
    }
  }

  async function onReply({
    content,
    image,
    document,
    internal,
  }: {
    content: string;
    image?: FormImage;
    document?: FormDocument;
    internal?: boolean;
  }) {
    const data: QuickReplyRepairRequestCreateCommentRequest = {
      content,
      imageId: undefined,
      documentIds: [],
      internal,
    };

    if (image) {
      if (isImageUploaded(image)) {
        data.imageId = image.id;
      } else {
        const uploadedImage = await uploadImageMutation({ file: image.file });
        data.imageId = uploadedImage.id;
      }
    }

    if (document) {
      if (isDocumentUploaded(document)) {
        data.documentIds = [document.id];
      } else {
        const uploadedDocument = await uploadDocumentMutation({ file: document.file });
        data.documentIds = [uploadedDocument.id];
      }
    }

    await reply({
      payload: data,
      failureMessage: internal
        ? t("page.quick-reply-repair-request.note-error")
        : t("page.quick-reply-repair-request.comment-error"),
    });
  }

  async function editComment({ commentId, content, images, documents }: CommentPayload & { commentId: string }) {
    const data: QuickReplyRepairRequestEditCommentRequest = {
      content,
      imageId: undefined,
      documentIds: [],
    };

    if (images.length > 0) {
      if (isImageUploaded(images[0])) {
        data.imageId = images[0].id;
      } else {
        const uploadedImage = await uploadImageMutation({ file: images[0].file });
        data.imageId = uploadedImage.id;
      }
    }

    if (documents.length > 0) {
      if (isDocumentUploaded(documents[0])) {
        data.documentIds = [documents[0].id];
      } else {
        const uploadedDocument = await uploadDocumentMutation({ file: documents[0].file });
        data.documentIds = [uploadedDocument.id];
      }
    }

    await editReply({
      payload: {
        commentId,
        ...data,
      },
      failureMessage: t("page.tickets.details.edit-note.error"),
    });
  }

  const onChangeStatus = useCallback(
    async (status: QuickReplyRepairRequestStatusDto) => {
      if (status.id === data?.status.id) {
        return;
      }

      await changeStatusMutation({
        request: {
          statusId: status.id,
          silent: false,
        },
        failureMessage: t("page.quick-reply-repair-request.status-change-error"),
      });

      if (status.defaultStatusId === "inProgress" && data?.canAddInternalNote && data?.canAddPublicComment) {
        leaveCommentModalOpenHandlers.setTrue();
      }
    },
    [
      changeStatusMutation,
      data?.canAddInternalNote,
      data?.canAddPublicComment,
      data?.status.id,
      leaveCommentModalOpenHandlers,
      t,
    ],
  );

  if (!data || !loggedInUser) {
    return null;
  }

  const allowedCommentAttachments: BaseCommentFieldProps["allowedAttachments"] = ["image"];
  if (loggedInUser.isAdmin) {
    allowedCommentAttachments.push("document");
  }
  const allowedCommentTypes: CommentType[] = [];
  if (data.canAddPublicComment) allowedCommentTypes.push("public");
  if (data.canAddInternalNote) allowedCommentTypes.push("internal");

  return (
    <div className="min-h-full overflow-x-hidden">
      {!showHeader ? null : (
        <QuickReplyMenuBar
          user={loggedInUser}
          loginUrl={
            data.hasMoreProjects
              ? {
                  pathname: routes.portfolio.tickets(),
                  search: `openTicket=${btoa(`${data.projectId}|${data.requestId}`)}`,
                }
              : routes.tickets.details({
                  id: data.requestId,
                  slug: data.projectSlug,
                })
          }
        />
      )}
      <div
        className={twJoin(
          "flex flex-col items-center justify-center gap-4 px-0 py-2 sm:p-4 xl:flex-row xl:items-start",
          !showHeader ? "pb-0" : "pb-24",
        )}
      >
        <main className="flex max-w-3xl flex-col gap-4 xl:flex-1">
          <TicketInfoHeader
            isChangingStatus={isChangingStatus}
            onChangeAssignee={(assignee) => {
              if (data.canAddInternalNote) {
                setChangedAssignee(assignee);
              } else {
                void changeAssigneeMutation({
                  request: { assigneeId: assignee.id },
                  failureMessage: t("page.quick-reply-repair-request.assignee-change-error"),
                });
              }
            }}
            onChangeStatus={onChangeStatus}
            project={data.projectName}
            canChangeStatus={canChangeStatus}
            canChangeAssignee={canChangeAssignee}
            ticket={data}
            showProject={showHeader}
            changedAssignee={changedAssignee}
          />
          <div className="rounded-lg bg-white">
            <div className="p-4" data-testid="quick-repair-request-post">
              <div className="flex flex-col gap-2">
                <span data-testid="ticket-title" className="text-headline2">
                  {translation?.title || data.title}
                </span>
                <p data-testid="ticket-content">
                  <LinkFormatter>{translation?.content || data.content}</LinkFormatter>
                </p>
                <Gallery images={data.images} />
              </div>
            </div>

            {data.languageIsoCode !== data.loggedInUser.language.id && (
              <div className="ml-auto px-5 pb-5">
                <Button
                  styling="ghostPrimary"
                  isLoading={translationIsLoading}
                  onClick={() => {
                    if (translation) {
                      onHideTranslation();
                    } else {
                      onTranslate();
                    }
                  }}
                >
                  <span>
                    {translation
                      ? t("page.quick-reply-repair-request.content.translate.original")
                      : t("page.quick-reply-repair-request.content.translate")}
                  </span>
                </Button>
              </div>
            )}
          </div>
          <div className="rounded-lg bg-white">
            {activities && activities.length > 0 ? (
              <div className="px-3">
                <TicketActivitiesSection
                  token={token}
                  languageId={loggedInUser.language.id}
                  user={loggedInUser}
                  activities={activities}
                  commentCount={data.publicCommentCount}
                  isLoadingMore={isFetchingMoreActivities}
                  canLoadMore={hasMoreComments}
                  isReadonly={readonly}
                  onLoadMore={fetchMoreComments}
                  onNoteEdit={setEditingComment}
                />
              </div>
            ) : null}

            {readonly ? (
              <div className="h-5" />
            ) : (
              <form className="p-3">
                <TicketCommentField
                  allowedAIActions={["generation", "responsibilitySuggestion"]}
                  allowedCommentTypes={allowedCommentTypes}
                  onComment={onCreateComment}
                  onEditComment={editComment}
                  editingComment={editingComment}
                  onCancelEditComment={() => setEditingComment(undefined)}
                  ticketId={data.requestId}
                  user={loggedInUser}
                  allowedAttachments={allowedCommentAttachments}
                  usage="quickReply"
                />
              </form>
            )}
          </div>
        </main>
        <aside className="flex w-full flex-col gap-4 md:max-w-xs">
          <TicketResidentInfo ticket={data} />

          {data.rems ? (
            <TicketRemsCard token={token} ticketIsCollective={data.isCollective} rems={data.rems} withOutline />
          ) : null}
        </aside>
      </div>
      <TicketLeaveCommentModal
        ticketId={data.requestId}
        title={t("page.quick-reply-repair-request.leave-comment-title")}
        description={
          <Trans
            i18nKey="page.quick-reply-repair-request.leave-comment-description"
            components={{
              yellow: <span className="text-yellow-700" />,
            }}
          />
        }
        user={loggedInUser}
        canCommentInternal={data.canAddInternalNote}
        canCommentPublic={data.canAddPublicComment}
        isOpened={isLeaveCommentModalOpened}
        onOpenChange={leaveCommentModalOpenHandlers.set}
        onSubmit={async (value, note) => {
          try {
            await reply({
              payload: {
                content: value,
                internal: note,
              },
              failureMessage: note
                ? t("page.quick-reply-repair-request.note-error")
                : t("page.quick-reply-repair-request.comment-error"),
            });
          } finally {
            leaveCommentModalOpenHandlers.setFalse();
          }
        }}
      />
      <TicketLeaveCommentModal
        ticketId={data.requestId}
        title={
          <Trans
            i18nKey="page.quick-reply-repair-request.assignee-change-title"
            values={{ name: changedAssignee?.fullName }}
            components={{
              bold: <strong className="font-old-semibold" />,
            }}
          />
        }
        description={
          <Trans
            i18nKey="page.quick-reply-repair-request.assignee-change-description"
            components={{
              yellow: <span className="text-yellow-700" />,
            }}
          />
        }
        user={loggedInUser}
        canCommentInternal={data.canAddInternalNote}
        canCommentPublic={false}
        isOpened={!!changedAssignee}
        onOpenChange={async () => {
          setChangedAssignee(undefined);
          if (changedAssignee) {
            await changeAssigneeMutation({
              request: { assigneeId: changedAssignee.id },
              failureMessage: t("page.quick-reply-repair-request.assignee-change-error"),
            });
          }
        }}
        onSubmit={async (note) => {
          if (changedAssignee) {
            try {
              await changeAssigneeMutation({
                request: {
                  assigneeId: changedAssignee.id,
                  note,
                },
                failureMessage: t("page.quick-reply-repair-request.assignee-change-error"),
              });
            } finally {
              setChangedAssignee(undefined);
            }
          }
        }}
        assignee={changedAssignee}
      />
      <TicketStatusChangeModal
        title={t("component.status-change-modal.title-via-email")}
        description={t("component.status-change-modal.description")}
        isOpened={!!targetStatus}
        onOpenChange={() => {
          setTargetStatusId(null);
        }}
        onSubmit={async (status) => {
          try {
            if (status.id === data.status.id) {
              return;
            }

            await changeStatusMutation({
              request: {
                statusId: status.id,
                silent: false,
              },
              failureMessage: t("page.quick-reply-repair-request.status-change-error"),
            });

            if (status.defaultStatusId === "inProgress" && data.canAddInternalNote && data.canAddPublicComment) {
              leaveCommentModalOpenHandlers.setTrue();
            }
          } finally {
            setTargetStatusId(null);
          }
        }}
        targetStatus={targetStatus!}
        possibleStatuses={sortedStatuses}
      />
      <TicketStatusChangeModal
        title={t("component.status-change-modal.title-after-response")}
        description={t("component.status-change-modal.description")}
        isOpened={isChangeStatusModal}
        onOpenChange={changeStatusModalHandler.set}
        onSubmit={async (status) => {
          try {
            if (status.id !== data.status.id) {
              await changeStatusMutation({
                request: {
                  statusId: status.id,
                  silent: true,
                },
                failureMessage: t("page.quick-reply-repair-request.status-change-error"),
              });
            }
          } finally {
            changeStatusModalHandler.setFalse();
          }
        }}
        targetStatus={sortedStatuses[0]}
        possibleStatuses={sortedStatuses}
      />
    </div>
  );
}

function useData(token: string) {
  const quickReplyTicketQueries = useQuickReplyTicketQueries();
  const {
    data: ticket,
    isLoading: isLoadingTicket,
    error: errorTicket,
  } = useQuery(quickReplyTicketQueries.details(token));
  const activitiesQuery = useInfiniteQuery(quickReplyTicketQueries.list(token));
  const replyMutation = quickReplyTicketMutations.useAddComment({ token });
  const editReplyMutation = quickReplyTicketMutations.useUpdateComment({
    token,
  });
  const uploadImageMutation = quickReplyTicketMutations.useAddImage({ token });
  const uploadDocumentMutation = quickReplyTicketMutations.useAddDocument({ token });
  const changeAssigneeMutation = quickReplyTicketMutations.useUpdateAssignee({ token });
  const changeStatusMutation = quickReplyTicketMutations.useUpdateStatus({ token });

  const [showTranslation, showTranslationHandler] = useBool();
  const translation = useQuery({
    ...quickReplyTicketQueries.translationDetails({ token, languageId: ticket?.loggedInUser.language.id || "-" }),
    enabled: showTranslation,
  });

  const activities = useMemo(
    () => activitiesQuery.data?.pages.flatMap((x) => x.items) || [],
    [activitiesQuery.data?.pages],
  );

  return {
    token,
    data: ticket,
    error: errorTicket,
    isLoading: isLoadingTicket,
    activities,
    isFetchingMoreActivities: activitiesQuery.isFetchingNextPage,
    hasMoreComments: activitiesQuery.hasNextPage,
    isChangingStatus: changeStatusMutation.isPending,
    translation: showTranslation ? translation.data?.data : undefined,
    translationIsLoading: translation.isLoading,
    onTranslate: showTranslationHandler.setTrue,
    onHideTranslation: showTranslationHandler.setFalse,
    fetchMoreComments: activitiesQuery.fetchNextPage,
    uploadImageMutation: uploadImageMutation.mutateAsync,
    uploadDocumentMutation: uploadDocumentMutation.mutateAsync,
    reply: replyMutation.mutateAsync,
    editReply: editReplyMutation.mutateAsync,
    changeAssigneeMutation: changeAssigneeMutation.mutateAsync,
    changeStatusMutation: changeStatusMutation.mutateAsync,
  };
}
