export async function initializeCookieBot(callback?: () => void): Promise<void> {
  if (!window.COOKIEBOT) {
    setTimeout(() => {
      callback?.();
    }, 1);

    return;
  }

  const scriptId = "Cookiebot";
  if (document.getElementById(scriptId)) {
    return;
  }

  await new Promise<void>((resolve) => {
    const script = document.createElement("script");
    script.id = scriptId;
    script.src = "https://consent.cookiebot.com/uc.js";
    script.setAttribute("data-cbid", "f2fbde9b-b30c-4273-ac05-c31dd52929ac");
    script.setAttribute("data-blockingmode", "auto");
    script.type = "text/javascript";
    script.addEventListener("load", () => resolve());
    // When cookiebot is down or blocked, we should still resolve the promise
    script.addEventListener("error", () => resolve());
    window.addEventListener("CookiebotOnConsentReady", () => {
      callback?.();
    });

    document.head.insertBefore(script, document.head.firstChild);
  });
}
