import type { UserRoleDto } from "api/types";
import { useSessionUser } from "hooks/Network/useSessionUser";

interface Props {
  getPageForRole: (roleType: UserRoleDto["type"]) => React.ReactNode;
}

export function RolePage({ getPageForRole }: Props): React.ReactNode {
  const sessionUser = useSessionUser();

  const page = getPageForRole(sessionUser.role.type);

  return page;
}
