import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useState } from "react";

import type { LayoutProps } from "./Layout";

interface Props {
  children: (data: LayoutProps) => React.ReactNode;
}

const AUTOMATED_SURVEYS_PER_PAGE = 15;

export function Loader(props: Props): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const [page, setPage] = useState(0);

  const surveyQueryParams = {
    Limit: AUTOMATED_SURVEYS_PER_PAGE,
    Offset: page * AUTOMATED_SURVEYS_PER_PAGE,
  };
  const {
    data: automatedSurveys,
    error: automatedSurveysError,
    isLoading: isInitialLoadingAutomatedSurveys,
    isFetching: isFetchingAutomatedSurveys,
  } = useQuery({
    queryKey: QUERY_KEYS.AUTOMATED_SURVEY_QUEUE_LIST(projectId, surveyQueryParams),
    queryFn: () => api.getAutomatedSurveysQueuesV1(surveyQueryParams),
    select: commonAPIDataSelector,
    placeholderData: keepPreviousData,
    staleTime: 5000,
  });

  const error = automatedSurveysError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  if (isInitialLoadingAutomatedSurveys && !automatedSurveys) {
    return <FullSizeLoader withPadding />;
  }

  const totalPages = automatedSurveys ? Math.ceil(automatedSurveys.total / AUTOMATED_SURVEYS_PER_PAGE) : 0;

  return props.children({
    automatedSurveys: automatedSurveys?.items ?? [],
    isLoading: isFetchingAutomatedSurveys,
    page,
    totalPages,
    onPageChange: setPage,
  });
}
