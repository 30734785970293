import type { UserRoleDetailsDto } from "api/types";

import { Layout } from "./Layout";
import { Loader } from "./Loader";
import type { RoleWizardStep } from "./Manager";

export function RoleCreateOrEditPage(): React.ReactNode {
  return <Loader>{(props) => <Layout {...props} />}</Loader>;
}

export type CreateOrEditPageState =
  | {
      roleId?: string;
      roleDetails?: UserRoleDetailsDto;
      initialStepId?: RoleWizardStep;
    }
  | undefined;
