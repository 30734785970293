import { InfoIcon } from "components/InfoIcon/InfoIcon";

interface Props {
  label: string;
  htmlFor?: string;
  description?: string;
  tooltip?: string;
  required?: boolean;
  isSubLabel?: boolean;
  hideLabel?: boolean;
  actions?: React.ReactNode;
}

export function FormField(props: React.PropsWithChildren<Props>): React.ReactNode {
  if (props.hideLabel) {
    return props.children;
  }

  const labelContent = (props.required ? "* " : "") + props.label;
  const title = (
    <>
      {labelContent || props.tooltip ? (
        <span className="mb-1 inline-flex gap-2">
          {props.isSubLabel ? (
            <span className="text-caption">{labelContent}</span>
          ) : (
            <span className="text-body-bold">{labelContent}</span>
          )}
          {props.tooltip ? <InfoIcon tooltip={props.tooltip} /> : null}
        </span>
      ) : null}
      {props.description != null && <p className="-mt-1 mb-1 text-grey-700">{props.description}</p>}
    </>
  );

  return (
    <div className={props.isSubLabel ? undefined : "flex-1"}>
      <div className="flex justify-between gap-2">
        {props.htmlFor ? <label htmlFor={props.htmlFor}>{title}</label> : <span>{title}</span>}
        {props.actions}
      </div>
      {props.children}
    </div>
  );
}
