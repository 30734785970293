import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { OnboardingScreenDto } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";

import type { LayoutProps } from "./Layout";

export function Loader(props: { children: (data: LayoutProps) => React.ReactNode }): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();
  const {
    data: onboardingScreens,
    isPending: isLoadingOnboardingScreens,
    error: onboardingScreensError,
  } = useQuery({
    queryKey: QUERY_KEYS.ONBOARDING_SCREENS_LIST(projectId),
    queryFn: () => api.getOnboardingScreensV1(),
    select: commonAPIDataSelector,
  });
  const {
    data: projectDetails,
    isPending: isLoadingProjectDetails,
    error: projectDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.PROJECT_DETAILS(projectId),
    queryFn: () => api.getProjectDetailsV1(),
    select: commonAPIDataSelector,
  });

  const showFlashToast = useFlashToast();
  const queryClient = useQueryClient();
  const deleteOnboardingScreen = useMutation({
    mutationFn: (onboardingScreen: OnboardingScreenDto) => api.deleteOnboardingScreensByIdV1(onboardingScreen.id),
    onSuccess() {
      showFlashToast({
        type: "success",
        title: t("page.onboarding-screens.actions.delete.notifications.success"),
      });
    },
    onError() {
      showFlashToast({
        type: "error",
        title: t("page.onboarding-screens.actions.delete.notifications.failed"),
      });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ONBOARDING_SCREENS_LIST(projectId) });
    },
  });

  const error = onboardingScreensError || projectDetailsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const isLoading = isLoadingOnboardingScreens || isLoadingProjectDetails;
  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  return props.children({
    logoLight: projectDetails?.logoLight?.url,
    styling: {
      bgColor: projectDetails?.styling.navigationColor,
      textColor: projectDetails?.styling.textLightColor,
    },
    onboardingScreens: onboardingScreens?.items ?? [],
    onDelete: deleteOnboardingScreen.mutateAsync,
  });
}
