import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { usePermission } from "hooks/usePermission";
import { canListMessages } from "modules/messages/permissions";
import { QUERY_KEYS } from "query-keys";
import { useMemo } from "react";
import type { ApiQueryParams } from "types/api-types";

import type { LayoutProps } from "./Layout";

interface Props {
  children: (props: LayoutProps) => React.ReactNode;
}

const POST_LIMIT = 5;

export function Loader({ children }: Props): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const session = useSessionUser();
  const hasPermission = usePermission();

  const messagesFilter = {
    Tab: "all",
    Offset: 0,
    Limit: POST_LIMIT,
    Connection: "included",
    ExcludeScheduledPosts: true,
    ExcludeArchivedPosts: true,
  } satisfies ApiQueryParams<"getMessagesV2">;

  const {
    data: postsData,
    isLoading: isLoadingUserPosts,
    error: errorLoadingUserPosts,
  } = useQuery({
    queryKey: QUERY_KEYS.MESSAGES_FEED_OLD(projectId, messagesFilter),
    queryFn: () => api.getMessagesV2(messagesFilter),
    select: commonAPIDataSelector,
    enabled: hasPermission(canListMessages),
  });

  const {
    data: widgets,
    isLoading: isLoadingWidgets,
    error: errorLoadingWidgets,
  } = useQuery({
    queryKey: QUERY_KEYS.DATA_WIDGETS_LIST(projectId),
    queryFn: () => api.getWidgetsActiveV1(),
    select: commonAPIDataSelector,
  });

  const posts = useMemo(() => postsData?.items, [postsData]);

  const error = errorLoadingUserPosts || errorLoadingWidgets;
  if (error) {
    return <ErrorPage error={error} />;
  }

  return children({
    posts: posts ?? [],
    isLoadingPosts: isLoadingUserPosts,
    widgets: widgets?.activeWidgets ?? [],
    canViewWidgets: session.isAdmin && session.role.type !== "maintenance",
    isLoadingWidgets,
  });
}
