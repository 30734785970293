export const helpCategoriesRoutes = {
  list: "",
  create: "new",
  details: ":id",
  edit: ":id/edit",
} as const;

export const interestGroupsRoutes = {
  list: "",
  create: "new",
  details: ":id",
  edit: ":id/edit",
  editResidentGroup: "resident/:id/edit",
  createResidentGroup: "resident/new",
} as const;
