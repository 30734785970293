import { ErrorPage } from "components/Error/ErrorPage";
import { RequireAuth } from "components/Routing/RequireAuth";
import type { RouteObject } from "react-router-dom";

import { ActiveSurveyDetailsPage } from "./pages/ActiveSurveyDetails";
import { CreateOrEditAutomatedSurveysPage } from "./pages/CreateOrEdit";
import { ListAutomatedSurveysPage } from "./pages/List";
import { canListAutomatedSurveys, canManageAutomatedSurveys } from "./permissions";
import { automatedSurveysRoutes } from "./routes";

export const automatedSurveysRoute = [
  {
    path: automatedSurveysRoutes.list,
    element: (
      <RequireAuth permission={canListAutomatedSurveys}>
        <ListAutomatedSurveysPage />
      </RequireAuth>
    ),
  },
  {
    path: automatedSurveysRoutes.create,
    element: (
      <RequireAuth permission={canManageAutomatedSurveys}>
        <CreateOrEditAutomatedSurveysPage />
      </RequireAuth>
    ),
  },
  {
    path: automatedSurveysRoutes.edit,
    element: (
      <RequireAuth permission={canManageAutomatedSurveys}>
        <CreateOrEditAutomatedSurveysPage />
      </RequireAuth>
    ),
  },
  {
    path: automatedSurveysRoutes.details,
    element: (
      <RequireAuth permission={canListAutomatedSurveys}>
        <ActiveSurveyDetailsPage />
      </RequireAuth>
    ),
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
