import { routes } from "routes";

export function resolveOldLink(link: string, slug?: string): string {
  if (!slug) {
    return link;
  }

  link = link.split("?")[0];

  // matches /messages/124 or /messages#120
  const messageMatch = link.match(/\/messages\/(\d+)$/) || link.match(/\/messages#(\d+)$/);
  if (messageMatch) {
    const messageId = messageMatch[1];

    return routes.messageFeed.details({ slug, id: messageId });
  }

  // matches /tickets/124 or /repair-requests/123
  const ticketMatch = link.match(/\/tickets\/(\d+)$/) || link.match(/\/repair-requests\/(\d+)$/);
  if (ticketMatch) {
    const ticketId = ticketMatch[1];

    return routes.tickets.details({ slug, id: ticketId });
  }

  return link;
}
