import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useDeleteRole } from "modules/roles/hooks/useDelete";
import { QUERY_KEYS } from "query-keys";

import type { LayoutProps } from "./Layout";

interface Props {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: Props): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const {
    data: roles,
    isPending: isRolesLoading,
    error,
  } = useQuery({
    queryKey: QUERY_KEYS.ROLE_LIST(projectId),
    queryFn: () => api.getPermissionsRolesV1(),
    select: commonAPIDataSelector,
  });
  const { mutateAsync: deleteRole } = useDeleteRole();

  if (error) {
    return <ErrorPage error={error} />;
  }

  return roles == null || isRolesLoading ? (
    <FullSizeLoader withPadding />
  ) : (
    <>{props.children({ roles, deleteRole })}</>
  );
}
