import type { SortingState } from "@tanstack/react-table";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import type { ProjectOverviewItemDto } from "api/types";
import { TableSortIcon } from "components/Icons/Icons";
import { ProjectLogo } from "components/ProjectLogo/ProjectLogo";
import { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import { calculateStats, optionalNumberSort } from "../util";
import { AddProjectButton } from "./AddProjectButton";
import { BluePill } from "./BluePill";

interface Props {
  items: ProjectOverviewItemDto[];
  showAddProject: () => void;
  showEngagement: boolean;
  onChangeProject: (projectId: string) => void;
}

export const ListView = memo(function ListView({
  items,
  showAddProject,
  showEngagement,
  onChangeProject,
}: Props): React.ReactNode {
  const { t } = useTranslation();

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "name",
      desc: false,
    },
  ]);

  const tableData = useMemo(
    () =>
      items.map((x) => ({
        ...x,
        ...calculateStats(x),
      })),
    [items],
  );

  const canSeeAdoptionOrEngagement = items.some(
    (x) => x.userRoleType !== "caretaker" && x.userRoleType !== "maintenance",
  );

  type ColumnType = (typeof tableData)[number];

  const columns = useMemo(() => {
    const helper = createColumnHelper<ColumnType>();

    return [
      helper.accessor("logoUrl", {
        header: "",
        cell: (cell) => <ProjectLogo className="size-10" src={cell.getValue()} />,
        enableSorting: false,
      }),
      helper.accessor("name", {
        header: t("page.portfolio.table.header.name"),
        cell: (cell) => (
          <>
            {cell.getValue()}
            {cell.row.original.type === "companyBased" ? (
              <span className="-my-1 ml-2 rounded bg-blue-100 p-1 text-caption-bold text-blue-700">
                {t("page.portfolio.project-type.company")}
              </span>
            ) : null}
          </>
        ),
      }),
      helper.accessor("city", {
        header: t("page.portfolio.table.header.city"),
        cell: (cell) => cell.getValue() || "-",
      }),
      helper.accessor("feelingAtHomeRating", {
        header: t("page.portfolio.table.header.fah-rating"),
        cell: (cell) => {
          const value = cell.getValue();
          if (!value?.value) {
            return <span>-</span>;
          }

          return (
            <span className="flex items-center whitespace-nowrap">
              {value.value}/ 10
              <BluePill percentage={value.changePercentage} withStar />
            </span>
          );
        },
        sortingFn: (a, b) =>
          optionalNumberSort(a.original.feelingAtHomeRating.value, b.original.feelingAtHomeRating.value),
      }),
      helper.accessor("averageTicketRating", {
        header: t("page.portfolio.table.header.avg-rating"),
        cell: (cell) => {
          const value = cell.getValue();
          if (!value?.value) {
            return <span>-</span>;
          }

          return (
            <span className="flex items-center whitespace-nowrap">
              {value.value}/ 10
              <BluePill percentage={value.changePercentage} withStar />
            </span>
          );
        },
        sortingFn: (a, b) =>
          optionalNumberSort(a.original.averageTicketRating.value, b.original.averageTicketRating.value),
      }),
      ...(canSeeAdoptionOrEngagement
        ? [
            helper.accessor("adoptionPercentage", {
              header: t("page.portfolio.table.header.adoption"),
              cell: (cell) => {
                const value = cell.getValue();
                if (!value?.value) {
                  return <span>-</span>;
                }

                return (
                  <span className="flex items-center">
                    {value.value}%<BluePill percentage={value.changePercentage} />
                  </span>
                );
              },
              sortingFn: (a, b) =>
                optionalNumberSort(a.original.adoptionPercentage.value, b.original.adoptionPercentage.value),
            }),
          ]
        : []),
      ...(canSeeAdoptionOrEngagement && showEngagement
        ? [
            helper.accessor("engagedResidentPercentage", {
              header: t("page.portfolio.table.header.engagement"),
              cell: (cell) => {
                const value = cell.getValue();
                if (!value?.value) {
                  return <span>-</span>;
                }

                return (
                  <span className="flex items-center">
                    {value.value}%<BluePill percentage={value.changePercentage} />
                  </span>
                );
              },
              sortingFn: (a, b) =>
                optionalNumberSort(
                  a.original.engagedResidentPercentage.value,
                  b.original.engagedResidentPercentage.value,
                ),
            }),
          ]
        : []),
    ];
  }, [canSeeAdoptionOrEngagement, showEngagement, t]);

  const table = useReactTable<ColumnType>({
    columns,
    sortingFns: {
      alphanumeric: (rowA, rowB, columnId) => {
        const valueA = (rowA.original[columnId] as string).toLowerCase();
        const valueB = (rowB.original[columnId] as string).toLowerCase();

        return valueB.localeCompare(valueA) > 0 ? -1 : 1;
      },
    },
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <>
      <div className="overflow-x-auto" data-testid="list-view">
        <table className="m-0 w-full rounded-md p-0 text-body">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr className="bg-white" key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const isSorted = header.column.getIsSorted();
                  const canSort = header.column.getCanSort();

                  return (
                    <td
                      key={header.id}
                      colSpan={header.colSpan}
                      className={twJoin(
                        "group border-l border-grey-100 px-2 py-3 text-left uppercase first:border-l-0",
                        isSorted ? "text-aop-basic-blue-500" : "text-grey-600",
                        canSort ? "cursor-pointer hocus:bg-grey-100" : undefined,
                      )}
                    >
                      {header.isPlaceholder ? null : (
                        <div className="flex items-center gap-1" onClick={header.column.getToggleSortingHandler()}>
                          <span className="text-caption">
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </span>
                          {canSort ? (
                            <span className="shrink-0">
                              <TableSortIcon sorted={!!isSorted} desc={isSorted === "desc"} />
                            </span>
                          ) : null}
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className="group cursor-pointer bg-white odd:bg-white/80 hocus:z-10 hocus:scale-100 hocus:bg-white hocus:shadow hocus:odd:bg-white"
                  onClick={() => onChangeProject(row.original.id)}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      onChangeProject(row.original.id);
                    }
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      className="!group-hover:border-white h-12 border-l border-grey-100 px-2 text-left first:border-l-0 first:pl-6 first:pr-0 [&:nth-child(2)]:border-l-0"
                      key={cell.column.id}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end">
        <AddProjectButton onClick={showAddProject}>{t("page.portfolio.add-new-project")}</AddProjectButton>
      </div>
    </>
  );
});
