import { Portal, Tooltip as ArkTooltip } from "@ark-ui/react";
import { useKeyUpPressed } from "hooks/useKeyUpPressed";
import type React from "react";
import { twJoin } from "tailwind-merge";

export interface TooltipProps {
  tooltip: React.ReactNode;
  children: React.ReactElement;
  offsetY?: number;
}

export function Tooltip({ children, tooltip, offsetY }: TooltipProps): React.ReactNode {
  if (tooltip == null || tooltip == "") {
    return children;
  }

  return (
    <WithTooltip tooltip={tooltip} offsetY={offsetY}>
      {children}
    </WithTooltip>
  );
}

function WithTooltip({ children, tooltip, offsetY }: TooltipProps) {
  // To make sure we don't render a button around an interactive element
  const childType = children.type;
  const isInteractiveChild =
    childType === "button" ||
    childType === "a" ||
    childType === "input" ||
    childType === "label" ||
    childType === "select" ||
    childType === "textarea";

  const justPressedTab = useKeyUpPressed("Tab", 10);

  return (
    <ArkTooltip.Root
      openDelay={500}
      closeDelay={500}
      positioning={{
        placement: "top",
        offset: {
          mainAxis: offsetY,
        },
      }}
    >
      <ArkTooltip.Trigger
        onFocus={(e) => {
          // Only prevent focus if the focus was triggered by a keyboard event
          // This is to make sure the tooltip doesn't show for a couple milliseconds when clicking on the element
          if (!justPressedTab) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        asChild={isInteractiveChild}
        className={isInteractiveChild ? "" : "cursor-[inherit]"}
      >
        {children}
      </ArkTooltip.Trigger>
      <Portal>
        <ArkTooltip.Positioner className="!z-20">
          <ArkTooltip.Content
            className={twJoin(
              "max-w-[80%] whitespace-pre-line rounded-2px bg-grey-900 px-3 py-2 text-center text-overline text-white shadow-md md:max-w-sm",
              "repeat-1 data-[placement=bottom]:origin-top data-[placement=left]:origin-right data-[placement=right]:origin-left data-[placement=top]:origin-bottom",
              "data-[state=open]:animate-in data-[state=open]:fade-in data-[state=open]:zoom-in",
              "data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=closed]:zoom-out",
            )}
          >
            <ArkTooltip.Arrow
              style={{
                // @ts-expect-error --css-vars
                "--arrow-size": "8px",
                // bg-grey-dark-800
                "--arrow-background": "#4a4a68",
              }}
            >
              <ArkTooltip.ArrowTip />
            </ArkTooltip.Arrow>
            {tooltip}
          </ArkTooltip.Content>
        </ArkTooltip.Positioner>
      </Portal>
    </ArkTooltip.Root>
  );
}
