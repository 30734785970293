import type { QuickReplyRepairRequestActivityDto, QuickReplyRepairRequestCommentDto, UserDto } from "api/types";
import { Button } from "components/Button/Button";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { LoadingIcon } from "components/Icons/Icons";
import { TicketStatus } from "components/Ticket/TicketStatus";
import { AnimatePresence } from "framer-motion";
import { memo, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { groupActivities, TicketActivityWrapper, TicketComment } from "./TicketActivity";

interface Props {
  token: string;
  languageId: string;
  isLoadingMore?: boolean;
  canLoadMore?: boolean;
  user: UserDto;
  activities: QuickReplyRepairRequestActivityDto[];
  commentCount?: number;
  isReadonly?: boolean;
  onLoadMore?: () => void;
  onNoteEdit?: (comment: QuickReplyRepairRequestCommentDto) => void;
}

function TicketActivitySection({
  token,
  languageId,
  isLoadingMore,
  canLoadMore,
  user,
  activities,
  commentCount,
  isReadonly,
  onLoadMore,
  onNoteEdit,
}: Props): React.ReactNode {
  const { t } = useTranslation();

  const groupedActivities = useMemo(
    () => groupActivities(activities.filter((x) => x.assigneeChange || x.comment || x.statusChange)),
    [activities],
  );

  return (
    <div>
      <div className="relative flex px-2 pb-0 pt-4 text-caption text-aop-basic-blue-500">
        {canLoadMore && onLoadMore ? (
          <Button styling="tertiary" onClick={onLoadMore} data-testid="comments-load-more">
            <span className="text-caption">{t("component.community-post-comments.load-more")}</span>
          </Button>
        ) : null}

        {isLoadingMore ? <LoadingIcon className="absolute inset-0 mx-auto my-4 w-6" /> : null}

        {commentCount !== undefined && (
          <span data-testid="comments-public-comment-amount" className="ml-auto text-caption text-grey-500">
            {t("component.community-post-comments.comment-count", { count: commentCount })}
          </span>
        )}
      </div>
      <div className="mt-2 rounded-lg border border-grey-100 pl-2">
        {groupedActivities.map(([date, activities]) => (
          <div key={date.toString()} data-testid="comment-date-group">
            <div className="relative my-4 flex items-center justify-center whitespace-nowrap text-caption text-grey-300">
              <span className="absolute h-px w-full max-w-xs bg-grey-300" />
              <span className="relative bg-white px-4 text-caption">
                <FormattedDate format="dateRelative" date={new Date(Number(date)).toISOString()} />
              </span>
            </div>
            <div>
              <AnimatePresence>
                {activities.map((activity) => {
                  if (activity.comment) {
                    return (
                      <TicketComment
                        token={token}
                        languageId={languageId}
                        key={"comment-" + activity.comment.id}
                        isOwn={activity.comment.author.id === user.id}
                        comment={activity.comment}
                        isReadonly={isReadonly}
                        onEdit={onNoteEdit}
                      />
                    );
                  }

                  if (activity.assigneeChange) {
                    return (
                      <TicketActivityWrapper
                        key={"assignee-change-" + activity.assigneeChange.id}
                        author={activity.assigneeChange.author}
                        postedAt={activity.postedAt}
                      >
                        {activity.assigneeChange.oldAssignee ? (
                          <Trans
                            i18nKey="component.repair-request-activity-section.assignee-change"
                            components={{
                              assignee: (
                                <span
                                  className="mx-1 font-old-semibold not-italic"
                                  data-testid="activity-assignee-change"
                                  data-value={activity.assigneeChange.newAssignee.fullName}
                                />
                              ),
                            }}
                            values={{
                              oldAssignee: activity.assigneeChange.oldAssignee.fullName,
                              newAssignee: activity.assigneeChange.newAssignee.fullName,
                            }}
                          />
                        ) : (
                          <Trans
                            i18nKey="component.repair-request-activity-section.new-assignee"
                            components={{
                              assignee: (
                                <span
                                  className="mx-1 font-old-semibold not-italic"
                                  data-testid="activity-assignee-change"
                                  data-value={activity.assigneeChange.newAssignee.fullName}
                                />
                              ),
                            }}
                            values={{
                              newAssignee: activity.assigneeChange.newAssignee.fullName,
                            }}
                          />
                        )}
                      </TicketActivityWrapper>
                    );
                  }

                  if (activity.statusChange) {
                    return (
                      <TicketActivityWrapper
                        key={"status-change-" + activity.statusChange.id}
                        author={activity.statusChange.author}
                        postedAt={activity.postedAt}
                      >
                        <Trans
                          i18nKey="component.repair-request-activity-section.status-change"
                          components={{
                            oldStatus: (
                              <span className="mx-1 inline-block font-old-semibold not-italic">
                                <TicketStatus {...activity.statusChange.oldStatus} />
                                <span />
                              </span>
                            ),
                            newStatus: (
                              <span
                                className="mx-1 inline-block font-old-semibold not-italic"
                                data-testid="activity-status-change"
                                data-value={activity.statusChange.newStatus.id}
                              >
                                <TicketStatus {...activity.statusChange.newStatus} />
                                <span />
                              </span>
                            ),
                          }}
                        />
                      </TicketActivityWrapper>
                    );
                  }

                  return null;
                })}
              </AnimatePresence>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const TicketActivitiesSectionMemo = memo(TicketActivitySection);

export { TicketActivitiesSectionMemo as TicketActivitiesSection };
