import { de, enGB as en, nl } from "date-fns/locale";

export const locales = {
  en,
  nl,
  de,
};

// Some of these translations are unused, because they're limited in our date formatting
export const hardcodedTranslations = {
  de: {
    errors: {
      connectionFailure: {
        title: "Bei uns ist etwas schief gelaufen!\nDas Entwicklungsteam wird sich damit befassen.",
        error: "Error:",
        unknown: "Unbekannt",
        solutions: "Mögliche Lösungen:",
        refresh: "Aktualisieren",
        page: "Sie die Webseite",
        later: "Versuchen Sie es später noch einmal!",
        contact: "Ist der Dienst zu lange nicht verfügbar?\nKontakt",
      },
    },
  },
  nl: {
    errors: {
      connectionFailure: {
        title: "Er is iets fout gegaan aan onze kant!\nHet devteam gaat er naar kijken",
        error: "Error:",
        unknown: "Onbekend",
        solutions: "Mogelijke oplossingen:",
        refresh: "Herlaad",
        page: "de pagina",
        later: "Probeer het later opnieuw!",
        contact: "Service te lang onbeschikbaar?\nEmail",
      },
    },
  },
  en: {
    errors: {
      connectionFailure: {
        title: "Something is wrong on our end!\nDevteam is looking into it",
        error: "Error:",
        unknown: "Unknown",
        solutions: "Possible solutions:",
        refresh: "Refresh",
        page: "the page",
        later: "Come back later!",
        contact: "Service unavailable for too long?\nContact",
      },
    },
  },
} as const;

export const fallbackLanguageCode: keyof typeof hardcodedTranslations = "nl";
