import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { UserProfileTicketsConfigurationRequest } from "api/types";
import alertIcon from "assets/icons/annotation-alert.svg";
import cameraIcon from "assets/icons/camera-plus.svg";
import chevronLeftIcon from "assets/icons/chevron-left.svg";
import commentIcon from "assets/icons/message-circle-01.svg";
import sendIcon from "assets/icons/send-03.svg";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { isDocumentUploaded } from "components/DocumentInput/useDocumentFile";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormDocumentInput } from "components/Form/FormDocumentInput";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { FormTextArea } from "components/Form/FormTextArea";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Phone } from "components/Phone/Phone";
import { PhoneInner } from "components/Phone/PhoneInner";
import { PhoneStatusBar } from "components/Phone/PhoneStatusBar";
import { Switch } from "components/Switch/Switch";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { validateSize } from "helpers/file-size";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { createRequiredStringRule } from "helpers/rules";
import { isValidHttpUrl } from "helpers/validation";
import { useBool } from "hooks/useBool";
import { QUERY_KEYS } from "query-keys";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

interface FormValues {
  customIntroText?: string;
  regulationDocument?: FormDocument[];
  regulationLink?: string;
  requireSignOffRegulations: boolean;
  autoReplyText?: string;
}

interface PreviewData {
  showIntro: boolean;
  customIntroText: string;
  showRegulations: boolean;
  isRegulationsRequired: boolean;
  showAutoReply: boolean;
  autoReplyText: string;
}

const MAX_CUSTOM_INTRO_TEXT_LENGTH = 400;
const MAX_AUTO_REPLY_TEXT_LENGTH = 1200;

export function TicketSettingsPage(): React.ReactNode {
  const api = useApi();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();
  const [previewData, setPreviewData] = useState<PreviewData>({
    showIntro: false,
    customIntroText: "",
    showRegulations: false,
    isRegulationsRequired: false,
    showAutoReply: false,
    autoReplyText: "",
  });

  const {
    data: ticketSettings,
    isPending: isLoadingTicketSettings,
    error: errorLoadingTicketSettings,
  } = useQuery({
    queryKey: QUERY_KEYS.TICKET_SETTINGS,
    queryFn: () => api.getUserProfileTicketsConfigurationV1(),
    select: commonAPIDataSelector,
  });

  const setTicketSettings = useMutation({
    mutationFn: (req: UserProfileTicketsConfigurationRequest) => api.putUserProfileTicketsConfigurationV1(req),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKET_SETTINGS });
      showFlashToast({ type: "success", title: t("page.ticket-settings.success") });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.ticket-settings.error") });
    },
  });

  const { mutateAsync: uploadDocument, isPending: isUploadingDocument } = useMutation({
    mutationFn: (payload: { File: File } | { Link: string }) => api.postFilesDocumentsV1(payload).then((x) => x.data),
  });

  async function uploadDocumentIfNotUploaded(values: FormValues): Promise<string | undefined> {
    const document = values.regulationDocument?.[0];
    if (isDocumentUploaded(document)) {
      return document.id;
    } else if (document) {
      const uploadedDocument = await uploadDocument({ File: document.file });

      return uploadedDocument.id;
    } else if (values.regulationLink) {
      if (values.regulationLink === ticketSettings?.regulations?.url) {
        return ticketSettings.regulations.id;
      } else {
        const uploadedDocument = await uploadDocument({ Link: values.regulationLink });

        return uploadedDocument.id;
      }
    } else {
      return undefined;
    }
  }

  async function handleSubmit(values: FormValues) {
    const regulationsId = await uploadDocumentIfNotUploaded(values);

    await setTicketSettings.mutateAsync({
      customIntroText: values.customIntroText ? values.customIntroText : undefined,
      regulationsId: regulationsId ?? undefined,
      requireSignOffRegulations: values.requireSignOffRegulations,
      autoReplyText: values.autoReplyText ? values.autoReplyText : undefined,
    });
  }

  if (isLoadingTicketSettings) {
    return <FullSizeLoader />;
  }

  if (errorLoadingTicketSettings) {
    return <ErrorPage error={errorLoadingTicketSettings} />;
  }

  const breadcrumbs = [
    { name: t("page.ticket-settings.breadcrumbs.portfolio"), to: routes.portfolio.overview() },
    { name: t("page.ticket-settings.breadcrumbs.tickets"), to: routes.portfolio.tickets() },
    { name: t("page.ticket-settings.breadcrumbs.self") },
  ];

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.ticket-settings.title")}
      subTitle={<Breadcrumbs pages={breadcrumbs} />}
    >
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2 flex flex-col gap-4 rounded-lg bg-white p-4 lg:col-span-1">
          <span className="text-headline3 leading-old-headline4">
            {t("page.ticket-settings.custom-settings.title")}
          </span>
          <span className="text-caption">{t("page.ticket-settings.custom-settings.subtext")}</span>
          <TicketSettingsForm
            defaultFormValues={{
              customIntroText: ticketSettings?.customIntroText,
              requireSignOffRegulations: ticketSettings?.requireSignOffRegulations ?? false,
              regulationDocument:
                ticketSettings?.regulations && ticketSettings.regulations.fileName
                  ? [ticketSettings.regulations]
                  : undefined,
              regulationLink:
                ticketSettings?.regulations?.url && !ticketSettings?.regulations?.fileName
                  ? ticketSettings.regulations.url
                  : undefined,
              autoReplyText: ticketSettings?.autoReplyText,
            }}
            onSubmit={handleSubmit}
            isSubmitting={setTicketSettings.isPending || isUploadingDocument}
            onChange={setPreviewData}
          />
        </div>
        {previewData.showIntro || previewData.showAutoReply ? (
          <div className="col-span-1 hidden flex-col items-center gap-8 rounded-lg bg-white p-4 lg:flex">
            {previewData.showIntro ? (
              <TicketIntroTextAppPreview
                customIntroText={previewData.customIntroText}
                showRegulations={previewData.showRegulations}
                isRegulationsRequired={previewData.isRegulationsRequired}
              />
            ) : null}
            {previewData.showAutoReply ? <TicketAutoReplyAppPreview autoReplyText={previewData.autoReplyText} /> : null}
          </div>
        ) : null}
      </div>
    </DocumentPaper>
  );
}

interface TicketSettingsFormProps {
  defaultFormValues: FormValues;
  onSubmit: (values: UserProfileTicketsConfigurationRequest) => void;
  isSubmitting: boolean;
  onChange: (data: PreviewData) => void;
}

function TicketSettingsForm({
  defaultFormValues,
  onSubmit,
  isSubmitting,
  onChange,
}: TicketSettingsFormProps): React.ReactNode {
  const form = useForm<FormValues>({ defaultValues: defaultFormValues });
  const { t } = useTranslation();
  const [showCustomIntroText, showCustomIntroTextHandler] = useBool(!!defaultFormValues.customIntroText);
  const [showAutoReplyText, showAutoReplyTextHandler] = useBool(!!defaultFormValues.autoReplyText);

  const customIntroText = useWatch({ control: form.control, name: "customIntroText" });
  const requireSignOffRegulations = useWatch({ control: form.control, name: "requireSignOffRegulations" });
  const regulationDocument = useWatch({ control: form.control, name: "regulationDocument" });
  const regulationLink = useWatch({ control: form.control, name: "regulationLink" });
  const autoReplyText = useWatch({ control: form.control, name: "autoReplyText" });

  useEffect(() => {
    onChange({
      showIntro: showCustomIntroText,
      customIntroText: customIntroText ?? "",
      showRegulations: requireSignOffRegulations || !!regulationDocument || !!regulationLink,
      isRegulationsRequired: requireSignOffRegulations,
      showAutoReply: showAutoReplyText,
      autoReplyText: autoReplyText ?? "",
    });
  }, [
    onChange,
    showCustomIntroText,
    customIntroText,
    requireSignOffRegulations,
    regulationDocument,
    regulationLink,
    showAutoReplyText,
    autoReplyText,
  ]);

  useEffect(() => {
    if (!showCustomIntroText) {
      form.setValue("customIntroText", "");
      form.setValue("requireSignOffRegulations", false);
      form.setValue("regulationDocument", undefined);
      form.setValue("regulationLink", "");
    }

    if (showCustomIntroText && !form.getValues("customIntroText")) {
      form.setValue("customIntroText", t("page.resident-tickets.create-modal.intro-text"));
    }

    if (!showAutoReplyText) {
      form.setValue("autoReplyText", "");
    }
  }, [t, showCustomIntroText, showAutoReplyText, form]);

  return (
    <Form formMethods={form} onSubmit={onSubmit}>
      <FormContent maxWidth="4xl">
        <div className="flex items-center gap-2">
          <Switch isChecked={showCustomIntroText} onChange={showCustomIntroTextHandler.toggle} />
          <span className="text-body-bold">{t("page.ticket-settings.ticket-conditions.title")}</span>
        </div>
        <span className="whitespace-pre-wrap text-caption">{t("page.ticket-settings.ticket-conditions.subtext")}</span>
        {showCustomIntroText ? (
          <>
            <FormField label={t("page.ticket-settings.ticket-conditions.label")} required>
              <FormTextArea<FormValues>
                name="customIntroText"
                placeholder={t("page.ticket-settings.ticket-conditions.placeholder")}
                rules={{
                  validate: {
                    required: createRequiredStringRule(t, "page.ticket-settings.ticket-conditions.label"),
                  },
                  maxLength: {
                    value: MAX_CUSTOM_INTRO_TEXT_LENGTH,
                    message: t("components.form.error.max-length", { length: MAX_CUSTOM_INTRO_TEXT_LENGTH }),
                  },
                }}
              />
            </FormField>
            <FormCheckbox<FormValues>
              name="requireSignOffRegulations"
              label={t("page.ticket-settings.ticket-regulations.title")}
            />
            <FormField
              label={t("page.ticket-settings.ticket-regulations.file.label")}
              required={requireSignOffRegulations}
            >
              <FormDocumentInput<FormValues, "regulationDocument">
                name="regulationDocument"
                accept="application/pdf"
                withPreview
                disabled={!!regulationLink}
                rules={{
                  validate: {
                    required: () => {
                      if (requireSignOffRegulations && !regulationLink && !regulationDocument?.[0]) {
                        return t("components.form.error.either-required", {
                          inputName1: t("page.ticket-settings.ticket-regulations.file.label"),
                          inputName2: t("page.ticket-settings.ticket-regulations.url.label"),
                        });
                      }
                    },
                    size(doc) {
                      if (doc) {
                        return validateSize(t, doc);
                      }
                    },
                  },
                }}
              />
            </FormField>

            <span className="text-body-bold uppercase text-grey-400">
              {t("page.ticket-settings.ticket-regulations.separator")}
            </span>

            <FormField
              label={t("page.ticket-settings.ticket-regulations.url.label")}
              required={requireSignOffRegulations}
            >
              <FormInput<FormValues, "regulationLink">
                name="regulationLink"
                placeholder={t("page.ticket-settings.ticket-regulations.url.placeholder")}
                disabled={regulationDocument && regulationDocument.length > 0}
                rules={{
                  validate: {
                    required: () => {
                      if (requireSignOffRegulations && !regulationLink && !regulationDocument?.[0]) {
                        return t("components.form.error.either-required", {
                          inputName1: t("page.ticket-settings.ticket-regulations.file.label"),
                          inputName2: t("page.ticket-settings.ticket-regulations.url.label"),
                        });
                      }
                    },
                    isValid: (value) => {
                      if (value && !isValidHttpUrl(value)) {
                        return t("components.form.error.invalid-url");
                      }
                    },
                  },
                }}
              />
            </FormField>
          </>
        ) : null}

        <div className="border-b border-grey-300" />

        <div className="flex items-center gap-2">
          <Switch isChecked={showAutoReplyText} onChange={showAutoReplyTextHandler.toggle} />
          <span className="text-body-bold">{t("page.ticket-settings.auto-reply.title")}</span>
        </div>
        <span className="whitespace-pre-wrap text-caption">{t("page.ticket-settings.auto-reply.subtext")}</span>
        {showAutoReplyText ? (
          <FormField label={t("page.ticket-settings.auto-reply.label")} required>
            <FormTextArea<FormValues>
              name="autoReplyText"
              placeholder={t("page.ticket-settings.auto-reply.placeholder")}
              rules={{
                validate: {
                  required: createRequiredStringRule(t, "page.ticket-settings.auto-reply.label"),
                },
                maxLength: {
                  value: MAX_AUTO_REPLY_TEXT_LENGTH,
                  message: t("components.form.error.max-length", { length: MAX_AUTO_REPLY_TEXT_LENGTH }),
                },
              }}
            />
          </FormField>
        ) : null}
      </FormContent>
      <Button type="submit" isLoading={isSubmitting}>
        {t("page.ticket-settings.submit")}
      </Button>
    </Form>
  );
}

interface TicketIntroTextAppPreviewProps {
  customIntroText: string;
  showRegulations: boolean;
  isRegulationsRequired: boolean;
}

function TicketIntroTextAppPreview({
  customIntroText,
  showRegulations,
  isRegulationsRequired,
}: TicketIntroTextAppPreviewProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className="flex h-1/2 w-1/3 flex-col items-center gap-4">
      <span className="text-body-bold">{t("page.ticket-settings.app-preview.title.intro-text")}</span>
      <div className="size-full">
        <Phone>
          <div className="size-full bg-black">
            <PhoneStatusBar fill="white" />
          </div>
          <div className="absolute left-6 top-12 h-full w-5/6 rounded-lg bg-aop-off-white/80" />
          <div className="absolute left-4 top-14 h-full w-[89%] rounded-lg bg-aop-off-white p-2">
            <div className="flex items-center justify-between pb-4">
              <Icon name={chevronLeftIcon} />
              <span className="text-[12px] font-old-semibold text-aop-dark-blue-500">
                {t("page.ticket-settings.app-preview.intro-text.title")}
              </span>
              <span className="text-[12px] font-old-semibold text-aop-basic-blue-500">{t("common.action.send")}</span>
            </div>
            <div className="flex h-[70%] flex-col gap-2 overflow-y-auto">
              <span className="text-[14px] font-old-semibold">
                {t("page.ticket-settings.app-preview.intro-text.ticket-category")}
              </span>
              <span className="text-[12px] font-old-semibold">{t("page.resident-tickets.create-modal.subtitle")}</span>
              <span className="whitespace-pre-line text-[12px]">{customIntroText}</span>
              <div className="flex flex-col gap-0.5">
                <div className="rounded-t-lg bg-white p-4">
                  <span className="text-[12px] text-grey-500">
                    {t("page.ticket-settings.app-preview.intro-text.subject")}
                  </span>
                </div>
                <div className="h-32 bg-white p-4">
                  <span className="text-[12px] text-grey-500">
                    {t("page.ticket-settings.app-preview.intro-text.body")}
                  </span>
                </div>
                <div
                  className={twJoin(
                    "flex items-center gap-1 bg-white p-4 text-aop-dark-blue-500",
                    !showRegulations ? "rounded-b-lg" : undefined,
                  )}
                >
                  <Icon name={cameraIcon} />
                  <span className="text-[14px] font-old-semibold">
                    {t("page.ticket-settings.app-preview.intro-text.image")}
                  </span>
                </div>
                {showRegulations ? (
                  <div className="flex items-center justify-between rounded-b-lg bg-white p-4">
                    <span className="text-[10px]">
                      <Trans
                        i18nKey="page.ticket-settings.app-preview.intro-text.regulations"
                        components={{ b: <span className="font-old-semibold text-aop-basic-blue-500" /> }}
                      />
                    </span>
                    {isRegulationsRequired ? <Switch isChecked={false} onChange={() => null} /> : null}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="absolute bottom-3 left-4 h-20 w-[89%] bg-aop-off-white p-4">
            <div className="rounded-lg bg-aop-basic-blue-500 p-3 text-center text-white">{t("common.action.send")}</div>
          </div>
        </Phone>
      </div>
    </div>
  );
}

interface TicketAutoReplyAppPreviewProps {
  autoReplyText: string;
}

function TicketAutoReplyAppPreview({ autoReplyText }: TicketAutoReplyAppPreviewProps): React.ReactNode {
  const { i18n, t } = useTranslation();

  return (
    <div className="flex h-1/2 w-1/3 flex-col items-center gap-4">
      <span className="text-body-bold">{t("page.ticket-settings.app-preview.title.auto-reply")}</span>
      <div className="size-full">
        <Phone>
          <PhoneInner noBackground>
            <div className="relative flex size-full flex-col items-center bg-aop-off-white">
              <span className="mt-10 text-[12px] font-old-semibold text-aop-dark-blue-500">
                {t("page.ticket-settings.app-preview.auto-reply.title")}
              </span>
              <div className="mt-3 flex h-4/5 w-full flex-col overflow-y-auto">
                <div className="flex items-center gap-4 bg-blue-100 p-4">
                  <Icon name={alertIcon} className="text-blue-500" />
                  <span className="text-[10px] text-grey-900">
                    {t("page.ticket-settings.app-preview.auto-reply.visibility")}
                  </span>
                </div>
                <div className="flex flex-col gap-4 bg-white p-4">
                  <Label theme="yellow" size="small">
                    {t("page.ticket-settings.app-preview.auto-reply.status")}
                  </Label>
                  <div className="flex items-center gap-2">
                    <div className="size-7 shrink-0">
                      <UserAvatar hideDeletedIcon />
                    </div>
                    <div className="flex w-full flex-col gap-1 text-[10px] text-grey-500">
                      <span>
                        <Trans
                          i18nKey="page.ticket-settings.app-preview.auto-reply.reporter"
                          components={{ b: <strong className="text-black" /> }}
                        />
                      </span>
                      <span>
                        {t("page.ticket-settings.app-preview.auto-reply.date", {
                          date: formatDate(i18n, "datetime", new Date()),
                        })}
                      </span>
                    </div>
                  </div>
                  <span className="text-[14px] font-old-semibold">
                    {t("page.ticket-settings.app-preview.auto-reply.ticket-title")}
                  </span>
                  <span className="text-[12px]">
                    {t("page.ticket-settings.app-preview.auto-reply.ticket-description")}
                  </span>
                  <div className="flex items-center gap-10">
                    <span className="flex items-center gap-1 text-[12px] font-old-semibold">
                      <Icon name={commentIcon} /> 0
                    </span>
                    <span className="text-[12px] font-old-semibold text-aop-basic-blue-500">
                      {t("page.ticket-settings.app-preview.auto-reply.close")}
                    </span>
                  </div>
                </div>
                <div className="flex items-start gap-2 p-4">
                  <div className="size-7 shrink-0">
                    <UserAvatar hideDeletedIcon />
                  </div>
                  <div className="flex w-full flex-col gap-1 rounded-lg bg-white p-2">
                    <span className="text-[12px] font-old-semibold">
                      {t("page.ticket-settings.app-preview.auto-reply.account")}
                    </span>
                    <span className="whitespace-pre-line text-[12px]">{autoReplyText}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute bottom-0 left-0 w-full bg-aop-off-white p-4">
              <div className="flex gap-2">
                <div className="size-fit rounded-sm bg-white p-1">
                  <Icon name={cameraIcon} size={20} />
                </div>
                <div className="flex w-full items-center justify-between rounded-sm bg-white px-1 py-0.5">
                  <span className="text-[12px] text-grey-500">
                    {t("page.ticket-settings.app-preview.auto-reply.comment")}
                  </span>
                  <span className="size-fit rounded-sm bg-aop-basic-blue-500 p-1 text-white">
                    <Icon name={sendIcon} />
                  </span>
                </div>
              </div>
            </div>
          </PhoneInner>
        </Phone>
      </div>
    </div>
  );
}
