const urlRegex = /\bhttps?:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]/gi;
export function urlify(text: string): (React.ReactNode | string)[] {
  if (!text) {
    return [];
  }

  const elements = [];
  let lastLastIndex = 0;

  text.replace(urlRegex, (match, offset) => {
    if (offset > lastLastIndex) {
      elements.push(text.slice(lastLastIndex, offset));
    }

    elements.push(
      <a
        key={offset}
        href={match}
        target="_blank"
        rel="noopener noreferrer"
        className="text-aop-basic-blue-500 visited:text-purple hover:text-blue-700 focus:text-blue-700"
      >
        {match}
      </a>,
    );

    lastLastIndex = offset + match.length;

    return match;
  });

  if (lastLastIndex < text.length) {
    elements.push(text.slice(lastLastIndex));
  }

  return elements;
}
