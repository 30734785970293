import type { BuildingDto } from "api/types";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { createRequiredStringRule } from "helpers/rules";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface LayoutProps {
  existingBuildings: BuildingDto[];
  isEditMode: boolean;
  defaultValues?: Partial<FormValues>;
  isSubmitting: boolean;
  onSubmit: (data: FormValues) => void;
}

export function Layout({
  existingBuildings,
  isEditMode,
  defaultValues,
  isSubmitting,
  onSubmit,
}: LayoutProps): React.ReactNode {
  const formMethods = useForm<FormValues>({ defaultValues });
  const { t } = useTranslation();

  return (
    <DocumentPaper
      theme="constrained"
      title={isEditMode ? t("page.buildings.edit.title") : t("page.buildings.new.title")}
    >
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <CreateEditForm isEditMode={isEditMode} isSubmitting={isSubmitting} existingBuildings={existingBuildings} />
      </Form>
    </DocumentPaper>
  );
}

function CreateEditForm({
  isEditMode,
  isSubmitting,
  existingBuildings,
}: {
  isEditMode: boolean;
  isSubmitting: boolean;
  existingBuildings: LayoutProps["existingBuildings"];
}) {
  const { t } = useTranslation();

  const existingBuildingNames = new Set(existingBuildings.map((x) => x.name.toString().trim().toLowerCase()));

  return (
    <FormContent>
      <FormField label={t("page.buildings.form.name")} required htmlFor="name">
        <FormInput<FormValues, "name">
          id="name"
          name="name"
          rules={{
            validate: {
              required: createRequiredStringRule(t, "page.buildings.form.name"),
              isDuplicate(value: string) {
                if (existingBuildingNames.has(value.trim().toLowerCase())) {
                  return t("page.buildings.form.name.error.duplicate");
                }
              },
            },
          }}
        />
      </FormField>

      <Button type="submit" isLoading={isSubmitting}>
        {isEditMode ? t("page.buildings.edit.submit") : t("page.buildings.new.submit")}
      </Button>
    </FormContent>
  );
}

interface FormValues {
  name: string;
}
