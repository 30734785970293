import type { DocumentsItemDto } from "api/types";

import { Layout } from "./Layout";
import { Loader } from "./Loader";

interface Props {
  item: DocumentsItemDto | undefined;
  parentFolderId: string;
  onClose: () => void;
}

export function DocumentsMoveItemModal(loaderProps: Props): React.ReactNode {
  return <Loader {...loaderProps}>{(layoutProps) => <Layout {...layoutProps} />}</Loader>;
}
