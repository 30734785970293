import { createContext } from "react";

export interface SidebarContextValue {
  isFaded?: boolean;
  isCollapsed: boolean;
  isBodyScrolled: boolean;
  onToggleCollapse: (state: boolean) => void;
  onToggleBodyScrolled: (state: boolean) => void;
}

export const SidebarContext = createContext<SidebarContextValue | null>(null);
