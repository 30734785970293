import { ErrorPage } from "components/Error/ErrorPage";
import { canAccessProjectOnboadingScreens } from "components/ProjectSidebar/permissions";
import { RequireAuth } from "components/Routing/RequireAuth";
import type { RouteObject } from "react-router-dom";

import { CreateOrEditOnboardingScreenPage } from "./pages/CreateOrEdit";
import { DashboardLinkListPage } from "./pages/List";
import { onboardingScreensRoutes } from "./routes";

export const onboardingScreensRoute = [
  {
    path: onboardingScreensRoutes.list,
    element: (
      <RequireAuth permission={canAccessProjectOnboadingScreens}>
        <DashboardLinkListPage />
      </RequireAuth>
    ),
  },
  {
    path: onboardingScreensRoutes.new,
    element: (
      <RequireAuth permission={(x) => x.isSuperAdmin}>
        <CreateOrEditOnboardingScreenPage />
      </RequireAuth>
    ),
  },
  {
    path: onboardingScreensRoutes.edit,
    element: (
      <RequireAuth permission={(x) => x.isSuperAdmin}>
        <CreateOrEditOnboardingScreenPage />
      </RequireAuth>
    ),
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
