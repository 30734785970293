import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { SurveyDto } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useDebounce } from "hooks/useDebounce";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useDeferredValue, useState } from "react";
import { useTranslation } from "react-i18next";

import type { LayoutProps } from "./Layout";

interface Props {
  children: (data: LayoutProps) => React.ReactNode;
}

const SURVEYS_PER_PAGE = 15;
const DEBOUNCE_WAIT = 250;

export function Loader(props: Props): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState("");
  const deferredQuery = useDeferredValue(useDebounce(query.toLowerCase().trim(), DEBOUNCE_WAIT));

  const queryParams = {
    Limit: SURVEYS_PER_PAGE,
    Offset: page * SURVEYS_PER_PAGE,
    search: deferredQuery,
  };
  const {
    data: surveys,
    error: surveysError,
    isFetching: isFetchingSurveys,
  } = useQuery({
    queryKey: QUERY_KEYS.SURVEYS_QUERY(projectId, queryParams),
    queryFn: () => api.getSurveysV1(queryParams),
    select: commonAPIDataSelector,
    placeholderData: keepPreviousData,
    staleTime: 5000,
  });

  const showFlashToast = useFlashToast();
  const queryClient = useQueryClient();
  const deleteSurvey = useMutation({
    mutationFn: (survey: SurveyDto) => api.deleteSurveysByIdV1(survey.id),
    onSuccess() {
      showFlashToast({ type: "success", title: t("page.surveys.actions.delete.notifications.success") });
    },
    onError() {
      showFlashToast({ type: "error", title: t("page.surveys.actions.delete.notifications.failed") });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SURVEYS(projectId) });
    },
  });

  const onSearch = useCallback((value: string) => {
    setQuery(value);
    setPage(0);
  }, []);

  const error = surveysError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const totalPages = surveys ? Math.ceil(surveys.total / SURVEYS_PER_PAGE) : 0;

  return props.children({
    surveys: surveys?.items ?? [],
    isLoading: isFetchingSurveys,
    page,
    totalPages,
    totalSurveys: surveys?.total,
    searchQuery: query,
    deferredSearchQuery: deferredQuery,
    onPageChange: setPage,
    onSearch,
    onDelete: deleteSurvey.mutateAsync,
  });
}
