import { CreateOrEditLayout } from "modules/community-groups/components/CreateOrEdit/Layout";
import { CreateOrEditLoader } from "modules/community-groups/components/CreateOrEdit/Loader";
import { useTranslation } from "react-i18next";

export function InterestGroupCreateOrEditPage(): React.ReactNode {
  const { t } = useTranslation();

  return (
    <CreateOrEditLoader
      type="interest"
      notifications={{
        create: {
          success: t("model.interest-group.action.create.notification.success"),
          error: t("model.interest-group.action.create.notification.error"),
        },
        edit: {
          success: t("model.interest-group.action.edit.notification.success"),
          error: t("model.interest-group.action.edit.notification.error"),
        },
        realEstate: {
          create: {
            success: t("model.real-estate-group.action.create.notification.success"),
            error: t("model.real-estate-group.action.create.notification.error"),
          },
          edit: {
            success: t("model.real-estate-group.action.edit.notification.success"),
            error: t("model.real-estate-group.action.edit.notification.error"),
          },
        },
      }}
    >
      {(props) => (
        <CreateOrEditLayout
          {...props}
          translations={{
            title: props.isEditMode
              ? t("page.interest-group.create-or-edit.edit-mode.title")
              : t("page.interest-group.create-or-edit.create-mode.title"),
            actionFinish: props.isEditMode
              ? t("component.wizard.action.edit.finish")
              : t("component.wizard.action.create.finish"),
            generalStep: t("component.wizard.step-general"),
            audienceStep: t("component.wizard.step-audience"),
            permissionStep: t("component.wizard.step-permissions"),
            fieldName: t("model.interest-group.name"),
            fieldNamePlaceholder: t("model.interest-group.name.placeholder"),
            fieldDescription: t("model.interest-group.description"),
            fieldDescriptionPlaceholder: t("model.interest-group.description.placeholder"),
            fieldIcon: t("model.interest-group.icon-char"),
            fieldIconDescription: t("model.interest-group.icon-char.description"),
            fieldIconPlaceholder: t("model.interest-group.icon-char.placeholder"),
            fieldImage: t("model.interest-group.image"),
            fieldImageDescription: t("model.interest-group.image.description"),
            fieldImagePlaceholder: t("model.interest-group.image.placeholder"),
            fieldAudienceDescription: t("model.constraint.audience"),
            fieldAddressDescription: t("page.interest-group.create-or-edit.constraint.address.description"),
            fieldBuildingDescription: t("page.interest-group.create-or-edit.constraint.building.description"),
            fieldCompanyDescription: t("page.interest-group.create-or-edit.constraint.company.description"),
            fieldFloorDescription: t("page.interest-group.create-or-edit.constraint.floor.description"),
            appPreviewTitle: t("page.interest-group.create-or-edit.app-preview.title"),
            appPreviewOpen: t("page.interest-group.create-or-edit.app-preview.open"),
            canComment: t("model.permission.community.interest-group.can-comment"),
            canCreateAnnouncement: t("model.permission.community.interest-group.can-create-announcement"),
            canCreatePost: t("model.permission.community.interest-group.can-create-post"),
            canListPosts: t("model.permission.community.interest-group.can-list-posts"),
          }}
        />
      )}
    </CreateOrEditLoader>
  );
}
