import { useMutation } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { LanguageDto, TranslateRequest } from "api/types";
import iconGlobe02 from "assets/icons/globe-02.svg";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormContent, FormSplitContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormInput } from "components/Form/FormInput";
import { FormTextArea } from "components/Form/FormTextArea";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { PreviewLayout } from "components/Layouts/PreviewLayout";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Phone } from "components/Phone/Phone";
import { validateSize } from "helpers/file-size";
import type { FormTranslations } from "helpers/languages";
import { useCurrentTranslation } from "helpers/languages";
import { OnboardingScreenPreview } from "modules/onboarding-screens/components/OnboardingScreenPreview";
import { useForm, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface LayoutProps {
  logoLight?: string;
  isEditMode: boolean;
  defaultValues?: Partial<FormValues>;
  languages: LanguageDto[];
  styling: {
    bgColor?: string;
    textColor?: string;
  };
  isSubmitting: boolean;
  onSubmit: (data: FormValues) => void;
}

export function Layout({
  logoLight,
  isEditMode,
  defaultValues,
  languages,
  styling,
  isSubmitting,
  onSubmit,
}: LayoutProps): React.ReactNode {
  const formMethods = useForm<FormValues>({ defaultValues });
  const { t } = useTranslation();

  return (
    <DocumentPaper
      theme="no-gaps"
      title={isEditMode ? t("page.onboarding-screens.edit.title") : t("page.onboarding-screens.new.title")}
    >
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <PreviewLayout
          previewTitleText={t("page.onboarding-screens.preview.title")}
          showPreviewButtonText={t("page.onboarding-screens.preview.open")}
          preview={<Preview styling={styling} logoLight={logoLight} />}
        >
          <CreateEditForm isEditMode={isEditMode} languages={languages} isSubmitting={isSubmitting} />
        </PreviewLayout>
      </Form>
    </DocumentPaper>
  );
}

const TITLE_MAX_LENGTH = 100;
const SUBTITLE_MAX_LENGTH = 300;

function CreateEditForm({
  isEditMode,
  languages,
  isSubmitting,
}: {
  isEditMode: boolean;
  languages: LanguageDto[];
  isSubmitting: boolean;
}) {
  const { t } = useTranslation();

  const formMethods = useFormContext<FormValues>();

  const api = useApi();
  const { mutateAsync: translate, isPending: isTranslating } = useMutation({
    mutationFn: (payload: TranslateRequest) => api.postTranslationsTranslateV1(payload).then((x) => x.data),
  });

  async function onTranslate(field: "title" | "subtitle", languageId: LanguageDto["id"]) {
    const value = formMethods.getValues(`${field}Translations.${languageId}`);
    if (!value) {
      return;
    }

    const result = await translate({
      languages: languages.filter((l) => l.id !== languageId).map((l) => l.id),
      text: value,
    });
    if (result) {
      for (const translation of result) {
        formMethods.setValue(`${field}Translations.${translation.languages}`, translation.text);
      }
    }
  }

  return (
    <FormContent>
      <FormSplitContent splitAt="2xl">
        {languages.map((lng) => (
          <FormField
            key={lng.id}
            htmlFor={`title-translations-${lng.id}`}
            label={`${t("page.onboarding-screens.form.title")} (${lng.poEditorCode})`}
            actions={
              <Button
                styling="ghostPrimary"
                onClick={() => onTranslate("title", lng.id)}
                icon={<Icon name={iconGlobe02} size={16} />}
              >
                {t("page.onboarding-screens.form.title.translate")}
              </Button>
            }
          >
            <FormInput<FormValues>
              className="max-w-full sm:w-96"
              id={`title-translations-${lng.id}`}
              name={`titleTranslations.${lng.id}`}
              rules={{
                maxLength: {
                  value: TITLE_MAX_LENGTH,
                  message: t("components.form.error.max-length", {
                    length: TITLE_MAX_LENGTH,
                  }),
                },
              }}
              disabled={isTranslating}
            />
          </FormField>
        ))}
      </FormSplitContent>
      <FormSplitContent splitAt="xl">
        {languages.map((lng) => (
          <FormField
            key={lng.id}
            htmlFor={`subtitle-translations-${lng.id}`}
            label={`${t("page.onboarding-screens.form.subtitle")} (${lng.poEditorCode})`}
            actions={
              <Button
                styling="ghostPrimary"
                onClick={() => onTranslate("subtitle", lng.id)}
                icon={<Icon name={iconGlobe02} size={16} />}
              >
                {t("page.onboarding-screens.form.subtitle.translate")}
              </Button>
            }
          >
            <FormTextArea<FormValues>
              className="max-w-full sm:w-96"
              id={`subtitle-translations-${lng.id}`}
              name={`subtitleTranslations.${lng.id}`}
              rules={{
                maxLength: {
                  value: SUBTITLE_MAX_LENGTH,
                  message: t("components.form.error.max-length", {
                    length: SUBTITLE_MAX_LENGTH,
                  }),
                },
              }}
              disabled={isTranslating}
            />
          </FormField>
        ))}
      </FormSplitContent>
      <FormField htmlFor="image" label={t("page.onboarding-screens.form.image")} required>
        <FormImageInput<FormValues, "image">
          name="image"
          id="image"
          data-testid="onboarding-screen-image"
          rules={{
            required: t("components.form.error.required", {
              inputName: t("page.onboarding-screens.form.image"),
            }),
            validate: {
              size(image) {
                if (image) {
                  return validateSize(t, image);
                }
              },
            },
          }}
        />
      </FormField>
      <Button type="submit" isLoading={isSubmitting}>
        {isEditMode ? t("page.onboarding-screens.edit.submit") : t("page.onboarding-screens.new.submit")}
      </Button>
    </FormContent>
  );
}

interface FormValues {
  titleTranslations: FormTranslations;
  subtitleTranslations: FormTranslations;
  image: FormImage[];
}

function Preview({ logoLight, styling }: { logoLight?: string; styling: LayoutProps["styling"] }) {
  const { titleTranslations, subtitleTranslations, image } = useWatch<FormValues>();
  const title = useCurrentTranslation(titleTranslations).substring(0, TITLE_MAX_LENGTH);
  const subtitle = useCurrentTranslation(subtitleTranslations).substring(0, SUBTITLE_MAX_LENGTH);

  return (
    <Phone>
      <OnboardingScreenPreview
        logoLight={logoLight}
        imageUrl={image?.[0]?.url || ""}
        title={title}
        subtitle={subtitle}
        styling={styling}
        hasNotch
      />
    </Phone>
  );
}
