import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { QUERY_KEYS } from "query-keys";
import { useState } from "react";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

const AUTOMATED_SURVEYS_LIMIT = 10;

export function Loader(props: LoaderProps): React.ReactNode {
  const api = useApi();
  const [page, setPage] = useState<number>(0);

  const automatedSurveysQueryParams = {
    Offset: page * AUTOMATED_SURVEYS_LIMIT,
    Limit: AUTOMATED_SURVEYS_LIMIT,
  };

  const {
    data: automatedSurveys,
    isFetching: isLoadingAutomatedSurveys,
    error,
  } = useQuery({
    queryKey: QUERY_KEYS.AUTOMATED_SURVEY_LIST(automatedSurveysQueryParams),
    queryFn: () => api.getAutomatedSurveysV1(automatedSurveysQueryParams),
    select: commonAPIDataSelector,
    placeholderData: keepPreviousData,
    staleTime: 5000,
  });

  if (error) {
    return <ErrorPage error={error} />;
  }

  const totalPages = automatedSurveys ? Math.ceil(automatedSurveys.total / AUTOMATED_SURVEYS_LIMIT) : 0;

  return props.children({
    automatedSurveys: automatedSurveys?.items ?? [],
    isLoadingAutomatedSurveys,
    page,
    onPageChange: setPage,
    totalPages,
  });
}
