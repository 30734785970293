import type { UserDto } from "api/types";
import { Button } from "components/Button/Button";
import { LoadingIcon } from "components/Icons/Icons";
import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import { useBool } from "hooks/useBool";
import { useTranslation } from "react-i18next";

import { TicketCommentField } from "./TicketCommentField/TicketCommentField";
import type { CommentType } from "./TicketCommentField/TicketCommentFieldPure";

type TicketLeaveCommentModalProps = ModalBaseProps & {
  ticketId: string;
  title: React.ReactNode;
  description: React.ReactNode;
  canCommentInternal: boolean;
  canCommentPublic: boolean;
  assignee?: UserDto;
  user: UserDto;
  onSubmit: (value: string, note: boolean) => void | Promise<void>;
};

export function TicketLeaveCommentModal({
  ticketId,
  title,
  description,
  canCommentInternal,
  canCommentPublic,
  isOpened,
  onOpenChange,
  user,
  onSubmit,
}: TicketLeaveCommentModalProps): React.ReactNode {
  const { t } = useTranslation();
  const [submitting, submittingHandlers] = useBool();

  async function submit({ value, note = false }: { value: string; note?: boolean }) {
    if (submitting) {
      return;
    }

    try {
      submittingHandlers.setTrue();

      if (value) {
        await onSubmit?.(value, note);
      } else {
        onOpenChange(false);
      }
    } finally {
      submittingHandlers.setFalse();
    }
  }

  function onClose() {
    if (submitting) {
      return;
    }

    try {
      submittingHandlers.setTrue();

      onOpenChange(false);
    } finally {
      submittingHandlers.setFalse();
    }
  }

  const allowedCommentTypes: CommentType[] = [];
  if (canCommentPublic) allowedCommentTypes.push("public");
  if (canCommentInternal) allowedCommentTypes.push("internal");

  return (
    <Modal.Root
      data-testid="quick-repair-request-leave-comment-modal"
      {...{ title, description, isOpened, onOpenChange }}
    >
      {submitting ? (
        <LoadingIcon className="mx-auto mb-6 mt-8 w-8" />
      ) : (
        <>
          <TicketCommentField
            allowedCommentTypes={allowedCommentTypes}
            user={user}
            onComment={(payload) => submit({ value: payload.content, note: payload.type === "internal" })}
            ticketId={ticketId}
          />

          <Modal.Actions>
            <Button styling="secondary" onClick={onClose}>
              {t("component.tickets.leave-comment-modal.skip")}
            </Button>
          </Modal.Actions>
        </>
      )}
    </Modal.Root>
  );
}
