import type { DocumentsItemDto } from "api/types";
import type { Breadcrumb } from "components/Breadcrumbs/Breadcrumbs";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { LoadingIcon } from "components/Icons/Icons";
import { Modal } from "components/Modal/Modal";
import { DocumentsFileIcon } from "modules/documents/components/DocumentsFileIcon";
import { useTranslation } from "react-i18next";

export interface LayoutProps {
  isLoading: boolean;
  items: { id: string; name: string }[];
  item: DocumentsItemDto | undefined;
  breadcrumbs: Breadcrumb[];
  isSubmitting: boolean;
  canMove: boolean;
  onNavigateToFolder: (folderId: string) => void;
  onSubmit: () => void;
  onClose: () => void;
}

export function Layout({
  isLoading,
  items,
  item,
  breadcrumbs,
  isSubmitting,
  canMove,
  onNavigateToFolder,
  onSubmit,
  onClose,
}: LayoutProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <Modal.Root
      isOpened={item != null}
      onOpenChange={(state) => {
        if (!state) {
          onClose();
        }
      }}
      title={t("component.documents.move-item-modal.title")}
      size="sm"
      data-testid="documents-move-modal"
    >
      {item ? (
        <div className="flex items-center gap-2">
          <div className="shrink-0">
            <DocumentsFileIcon type={item.type} />
          </div>
          <span className="text-caption">{item.name}</span>
        </div>
      ) : null}
      <hr className="mb-4 mt-2 h-px border-0 bg-grey-300" />
      <Breadcrumbs pages={breadcrumbs} />
      <div className="my-4 min-h-52 rounded-3px border border-grey-300 p-4">
        {
          // eslint-disable-next-line no-nested-ternary
          isLoading ? (
            <FullSizeLoader />
          ) : items.length === 0 ? (
            <span className="text-caption text-grey-400">{t("component.documents.move-item-modal.list-empty")}</span>
          ) : (
            <ul className="flex flex-col gap-1.5">
              {items.map(({ id, name }) => (
                <li key={id}>
                  <button className="flex items-center gap-2" onClick={() => onNavigateToFolder(id)}>
                    <div className="shrink-0">
                      <DocumentsFileIcon type="folder" />
                    </div>
                    <span className="text-caption">{name}</span>
                  </button>
                </li>
              ))}
            </ul>
          )
        }
      </div>
      {isSubmitting ? (
        <LoadingIcon className="mx-auto mb-2 mt-6 w-8" />
      ) : (
        <Modal.Actions>
          <Modal.Close>
            <Button styling="secondary">{t("common.action.cancel")}</Button>
          </Modal.Close>
          <Button data-testid="documents-move-modal-submit" onClick={() => onSubmit()} disabled={!canMove}>
            {t("component.documents.move-item-modal.action.move")}
          </Button>
        </Modal.Actions>
      )}
    </Modal.Root>
  );
}
