import { ApiClientContext } from "api/ApiClientProvider";
import type { Api } from "api/types";
import { useContext } from "react";

export function useApiClient(): Api<null> {
  return useContext(ApiClientContext).api;
}

export function useApi(): Api<null>["api"] {
  return useApiClient().api;
}
