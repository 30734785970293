import { useMutation } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { DocumentDto } from "api/types";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { isDocumentUploaded } from "components/DocumentInput/useDocumentFile";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { parseErrorMessage } from "helpers/Network/errors";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export function useUploadDocument(): {
  uploadDocument: UploadDocument;
  uploadFormDocument: UploadFormDocument;
  isUploadingDocument: boolean;
} {
  const { t } = useTranslation();
  const api = useApi();
  const showFlashToast = useFlashToast();

  const { mutateAsync: uploadDocument, isPending: isUploadingDocument } = useMutation({
    mutationFn: ({ file }: DocumentRequest) => {
      const payload = { File: file };

      return api.postFilesDocumentsV1(payload).then(({ data }) => data);
    },

    onError: (error) => {
      showFlashToast({
        type: "error",
        title: t("model.document.action.upload.error"),
        description: parseErrorMessage(error),
      });
    },
  });

  const uploadFormDocument = useCallback(
    async (formDocument: FormDocument | undefined): Promise<DocumentDto | undefined> => {
      if (!formDocument) {
        return undefined;
      }

      if (isDocumentUploaded(formDocument)) {
        return formDocument;
      }

      return await uploadDocument(formDocument);
    },
    [uploadDocument],
  );

  return { uploadDocument, uploadFormDocument, isUploadingDocument };
}

interface DocumentRequest {
  file: File;
}

export type UploadDocument = (image: DocumentRequest) => Promise<DocumentDto>;
export type UploadFormDocument = (document: FormDocument | undefined) => Promise<DocumentDto | undefined>;
