import { useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { useBool } from "hooks/useBool";
import { useQueryParam } from "hooks/useQueryParam";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import type { LayoutProps } from "./Layout";

const locationInputOptions = { app: "navigationLinkApp", email: "navigationLinkEmail" } as const;

let initialConnect = false;

export function Loader({ children }: { children: (props: LayoutProps) => React.ReactNode }): React.ReactNode {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const api = useApi();
  const [isLoading, loadingHandlers] = useBool(true);
  const [isSubmitting, submittingHandlers] = useBool(false);
  const [error, setError] = useState<unknown>();
  const [validationError, setValidationError] = useState<"unknownLocation" | "locationUserLimitReached" | undefined>(
    undefined,
  );

  const [queryParamCode] = useQueryParam("code");
  const [queryParamLocationCodeInput] = useQueryParam("type");

  const onRegister = useCallback(
    async function register(code: string) {
      submittingHandlers.setTrue();

      try {
        const result = await api.postRegistrationConnectV2({
          languageId: i18n.language || undefined,
          locationCode: code,
          locationCodeInput: "manual",
        });

        if (result.data.result === "success") {
          void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CONNECTED_PROJECTS });
          // SessionUserLoader will navigate to onboarding flow
          navigate("/");
        } else if (result.data.result === "locationUserLimitReached") {
          setValidationError(result.data.result);
        } else if (result.data.result === "unknownLocation") {
          setValidationError(result.data.result);
        }
      } catch (error) {
        setError(error);
      } finally {
        submittingHandlers.setFalse();
      }
    },
    [submittingHandlers, api, i18n.language, queryClient, navigate],
  );

  useEffect(() => {
    async function execute() {
      if (initialConnect) {
        return;
      }

      initialConnect = true;

      try {
        const result = await api.postRegistrationConnectV2({
          languageId: i18n.language || undefined,
          locationCode: queryParamCode || undefined,
          locationCodeInput: queryParamCode
            ? (queryParamLocationCodeInput
                ? locationInputOptions[queryParamLocationCodeInput as keyof typeof locationInputOptions]
                : undefined) || "unknown"
            : "noInput",
        });

        if (result.data.result === "success") {
          void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CONNECTED_PROJECTS });
          // SessionUserLoader will navigate to onboarding flow
          navigate("/");
        } else if (result.data.result === "locationUserLimitReached") {
          setValidationError(result.data.result);
        }
        // If we have an unknown location we shouldn't show an error
      } catch (error) {
        setError(error);
      } finally {
        loadingHandlers.setFalse();
      }
    }

    void execute();
  }, [api, queryParamCode, i18n.language, loadingHandlers, navigate, queryParamLocationCodeInput, queryClient]);

  return children({
    onRegister: onRegister,
    isSubmitting: isSubmitting,
    isLoading: isLoading,
    error: error,
    validationError,
  });
}
