import { useQuery } from "@tanstack/react-query";
import { ProjectHeaderName } from "api/ApiClientProvider";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useQueryParam } from "hooks/useQueryParam";
import { QUERY_KEYS } from "query-keys";
import { useState } from "react";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

const PAGE_SIZE = 30;

export function Loader(props: LoaderProps): React.ReactNode {
  const api = useApi();

  const [projectId, setProjectId] = useQueryParam<string>("tab");
  const [previousProjectId, setPreviousProjectId] = useState(projectId);
  const [page, setPage] = useQueryParam("page");

  const pageNumber = (Number(page) || 1) - 1;
  const query = {
    Offset: pageNumber * PAGE_SIZE,
    Limit: PAGE_SIZE,
  };

  const { data: projects = [], isPending: isLoadingProjects } = useConnectedProjects();
  const {
    data: triggeredAlerts,
    isPending: isLoadingAlerts,
    error: alertsError,
  } = useQuery({
    queryKey: QUERY_KEYS.ALERTS_TRIGGERED_LIST(query, projectId),
    queryFn: () =>
      api.getTriggeredAlertsV1(query, {
        headers: {
          // Empty values are filtered out by the customFetch function
          [ProjectHeaderName]: projectId || "",
        },
      }),
    select: commonAPIDataSelector,
  });

  const isLoading = isLoadingAlerts || isLoadingProjects;
  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  const error = alertsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const totalPages = triggeredAlerts ? Math.ceil(triggeredAlerts.total / PAGE_SIZE) : 0;

  return props.children({
    triggeredAlerts: triggeredAlerts,
    pageIndex: pageNumber,
    projects,
    onPageChange: (page) => setPage((page + 1).toString()),
    totalPages,
    projectId: projectId || undefined,
    previousProjectId: previousProjectId || undefined,
    onProjectChange: (x) => {
      setPreviousProjectId(projectId);
      setProjectId(x || null);
    },
  });
}
