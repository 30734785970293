import type { VictoryLabelProps } from "victory";

export function LegendDataComponent(
  props: VictoryLabelProps & { datum?: { symbol?: { type: string; fill: string } } },
): React.ReactNode {
  const { x = 0, y = 0, datum } = props;
  const type = datum?.symbol?.type?.toLowerCase();

  return type === "minus" ? (
    <rect x={x - 5} y={y} width={10} height={2} rx="1" fill={datum?.symbol?.fill} />
  ) : (
    <rect x={x - 4} y={y - 4} width={8} height={8} rx="2" fill={datum?.symbol?.fill} />
  );
}

export interface LegendDataIconProps {
  fill: string;
  type?: "minus" | "square";
}

export function LegendDataIcon({ fill, type = "square" }: LegendDataIconProps): React.ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={12} height={12} viewBox="-5 -5 10 10">
      <LegendDataComponent
        datum={{
          symbol: {
            fill: fill,
            type: type,
          },
        }}
      />
    </svg>
  );
}
