import { FormErrorWrapper } from "components/Form/FormErrorWrapper";
import type { StarInputProps } from "components/StarInput/StarInput";
import { StarInput } from "components/StarInput/StarInput";
import type { FieldPath, FieldPathValue, FieldValues, RegisterOptions } from "react-hook-form";
import { useController } from "react-hook-form";

export interface FormStarInput<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> extends Omit<StarInputProps, "value" | "onChange"> {
  name: TName;
  defaultValue?: FieldPathValue<TFormValues, TName>;
  rules?: RegisterOptions;
  "data-testid"?: string;
}

export function FormStarInput<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({
  name,
  defaultValue,
  rules,
  "data-testid": dataTestid,
  ...props
}: FormStarInput<TFormValues, TName>): React.ReactNode {
  const { field } = useController<TFormValues, TName>({ name, rules, defaultValue });

  return (
    <FormErrorWrapper<TFormValues> name={name} data-testid={dataTestid}>
      <StarInput value={field.value} onChange={field.onChange} {...props} />
    </FormErrorWrapper>
  );
}
