import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";

import type { LayoutProps } from "./Layout";

export function Loader(props: { children: (data: LayoutProps) => React.ReactNode }): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();
  const {
    data: companies,
    isLoading,
    error,
  } = useQuery({
    queryKey: QUERY_KEYS.COMPANY_LIST(projectId),
    queryFn: () => api.getCompaniesV1({ Limit: 10000, Offset: 0 }),
    select: commonAPIDataSelector,
  });

  const showFlashToast = useFlashToast();
  const queryClient = useQueryClient();
  const deleteCompany = useMutation({
    mutationFn: (companyId: string) => api.deleteCompaniesByIdV1(companyId),
    onSuccess() {
      showFlashToast({ type: "success", title: t("page.companies.actions.delete.notifications.success") });
    },
    onError() {
      showFlashToast({ type: "error", title: t("page.companies.actions.delete.notifications.failed") });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMPANY_LIST(projectId) });
    },
  });
  const refreshLocationCode = useMutation({
    mutationFn: (companyId: string) => api.putCompaniesRegenerateLocationCodeV1(companyId),
    onSuccess() {
      showFlashToast({
        type: "success",
        title: t("page.companies.actions.refresh-location-code.notifications.success"),
      });
    },
    onError() {
      showFlashToast({
        type: "error",
        title: t("page.companies.actions.refresh-location-code.notifications.failed"),
      });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMPANY_LIST(projectId) });
    },
  });

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  return props.children({
    companies: companies?.items ?? [],
    onRefreshLocationCode: refreshLocationCode.mutateAsync,
    onDelete: deleteCompany.mutateAsync,
  });
}
