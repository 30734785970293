// Flag icons come from https://github.com/HatScripts/circle-flags

import type { LanguageDto } from "api/types";
import germanFlag from "assets/icons/flags/de.svg";
import englishFlag from "assets/icons/flags/en.svg";
import dutchFlag from "assets/icons/flags/nl.svg";
import { Icon } from "components/Icon/Icon";

interface FlagIconProps {
  languageId: LanguageDto["id"];
  width?: number;
}

export function getFlagIcon({ languageId, width = 20 }: FlagIconProps): React.ReactNode {
  switch (languageId) {
    case "nl":
    case "dutch":
      return <Icon name={dutchFlag} size={width} />;
    case "en":
    case "english":
      return <Icon name={englishFlag} size={width} />;
    case "de":
    case "german":
      return <Icon name={germanFlag} size={width} />;
    default:
      return null;
  }
}
