import type { ImageDto } from "api/types";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { twJoin } from "tailwind-merge";

interface Props {
  images: (ImageDto | undefined)[];
  limit: number;
  totalElements?: number;
  isGreyedOut?: boolean;
  onClick?: () => void;
  "data-testid"?: string;
}

export function UserAvatarList(props: Props): React.ReactNode {
  const elementsToShow = props.images.slice(0, props.limit);
  const amountOfRestElements = props.totalElements
    ? props.totalElements - elementsToShow.length
    : props.images.length - elementsToShow.length;

  const Component = props.onClick ? "button" : "div";

  return (
    <Component className="h-6" onClick={props.onClick} type={props.onClick ? "button" : undefined}>
      <div
        className={twJoin(
          "flex h-full items-center -space-x-1 overflow-hidden",
          props.onClick ? "cursor-pointer" : undefined,
        )}
        data-testid={props["data-testid"]}
      >
        {elementsToShow.map((image, index) => (
          <div key={image?.id || "image-" + index} data-testid="avatar-item">
            <UserAvatar className="size-6 ring-2 ring-white" img={image} />
          </div>
        ))}
        {amountOfRestElements > 0 && (
          <div
            className="z-10 flex w-fit items-center justify-center rounded-full bg-white text-[10px] text-black ring-2 ring-white"
            data-testid="avatar-rest-items"
          >
            +<strong>{amountOfRestElements}</strong>
          </div>
        )}
      </div>
    </Component>
  );
}
