import { Api } from "api/types";
import { addToHeaders } from "helpers/headers";
import { useConfig } from "providers/ConfigProvider";
import type { MutableRefObject } from "react";
import React, { useMemo, useRef } from "react";

export const ProjectHeaderName = "X-ProjectId";
export const ClientInfoHeaderName = "Client-Info";

export const ApiClientContext = React.createContext<{ api: Api<null> }>(null!);

interface Props {
  projectId?: string;
}

export function ApiClientProvider({ projectId, children }: React.PropsWithChildren<Props>): React.ReactNode {
  const baseUrl = useConfig("newCoreApiRootUri");
  const dataRef = useRef({ projectId });
  dataRef.current.projectId = projectId;

  const value = useMemo(() => ({ api: buildApiClient(baseUrl, dataRef) }), [baseUrl]);

  return <ApiClientContext.Provider value={value}>{children}</ApiClientContext.Provider>;
}

const buildApiClient = function (baseUrl: string, dataRef: MutableRefObject<{ projectId?: string }>): Api<null> {
  if (!baseUrl) {
    throw new Error(`No API base URL configured.
    If you're in development you have to fix your .env file (check .env.example).
    If you're in acceptance/production check whether ConfigCat has "newCoreApiRootUri" set to valid URL`);
  } else if (typeof baseUrl !== "string") {
    throw new Error(
      `BaseURL of configuration variable "newCoreApiRootUri" has wrong type configured: ${typeof baseUrl}: ${baseUrl}`,
    );
  } else {
    return new Api<null>({
      baseUrl: baseUrl,
      customFetch: (input, init) => {
        init = populateProjectIdHeader(init, dataRef.current.projectId);
        init = populateClientInfoHeader(init);

        return fetch(input, init);
      },
    });
  }
};

/**
 * Populates (or removes) the projectId header in the request init object.
 */
function populateProjectIdHeader(init: RequestInit | undefined, projectId: string | undefined) {
  return addToHeaders(init, ProjectHeaderName, projectId);
}
/**
 * Populates (or removes) the Client info header in the request init object.
 */
function populateClientInfoHeader(init: RequestInit | undefined) {
  return addToHeaders(init, ClientInfoHeaderName, "platform=web");
}
