import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { AddressDto } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";

import type { LayoutProps } from "./Layout";

export function Loader(props: { children: (data: LayoutProps) => React.ReactNode }): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();
  const {
    data: addresses,
    isLoading,
    error,
  } = useQuery({
    queryKey: QUERY_KEYS.ADDRESS_LIST(projectId),
    queryFn: () => api.getAddressesV1(),
    select: commonAPIDataSelector,
  });

  const {
    data: addressTypes,
    isPending: isLoadingAddressTypes,
    error: addressTypesError,
  } = useQuery({
    queryKey: QUERY_KEYS.ADDRESS_TYPES(projectId),
    queryFn: () => api.getAddressesTypesV1(),
    select: commonAPIDataSelector,
  });

  const showFlashToast = useFlashToast();
  const queryClient = useQueryClient();
  const deleteAddress = useMutation({
    mutationFn: (address: AddressDto) => api.deleteAddressesByIdV1(address.id),
    onSuccess() {
      showFlashToast({ type: "success", title: t("page.addresses.actions.delete.notifications.success") });
    },
    onError() {
      showFlashToast({ type: "error", title: t("page.addresses.actions.delete.notifications.failed") });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ADDRESS_LIST(projectId) });
    },
  });
  const refreshLocationCode = useMutation({
    mutationFn: (addressId: string) => api.putAddressesRegenerateLocationCodeV1(addressId),
    onSuccess() {
      showFlashToast({
        type: "success",
        title: t("page.addresses.actions.refresh-location-code.notifications.success"),
      });
    },
    onError() {
      showFlashToast({
        type: "error",
        title: t("page.addresses.actions.refresh-location-code.notifications.failed"),
      });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ADDRESS_LIST(projectId) });
    },
  });

  if (error || addressTypesError) {
    return <ErrorPage error={error} />;
  }

  if (isLoading || isLoadingAddressTypes) {
    return <FullSizeLoader withPadding />;
  }

  return props.children({
    addresses: addresses?.items ?? [],
    addressTypes: addressTypes ?? [],
    onRefreshLocationCode: refreshLocationCode.mutateAsync,
    onDelete: deleteAddress.mutateAsync,
  });
}
