import type { ConnectedProjectDto } from "api/types";
import { getLocalStorageValue, updateLocalStorage } from "hooks/useLocalStorage";
import { minBy } from "lodash-es";

const RECENT_PROJECT_KEY = "most-recent-project";

export function setRecentProject(projectId: string): void {
  updateLocalStorage(RECENT_PROJECT_KEY, projectId);
}

export function getRecentProject(projects: ConnectedProjectDto[]): ConnectedProjectDto {
  const recentProjectId = getLocalStorageValue(RECENT_PROJECT_KEY, undefined);
  if (recentProjectId) {
    const recentProject = projects.find((p) => p.id === recentProjectId);
    if (recentProject) {
      return recentProject;
    }
  }

  return minBy(projects, (x) => x.name)!;
}
