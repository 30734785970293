import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import iconBell01 from "assets/icons/bell-01.svg";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { NavigationSidebarUserMenu } from "components/NavigationSidebar/NavigationSidebarUserMenu";
import { Sidebar } from "components/Sidebar/Sidebar";
import { SidebarItem } from "components/Sidebar/SidebarItem";
import type { SidebarBodyRootProps } from "components/Sidebar/types";
import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUserOptional } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useKey } from "hooks/useKey";
import { usePermission } from "hooks/usePermission";
import { useScreenIsBiggerThan } from "hooks/useScreenIsBiggerThan";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

import { useProjectSidebarManager } from "./items";
import { canAccessAdminNotifications } from "./permissions";
import { ProjectSidebarHeader } from "./ProjectSidebarHeader";

interface ProjectSidebarProps extends Omit<SidebarBodyRootProps, "children"> {}

export function ProjectSidebar({
  isOpened,
  isCollapsed,
  onToggleOpen,
  onToggleCollapse,
}: ProjectSidebarProps): React.ReactNode {
  const [isProjectSwitcherVisible, projectSwitcherVisibilityHandlers] = useBool();

  const projectId = useProjectId();
  const { t } = useTranslation();
  const slug = useSlug();
  const api = useApi();
  const [sessionUser] = useSessionUserOptional({ keepPreviousData: true });
  const isDesktop = useScreenIsBiggerThan("md");
  const { navigationItems } = useProjectSidebarManager();
  const hasPermission = usePermission();

  const { data: adminNotificationStats } = useQuery({
    queryKey: QUERY_KEYS.ADMIN_NOTIFICATION_STATUS(projectId),
    queryFn: () => api.getAdminNotificationsStatusV1(),
    select: commonAPIDataSelector,
    staleTime: 30 * 1000,
    refetchInterval: 30 * 1000,
    enabled: hasPermission(canAccessAdminNotifications),
  });

  useKey("Escape", projectSwitcherVisibilityHandlers.setFalse, isProjectSwitcherVisible);

  return (
    <Sidebar.Root {...{ isOpened, isCollapsed, onToggleCollapse, onToggleOpen }}>
      <AnimatePresence>
        {!sessionUser && (
          <motion.div className="absolute inset-0 z-20 size-full bg-white">
            <FullSizeLoader size="small" />
          </motion.div>
        )}
      </AnimatePresence>
      <ProjectSidebarHeader {...{ onToggleOpen }} />
      <Sidebar.Body items={navigationItems}>
        {sessionUser?.isAdmin && isDesktop && (
          <div className="mt-8 flex w-full flex-col gap-2 py-4">
            {!isCollapsed && (
              <h3 className="text-overline-bold uppercase tracking-wide text-grey-700">
                {t("navigation.notifications.section-title")}
              </h3>
            )}
            {hasPermission(canAccessAdminNotifications) && (
              <ul>
                <SidebarItem
                  label={t("navigation.notifications.title")}
                  newEventCount={adminNotificationStats?.amount}
                  isNewEventAvailable={adminNotificationStats?.hasUnread}
                  icon={<Icon name={iconBell01} size={16} />}
                  to={routes.adminNotifications.list({ slug })}
                />
              </ul>
            )}
          </div>
        )}
      </Sidebar.Body>
      <Sidebar.Footer>
        <NavigationSidebarUserMenu />
      </Sidebar.Footer>
    </Sidebar.Root>
  );
}
