import iconArrowDownRight from "assets/icons/arrow-down-right.svg";
import iconArrowRight from "assets/icons/arrow-right.svg";
import iconArrowUpRight from "assets/icons/arrow-up-right.svg";
import { Icon } from "components/Icon/Icon";
import { twJoin } from "tailwind-merge";

type Props = {
  increasingIsBad?: boolean;
  isWide?: boolean;
  showPlus?: boolean;
} & (
  | { type: "number" | "percentage"; oldValue: number; newValue: number; decimalPlaces?: number }
  | { percentage: number; showZero?: boolean }
);

export function GrowLabel({ increasingIsBad = false, isWide, showPlus, ...props }: Props): React.ReactNode {
  const isGrowth = "percentage" in props ? props.percentage > 0 : props.newValue > props.oldValue;
  const isSame = "percentage" in props ? props.percentage === 0 : props.newValue === props.oldValue;

  // eslint-disable-next-line no-nested-ternary
  const icon = isSame ? (
    <Icon name={iconArrowRight} size={16} />
  ) : isGrowth ? (
    <Icon name={iconArrowUpRight} size={16} />
  ) : (
    <Icon name={iconArrowDownRight} size={16} />
  );

  const value = resolveValue(isSame, props);

  return (
    <span
      className={twJoin(
        "inline-flex items-center justify-between gap-1 whitespace-nowrap rounded py-1 pl-1 pr-1.5 text-caption-bold",
        // eslint-disable-next-line no-nested-ternary
        isSame
          ? "bg-blue-100 text-blue-600"
          : increasingIsBad === isGrowth
            ? "bg-red-100 text-red-600"
            : "bg-green-100 text-green-600",
        isWide ? "min-w-20" : undefined,
      )}
    >
      {icon}
      {value}
    </span>
  );
}

function resolveValue(isSame: boolean, props: Props): string | number {
  if (isSame || ("oldValue" in props && props.oldValue === 0)) {
    if ("showZero" in props && props.showZero) {
      return "0%";
    }

    return "---";
  }

  if ("type" in props && props.type === "number") {
    if (props.decimalPlaces) {
      return (props.newValue - props.oldValue).toFixed(props.decimalPlaces);
    }

    return Math.round((props.newValue - props.oldValue) * 10) / 10;
  }

  const percentage = getPercentage(props);

  return `${percentage}%`;
}

function getPercentage(props: Props) {
  const percentage =
    "percentage" in props ? props.percentage : ((props.oldValue - props.newValue) / props.oldValue) * 100 * -1;

  const percentageDifference = Math.round(percentage * 10) / 10;

  if (percentageDifference > 999) {
    return 999;
  }

  if (percentageDifference < -999) {
    return -999;
  }

  if ("decimalPlaces" in props) {
    return percentageDifference.toFixed(props.decimalPlaces);
  }

  return percentageDifference;
}
