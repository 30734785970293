import type { RefObject } from "react";
import { useEffect, useRef } from "react";

export function useResizeObserver<T extends Element>(
  ref: RefObject<T> | Document,
  callback: ResizeObserverCallback,
): void {
  const observer = useRef(new ResizeObserver(callback));

  useEffect(() => {
    const currentObserver = observer.current;
    const element = ref instanceof Document ? ref.body : ref.current;

    if (element) {
      currentObserver.observe(element);

      return () => currentObserver.unobserve(element);
    }
  }, [ref, observer]);
}
