import { Collapsible as ArkCollapsible, Menu as ArkMenu, Portal as ArkPortal } from "@ark-ui/react";
import { autoUpdate, FloatingPortal, useFloating } from "@floating-ui/react";
import { ChevronIcon } from "components/Icons/Icons";
import { AnimatePresence, motion } from "framer-motion";
import { twResolve } from "helpers/tw-resolve";
import { useBool } from "hooks/useBool";
import { useScreenIsBiggerThan } from "hooks/useScreenIsBiggerThan";
import { useSidebarManager } from "hooks/useSidebar";
import type React from "react";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import { sidebarItemLabelAnimation } from "./animations";
import { SidebarItem, SidebarItemFloatingTooltip, sidebarItemVariants } from "./SidebarItem";
import type { SidebarBaseItemType, SidebarGroupedItemType } from "./types";

interface SidebarGroupedItemProps extends SidebarGroupedItemType {}

export function SidebarGroupedItem({ label, icon, items, ...props }: SidebarGroupedItemProps): React.ReactNode {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [isTooltipVisible, tooltipVisibilityHandlers] = useBool(false);

  const [isActive, activityHandlers] = useBool(false);
  const [isExpanded, expandHandlers] = useBool(isActive);
  const [isMenuVisible, menuVisibilityHandlers] = useBool(false);

  const { refs, floatingStyles } = useFloating({ whileElementsMounted: autoUpdate, placement: "right" });
  const location = useLocation();
  const { isCollapsed } = useSidebarManager();
  const isDesktop = useScreenIsBiggerThan("md");

  useEffect(() => {
    const isAnyItemActive = items.some((x) => location.pathname.includes(x.to));

    activityHandlers.set(isAnyItemActive);
    expandHandlers.set(isAnyItemActive);
    // Use `JSON.stringify` to compare array values rather than reference
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityHandlers, expandHandlers, JSON.stringify(items), location.pathname]);

  // Reset menu when sidebar is collapsed
  useEffect(() => {
    if (!isCollapsed) return;

    expandHandlers.set(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapsed]);

  useEffect(() => {
    if (!isMenuVisible) return;

    menuVisibilityHandlers.set(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const showLabel = ({ withDelay }: { withDelay: boolean }) => {
    if (withDelay) {
      const timer = setTimeout(() => {
        tooltipVisibilityHandlers.set(true);
      }, 300);

      setTimer(timer);
    } else {
      tooltipVisibilityHandlers.set(true);
    }
  };

  const hideLabel = () => {
    if (timer) {
      clearTimeout(timer);
    }

    tooltipVisibilityHandlers.set(false);
  };

  if (items.length === 0) return null;

  if (items.length === 1) return <SidebarItem {...items[0]} {...{ icon }} key={undefined} />;

  if (isCollapsed) {
    return (
      <li className="w-full animate-in fade-in">
        <ArkMenu.Root
          positioning={{
            placement: "right",
          }}
          open={isMenuVisible}
          onOpenChange={menuVisibilityHandlers.toggle}
        >
          <ArkMenu.Trigger asChild ref={refs.setReference}>
            <button
              className={sidebarItemVariants({
                isActive: isActive || isMenuVisible,
              })}
              data-testid={props["data-testid"]}
              onMouseEnter={isCollapsed ? () => showLabel({ withDelay: true }) : undefined}
              onMouseLeave={isCollapsed ? hideLabel : undefined}
              // Required for screenreader when sidebar is collapsed
              aria-label={label}
            >
              <div className="flex items-center gap-2 truncate">
                {icon}
                <AnimatePresence>
                  {!isCollapsed && (
                    <motion.span
                      className="block truncate"
                      variants={isDesktop ? sidebarItemLabelAnimation : undefined}
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                    >
                      {label}
                    </motion.span>
                  )}
                </AnimatePresence>
              </div>
              {!isCollapsed && (
                <ChevronIcon orientation={isExpanded ? "down" : "right"} className="mr-0.5 w-3.5 shrink-0" />
              )}
            </button>
          </ArkMenu.Trigger>
          <ArkPortal>
            <ArkMenu.Positioner className="!z-50">
              <ArkMenu.Content asChild>
                <div
                  className={twJoin(
                    "rounded-lg border border-grey-100 bg-white p-2 shadow-lg focus-visible:outline-none",
                    "data-[state=open]:animate-in data-[state=open]:fade-in",
                    "data-[state=closed]:animate-out data-[state=closed]:fade-out",
                  )}
                >
                  <ArkMenu.ItemGroup className="flex flex-col gap-2">
                    <ArkMenu.ItemGroupLabel className="p-2 font-old-semibold">{label}</ArkMenu.ItemGroupLabel>
                    <ul className="flex w-full flex-col gap-2">
                      {items.map((item) => (
                        <SidebarGroupedItemFloatingMenuItem
                          label={item.label}
                          to={item.to}
                          exact={item.exact}
                          data-testid={item["data-testid"]}
                          key={item.key}
                        />
                      ))}
                    </ul>
                  </ArkMenu.ItemGroup>
                </div>
              </ArkMenu.Content>
            </ArkMenu.Positioner>
          </ArkPortal>
        </ArkMenu.Root>
        {/* Floating tooltip */}
        <AnimatePresence>
          {!isMenuVisible && isTooltipVisible && (
            <FloatingPortal>
              <SidebarItemFloatingTooltip
                label={label}
                ref={refs.setFloating}
                style={floatingStyles}
                isVisible={isTooltipVisible}
              />
            </FloatingPortal>
          )}
        </AnimatePresence>
      </li>
    );
  }

  return (
    <li className="w-full">
      <ArkCollapsible.Root open={isExpanded} onOpenChange={expandHandlers.toggle}>
        <ArkCollapsible.Trigger asChild>
          <button
            className={twJoin(
              "flex h-10 w-full items-center justify-between gap-1 rounded-lg p-3 transition-colors duration-200 ease-linear",
              "focus:outline-none focus-visible:bg-aop-basic-blue-100 md:hover:bg-aop-basic-blue-100",
              isActive || isExpanded
                ? "bg-aop-basic-blue-100 font-old-semibold text-aop-basic-blue-500"
                : "font-old-medium",
            )}
            data-testid={props["data-testid"]}
          >
            <div className="flex items-center gap-2">
              {icon}
              <motion.span
                className="block whitespace-nowrap"
                variants={sidebarItemLabelAnimation}
                initial={isDesktop ? "hidden" : "visible"}
                animate="visible"
                exit="hidden"
              >
                {label}
              </motion.span>
            </div>
            <ChevronIcon orientation={isExpanded ? "down" : "right"} className="mr-0.5 w-3.5 shrink-0" />
          </button>
        </ArkCollapsible.Trigger>
        <ArkCollapsible.Content className="overflow-hidden data-[state=closed]:animate-collapse-y data-[state=open]:animate-expand-y">
          <ul className="flex w-full flex-col gap-2 py-2 pl-8">
            {items.map((item) => (
              <SidebarItem {...item} key={item.key} />
            ))}
          </ul>
        </ArkCollapsible.Content>
      </ArkCollapsible.Root>
    </li>
  );
}

interface SidebarGroupedItemMenuItemProps
  extends Pick<SidebarBaseItemType, "label" | "to" | "exact" | "data-testid" | "onClick"> {}

export function SidebarGroupedItemFloatingMenuItem({
  label,
  exact,
  to,
  onClick,
}: SidebarGroupedItemMenuItemProps): React.ReactNode {
  return (
    <ArkMenu.Item value={label} asChild>
      {/* Hide focus-visible styling, rely on `data-highlighted` */}
      <NavLink
        className={twResolve(
          sidebarItemVariants(),
          "focus-visible:bg-transparent data-[highlighted]:bg-aop-basic-blue-100",
        )}
        tabIndex={-1}
        end={exact}
        {...{ to, onClick }}
      >
        <span className="block whitespace-nowrap">{label}</span>
      </NavLink>
    </ArkMenu.Item>
  );
}
