import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { LanguageDto, TranslationRequest, TranslationsDto } from "api/types";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { QUERY_KEYS } from "query-keys";

export type FormTranslations = Record<LanguageDto["id"], string>;

export function createFormTranslations<
  TTranslation extends TTranslationKey extends never ? TranslationsDto : { languageId: LanguageDto["id"] },
  TTranslationKey extends keyof TTranslation,
>(data: {
  languages: LanguageDto[];
  translations?: TTranslation[] | undefined;
  translationKey?: TTranslationKey;
}): FormTranslations {
  const textSelector = data.translationKey;

  return Object.fromEntries(
    data.languages.map((lng) => {
      const translation = data.translations?.find((translation) => lng.id === translation.languageId);
      const value = translation && (textSelector ? translation[textSelector] : (translation as TranslationsDto).value);

      return [lng.id, value || ""];
    }),
  ) as FormTranslations;
}

export function toTranslationsRequest(formTranslations: FormTranslations): TranslationRequest[] {
  return Object.entries(formTranslations).map<TranslationRequest>(([languageId, value]) => ({
    languageId: languageId as LanguageDto["id"],
    value,
  }));
}

/*
 * hooks
 * */
export function useCurrentTranslation(
  translations: Partial<FormTranslations> | undefined,
): FormTranslations[keyof FormTranslations] {
  const {
    language: { id: languageId },
  } = useSessionUser();

  return translations?.[languageId] ?? "";
}

export function useLanguages(): UseQueryResult<LanguageDto[], unknown> {
  const projectId = useProjectId();
  const api = useApi();

  return useQuery({
    queryKey: QUERY_KEYS.LANGUAGES(projectId),
    queryFn: () => api.getLanguagesV2(),
    select: commonAPIDataSelector,
    notifyOnChangeProps: ["data", "isFetching", "error"],
    staleTime: Infinity,
  });
}

/**
 * Super admin only
 */
export function useAllLanguages(): UseQueryResult<LanguageDto[], unknown> {
  const api = useApi();

  return useQuery({
    queryKey: QUERY_KEYS.LANGUAGES_ALL,
    queryFn: () => api.getLanguagesAllV1(),
    select: commonAPIDataSelector,
    notifyOnChangeProps: ["data", "isFetching", "error"],
    staleTime: Infinity,
  });
}
