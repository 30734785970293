import "./RichTextView.css";

export interface RichTextViewProps {
  title?: string;
  children: string;
}

export const RichTextView = ({ children, ...props }: RichTextViewProps): React.ReactNode => {
  return (
    <div
      {...props}
      className="aop-cleanslate w-full [word-break:break-word]"
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
};
