import iconStar01 from "assets/icons/star-01.svg";
import { Icon } from "components/Icon/Icon";

export function Star(): React.ReactNode {
  return (
    <span className="block rounded-full border border-yellow-100 bg-white p-px">
      <Icon name={iconStar01} size={12} className="fill-yellow-600 text-yellow-600" />
    </span>
  );
}
