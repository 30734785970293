import { Button } from "components/Button/Button";
import { LoadingIcon } from "components/Icons/Icons";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { Select } from "components/Select/Select";
import { useBool } from "hooks/useBool";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { TicketStatus } from "./TicketStatus";

type Status = {
  id: string;
  description: string;
  labelColor: string;
  defaultStatusId: string;
};

type TicketStatusChangeModalProps = ModalBaseProps & {
  title: string;
  description: string;
  targetStatus: Status;
  possibleStatuses: Status[];
  onSubmit?: (status: Status) => Promise<void> | void;
};

export function TicketStatusChangeModal({
  isOpened,
  onOpenChange,
  title,
  description,
  onSubmit,
  targetStatus,
  possibleStatuses,
}: TicketStatusChangeModalProps): React.ReactNode {
  const { t } = useTranslation();
  const [submitting, submittingHandlers] = useBool();
  const [status, setStatus] = useState<Status>();

  useEffect(() => {
    setStatus(targetStatus);
  }, [targetStatus]);

  async function close(submit: boolean) {
    if (submitting) {
      return;
    }

    submittingHandlers.setTrue();

    try {
      if (submit && status) {
        await onSubmit?.(status);
      } else {
        onOpenChange(false);
      }
    } finally {
      submittingHandlers.setFalse();
    }
  }

  return (
    <Modal.Root
      data-testid="quick-repair-request-status-modal"
      size="sm"
      isNested
      {...{ isOpened, title, description, onOpenChange }}
    >
      <div className="mx-auto w-full min-w-64">
        <Select
          placeholder={t("model.repair-request.status-change-label")}
          selected={status}
          disabled={submitting}
          items={possibleStatuses}
          onChange={setStatus}
          keySelector={(x) => x.id}
          renderOption={(status) => <TicketStatus {...status} />}
        />
      </div>
      {submitting ? (
        <LoadingIcon className="mx-auto mb-2 mt-4 w-8" />
      ) : (
        <div className="mt-6 flex w-full items-center justify-end gap-2">
          <Button
            styling="secondary"
            onClick={() => close(false)}
            data-testid="quick-repair-request-status-modal-reject"
          >
            {t("common.action.no-thanks")}
          </Button>
          <Button onClick={() => close(true)} data-testid="quick-repair-request-status-modal-apply">
            {t("common.action.change")}
          </Button>
        </div>
      )}
    </Modal.Root>
  );
}
