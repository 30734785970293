import { useMutation } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { useImageResolver } from "api/hooks/useImageResolver";
import type { ImageDto } from "api/types";
import iconCheck from "assets/icons/check.svg";
import iconPlus from "assets/icons/plus.svg";
import award from "assets/images/award.svg";
import bgPlaceholder from "assets/images/image-placeholder.png";
import { StaticButton } from "components/Button/StaticButton";
import { ToggleButton } from "components/Button/ToggleButton";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Icon } from "components/Icon/Icon";
import { useSlug } from "hooks/useSlug";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

interface PromotedGroupProps {
  id: string;
  name: string;
  description: string;
  image?: ImageDto;
  isReadonly?: boolean;
  isResidentGroup?: boolean;
  isOwner?: boolean;
  isMember?: boolean;
  followCallback?: (id: string, action: "join" | "leave") => void;
}

export function PromotedGroupCard({
  id,
  name,
  description,
  image,
  isReadonly,
  isResidentGroup,
  isOwner,
  isMember,
  followCallback,
}: PromotedGroupProps): React.ReactNode {
  const api = useApi();
  const slug = useSlug();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const showFlashToast = useFlashToast();
  const resolveImage = useImageResolver();

  const { mutate: follow } = useMutation({
    mutationFn: () => api.postGroupsFollowV1(id),
    onSuccess() {
      followCallback?.(id, "join");
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.interest-group.action.follow.notification-error"),
      });
    },
  });

  const { mutate: unfollow } = useMutation({
    mutationFn: () => api.postGroupsUnfollowV1(id),
    onSuccess() {
      followCallback?.(id, "leave");
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.interest-group.action.unfollow.notification-error"),
      });
    },
  });

  const onClickToggleFollow = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (isMember) unfollow();
    else follow();
  };

  return (
    <div
      className={twJoin("flex w-72 flex-col rounded-lg bg-white shadow-sm", !isReadonly && "cursor-pointer")}
      onClick={isReadonly ? undefined : () => navigate(routes.interestGroups.details({ slug, id }))}
    >
      <div
        className="flex h-40 w-full flex-col items-center justify-center gap-2 rounded-t-lg bg-grey-400 bg-cover bg-center p-2"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8)), url(${image ? resolveImage(image, "intrinsic") : bgPlaceholder}) `,
        }}
      >
        <span className="text-center text-headline3 leading-old-headline4 text-white">{name}</span>
        <div className="flex items-center gap-1">
          {isOwner && isResidentGroup ? (
            <StaticButton>
              <div className="flex items-center gap-1">
                <img className="size-4" src={award} alt="" />
                <span>{t("model.interest-group.owner")}</span>
              </div>
            </StaticButton>
          ) : (
            <ToggleButton
              config={{
                checked: {
                  icon: <Icon name={iconCheck} size={16} />,
                  label: t("model.interest-group.action.unfollow"),
                },
                unchecked: {
                  icon: <Icon name={iconPlus} size={16} />,
                  label: t("model.interest-group.action.follow"),
                },
              }}
              className={twJoin(isReadonly && "pointer-events-none")}
              onClick={onClickToggleFollow}
              disabled={isReadonly}
              isChecked={isMember}
            />
          )}
        </div>
      </div>
      <div className="rounded-b-lg p-4">
        <span className="whitespace-pre-wrap text-caption">{description}</span>
      </div>
    </div>
  );
}
