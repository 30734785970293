import type { QuickReplyRepairRequestDto, QuickReplyRepairRequestStatusDto, UserDto } from "api/types";
import iconCalendar from "assets/icons/calendar.svg";
import iconUser01 from "assets/icons/user-01.svg";
import iconUsers01 from "assets/icons/users-01.svg";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { Select } from "components/Select/Select";
import { TicketStatus } from "components/Ticket/TicketStatus";
import { parseISO } from "date-fns";
import { daysBetween } from "helpers/date";
import { Trans, useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface TicketInfoHeaderProps {
  ticket: QuickReplyRepairRequestDto;
  project: string;
  isChangingStatus: boolean;
  canChangeStatus: boolean;
  canChangeAssignee: boolean;
  onChangeStatus: (status: QuickReplyRepairRequestStatusDto) => void;
  onChangeAssignee: (assignee: UserDto) => void;
  changedAssignee?: UserDto;
  showProject: boolean;
}

export function TicketInfoHeader({
  ticket,
  project,
  canChangeStatus,
  canChangeAssignee,
  isChangingStatus,
  onChangeStatus,
  onChangeAssignee,
  changedAssignee,
  showProject,
}: TicketInfoHeaderProps): React.ReactNode {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex flex-col gap-6 rounded-lg border-b border-grey-100 bg-white p-4">
      <div className="flex flex-wrap gap-x-6 gap-y-2">
        {ticket.isCollective ? (
          <DetailInfo title={t("page.tickets.details.info.type")}>
            <div className="flex items-center gap-1 rounded bg-grey-100 p-2">
              <Icon name={iconUsers01} className="text-blue-500" />
              <span className="text-caption-bold text-blue-600">{t("page.tickets.details.info.type.collective")}</span>
            </div>
          </DetailInfo>
        ) : (
          <DetailInfo title={t("page.tickets.details.info.type")}>
            <div className="flex items-center gap-1 rounded bg-grey-100 p-2">
              <Icon name={iconUser01} />
              <span className="text-caption-bold text-grey-700">{t("page.tickets.details.info.type.private")}</span>
            </div>
          </DetailInfo>
        )}
        <DetailInfo title={t("page.tickets.details.info.id")}>
          <div className="gap-1 rounded bg-blue-100 px-2 py-1.5">
            <span className="text-caption-bold">{ticket.requestId}</span>
          </div>
        </DetailInfo>
        <DetailInfo title={t("page.tickets.details.info.open-since")}>
          <div className="gap-1 rounded bg-grey-100 px-2 py-1.5">
            <span className="text-caption-bold">
              {t("page.tickets.details.info.open-since.content", {
                count: daysBetween(parseISO(ticket.postedAt), new Date()),
              })}
            </span>
          </div>
        </DetailInfo>
        <DetailInfo title={t("page.tickets.details.info.status")} smallGap>
          {isChangingStatus ? (
            <LoadingIcon className="my-1.5 ml-3 w-5" />
          ) : (
            <Select
              disabled={ticket.possibleStatuses?.length === 0 || !canChangeStatus}
              selected={ticket.status}
              items={ticket.possibleStatuses || []}
              keySelector={(x) => x.id}
              renderOption={(x) => <TicketStatus description={x.description} labelColor={x.labelColor} />}
              onChange={onChangeStatus}
            />
          )}
        </DetailInfo>
        <DetailInfo title={t("page.tickets.details.info.assignee")} smallGap>
          <Select
            placeholder={t("page.tickets.details.info.assignee.unassigned")}
            disabled={!canChangeAssignee}
            selected={changedAssignee || ticket.assignee}
            items={ticket.possibleAssignees || []}
            keySelector={(x) => x.id}
            renderOption={(x) => x.fullName}
            onChange={onChangeAssignee}
          />
        </DetailInfo>
        {showProject && project ? (
          <DetailInfo title={t("page.tickets.details.info.project")}>
            <div className="gap-1 rounded bg-grey-100 px-2 py-1.5">
              <span className="text-caption-bold">{project}</span>
            </div>
          </DetailInfo>
        ) : null}
        <DetailInfo title={t("page.tickets.details.info.category")}>
          <div className="gap-1 rounded bg-grey-100 px-2 py-1.5">
            <span className="text-caption-bold">{ticket.category.name}</span>
          </div>
        </DetailInfo>
      </div>
      <div className="flex items-center gap-2">
        <Icon name={iconCalendar} />
        <span className="text-caption">
          <Trans
            i18nKey="page.tickets.details.created-at"
            values={{
              date: formatDate(i18n, "datetime", ticket.postedAt),
              name: ticket.author.fullName,
            }}
            components={{
              bold: <b />,
            }}
          />
        </span>
      </div>
    </div>
  );
}

function DetailInfo({ title, children, smallGap }: { title: string; children: React.ReactNode; smallGap?: boolean }) {
  return (
    <div className={twJoin("flex flex-col", smallGap ? "gap-1" : "gap-1.5")}>
      <span className="text-overline-bold text-grey-700">{title}</span>
      <div className="flex">{children}</div>
    </div>
  );
}
