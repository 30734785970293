import { twResolve } from "helpers/tw-resolve";

export interface ProjectLogoProps {
  src: string | undefined;
  className?: string;
}

export function ProjectLogo({ src, className }: ProjectLogoProps): React.ReactNode {
  return (
    <div className={twResolve("relative size-full rounded-md border border-grey-100 bg-white object-cover", className)}>
      {src ? (
        <img className="relative size-full select-none rounded-md object-contain p-1" src={src} alt="" loading="lazy" />
      ) : undefined}
    </div>
  );
}
