import { keepPreviousData, useQuery } from "@tanstack/react-query";
import type { CellContext } from "@tanstack/react-table";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useApi } from "api/hooks/useApi";
import type { UserLookupDto } from "api/types";
import { Anchor } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import { ErrorPage } from "components/Error/ErrorPage";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormInput } from "components/Form/FormInput";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Label } from "components/Label/Label";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Table } from "components/Table/Table";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useQueryParams } from "hooks/useQueryParam";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";
import type { ApiQueryParams } from "types/api-types";

type UsersLookupInputs = ApiQueryParams<"getUsersLookupV1">;

type UsersLookupInputsAsParams = {
  [K in keyof UsersLookupInputs]: string;
};

export function UsersLookupPage(): React.ReactNode {
  const TABLE_ROWS_LIMIT_STEP = 10;
  const { t } = useTranslation();
  const api = useApi();
  const [searchQuery, setSearchQuery] = useQueryParams<UsersLookupInputsAsParams>();
  const isEnableDataFetching =
    searchQuery.City ||
    searchQuery.Street ||
    searchQuery.House ||
    searchQuery.FirstName ||
    searchQuery.LastName ||
    searchQuery.Email;
  const { data, isLoading, error } = useQuery({
    queryKey: ["users-lookup", searchQuery],
    queryFn: () =>
      api.getUsersLookupV1({
        City: searchQuery.City,
        Street: searchQuery.Street,
        House: searchQuery.House,
        FirstName: searchQuery.FirstName,
        LastName: searchQuery.LastName,
        Email: searchQuery.Email,
        IncludeDeleted: searchQuery.IncludeDeleted === "true",
        IncludeAdmins: searchQuery.IncludeAdmins === "true",
        IncludeNotRegistered: searchQuery.IncludeNotRegistered === "true",
        Limit: Number(searchQuery.Limit) || TABLE_ROWS_LIMIT_STEP,
      }),
    select: commonAPIDataSelector,
    enabled: !!isEnableDataFetching,
    placeholderData: keepPreviousData,
    staleTime: 5000,
  });
  const { data: connectedProjects = [] } = useConnectedProjects();

  const formMethods = useForm<NonNullable<UsersLookupInputs>>({
    defaultValues: {
      City: searchQuery.City || "",
      Street: searchQuery.Street || "",
      House: searchQuery.House || "",
      FirstName: searchQuery.FirstName || "",
      LastName: searchQuery.LastName || "",
      Email: searchQuery.Email || "",
      IncludeDeleted: searchQuery.IncludeDeleted ? searchQuery.IncludeDeleted === "true" : true,
      IncludeAdmins: searchQuery.IncludeAdmins ? searchQuery.IncludeAdmins === "true" : true,
      IncludeNotRegistered: searchQuery.IncludeNotRegistered ? searchQuery.IncludeNotRegistered === "true" : true,
    },
  });

  const columns = useMemo(() => {
    const helper = createColumnHelper<UserLookupDto>();

    function DeletedRow({ cell, children }: { cell: CellContext<UserLookupDto, any>; children: React.ReactNode }) {
      return (
        <span className={twJoin("relative", cell.row.original.deletedAt ? "text-grey-500 line-through" : "text-black")}>
          {children}
        </span>
      );
    }

    return [
      helper.accessor("id", {
        header: "id",
        cell: (cell) => <DeletedRow cell={cell}>{cell.getValue()}</DeletedRow>,
        maxSize: 100,
      }),
      helper.accessor("fullName", {
        header: "Name",
        cell: (cell) => {
          const original = cell.row.original;
          const connectedProject = connectedProjects.find((x) => x.id === original.projectId);

          return (
            <DeletedRow cell={cell}>
              {connectedProject ? (
                <Anchor to={routes.users.details({ slug: connectedProject.slug, id: original.id })}>
                  {cell.getValue()}
                </Anchor>
              ) : (
                cell.getValue()
              )}
            </DeletedRow>
          );
        },
      }),
      helper.accessor("email", {
        header: "Email",
        cell: (cell) => <DeletedRow cell={cell}>{cell.getValue()}</DeletedRow>,
      }),
      helper.accessor("projectName", {
        header: "Project Name",
        cell: (cell) => <DeletedRow cell={cell}>{cell.getValue()}</DeletedRow>,
      }),
      helper.accessor("createdAt", {
        header: "Created at",
        cell: (cell) => {
          const value = cell.getValue();
          if (!value) {
            return <span />;
          }

          return (
            <DeletedRow cell={cell}>
              <FormattedDate date={value} format="dateRelative" />
            </DeletedRow>
          );
        },
      }),
      helper.accessor("deletedAt", {
        header: t("page.user-overview.table.status"),
        cell: (cell) => {
          if (cell.getValue()) {
            return <Label theme="red">{t("page.user-overview.table.status.deleted")}</Label>;
          } else if (cell.row.original.registeredAt) {
            return <Label theme="green">{t("page.user-overview.table.status.registered")}</Label>;
          } else {
            return <Label theme="yellow">{t("page.user-overview.table.status.invited")}</Label>;
          }
        },
        enableSorting: false,
        maxSize: 150,
      }),
      helper.accessor("locatedAt", {
        header: "Address",
        cell: (cell) => <DeletedRow cell={cell}>{cell.getValue()}</DeletedRow>,
      }),
      helper.accessor("city", {
        header: "City",
        cell: (cell) => <DeletedRow cell={cell}>{cell.getValue()}</DeletedRow>,
      }),
      helper.accessor("zipCode", {
        header: "Zip",
        cell: (cell) => <DeletedRow cell={cell}>{cell.getValue()}</DeletedRow>,
        maxSize: 120,
      }),
    ];
  }, [connectedProjects, t]);

  const tableInstance = useReactTable<UserLookupDto>({
    columns,
    data: data?.items || [],
    getCoreRowModel: getCoreRowModel(),
  });

  function submit(values: UsersLookupInputs) {
    setSearchQuery({
      City: values.City,
      Street: values.Street,
      House: values.House,
      FirstName: values.FirstName,
      LastName: values.LastName,
      Email: values.Email,
      IncludeDeleted: values.IncludeDeleted ? "true" : undefined,
      IncludeAdmins: values.IncludeAdmins ? "true" : undefined,
      IncludeNotRegistered: values.IncludeNotRegistered ? "true" : undefined,
    });
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <DocumentPaper
      theme="wide"
      title={t("page.user-lookup.title")}
      header={
        <Form
          className="mb-3 grid grid-cols-1 items-center gap-4 sm:grid-cols-3"
          formMethods={formMethods}
          onSubmit={submit}
          noPrompt
        >
          <label className="flex flex-col gap-1">
            <span>City</span>
            <FormInput<UsersLookupInputs> name="City" />
          </label>
          <label className="flex flex-col gap-1">
            <span>Street</span>
            <FormInput<UsersLookupInputs> name="Street" />
          </label>
          <label className="flex flex-col gap-1">
            <span>House</span>
            <FormInput<UsersLookupInputs> name="House" />
          </label>
          <label className="flex flex-col gap-1">
            <span>First Name</span>
            <FormInput<UsersLookupInputs> name="FirstName" />
          </label>
          <label className="flex flex-col gap-1">
            <span>Last Name</span>
            <FormInput<UsersLookupInputs> name="LastName" />
          </label>
          <label className="flex flex-col gap-1">
            <span>Email</span>
            <FormInput<UsersLookupInputs> name="Email" />
          </label>
          <div className="flex flex-wrap gap-4 sm:col-span-3">
            <FormCheckbox
              label="Include deleted"
              name="IncludeDeleted"
              onChange={() => submit(formMethods.getValues())}
            />
            <FormCheckbox
              label="Include admins"
              name="IncludeAdmins"
              onChange={() => submit(formMethods.getValues())}
            />
            <FormCheckbox
              label="Include not registered"
              name="IncludeNotRegistered"
              onChange={() => submit(formMethods.getValues())}
            />
          </div>
          <Button type="submit">{isLoading ? "Loading..." : "Search"}</Button>
          {data?.total != null && (
            <span>
              Shows {data?.items?.length || 0} of {data?.total} users
            </span>
          )}
        </Form>
      }
    >
      {isEnableDataFetching ? (
        <div className="block max-w-full overflow-x-auto overflow-y-hidden">
          {
            // eslint-disable-next-line no-nested-ternary
            data?.items?.length ? (
              <Table<UserLookupDto> className="min-w-[1000px]" table={tableInstance} />
            ) : isLoading ? (
              <FullSizeLoader size="small" />
            ) : (
              "No users found"
            )
          }
        </div>
      ) : (
        "Please fill in the form and click 'Search' to see the results."
      )}
      {data?.hasMore && (
        <Button
          styling="secondary"
          className="mt-3 w-full justify-center"
          onClick={() =>
            setSearchQuery({
              ...searchQuery,
              Limit: String((Number(searchQuery.Limit) || TABLE_ROWS_LIMIT_STEP) + TABLE_ROWS_LIMIT_STEP),
            })
          }
        >
          Show more
        </Button>
      )}
    </DocumentPaper>
  );
}
