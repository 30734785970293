import { SidebarContext, type SidebarContextValue } from "components/Sidebar/SidebarContext";
import { useContext } from "react";

export const useSidebarManager = (): SidebarContextValue => {
  const context = useContext(SidebarContext);

  if (!context) {
    throw new Error("Components should be rendered inside the SidebarContext.Provider component");
  }

  return context;
};
