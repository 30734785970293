import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { CreateFastMenuActionItemRequest, UpdateFastMenuActionItemRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";

import type { LayoutProps } from "./Layout";

export function Loader(props: { children: (data: LayoutProps) => React.ReactNode }): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const {
    data: buttonOptions,
    isLoading: isLoadingPlusButtonOptions,
    error: isPlusButtonOptionsError,
  } = useQuery({
    queryKey: QUERY_KEYS.PLUS_BUTTON_OPTIONS_LIST(projectId),
    queryFn: () => api.getFastActionItemsV1(),
    select: commonAPIDataSelector,
  });

  const {
    data: projectDetails,
    isLoading: isLoadingProjectDetails,
    error: projectDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.PROJECT_DETAILS(projectId),
    queryFn: () => api.getProjectDetailsV1(),
    select: commonAPIDataSelector,
  });

  const createButtonOption = useMutation({
    mutationFn: (payload: CreateFastMenuActionItemRequest) => api.postFastActionItemsV1(payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PLUS_BUTTON_OPTIONS_LIST(projectId) });
      showFlashToast({ type: "success", title: t("page.plus-button-links.create.success") });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.plus-button-links.create.error") });
    },
  });

  const editButtonOption = useMutation({
    mutationFn: ({ id, payload }: { id: string; payload: UpdateFastMenuActionItemRequest }) =>
      api.putFastActionItemsV1(id, payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PLUS_BUTTON_OPTIONS_LIST(projectId) });
      showFlashToast({ type: "success", title: t("page.plus-button-links.edit.success") });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.plus-button-links.edit.error") });
    },
  });

  const deleteButtonOption = useMutation({
    mutationFn: (id: string) => api.deleteFastActionItemsByIdV1(id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PLUS_BUTTON_OPTIONS_LIST(projectId) });
      showFlashToast({ type: "success", title: t("page.plus-button-links.delete.success") });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.plus-button-links.delete.error") });
    },
  });

  const error = isPlusButtonOptionsError || projectDetailsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const loading = isLoadingPlusButtonOptions || isLoadingProjectDetails;
  if (loading) {
    return <FullSizeLoader withPadding />;
  }

  return props.children({
    buttonOptions: buttonOptions ?? [],
    isZiezodanProject: projectDetails?.isZiezodan ?? false,
    createOption: createButtonOption.mutateAsync,
    isCreatingOption: createButtonOption.isPending,
    editOption: editButtonOption.mutateAsync,
    isEditingOption: editButtonOption.isPending,
    deleteOption: deleteButtonOption.mutateAsync,
    isDeletingOption: deleteButtonOption.isPending,
  });
}
