import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useDeleteRole({ onSuccess }: { onSuccess?: () => void } = {}) {
  const projectId = useProjectId();
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async (id: string) => {
      await api.deletePermissionsRolesByIdV1(id);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ROLE_LIST(projectId) });
      showFlashToast({
        type: "success",
        title: t("model.role.action.delete.notification.success"),
      });
      onSuccess?.();
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.role.action.delete.notification.error"),
      });
    },
  });
}
