import type {
  AddressDto,
  CompanyDto,
  EmailExistsDto,
  LanguageDto,
  UserCreateRequest,
  UserUpdateRequest,
} from "api/types";
import { Button } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import type { DatePickerValue } from "components/DateAndTimePicker/DateAndTimePicker";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormDateAndTimePicker } from "components/Form/FormDateAndTimePicker";
import { FormField } from "components/Form/FormField";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormInput } from "components/Form/FormInput";
import { FormSearchableSelect } from "components/Form/FormSearchableSelect";
import { FormSelect } from "components/Form/FormSelect";
import type { FormImage } from "components/ImageInput/useImageInput";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { addMinutes } from "date-fns";
import { formatAddress } from "helpers/address";
import { validateSize } from "helpers/file-size";
import { createRequiredStringRule } from "helpers/rules";
import { isValidEmail, isValidPhoneNumber } from "helpers/validation";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { useMemo, useRef } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "routes";

export interface LayoutProps {
  addresses: AddressDto[];
  companies: CompanyDto[];
  roles: { id: string; name: string; isAdmin: boolean }[];
  languages: LanguageDto[];
  checkEmailExists: (email: string) => Promise<EmailExistsDto>;
  createUser: (payload: UserCreateRequest, image: FormImage[]) => Promise<string>;
  updateUser: (payload: UserUpdateRequest, image: FormImage[]) => Promise<void>;
  defaultFormValues?: FormValues;
  canEmailBeEdited: boolean;
  canRoleBeEdited: boolean;
  canLocationBeEdited: boolean;
  isEditMode: boolean;
  isSelfEdit: boolean;
  isSubmitting: boolean;
}

export interface FormValues {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  email: string;
  address?: AddressDto;
  company?: CompanyDto;
  occupation?: string;
  language?: LanguageDto;
  role?: { id: string; name: string; isAdmin: boolean };
  avatar: FormImage[];
  hideChat: boolean;
  deactivateAfter: boolean;
  deactivateAfterDate: DatePickerValue;
  isOnlyAllowedOwnTickets: boolean;
  onboardingComplete: boolean;
  mfaMethod: "automatic" | "none" | "email";
  addMore: boolean;
}

const COMPANY_BASED = "companyBased";

const MAX_CHARACTERS = 255;

export function Layout({
  addresses,
  companies,
  roles,
  languages,
  checkEmailExists,
  createUser,
  updateUser,
  defaultFormValues,
  canEmailBeEdited,
  canRoleBeEdited,
  isSubmitting,
  canLocationBeEdited,
  isEditMode,
  isSelfEdit,
}: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sessionUser = useSessionUser();
  const hasPermission = usePermission();
  const [isCancelModalOpen, cancelModalHandler] = useBool(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const form = useForm<FormValues>({
    defaultValues: defaultFormValues,
  });
  const deactivateAfter = useWatch({ control: form.control, name: "deactivateAfter" });
  const { id: userIdParam } = useParams<{ id: string }>();

  const minStart = useMemo(() => addMinutes(new Date(), 15), []);

  async function handleSave(formValues: FormValues) {
    const payload: UserCreateRequest | UserUpdateRequest = {
      email: formValues.email,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      language: formValues.language!.id,
      mobileNumber: formValues.mobileNumber ?? "",
      deactivateAfter:
        formValues.deactivateAfter && formValues.deactivateAfterDate
          ? formValues.deactivateAfterDate.toISOString()
          : undefined,
      hideChat: formValues.hideChat,
      isOnlyAllowedOwnTickets: formValues.isOnlyAllowedOwnTickets,
      companyId:
        // If user is editing themselves and not allowed to get companies, we will use the address of the user
        // eslint-disable-next-line no-nested-ternary
        isSelfEdit && !companies.length
          ? sessionUser.company?.id
          : sessionUser.project.type === "companyBased"
            ? formValues.company?.id
            : undefined,
      addressId:
        // If user is editing themselves and not allowed to get addresses, we will use the address of the user
        // eslint-disable-next-line no-nested-ternary
        isSelfEdit && !addresses.length
          ? sessionUser.address?.id
          : sessionUser.project.type === "addressBased"
            ? formValues.address?.id
            : undefined,
      occupation: formValues.occupation,
      onboardingCompleted: formValues.onboardingComplete,
      mfaMethod: formValues.mfaMethod === "automatic" ? undefined : formValues.mfaMethod,
    };

    if (hasPermission((x) => x.userManagement.canAssignRoles)) {
      if (formValues.role?.id === "superAdmin") {
        payload.isSuperAdmin = true;
      } else {
        payload.roleId = formValues.role?.id;
      }
    }

    const isAddMoreChecked = formValues.addMore;
    const avatar = formValues.avatar;

    if (userIdParam) {
      await updateUser(payload, avatar);
      form.reset();

      // Go to previous page
      navigate(-1);
    } else {
      const userId = await createUser(payload, avatar);
      form.reset();
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }

      if (isAddMoreChecked) {
        form.setValue(
          "address",
          addresses.find((address) => address.id === payload.addressId),
        );
        form.setValue(
          "company",
          companies.find((company) => company.id === payload.companyId),
        );
        form.setValue(
          "language",
          languages.find((language) => language.id === payload.language),
        );
        form.setValue("addMore", true);
      } else if (sessionUser.isSuperAdmin && formValues.role?.isAdmin) {
        navigate(routes.adminNotifications.otherUserSettings({ slug, userId: userId }), { replace: true });
      } else {
        navigate(routes.users.list({ slug }), { replace: true });
      }
    }
  }

  const checkEmail = async (event: Event) => {
    if (!canEmailBeEdited) {
      return;
    }

    const target = event.target as HTMLInputElement;
    let value = target.value;
    if (!value) {
      return;
    }

    value = value.trim();

    const emailExists = await checkEmailExists(value);

    if (emailExists.exists && emailExists.userId !== userIdParam) {
      form.setError("email", { message: t("page.create-user.form.email.error.already-exists") });
    } else {
      form.clearErrors("email");
    }
  };

  function goBack() {
    form.reset();
    cancelModalHandler.setFalse();
    navigate(-1);
  }

  return (
    <DocumentPaper
      theme="constrained"
      title={isEditMode ? t("page.edit-user.title") : t("page.create-user.title")}
      subTitle={isEditMode ? t("page.create-user.edit.subtitle") : t("page.create-user.new.subtitle")}
    >
      <Form className="flex flex-col" formMethods={form} onSubmit={handleSave}>
        <FormContent>
          <FormField label={t("page.create-user.form.first-name.label")} required>
            <FormInput<FormValues>
              name="firstName"
              placeholder={t("page.create-user.form.first-name.placeholder")}
              rules={{
                validate: {
                  required: createRequiredStringRule(t, "page.create-user.form.first-name.label"),
                },
                maxLength: {
                  message: t("components.form.error.max-length", { length: MAX_CHARACTERS }),
                  value: MAX_CHARACTERS,
                },
              }}
            />
          </FormField>
          <FormField label={t("page.create-user.form.last-name.label")} required>
            <FormInput<FormValues>
              name="lastName"
              placeholder={t("page.create-user.form.last-name.placeholder")}
              rules={{
                validate: {
                  required: createRequiredStringRule(t, "page.create-user.form.last-name.label"),
                },
                maxLength: {
                  message: t("components.form.error.max-length", { length: MAX_CHARACTERS }),
                  value: MAX_CHARACTERS,
                },
              }}
            />
          </FormField>
          <FormField label={t("page.create-user.form.mobile.label")}>
            <FormInput<FormValues, "mobileNumber">
              name="mobileNumber"
              placeholder={t("page.create-user.form.mobile.placeholder")}
              type="tel"
              inputMode="tel"
              rules={{
                validate: {
                  isValid(value) {
                    value = value?.trim();

                    if (!value) {
                      return;
                    }

                    return isValidPhoneNumber(value) ? undefined : t("components.form.error.invalid-phone-number");
                  },
                },
              }}
            />
          </FormField>
          <FormField label={t("page.create-user.form.email.label")} required>
            <FormInput<FormValues, "email">
              name="email"
              placeholder={t("page.create-user.form.email.placeholder")}
              type="email"
              inputMode="email"
              disabled={!canEmailBeEdited}
              rules={{
                onBlur: checkEmail,
                validate: {
                  required: createRequiredStringRule(t, "page.create-user.form.email.label"),
                  isValid(value) {
                    value = value?.trim();

                    if (!value) {
                      return;
                    }

                    return isValidEmail(value) ? undefined : t("components.form.error.invalid-email-address");
                  },
                },
              }}
            />
          </FormField>
          {
            // eslint-disable-next-line no-nested-ternary
            isSelfEdit && !addresses.length && !companies.length ? null : sessionUser.project.type === COMPANY_BASED ? (
              <FormField label={t("page.create-user.form.company.label")} required>
                <FormSearchableSelect<FormValues, CompanyDto>
                  name="company"
                  placeholder={t("page.create-user.form.company.placeholder")}
                  items={companies}
                  keySelector={(x) => x.id}
                  renderOption={(x) => x.name}
                  searchableFieldSelector={(x) => [x.name]}
                  disabled={isEditMode && !canLocationBeEdited}
                  rules={{
                    required: t("components.form.error.required", {
                      inputName: t("page.create-user.form.company.label"),
                    }),
                  }}
                />
              </FormField>
            ) : (
              <FormField label={t("page.create-user.form.address.label")} required>
                <FormSearchableSelect<FormValues, AddressDto>
                  name="address"
                  placeholder={t("page.create-user.form.address.placeholder")}
                  items={addresses}
                  keySelector={(x) => x.id}
                  renderOption={(x) => formatAddress(x)}
                  searchableFieldSelector={(x) => [formatAddress(x)]}
                  disabled={isEditMode && !canLocationBeEdited}
                  rules={{
                    required: t("components.form.error.required", {
                      inputName: t("page.create-user.form.address.label"),
                    }),
                  }}
                />
              </FormField>
            )
          }
          {sessionUser.project.type === COMPANY_BASED ? (
            <FormField label={t("page.create-user.form.occupation.label")}>
              <FormInput<FormValues, "occupation">
                name="occupation"
                placeholder={t("page.create-user.form.occupation.placeholder")}
                type="occupation"
                rules={{
                  maxLength: {
                    message: t("components.form.error.max-length", { length: MAX_CHARACTERS }),
                    value: MAX_CHARACTERS,
                  },
                }}
              />
            </FormField>
          ) : null}
          <FormField label={t("page.create-user.form.language.label")} required>
            <FormSelect<FormValues, LanguageDto>
              name="language"
              placeholder={t("page.create-user.form.language.placeholder")}
              items={languages}
              keySelector={(x) => x.id}
              renderOption={(x) => x.description}
              rules={{
                required: t("components.form.error.required", {
                  inputName: t("page.create-user.form.language.label"),
                }),
              }}
            />
          </FormField>
          {hasPermission((x) => x.userManagement.canAssignRoles) && (
            <FormField label={t("page.create-user.form.role.label")} required>
              <FormSelect<FormValues, (typeof roles)[number]>
                name="role"
                placeholder={t("page.create-user.form.role.placeholder")}
                items={roles}
                keySelector={(x) => x.id}
                renderOption={(x) => x.name}
                disabled={!canRoleBeEdited}
                rules={{
                  required: t("components.form.error.required", {
                    inputName: t("page.create-user.form.role.label"),
                  }),
                }}
                onChange={(value) => {
                  if (!isEditMode && sessionUser.isSuperAdmin && value) {
                    form.setValue("onboardingComplete", value.isAdmin);
                  }
                }}
              />
            </FormField>
          )}
          {sessionUser.isAdmin && (
            <FormField label={t("page.create-user.form.avatar.label")}>
              <FormImageInput<FormValues, "avatar">
                ref={fileInputRef}
                name="avatar"
                rules={{
                  validate: {
                    size(image) {
                      if (image) {
                        return validateSize(t, image);
                      }
                    },
                  },
                }}
              />
            </FormField>
          )}
          <div className="flex flex-col gap-4">
            {sessionUser.isSuperAdmin && (
              <>
                <FormField label={t("page.create-user.form.mfa-method")}>
                  <FormSelect<FormValues, "automatic" | "none" | "email">
                    name="mfaMethod"
                    items={["automatic", "none"]}
                    keySelector={(x) => x}
                    renderOption={(x) => {
                      switch (x) {
                        case "automatic":
                          return t("page.create-user.form.mfa-method.automatic");
                        case "none":
                          return t("page.create-user.form.mfa-method.none");
                        case "email":
                          return t("page.create-user.form.mfa-method.email");
                        default:
                          return "-";
                      }
                    }}
                  />
                </FormField>
                <FormCheckbox<FormValues>
                  name="onboardingComplete"
                  label={t("page.create-user.form.onboarding-complete.label")}
                />
                <FormCheckbox<FormValues>
                  name="isOnlyAllowedOwnTickets"
                  label={t("page.create-user.form.is-only-allowed-own-tickets.label")}
                />
                <FormCheckbox<FormValues> name="hideChat" label={t("page.create-user.form.chat.label")} />
              </>
            )}
            {hasPermission((x) => x.userManagement.canDeleteUser) && !isSelfEdit && (
              <>
                <FormCheckbox<FormValues>
                  name="deactivateAfter"
                  label={t("page.create-user.form.deactivation-date.toggle.label")}
                />
                {deactivateAfter && (
                  <FormField label={t("page.create-user.form.deactivation-date.picker.label")} required>
                    <FormDateAndTimePicker<FormValues, "deactivateAfterDate">
                      name="deactivateAfterDate"
                      placeholder={t("page.create-user.form.deactivation-date.picker.placeholder")}
                      min={minStart}
                      rules={{
                        validate: {
                          laterThanMin: (startDate) => {
                            if (!startDate) {
                              return undefined;
                            }

                            return startDate < minStart
                              ? t("page.create-user.form.deactivation-date.picker.error.must-be-in-future")
                              : undefined;
                          },
                        },
                        required: {
                          message: t("components.form.error.required", {
                            inputName: t("page.create-user.form.deactivation-date.picker.label"),
                          }),
                          value: true,
                        },
                      }}
                    />
                  </FormField>
                )}
              </>
            )}
          </div>
        </FormContent>
        <div className="flex gap-6 self-end">
          {!isEditMode && <FormCheckbox<FormValues> name="addMore" label={t("page.create-user.form.add-more")} />}
          <Button
            data-testid="user-add-cancel-btn"
            styling="secondary"
            onClick={() => {
              if (Object.keys(form.formState.dirtyFields).length > 0) {
                cancelModalHandler.setTrue();
              } else {
                goBack();
              }
            }}
          >
            {t("common.action.cancel")}
          </Button>
          <Button type="submit" isLoading={isSubmitting} data-testid="user-add-submit-btn">
            {isEditMode ? t("page.create-user.form.submit.edit") : t("page.create-user.form.submit.new")}
          </Button>
        </div>
      </Form>
      <ConfirmModal
        id="cancel-modal"
        title={t("page.create-user.cancel-modal.title")}
        description={t("page.create-user.cancel-modal.description")}
        isLoading={false}
        onReject={cancelModalHandler.setFalse}
        onOpenChange={cancelModalHandler.set}
        rejectBtnProps={{
          "data-testid": "cancel-modal-cancel",
        }}
        onResolve={goBack}
        resolveBtnProps={{
          text: t("common.action.ok"),
          "data-testid": "cancel-modal-confirm",
        }}
        isOpen={isCancelModalOpen}
        shouldCloseOnEsc
        data-testid="cancel-modal"
      />
    </DocumentPaper>
  );
}
