import { formatISO, parse } from "date-fns";

export interface DateRangeOption {
  name: string;
  value: "last6Months" | "lastYear";
}

export function formatChartDate(d: { month: number; year: number }): string {
  const date = parse(`${d.year}-${d.month.toString().padStart(2, "0")}-01`, "yyyy-MM-dd", new Date());

  return formatISO(date);
}

/**
 * Split a line into multiple lines in order to not interpolate gaps
 */
export function splitLine<T, TSelector>(items: T[], keySelector?: (item: T) => TSelector): SplitLine<T> {
  const lines: SplitLine<T> = [[]];

  let lastWasDefined = false;
  for (const item of items) {
    if (keySelector ? keySelector(item) : item) {
      lines[lines.length - 1].push(item);
      lastWasDefined = true;
    } else {
      if (lastWasDefined) {
        lines.push(lines[lines.length - 1]?.map(() => undefined) ?? []);
      }

      lines[lines.length - 1].push(undefined);
      lastWasDefined = false;
    }
  }

  if (!lastWasDefined) {
    return lines.slice(0, lines.length - 1);
  }

  return lines;
}

type SplitLine<TItemValue> = (TItemValue | undefined)[][];
