import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import type { DateRangeOption } from "modules/analytics/util";
import { QUERY_KEYS } from "query-keys";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { NonEmptyArray } from "types/utility-types";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();
  const { project } = useSessionUser();

  const dateRangeOptions = useMemo<NonEmptyArray<DateRangeOption>>(
    () => [
      {
        name: t("common.date-range.last-months", { count: 6 }),
        value: "last6Months",
      },
      {
        name: t("common.date-range.last-year"),
        value: "lastYear",
      },
    ],
    [t],
  );

  const [dateRange, setDateRange] = useState<DateRangeOption>(dateRangeOptions[0]);

  const {
    data: adoptionData,
    isLoading: isLoadingAdoptionData,
    isRefetching: isRefetchingAdoptionData,
    error: adoptionError,
  } = useQuery({
    queryKey: QUERY_KEYS.ANALYTICS_DETAILS_ADOPTION(projectId, dateRange.value),
    queryFn: () => api.getAnalyticsAdoptionRateDetailsDetailsV1("adoption", { dateRange: dateRange.value }),
    select: commonAPIDataSelector,
  });

  const {
    data: vacatData,
    isLoading: isLoadingVacantData,
    isRefetching: isRefetchingVacantData,
    error: vacantError,
  } = useQuery({
    queryKey: QUERY_KEYS.ANALYTICS_DETAILS_ADOPTION_VACANT(projectId, dateRange.value),
    queryFn: () => api.getAnalyticsAdoptionRateDetailsDetailsV1("vacant", { dateRange: dateRange.value }),
    select: commonAPIDataSelector,
    enabled: project.type === "addressBased",
  });

  const error = adoptionError || vacantError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const loading = isLoadingAdoptionData || isLoadingVacantData;
  if (loading) {
    return <FullSizeLoader withPadding />;
  }

  return props.children({
    adoption: adoptionData ?? [],
    vacant: vacatData ?? [],
    isRefetchingAdoptionData,
    isRefetchingVacantData,
    selectedDateRange: dateRange,
    dateRangeOptions,
    onDateRangeChange: setDateRange,
  });
}
