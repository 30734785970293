import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { CommunityGroupV2Dto, ConstraintListItemDto } from "api/types";
import iconAnnotationInfo from "assets/icons/annotation-info.svg";
import { AudiencePreview } from "components/AudienceSelector/AudiencePreview";
import { Button } from "components/Button/Button";
import { Checkbox } from "components/Checkbox/Checkbox";
import { Drawer } from "components/Drawer/Drawer";
import { FormField } from "components/Form/FormField";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { MultiSelect } from "components/MultiSelect/MultiSelect";
import { Notice } from "components/Notice/Notice";
import { flattenAudience, getIconByAudienceType, mapAudienceByType, orderAudienceTypes } from "helpers/audience";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useConfig } from "providers/ConfigProvider";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import type { CreateOrEditFormFields } from "./CreateOrEdit/Layout";

type AudienceType = ConstraintListItemDto["type"];

type GroupAudienceSelectorProps = {
  isEditMode: boolean;
  groupType: Omit<CommunityGroupV2Dto["type"], "realEstate">;
  onSave: ({
    audience,
    isRealEstateGroup,
    isAllResidentsAdded,
  }: {
    audience: ConstraintListItemDto[];
    isRealEstateGroup: boolean;
    isAllResidentsAdded: boolean;
  }) => void;
};

export function GroupAudienceSelector({ isEditMode, groupType, onSave }: GroupAudienceSelectorProps): React.ReactNode {
  const formMethods = useFormContext<CreateOrEditFormFields>();
  const defaultAudience = useWatch<CreateOrEditFormFields, "audience">({ name: "audience" });
  const defaultAudienceGroupByType = mapAudienceByType(defaultAudience);
  const defaultIsRealEstateGroup = Boolean(useWatch<CreateOrEditFormFields, "isRealEstate">({ name: "isRealEstate" }));

  const [currAudience, setCurrAudience] =
    useState<Record<AudienceType, ConstraintListItemDto[]>>(defaultAudienceGroupByType);
  const [isRealEstateGroup, setIsRealEstateGroup] = useState(defaultIsRealEstateGroup);
  const [isAllResidentsAdded, setIsAllResidentsAdded] = useState(false);

  const isRealEstateGroupAllowed = useConfig("allowRealEstateGroups");
  const { t } = useTranslation();
  const api = useApi();
  const projectId = useProjectId();
  const {
    data: constraints,
    isPending: isPendingConstraints,
    isError: isErrorConstraints,
  } = useQuery({
    queryKey: QUERY_KEYS.CONSTRAINTS(projectId),
    queryFn: () => api.getConstraintsV1(),
    select: commonAPIDataSelector,
  });
  const sessionUser = useSessionUser();

  const isAnyAudienceSelected = Object.values(currAudience).some((audience) => audience.length > 0);

  // Handle the validity of real estate group selection based on selected audience
  useEffect(() => {
    if (!isRealEstateGroupAllowed) {
      return;
    }

    if (!isAnyAudienceSelected && isRealEstateGroup && !isEditMode) {
      setIsRealEstateGroup(false);
    }
  }, [isRealEstateGroupAllowed, formMethods, isAnyAudienceSelected, isEditMode, isRealEstateGroup]);

  useEffect(() => {
    if (isRealEstateGroup && sessionUser.isSuperAdmin) {
      setIsAllResidentsAdded(true);
    }
  }, [isRealEstateGroup, sessionUser.isSuperAdmin]);

  const onChangeAudienceItems = (audienceType: AudienceType, items: ConstraintListItemDto[]) => {
    setCurrAudience((prevValue) => ({ ...prevValue, [audienceType]: items }));
  };

  const onClickClose = () => {
    setCurrAudience(defaultAudienceGroupByType);
  };

  const onClickSave = () => {
    onSave({
      audience: flattenAudience(currAudience),
      isRealEstateGroup: isRealEstateGroup,
      isAllResidentsAdded,
    });
  };

  const availableAudience = useMemo(() => mapAudienceByType(constraints?.items || []), [constraints?.items]);
  const availableAudienceTypes = Object.keys(availableAudience) as AudienceType[];
  const configTexts = {
    apartmentType: {
      title: t("model.constraint.address"),
      description: t("component.audience-selector.general-description", {
        audienceTarget: t("common.entity.asset").toLowerCase(),
        audienceEntity: t("model.constraint.type.apartment-type").toLowerCase(),
      }),
      placeholder: t("component.audience-selector.general-placeholder", {
        audienceEntity: t("model.constraint.type.apartment-type").toLowerCase(),
      }),
    },
    floor: {
      title: t("model.constraint.floor"),
      description: t("component.audience-selector.general-description", {
        audienceTarget: t("common.entity.asset").toLowerCase(),
        audienceEntity: t("model.constraint.type.floor").toLowerCase(),
      }),
      placeholder: t("component.audience-selector.general-placeholder", {
        audienceEntity: t("model.constraint.type.floor").toLowerCase(),
      }),
    },
    building: {
      title: t("model.constraint.building"),
      description: t("component.audience-selector.general-description", {
        audienceTarget: t("common.entity.asset").toLowerCase(),
        audienceEntity: t("model.constraint.type.building").toLowerCase(),
      }),
      placeholder: t("component.audience-selector.general-placeholder", {
        audienceEntity: t("model.constraint.type.building").toLowerCase(),
      }),
    },
    company: {
      title: t("model.constraint.company"),
      description: t("component.audience-selector.general-description", {
        audienceTarget: t("common.entity.asset").toLowerCase(),
        audienceEntity: t("model.constraint.type.company").toLowerCase(),
      }),
      placeholder: t("component.audience-selector.general-placeholder", {
        audienceEntity: t("model.constraint.type.company").toLowerCase(),
      }),
    },
  };

  // Hide audience selector if there are no constraints available (aka no address)
  if (!isPendingConstraints && !isErrorConstraints && constraints.items.length === 0) {
    return null;
  }

  const isAudienceError = isRealEstateGroup && isEditMode && !isAnyAudienceSelected;

  return (
    <Drawer.Root
      title={t("component.audience-selector.title")}
      // Show existing audience from parent form instead of temporary audience state
      triggerComponent={<AudiencePreview audience={defaultAudience} truncate />}
      onClose={onClickClose}
    >
      {isPendingConstraints && <FullSizeLoader />}
      {!isPendingConstraints && !isErrorConstraints && (
        <Drawer.Body>
          <div className="flex w-full flex-col gap-4">
            {isEditMode && !isAudienceError && (
              <Notice
                icon={iconAnnotationInfo}
                type="info"
                message={t("component.audience-selector.edit-warning.group")}
              />
            )}
            {isAudienceError && (
              <Notice
                icon={iconAnnotationInfo}
                type="error"
                message={t("component.audience-selector.edit-warning.real-estate-group")}
              />
            )}
            <div data-testid="audience-root" className="flex w-full flex-col gap-4">
              {/* Order audience sections by a specific ordering */}
              {orderAudienceTypes(availableAudienceTypes).map((audienceType) => {
                const icon = getIconByAudienceType(audienceType);

                return (
                  <div key={audienceType} data-testid="audience-item" className="flex w-full flex-col gap-1">
                    <div className="flex w-full items-center justify-start gap-1">
                      {icon && <Icon name={icon} size={16} />}
                      <h2 className="text-headline4">{configTexts[audienceType].title}</h2>
                    </div>
                    <p className="text-grey-700">{configTexts[audienceType].description}</p>
                    <MultiSelect<ConstraintListItemDto>
                      selected={currAudience[audienceType]}
                      placeholder={configTexts[audienceType].placeholder}
                      items={availableAudience[audienceType]}
                      keySelector={({ id }) => id}
                      renderOption={({ key }) => key}
                      onChange={(items) => onChangeAudienceItems(audienceType, items)}
                    />
                  </div>
                );
              })}
            </div>
            {isRealEstateGroupAllowed && groupType === "interest" && (
              <FormField
                label={t("page.community-groups.create-or-edit.type.real-estate")}
                tooltip={t("page.community-groups.create-or-edit.type.real-estate.tooltip")}
              >
                <CheckboxField
                  label={t("page.community-groups.create-or-edit.type.real-estate")}
                  value={isRealEstateGroup}
                  disabled={isEditMode || !isAnyAudienceSelected}
                  onChange={setIsRealEstateGroup}
                />
              </FormField>
            )}
            {!isEditMode && sessionUser.isSuperAdmin && (
              <CheckboxField
                label={t("page.community-groups.create-or-edit.constraint.add-all-residents")}
                value={isAllResidentsAdded}
                disabled={isRealEstateGroup}
                onChange={setIsAllResidentsAdded}
              />
            )}

            <div className="mt-6 flex w-full items-center justify-end gap-2">
              <Drawer.CloseTrigger>
                <Button styling="secondary" onClick={onClickClose}>
                  {t("common.action.cancel")}
                </Button>
              </Drawer.CloseTrigger>
              <Drawer.CloseTrigger>
                <Button
                  styling="primary"
                  onClick={onClickSave}
                  disabled={isEditMode && isRealEstateGroup && !isAnyAudienceSelected}
                >
                  {t("common.action.set")}
                </Button>
              </Drawer.CloseTrigger>
            </div>
          </div>
        </Drawer.Body>
      )}
    </Drawer.Root>
  );
}

interface CheckboxProps {
  label: string;
  value: boolean;
  disabled: boolean;
  onChange: (value: boolean) => void;
}

function CheckboxField({ label, value, disabled, onChange }: CheckboxProps): React.ReactNode {
  return (
    <label className="inline-flex items-center gap-x-2">
      <span className={twJoin("order-1", disabled ? "text-grey-400" : undefined)}>{label}</span>
      <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} {...{ disabled }} />
    </label>
  );
}
