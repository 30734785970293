import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { AdminTicketDetailsDto, AppTicketDto } from "api/types";
import { Button } from "components/Button/Button";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Gallery } from "components/Gallery/Gallery";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { t } from "i18next";
import { QUERY_KEYS } from "query-keys";

export function TicketContent({ ticket }: { ticket: AdminTicketDetailsDto | AppTicketDto }): React.ReactNode {
  const sessionUser = useSessionUser();
  const projectId = useProjectId();
  const api = useApi();
  const showFlashToast = useFlashToast();

  const [hideTranslation, hideTranslationHandler] = useBool(true);
  const translation = useQuery({
    queryKey: QUERY_KEYS.TICKETS_DETAILS_TRANSLATION(projectId, ticket.id, sessionUser.language.id),
    queryFn: () => api.getTicketsTranslationsDetailsV1(ticket.id, sessionUser.language.id),
    retry: false,
    enabled: false,
  });

  const hasTranslation = translation.data && !hideTranslation;
  const translateButton =
    ticket.languageIsoCode !== sessionUser.language.id ? (
      <span className="mt-2 block">
        <Button
          styling="ghostPrimary"
          isLoading={translation.isLoading}
          onClick={async () => {
            hideTranslationHandler.toggle();

            if (!translation.data) {
              try {
                await translation.refetch();
              } catch (error) {
                showFlashToast({ type: "error", title: t("page.tickets.details.activities.comment.translate.failed") });
              }
            }
          }}
        >
          {hasTranslation
            ? t("page.tickets.details.activities.comment.translate.original")
            : t("page.tickets.details.activities.comment.translate")}
        </Button>
      </span>
    ) : null;

  const content = hasTranslation ? translation.data.data.content : ticket.content;

  return (
    <div className="rounded-lg bg-white p-4">
      <div className="flex flex-col gap-2">
        <span data-testid="ticket-title" className="text-headline2">
          {hasTranslation ? translation.data.data.title : ticket.title}
        </span>
        {content && (
          <p data-testid="ticket-content">
            <LinkFormatter>{content}</LinkFormatter>
            {translateButton}
          </p>
        )}
        <Gallery images={ticket.images} />
      </div>
    </div>
  );
}
