import { infiniteQueryOptions, keepPreviousData, queryOptions } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { sumBy } from "lodash-es";
import { QUERY_KEYS } from "query-keys";

export const useQuickReplyTicketQueries = () => {
  const api = useApi();

  const limit = 20;

  return {
    list: (token: string) =>
      infiniteQueryOptions({
        queryKey: QUERY_KEYS.QUICK_REPLY_REPAIR_ACTIVITIES(token),
        queryFn: ({ pageParam }) =>
          api
            .getQuickReplyRepairRequestActivitiesV1(token, {
              Offset: pageParam,
              Limit: limit,
            })
            .then((x) => x.data),
        initialPageParam: 0,
        getNextPageParam: (latestResponse, allResponses) => {
          const newOffset = sumBy(allResponses, (page) => page.items.length);

          return latestResponse?.hasMore ? newOffset : undefined;
        },
      }),
    details: (token: string) =>
      queryOptions({
        queryKey: QUERY_KEYS.QUICK_REPLY_REPAIR_DETAILS(token),
        queryFn: () => api.getQuickReplyRepairRequestDetailsV1(token),
        placeholderData: keepPreviousData,
        select: commonAPIDataSelector,
      }),
    translationDetails: ({ token, languageId }: { token: string; languageId: string }) =>
      queryOptions({
        queryKey: [...QUERY_KEYS.QUICK_REPLY_REPAIR_DETAILS(token), "translation"],
        queryFn: () => api.getQuickReplyRepairRequestTranslationsDetailsV1(token, languageId),
        retry: false,
      }),
    responsibilitySuggestion: (token: string) =>
      queryOptions({
        queryKey: QUERY_KEYS.QUICK_REPLY_REPAIR_RESPONSIBILITY_SUGGESTION(token),
        queryFn: () => api.getQuickReplyRepairRequestResponsibilitySuggestionV1(token),
        select: commonAPIDataSelector,
        retry: false,
        staleTime: Infinity,
      }),
    denialReplySuggestion: (token: string, query?: { additionalInfo: string }) =>
      queryOptions({
        queryKey: QUERY_KEYS.QUICK_REPLY_REPAIR_DENIAL_REPLY_SUGGESTION(token),
        queryFn: () => api.postQuickReplyRepairRequestAiSuggestionV1(token, query),
        select: commonAPIDataSelector,
        staleTime: 30 * 60 * 1000,
      }),
  };
};
