import type { ReminderStatusDto, SimpleProjectDto, UserDetailsWithAddressInterestsAndHelpDto } from "api/types";
import iconEyeOff from "assets/icons/eye-off.svg";
import { Button } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Tooltip } from "components/Tooltip/Tooltip";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { usePermission } from "hooks/usePermission";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface UserDetailProps {
  user: UserDetailsWithAddressInterestsAndHelpDto;
  deletedBy?: UserDetailsWithAddressInterestsAndHelpDto;
  projects: SimpleProjectDto[];
  reminderStatus?: ReminderStatusDto;
  sendReminder: () => void;
}

export function UserDetails({
  user: {
    id: userId,
    avatar,
    deletedAt,
    fullName,
    locatedAt,
    addressTags,
    createdAt,
    registeredAt,
    email,
    phoneNumber,
    occupation,
    biography,
    interestGroups,
    helpCategories,
    isAdmin,
    deleteAfter,
    project,
    hideHouseNumber,
  },
  deletedBy,
  reminderStatus,
  sendReminder,
  projects,
}: UserDetailProps): React.ReactNode {
  const sessionUser = useSessionUser();
  const { t, i18n } = useTranslation();
  const [isInviteModalOpen, inviteModalHandler] = useBool(false);
  const hasPermission = usePermission();

  const invitesTooltip = useMemo(
    () =>
      reminderStatus?.reminders
        ? reminderStatus.reminders
            .map((reminder) =>
              t("page.user-detail.invite-user.tooltip", { date: formatDate(i18n, "date", reminder.lastReminded) }),
            )
            .join("\n")
        : undefined,
    [reminderStatus?.reminders, t, i18n],
  );

  const building = useMemo(() => addressTags.find((tag) => tag.type === "building")?.key, [addressTags]);

  function handleSendReminder() {
    sendReminder();
    inviteModalHandler.setFalse();
  }

  const showReminderButton =
    !registeredAt &&
    (sessionUser.isSuperAdmin || reminderStatus?.canBeReminded || reminderStatus?.recentlyInvited) &&
    ((isAdmin && hasPermission((x) => x.userManagement.canEditAdminInfo)) ||
      (!isAdmin && hasPermission((x) => x.userManagement.canEditResidentInfo)));

  return (
    <>
      <div className="flex w-full flex-col gap-5 rounded-lg bg-white px-5 py-10">
        <div className="size-24 self-center">
          <UserAvatar img={avatar} isUserDeleted={!!deletedAt} big />
        </div>
        <div className="flex flex-col items-center gap-0.5">
          <span data-testid="user-fullname" className="text-center text-body-bold">
            {fullName}
          </span>
          <div className="flex flex-wrap items-center gap-1">
            <span data-testid="user-address" className="line-clamp-2 text-center">
              {locatedAt}
            </span>
            {hideHouseNumber && !sessionUser.isAdmin && sessionUser.id === userId && (
              <Tooltip tooltip={t("page.user-detail.hide-house-number.tooltip")}>
                <Icon name={iconEyeOff} className="text-grey-500" />
              </Tooltip>
            )}
          </div>
          <span data-testid="user-building" className="text-center">
            {
              // eslint-disable-next-line no-nested-ternary
              building && project
                ? t("page.user-detail.building", { building: building, project: project.name })
                : // eslint-disable-next-line no-nested-ternary
                  project
                  ? project.name
                  : building
            }
          </span>
        </div>
        <div className="mb-5 flex flex-col items-center gap-0.5">
          {!!createdAt && (
            <span className="text-caption text-grey-500">
              {t("page.user-detail.created", { date: formatDate(i18n, "date", createdAt) })}
            </span>
          )}
          {!!registeredAt && (
            <span className="text-caption text-grey-500">
              {t("page.user-detail.registered", { date: formatDate(i18n, "date", registeredAt) })}
            </span>
          )}
          {!!deleteAfter && !deletedAt && (
            <span className="text-caption text-grey-500">
              {t("page.user-detail.deleted-after", { date: formatDate(i18n, "date", deleteAfter) })}
            </span>
          )}
          {showReminderButton && (
            <>
              <span className="text-caption text-grey-500">{t("page.user-detail.not-registered")}</span>
              <Button
                className="mx-auto mt-4"
                styling="primary"
                title={
                  reminderStatus?.recentlyInvited
                    ? `${invitesTooltip}\n\n${t("page.user-detail.invite-user.recently-invited")}`
                    : invitesTooltip
                }
                onClick={inviteModalHandler.setTrue}
                disabled={
                  !reminderStatus?.canBeReminded || (!sessionUser.isSuperAdmin && reminderStatus?.recentlyInvited)
                }
              >
                {t("page.user-detail.invite-user.button")}
              </Button>
            </>
          )}
          {!!deletedAt && (
            <span className="text-caption text-grey-500">
              {t("page.user-detail.deleted", { date: formatDate(i18n, "date", deletedAt) })}
              {deletedBy ? (
                <>
                  {" "}
                  {t("page.user-detail.deleted-by")}{" "}
                  <span className="underline hover:text-grey-700">
                    {deletedBy.id === userId ? (
                      t("page.user-detail.deleted-by-themselves")
                    ) : (
                      <UserNameLink user={deletedBy}>{deletedBy.fullName}</UserNameLink>
                    )}
                  </span>
                </>
              ) : null}
            </span>
          )}
        </div>
        {(!!email || !!phoneNumber) && (
          <div className="flex flex-col gap-0.5">
            <span className="mb-2 border-b-[0.5px] border-grey-100 pb-2 text-caption-bold">
              {t("page.user-detail.contact-details")}
            </span>
            <span className="text-caption">{email}</span>
            <span className="text-caption">{phoneNumber}</span>
          </div>
        )}
        {!!occupation && (
          <div className="flex flex-col gap-0.5">
            <span className="mb-2 border-b-[0.5px] border-grey-100 pb-2 text-caption-bold">
              {t("page.user-detail.occupation")}
            </span>
            <span className="text-caption">{occupation}</span>
          </div>
        )}
        {!!biography && (
          <div className="flex flex-col gap-0.5">
            <span className="mb-2 border-b-[0.5px] border-grey-100 pb-2 text-caption-bold">
              {t("page.user-detail.biography")}
            </span>
            <span className="text-caption">
              <LinkFormatter>{biography}</LinkFormatter>
            </span>
          </div>
        )}
        {interestGroups?.length > 0 && (
          <div className="flex flex-col gap-0.5">
            <span className="mb-2 border-b-[0.5px] border-grey-100 pb-2 text-caption-bold">
              {t("page.user-detail.groups")}
            </span>
            <ul>
              {interestGroups.map((item) => (
                <li key={item.id}>
                  <span key={item.id} className="text-caption">
                    {item.name}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
        {helpCategories?.length > 0 && (
          <div className="flex flex-col gap-0.5">
            <span className="mb-2 border-b-[0.5px] border-grey-100 pb-2 text-caption-bold">
              {t("page.user-detail.help-categories")}
            </span>
            <ul>
              {helpCategories.map((item) => (
                <li key={item.id}>
                  <span className="text-caption">{item.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
        {projects?.length > 0 && sessionUser.isSuperAdmin && (
          <div className="flex flex-col gap-0.5">
            <span className="mb-2 border-b-[0.5px] border-grey-100 pb-2 text-caption-bold">
              {t("page.user-detail.connected-projects")}
            </span>
            <ul>
              {projects.map((item) => (
                <li key={item.id}>
                  <span className="text-caption">{item.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <ConfirmModal
        id="invite-modal"
        isLoading={false}
        isOpen={isInviteModalOpen}
        onReject={inviteModalHandler.setFalse}
        onOpenChange={inviteModalHandler.set}
        onResolve={handleSendReminder}
        resolveBtnProps={{
          text: t("page.user-detail.invite-user-modal.confirm"),
        }}
        title={t("page.user-detail.invite-user-modal.title")}
        shouldCloseOnEsc
      >
        <div className="flex flex-col gap-2 text-grey-600">
          <span className="text-caption">
            {t("page.user-detail.invite-user-modal.reminder-num", { count: reminderStatus?.reminders.length })}
          </span>
          <span className="text-caption">
            {t("page.user-detail.invite-user-modal.last-reminder", {
              date: reminderStatus?.reminders.length
                ? formatDate(i18n, "date", reminderStatus.reminders[reminderStatus.reminders.length - 1].lastReminded)
                : "-",
            })}
          </span>
        </div>
      </ConfirmModal>
    </>
  );
}
