import * as Sentry from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { Api } from "api/types";
import { NotificationProvider, useNotification } from "components/Notification/NotificationProvider";
import { resolveOldLink } from "helpers/old-url-resolver";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useSlug } from "hooks/useSlug";
import { useConfig } from "providers/ConfigProvider";
import type { ChannelAuthorizationHandler } from "pusher-js";
import Pusher from "pusher-js";
import { QUERY_KEYS } from "query-keys";
import { useEffect } from "react";

// State managed outside component because PusherProvider can rerender and cause unwanted reconnects
let pusherState: { userEmail: string; pusher: Pusher } | undefined = undefined;

export function PusherProvider({ children }: { children: React.ReactNode }): React.ReactNode {
  const projectId = useProjectId();
  const showNotification = useNotification();
  const user = useSessionUser();
  const queryClient = useQueryClient();
  const api = useApi();
  const pusherDashboardAppKey = useConfig("pusherDashboardAppKey");
  const slug = useSlug({ optional: true });

  useEffect(() => {
    if (!pusherDashboardAppKey) {
      return;
    }

    if (!user.isAdmin) {
      return;
    }

    if (!user.onboardedAt) {
      return;
    }

    if (!slug) {
      return;
    }

    let pusher = pusherState?.pusher;
    if (!pusherState || pusherState.userEmail !== user.email) {
      if (pusherState) {
        // Email is different, means different profile so we should disconnect
        pusherState.pusher.disconnect();
      }

      pusher = new Pusher(pusherDashboardAppKey, { cluster: "eu" });

      pusherState = {
        userEmail: user.email,
        pusher: pusher,
      };
    }

    const channelName = `private-user-${user.id}`;

    pusher!.config.channelAuthorizer = createAuthorizer(api);

    const channel = pusher!.subscribe(channelName);
    channel.bind("common", (payload: unknown) => {
      if (typeof payload === "object" && payload != null && "Message" in payload) {
        const data = payload as MessagePayload;

        showNotification({
          content: data.Message,
          type: data.Type,
          link: data.ButtonUrl ? resolveOldLink(data.ButtonUrl, slug) : undefined,
        });

        void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ADMIN_NOTIFICATION_STATUS(projectId) });
      } else {
        Sentry.captureMessage(`Invalid payload received from pusher: ${JSON.stringify(payload)}`);
      }
    });

    return () => {
      pusherState?.pusher.unsubscribe(channelName);
    };
  }, [
    api,
    projectId,
    pusherDashboardAppKey,
    queryClient,
    showNotification,
    slug,
    user.email,
    user.id,
    user.isAdmin,
    user.onboardedAt,
  ]);

  return <NotificationProvider>{children}</NotificationProvider>;
}

interface MessagePayload {
  Message: string;
  ButtonUrl?: string;
  Type: "unknown" | "message" | "messageComment" | "groupMessage" | "ticket" | "ticketAssignee" | "ticketComment";
}

function createAuthorizer(api: Api<unknown>["api"]) {
  // Override authorizer because its different per channel
  // The api changes per project (X-ProjectId header)
  return async function channelAuthorizer(params, callback) {
    try {
      const { data } = await api.postNotificationsPusherAuthenticateV1({
        socket_id: params.socketId,
        channel_name: params.channelName,
      });

      if (!data.auth) {
        throw new Error("No auth returned from notification pusher endpoint");
      }

      callback(null, { auth: data.auth, ...data });
    } catch (error: unknown) {
      callback(error as any, { auth: "" });
    }
  } as ChannelAuthorizationHandler;
}
