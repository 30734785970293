import { twResolve } from "helpers/tw-resolve";
import { useResizeObserver } from "hooks/useResizeObserver";
import type React from "react";
import { useRef } from "react";

// Frame 'borrowed' from https://www.figma.com/file/yg6EM405BtnPX1qXgOSgVT/iPhone-12-Pro-Mockup
export function Phone({ className, children }: React.PropsWithChildren<{ className?: string }>): React.ReactNode {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const phoneRef = useRef<SVGSVGElement>(null);

  function resize() {
    if (contentRef.current && phoneRef.current) {
      // Scale the content of the phone to the size of the frame
      const phoneBoundingRect = phoneRef.current.getBoundingClientRect();
      const scaleX = phoneBoundingRect.width / contentRef.current.offsetWidth;
      const scaleY = phoneBoundingRect.height / contentRef.current.offsetHeight;
      contentRef.current.style.transform = `scale(${scaleX}, ${scaleY})`;

      // Show phone only after transform is done so there's no content jump
      phoneRef.current.style.visibility = "visible";
    }
  }

  useResizeObserver(containerRef, resize);

  return (
    <div className="!not-sr-only visible !font-sans all-initial">
      <div ref={containerRef} className={twResolve("relative select-none", className)}>
        <svg
          className="pointer-events-none invisible relative z-20"
          ref={phoneRef}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 765 1544"
        >
          {/* Frame */}
          <rect width={736} height={1527} x={15} y={9} fill="transparent" stroke="#232A38" strokeWidth={17} rx={116} />

          {/* Buttons */}
          <path fill="#232A38" d="M0 247a2 2 0 012-2h5v58H2a2 2 0 01-2-2v-54z" />
          <path fill="#232A38" d="M2 358a2 2 0 012-2h3v116H4a2 2 0 01-2-2V358z" />
          <path fill="#232A38" d="M2 505a2 2 0 012-2h3v116H4a2 2 0 01-2-2V505z" />
          <path fill="#232A38" d="M765 577a2 2 0 01-2 2h-3V395h3a2 2 0 012 2v180z" />

          {/* Background */}
          <path
            fill="#232A38"
            fillRule="evenodd"
            // d="M19 122C19 61.801 67.801 13 128 13h510c60.199 0 109 48.801 109 109v1301c0 60.2-48.801 109-109 109H128c-60.199 0-109-48.8-109-109V122zm24-1c0-46.392 37.608-84 84-84h63c5.523 0 10 4.477 10 10v9c0 19.882 16.118 36 36 36h293c19.882 0 36-16.118 36-36v-9c0-5.523 4.477-10 10-10h64c46.392 0 84 37.608 84 84v1302c0 46.39-37.608 84-84 84H127c-46.392 0-84-37.61-84-84V121z"
            d="M19 122C19 61.801 67.801 13 128 13H638C698.199 13 747 61.801 747 122V1423C747 1483.2 698.199 1532 638 1532H128C67.801 1532 19 1483.2 19 1423V122ZM43 121C43 74.608 80.608 37 127 37H212C217.523 37 222 41.477 222 47V56C222 75.882 238.118 92 258 92H507C526.882 92 543 75.882 543 56V47C543 41.477 547.477 37 553 37H639C685.392 37 723 74.608 723 121V1423C723 1469.39 685.392 1507 639 1507H127C80.608 1507 43 1469.39 43 1423V121Z"
          />

          {/* Speaker grill */}
          <rect width={105} height={14} x={341} y={50} fill="#4F5560" rx={7} />

          {/* Camera */}
          <circle cx={285} cy={57} r={7} fill="#4F5560" />
        </svg>
        <div className="absolute left-0 top-0 z-10 size-full">
          <div
            className="h-[606px] w-[288px] origin-top-left overflow-hidden rounded-[60px] p-[10px_15px_14px_16px]"
            ref={contentRef}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
