import type { FormTranslations } from "helpers/languages";

import { Layout } from "./Layout";
import { Loader } from "./Loader";

export function FolderCreateOrEdit(): React.ReactNode {
  return <Loader>{(data) => <Layout {...data} />}</Loader>;
}

export interface CreateOrEditFormFields {
  nameTranslations: FormTranslations;
  parentFolderId: string;
}
